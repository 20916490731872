import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpIcon from '@material-ui/icons/Help';
import classnames from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import Checkbox from 'src/components/widgets/checkbox/Checkbox';
import { FilterKeys, FullResult } from 'src/types/SearchResult';
import { BusinessSize, HEALTH_AND_SAFETY, INSURANCES, IWI_GROUPS, PASIFIKA_GROUPS, REGIONS, REGIONS_NO_NATIONWIDE } from 'src/util/constants';
import { getBusinessSizeFullName, getOwnershipTypes } from 'src/util/helper';
import styles from './Filter.module.scss';

type Type = Omit<FilterKeys, 'category'>;

type Props = {
    results?: FullResult[];
    onChange?: (selected: string[]) => void;
    containerClassName?: string;
    showOnly?: boolean;
    defaultValue?: string[];
    expanded?: boolean;
    onExpandClick?: () => void;
    type: Type;
    wrapLabel?: boolean;
    fullyExpanded?: boolean;
}
export default function CheckboxFilter(props: Props) {
    const { results, onChange, containerClassName, type, showOnly, defaultValue, expanded, onExpandClick, wrapLabel, fullyExpanded } = props;
    const [selected, setSelected] = useState<string[]>(defaultValue ?? []);

    const options = useMemo(() => {
        let opts: { label: string, value: string, tip?: string }[] = [];
        switch (type) {
            case 'region-based':
                opts = REGIONS_NO_NATIONWIDE.map(value => ({
                    label: `${value} (${results?.filter(r => r.regionBased === value).length ?? 0})`,
                    value,
                }));
                break;
            case 'region-operation':
                opts = REGIONS.map(value => ({
                    label: `${value} (${results?.filter(r => r.regions?.includes(value) || r.regions?.includes('Nationwide')).length ?? 0})`,
                    value,
                }));
                break;
            case 'ownership':
                const ownershipTypes = getOwnershipTypes();
                for (const value in ownershipTypes) {
                    opts.push({
                        label: `${ownershipTypes[value].value} (${results?.filter(r => r.ownershipType === value).length ?? 0})`,
                        tip: ownershipTypes[value].tip,
                        value,
                    })
                }
                break;
            case 'health-safety-insurance':
                opts = HEALTH_AND_SAFETY.filter(i => i !== 'None').map(value => ({
                    label: `${value} (${results?.filter(r => r.healthAndSafetyQualifications?.includes(value)).length ?? 0})`,
                    value,
                }));
                break;
            case 'professional-indenmnity':
                opts = INSURANCES.filter(i => i !== 'None').map(value => ({
                    label: `${value} (${results?.filter(r => r.professionalIndemnity?.includes(value)).length ?? 0})`,
                    value,
                }));
                break;
            case 'business-size':
                opts = BusinessSizeOptions.map(value => ({
                    label: `${getBusinessSizeFullName(value)} (${results?.filter(r => r.businessSize === value).length ?? 0})`,
                    value,
                }));
                break;
            case 'iwi-affiliation':
                opts = IWI_GROUPS.map(value => ({
                    label: `${value} (${results?.filter(r => r.iwiAffiliations?.includes(value)).length ?? 0})`,
                    value,
                }));
                break;
            case 'pasifika-affiliation':
                opts = PASIFIKA_GROUPS.map(value => ({
                    label: `${value} (${results?.filter(r => r.pasifikaAffiliations?.includes(value)).length ?? 0})`,
                    value,
                }));
                break;
            case 'public-liability-insurance':
                opts = INSURANCES.filter(i => i !== 'None').map(value => ({
                    label: `${value} (${results?.filter(r => r.publicLiabilityInsurance?.includes(value)).length ?? 0})`,
                    value,
                }));
                break;
            case 'sub-contract-insurance':
                opts = INSURANCES.map(value => ({
                    label: `${value} (${results?.filter(r => r.minimumLevelOfInsuranceForSubContract === value).length ?? 0})`,
                    value,
                }));
                break;
            case 'sub-contract-health-safety':
                opts = HEALTH_AND_SAFETY.map(value => ({
                    label: `${value} (${results?.filter(r => r.healthAndSafetyForSubContract?.includes(value)).length ?? 0})`,
                    value,
                }));
                break;
            default:
                break;
        }
        return opts;
    }, [results, type]);

    const onCheckChange = useCallback((value: string, checked: boolean) => {
        let _selected = [...selected];
        if (checked) {
            if (!selected.includes(value)) {
                _selected.push(value);
            }
        } else {
            _selected = _selected.filter(s => s !== value);
        }
        setSelected(_selected);
        onChange?.(_selected);
    }, [onChange, selected]);

    const title = useMemo(() => {
        let title = '';
        switch (type) {
            case 'region-based':
                title = 'Region based'
                break;
            case 'region-operation':
                title = 'Region of operation'
                break;
            case 'ownership':
                title = 'Verified ownership type';
                break;
            case 'health-safety-insurance':
                title = 'Health and safety qualification';
                break;
            case 'professional-indenmnity':
                title = 'Professional indemnity insurance';
                break;
            case 'business-size':
                title = 'Business size';
                break;
            case 'iwi-affiliation':
                title = 'Iwi affiliation';
                break;
            case 'pasifika-affiliation':
                title = 'Indigenous Pasifika affiliation';
                break;
            case 'public-liability-insurance':
                title = 'Public liability insurance';
                break;
            case 'sub-contract-insurance':
                title = 'Minimum level of insurance required for subcontractors';
                break;
            case 'sub-contract-health-safety':
                title = 'Minimum health and safety prequalifications required for subcontactors';
                break;
            default:
                break;
        }
        return title;
    }, [type]);

    const onOnlyClick = (value: string) => {
        const _selected = [value];
        setSelected(_selected);
        onChange?.(_selected);

    }

    const labelClass = classnames(styles.label, {
        [styles.label_wrap]: wrapLabel,
    });

    const contentClass = classnames(styles.content, {
        [styles.fully_expanded]: fullyExpanded,
    });

    return (
        <div className={containerClassName}>
            <Accordion
                TransitionProps={{ unmountOnExit: true }}
                className={styles.collapse}
                expanded={expanded}
            >
                <AccordionSummary
                    classes={{ root: styles.accordion_summary }}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() => onExpandClick?.()}
                >
                    <label className={styles.title}>{title}</label>
                </AccordionSummary>
                <AccordionDetails className={contentClass}>
                    {options.map(({ label, value, tip }) => (
                        <div key={value} className={styles.item_container}>
                            <Checkbox
                                containerClassname={styles.checkbox_container}
                                className={styles.checkbox_item_padding}
                                labelProps={{ className: styles.checkbox_item_padding }}
                                checked={selected.includes(value)}
                                label={<div className={labelClass}>{label}</div>}
                                onChange={event => onCheckChange(value, event.target.checked)}
                            />
                            {tip && (
                                <div className={classnames(styles.tooltip, styles.checkbox_item_padding)}>
                                    <HelpIcon color='disabled' className={styles.icon} />
                                    <p>{tip}</p>
                                </div>
                            )}
                            {showOnly && <div className={styles.hidden_button} onClick={() => onOnlyClick(value)}>ONLY</div>}
                        </div>
                    ))}
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

const BusinessSizeOptions = [
    BusinessSize.Small,
    BusinessSize.Medium,
    BusinessSize.Large
];