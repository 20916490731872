
export enum SystemRole {
    USER= "USER",
    SUPPLIER_VIEWER = "SUPPLIER_VIEWER",
    ADMIN= "ADMIN",
    SUPER_ADMIN = "SUPER_ADMIN",
    REGIONAL_MANAGER = "REGIONAL_MANAGER",
}

export enum AccountRole {
    USER = "USER",
    ADMIN = "ADMIN",
}

export enum UserStatus {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
    DELETED = "DELETED",
}

export default interface AmotaiUser {
    id: string;
    email: string;
    systemRole: SystemRole;

    account: number;
    accountRole: AccountRole
    jobTitle: string;
    mobileNumber: string;
    phoneNumber: string;
    createdAt: string;
    firstName: string;
    lastName: string;

    avatar:string;

    status: UserStatus;
    name: string;
}
