import React from "react";
import { Link, useHistory } from "react-router-dom";
import BlockContent from "@sanity/block-content-to-react";
import moment from "moment/moment";
import styles from "./BlogDetail.module.scss";
import PageContainer from "../../widgets/pageContainer/PageContainer";
import Section from "../../widgets/section/Section";
import useSelector from "../../../hooks/useSelector";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import useDispatch from "../../../hooks/useDispatch";
import { getPost } from "../../../redux/actions/posts";
import sanityImageBuilder from "../../../util/sanityImageBuilder";
import IframeBlock from "../../widgets/iframeBlock/IframeBlock";


const serializers = {
    types: {
        image: (props: any) => <img alt={props.node.alt} src={sanityImageBuilder(props.node.asset).url()!} />,
        iframeBlock: (props: any) => {
            return (
                <IframeBlock key={props.node._key} iframe={props.node} />
            );
        },
    }

};

export default function BlogDetail() {
    const history = useHistory();
    const dispatch = useDispatch();
    const location = history?.location?.pathname ?? "";
    const slug = (location.match(/\b\/(\S*)/) || [])[1];
    const blog = useSelector((state) => state.posts[slug]);

    useAsyncEffect(async () => {
        if (!blog) {
            await dispatch(getPost(slug));
        }
    });

    const renderBlog = () => (
        <div className={styles.right}>
            <div className={styles.header}>
                <div className={styles.header_content}>
                    <h3>{blog?.title}</h3>
                    <span>{`${moment(blog?.createdAt).format("D MMMM YYYY")}, by ${blog?.author}`}</span>
                </div>
            </div>

            <Section className={""}>
                <BlockContent blocks={blog?.content} serializers={serializers} />
            </Section>
        </div>
    );

    return (
        <PageContainer className={styles.blog_detail_container}>
            <div className={styles.left}>
                <Link to={"/blog"}>&lt;&lt; Back to blog</Link>
            </div>
            {blog && renderBlog()}
        </PageContainer>
    );
}
