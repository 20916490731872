import React, { useState } from "react";
import styles from "../TermsAndConditionsHome.module.scss";
import PageContainer from "../../../widgets/pageContainer/PageContainer";
import BlockContent from "@sanity/block-content-to-react";
import sanityImageBuilder from "../../../../util/sanityImageBuilder";
import useDispatch from "../../../../hooks/useDispatch";
import useSelector from "../../../../hooks/useSelector";
import Policy from "../../../../types/Policy";
import { useParams, useHistory } from "react-router-dom";
import useMountEffect from "../../../../hooks/useMountEffect";
import { getPolicy } from "../../../../redux/actions/policy";
import { showError } from "../../../../redux/actions/snackbars";
import Loading from "../../../widgets/loading/Loading";


const serializers = {
    types: {
        image: (props: any) => <img alt={props.node.alt} src={sanityImageBuilder(props.node.asset).url()!} />
    }
};


export default function PolicyPage() {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const { slug } = useParams();
    const page = useSelector<Policy>(state => {
        return state.policy[`/${slug}`]!
    });
    useMountEffect(async () => {
        try {
            if (!page) {
                const p = await dispatch(getPolicy(slug));
                if (!p){
                    history.push("/terms-and-conditions")
                }
            }
        } catch (e) {
            dispatch(showError(e.message));
        }
        setLoading(false);
    });

    return (
        <PageContainer>
            {loading ? <Loading /> : ( page &&
                <>
                    <h3>{page.title}</h3>
                    <div className={styles.policies}>
                        <BlockContent blocks={page.content} serializers={serializers} />
                    </div>
                </>
            )}

        </PageContainer>
    );

}