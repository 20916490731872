import { Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, FormControlLabel, FormControlLabelProps } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import classnames from 'classnames';
import React, { ReactNode } from "react";
import styles from "./Checkbox.module.scss";

type CheckboxProps = {
    label?: ReactNode;
    containerClassname?: string;
    labelProps?: Omit<FormControlLabelProps, "control" | "label">;
} & Omit<MuiCheckboxProps, "name" | "error">;

export const StyledCheckbox = withStyles({
    root: {
        fontFamily: "Poppins, sans serif",
        color: "#CDCDCD",
        "&$checked": {
            color: "#008997",
        },
    },
    checked: {},
})((props: MuiCheckboxProps) => <MuiCheckbox color={"default"} {...props} />);

export default function Checkbox(props: CheckboxProps) {
    const { label, labelProps, disabled, containerClassname, ...otherProps } = props;
    const className = classnames(styles.check_box, {
        [styles.unchecked]: !props.checked
    }, props.className);
    const labelClassName = classnames(styles.check_box_container, containerClassname);

    return (
        <FormControlLabel
            {...(labelProps || {})}
            className={labelClassName}
            control={<MuiCheckbox {...otherProps} className={className} />}
            label={label}
            disabled={disabled}
        />
    );
}