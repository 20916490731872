import React, { useState } from "react";
import styles from "./Blog.module.scss";
import PageContainer from "../../widgets/pageContainer/PageContainer";
import Section from "../../widgets/section/Section";
import BlogCard from "../../widgets/blogCard/BlogCard";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import useDispatch from "../../../hooks/useDispatch";
import { getPosts, searchPosts } from "../../../redux/actions/posts";
import Post from "../../../types/Post";
import Loading from "../../widgets/loading/Loading";
import Filter, { IFilterFields } from "../../widgets/filter/Filter";
import PageLoader from "../../widgets/pageLoader/PageLoader";

export default function Blog() {
    const dispatch = useDispatch();
    const [posts, setPosts] = useState<Post[]>([])
    const [loading, setLoading] = useState(true);
    const [filterFields, setFilterFields] = useState<IFilterFields>();

    useAsyncEffect(async () => {
        const mPosts = await dispatch(getPosts());
        setPosts(mPosts);
        setLoading(false);
    });

    useAsyncEffect(async () => {
        setLoading(true);
        let mPosts:Post[];
        if(!!filterFields) {
            mPosts = await dispatch(searchPosts(filterFields));
        } else {
            mPosts = await dispatch(getPosts());
        }
        setPosts(mPosts);
        setLoading(false);
    }, [dispatch, filterFields])

    if (loading) {
        return <PageLoader />
    }

    return (
        <PageContainer containerClassName={styles.blog}>
            <h2>Blog</h2>
            <Section contentClass={styles.blog_content}>
                <Filter setFilterFields={setFilterFields} filterFields={filterFields} />
                {/*<div className={styles.blog_filter} />*/}
                {loading && <div className={styles.loading_wrapper}><Loading /></div>}
                <div className={styles.blog_listings}>
                    {posts.map(post => <BlogCard key={post.id} post={post} />)}
                </div>
            </Section>
        </PageContainer>
    )
}

