import firebase from "firebase/app";
import "firebase/auth";
import React, { useCallback, useEffect, useState } from "react";
import useDispatch from "src/hooks/useDispatch";
import { getMyAccount, getMyUser } from "src/redux/actions/users";
import Logo from "../../../resources/images/svg/amotai-logo-white.svg";
import styles from "./NavBar.module.scss";

const DesktopButtons = React.lazy(() => import("./components/desktopButtons/DesktopButtons"));

const MobileNav = React.lazy(() => import("./components/mobileNav/MobileNav"));

type ToolbarProps = {}

export default function NavBar(props: ToolbarProps) {
    const dispatch = useDispatch();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 766);
    const onWindowResize = useCallback(() => {
        if (!isMobile && window.innerWidth < 766) {
            setIsMobile(true);
        } else if (isMobile && window.innerWidth >= 766) {
            setIsMobile(false);
        }
    }, [isMobile]);

    useEffect(() => {
        window.addEventListener("resize", onWindowResize);
        return () => {
            window.removeEventListener("resize", onWindowResize);
        };
    }, [onWindowResize]);

    const onNavItemPress = async () => {
        if (firebase.auth().currentUser) {
            const user = await dispatch(getMyUser());
            if (user?.account) {
                await dispatch(getMyAccount());
            }
        }
    }

    return (
        <div className={styles.navbar}>
            <div className={styles.inner}>
                <div className={styles.logo}><a href={"/suppliers"}><img src={Logo} alt={"Amotai"} /></a></div>
                {/* <Input noPadding */}
                {/*       inputClassname={styles.search_input} */}
                {/*       placeholder={"Search name, service or category"} /> */}
                <div className={styles.nav}>
                    {isMobile ? (
                        <MobileNav onNavItemPress={onNavItemPress} />
                    ) : (
                            <DesktopButtons onNavItemPress={onNavItemPress} />
                    )}
                </div>
            </div>
        </div>
    );
}
