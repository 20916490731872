import { BusinessContact } from "../supplier/Page8";
import { Category } from "../../../../types/Category";
import Address from "../../../../types/Address";

export type BuyerClientFormValues = {
    // page 1
    organisationType: string;

    // page 2
    saasPlan: number;
    iwiAffiliation: string;
    mandateAgency?: string;

    // page 3 - userDTO details
    firstName: string;
    lastName: string;
    jobTitle: string;
    phoneNumber: string;
    mobileNumber: string;
    avatar: string;
    email: string;
    password: string;

    // page 4 - accountDTO details
    name: string;
    legalStructure: string;
    category: Category | {};
    size: number; // buyerClientDTO
    postalAddress: string;
    physicalAddress: string;
    physicalAddressComponents: Address | null;
    regions: string[];
    regionBased: string;

    // page 5 - buyerClientDTO details
    annualTurnover: string;
    annualAddressableSpend: string;
    approxAnnualIndirectSpend: string;
    socialProcurementInitiatives: string;

    // page 6 - accountDTO details
    logo: string;
    description: string;

    // page 7 - buyerClientDTO details
    minimumLevelOfInsuranceForSubContract: string;
    healthAndSafetyForSubContract: string[];

    // page 8 - businessContactDTO details
    businessContacts: BusinessContact[];

    // page 9
    cardName: string;
    invoicePayment: boolean;

    // page 10
    termsAgreed: string;
};

export const BuyerClientInitialValues: BuyerClientFormValues = {
    // page 1
    organisationType: "",

    // page 2
    //@ts-ignore
    saasPlan: undefined,
    iwiAffiliation: "",

    // page 3 - userDTO details
    firstName: "",
    lastName: "",
    jobTitle: "",
    phoneNumber: "",
    mobileNumber: "",
    avatar: "",
    email: "",
    password: "",

    // page 4 - accountDTO details
    name: "",
    legalStructure: "",
    category: {},
    size: 0, // buyerClientDTO
    postalAddress: "",
    physicalAddress: "",
    physicalAddressComponents: null,
    regions: [],
    regionBased: "",

    // page 5 - buyerClientDTO details
    annualTurnover: "",
    annualAddressableSpend: "",
    approxAnnualIndirectSpend: "",
    socialProcurementInitiatives: "",

    // page 6 - accountDTO details
    logo: "",
    description: "",

    // page 7 - buyerClientDTO details
    minimumLevelOfInsuranceForSubContract: "",
    healthAndSafetyForSubContract: [],

    // page 8 - businessContactDTO details
    businessContacts: [{ name: "", title: "", email: "", phoneNumber: "", avatar: "" }],
    // page 9
    cardName: "",
    invoicePayment: false,

    // page 10
    termsAgreed: "",
};
