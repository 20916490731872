import React, { forwardRef, Ref, useState } from "react";
import styles from "../../../pages/account/routes/billing/Billing.module.scss";
import Button from "../../button/Button";
import Dialog, { DialogRef } from "../../dialog/Dialog";
import useForwardRef from "../../../../hooks/useForwardRef";
import useDispatch from "../../../../hooks/useDispatch";
import { removeCard } from "../../../../redux/actions/cards";
import { showError } from "../../../../redux/actions/snackbars";

type DeleteCardDialogProps = {
    onClose?: () => void;
    cardId: string | undefined;
    accountId: number | undefined;
};

function DeleteCardDialog(props: DeleteCardDialogProps, ref: Ref<DialogRef>) {
    const { accountId, cardId } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const dialogRef = useForwardRef<DialogRef>(ref);
    const dispatch = useDispatch();

    const onClose = () => {
        dialogRef.current?.hide();
        props.onClose?.();
    };

    const onConfirmClick = async () => {
        try {
            setLoading(true);
            await dispatch(removeCard(accountId!, cardId!));
            setLoading(false);
            onClose();
        } catch (e) {
            dispatch(showError(e.message));
            setLoading(false);
        }
    };

    return (
        <Dialog ref={dialogRef} disableBackdropClick={loading} title={"Delete card"}>
            <div className={styles.dialog_content}>Are you sure you would like to delete this card? This
                action
                cannot be undone.
            </div>
            <div className={styles.dialog_footer}>
                <Button onClick={onConfirmClick} loading={loading}>Delete</Button>
                <span />
                <Button plain disabled={loading} onClick={onClose}>Cancel</Button>

            </div>
        </Dialog>
    );
}

export default forwardRef(DeleteCardDialog);
