import { BusinessContact } from "./Page8";
import { BusinessReferenceType } from "./Page9";
import BusinessOwner from "../../../../types/BusinessOwner";
import OldSupplierAccount from "../../../../types/OldSupplierAccount";
import { Category, SubCategory, SubSubCategory } from "../../../../types/Category";
import Address from "../../../../types/Address";

export type SupplierFormValues = {
    // page 1 - userDTO details
    firstName: string;
    lastName: string;
    jobTitle: string;
    phoneNumber: string;
    mobileNumber: string;
    avatar: string;
    email: string;
    password: string;

    // page 2 - supplierDTO details
    tradingName: string;
    legalStructure: string;
    legalName: string;
    yearEstablished: string;
    nzCompaniesNumber: string;
    nzbn: string;
    postalAddress: string;
    physicalAddress: string;
    physicalAddressComponents: Address | null;
    regions: string[]; // is in accountDTO
    regionBased: string;

    // page 3 - accountDTO details
    // category: Category;
    mainCategories: Category[];
    subCategories: SubCategory[];
    subSubCategories: SubSubCategory[];

    // page 4 - supplierDTO details
    turnoverInLastFinancialYear: string;
    professionalIndemnity: string;
    publicLiabilityInsurance: string;
    otherInsurances: { name: string, value: string }[];
    disclosures: string;

    // page 5 - supplierDTO details
    owners: BusinessOwner[];

    // page 6 - supplierDTO details
    totalFTE: number;
    maoriEmployees: number;
    pasifikaEmployees: number;
    nonMaleEmployees: number;
    employeesUnderTwentyFour: number;
    pathwaysProvided: string;

    // page 7 - accountDTO details
    logo: string;
    description: string;
    website: string;
    facebookLink: string;
    instagramLink: string;
    twitterLink: string;
    linkedInLink: string;
    youtubeLink: string;
    pinterestLink: string;

    // page 8 - businessContactDTO details
    businessContacts: BusinessContact[];

    // page 9 - businessReferenceDTO details
    businessReferences: BusinessReferenceType[];

    // page 10 - supplierDTO details
    healthAndSafetyQualifications: string[];
    healthAndSafetyIncidences: string;

    // page 11
    termsAgreed: string;
    adOption: string;
};


export const SupplierInitialValues = (oldSupplierAccount?: OldSupplierAccount | null) => {
    const { businessContactDTO, ...account } = oldSupplierAccount || {} as OldSupplierAccount;
    let firstName = "";
    let lastName = "";

    if (businessContactDTO?.name) {
        const nameSplit = businessContactDTO.name.split(" ");
        [firstName] = nameSplit;
        lastName = nameSplit.slice(1).join(" ");
    }

    return {
        // page 1 - userDTO details
        firstName,
        lastName,
        jobTitle: businessContactDTO?.title || "",
        phoneNumber: "",
        mobileNumber: businessContactDTO?.phoneNumber || "",
        avatar: "",
        email: businessContactDTO?.email || "",
        password: "",

        // page 2 - supplierDTO details
        tradingName: account.name || "",
        legalStructure: account.legalStructure || "", // is in accountDTO
        legalName: account.legalName || "",
        yearEstablished: account.yearEstablished || "",
        nzCompaniesNumber: account.nzCompaniesNumber || "",
        nzbn: account.nzbn || "",
        postalAddress: account.postalAddress || "",
        physicalAddress: account.physicalAddress || "",
        physicalAddressComponents: null,
        regions: account.regions || [], // is in accountDTO
        regionBased: "",

        // page 3 - accountDTO details
        category: "",
        subCategories: [],
        subSubCategories: [],

        // page 4 - supplierDTO details
        turnoverInLastFinancialYear: account.turnoverInLastFinancialYear || "",
        professionalIndemnity: "",
        publicLiabilityInsurance: "",
        otherInsurance: [{ name: "", value: "" }],
        disclosures: account.disclosures || "",

        // page 5 - supplierDTO details
        owners: [{ name: "", genderIdentity: "", pctOwned: "", iwiAffiliations: [], pasifikaAffiliation: [] }],

        // page 6 - supplierDTO details
        totalFTE: account.totalFTE || null,
        maoriEmployees: account.maoriEmployees || null,
        pasifikaEmployees: account.pasifikaEmployees || null,
        nonMaleEmployees: account.nonMaleEmployees || null,
        employeesUnderTwentyFour: account.employeesUnderTwentyFour || null,
        pathwaysProvided: account.pathwaysProvided || "",

        // page 7 - accountDTO details
        logo: "",
        description: "",
        // supplierDTO
        website: account.website || "",
        facebookLink: "",
        instagramLink: "",
        twitterLink: "",
        linkedInLink: "",
        youtubeLink: "",
        pinterestLink: "",

        // page 8 - businessContactDTO details
        businessContacts: [{ avatar: "", name: "", title: "", email: "", phoneNumber: "" }],

        // page 9 - businessReferenceDTO details
        businessReferences: account.businessReferences || [{
            projectName: "",
            client: "",
            contactName: "",
            contactEmail: "",
            contactPhoneNumber: ""
        }],

        // page 10 - supplierDTO details
        healthAndSafetyQualifications: [],
        healthAndSafetyIncidences: account.healthAndSafetyIncidences || "",

        // page 11
        termsAgreed: "",
        adOption: ''
    };
};
