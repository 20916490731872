import classNames from "classnames";
import { FieldArray, FieldArrayRenderProps, FormikProps, useFormikContext } from "formik";
import React from "react";
import * as Yup from "yup";
import Button from "../../../widgets/button/Button";
import FormikFileUpload from "../../../widgets/formikFields/FormikFileUpload";
import FormikInput from "../../../widgets/formikFields/FormikInput";
import FormikNumberInput from "../../../widgets/formikFields/FormikNumberInput";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import styles from "../buyerClient/BuyerClientOnboarding.module.scss";
import { SupplierFormValues } from "./supplierValidation";

const BusinessContactValidation = Yup.object({
    name: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    email: Yup.string().email("Not a valid email address").required("Required"),
    phoneNumber: Yup.string().required("Required"),
    avatar: Yup.string()
})

const maxContacts = 5

export const BusinessContactValidationSchema = Yup.object({
    businessContacts: Yup.array().of(BusinessContactValidation).min(1, "At least one contact is required")
})

export type BusinessContact = {
    name: string;
    title: string;
    email: string;
    phoneNumber: string;
    avatar: string;
}

export default function Page8(props: any) {

    const { values }: FormikProps<SupplierFormValues> = useFormikContext()

    const renderBusinessContact = (contact: BusinessContact, index: number, arrayHelpers: FieldArrayRenderProps) => (
        <div className={styles.contact_row} key={index}>
            <div className={styles.contact_input_wrapper}>
                <label>Upload profile image for preferred contact</label>
                <FormikFileUpload
                    name={`businessContacts[${index}].avatar`}
                    id={`contactAvatar${index}`}
                    label={"Select a profile image to upload"}
                    labelClassName={styles.file_input} preview={contact.avatar}
                    accept='image/*'
                />
                <FormikInput name={`businessContacts[${index}].name`}
                    inputClassname={classNames(styles.half_input, styles.first)}
                    label={"Contact name"} />
                <FormikInput name={`businessContacts[${index}].title`}
                    inputClassname={styles.half_input}
                    label={"Job title"} />
                <FormikInput name={`businessContacts[${index}].email`}
                    inputClassname={classNames(styles.half_input, styles.first)}
                    label={"Email address"} />
                <FormikNumberInput name={`businessContacts[${index}].phoneNumber`}
                    phone
                    formatMask={"(###) ###-#####"}
                    inputClassname={styles.half_input}
                    label={"Mobile number"} />
            </div>
            <Button plainLink onClick={() => arrayHelpers.remove(index)}>- Remove</Button>
        </div>
    )

    const renderFields = () => (
        <>
            <FieldArray name={"businessContacts"}
                render={(arrayHelpers) => (
                    values?.businessContacts?.length > 0
                        ? (
                            <>
                                {values.businessContacts.map((contact: BusinessContact, index: number) => renderBusinessContact(contact, index, arrayHelpers))}
                                <div className={styles.add_button}>
                                    {
                                        values?.businessContacts?.length === maxContacts
                                            ? <p>{`A maximum of ${maxContacts} contacts can be entered`}</p>
                                            : (
                                                <Button plainLink
                                                    disabled={values?.businessContacts?.length >= maxContacts}
                                                    onClick={() => arrayHelpers.push({
                                                        name: "",
                                                        title: "",
                                                        email: "",
                                                        phoneNumber: ""
                                                    })}>+ Add new contact</Button>
                                            )}

                                </div>
                            </>
                        )
                        : (
                            <div className={styles.add_button}>
                                <Button plainLink disabled={values?.businessContacts?.length >= maxContacts}
                                    onClick={() => arrayHelpers.push({
                                        name: "",
                                        title: "",
                                        email: "",
                                        phoneNumber: ""
                                    })}>+ Add new contact</Button>
                            </div>
                        )
                )} />
        </>
    );

    const renderFormStep = (children: any) => (
        <FormStep validationSchema={BusinessContactValidationSchema}>
            <h5>Who can businesses get in touch with for work?</h5>
            <p>These contacts will be shared on your business profile so that buyers can contact you directly.</p>
            <div className={styles.inner_container}>
                <div className={classNames(styles.business_contact_form, styles.onboarding_form)}>
                    {children}
                </div>
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Business Profile Contacts"} />
                <p><strong>These contacts will be shared on your business profile so that buyers can contact you
                    directly.</strong></p>

                {renderFields()}
            </div>
        )
    }

    return renderFormStep(renderFields());
}
