import React, { Ref, useState } from "react";
import styles from "./DeleteAccountDialog.module.scss";
import Dialog, { DialogRef } from "../../dialog/Dialog";
import useForwardRef from "../../../../hooks/useForwardRef";
import Button from "../../button/Button";
import useSelector from "../../../../hooks/useSelector";
import AmotaiAccount from "../../../../types/AmotaiAccount";
import useDispatch from "../../../../hooks/useDispatch";
import { deleteAccount } from "../../../../redux/actions/users";
import { showError, showSuccess } from "../../../../redux/actions/snackbars";


function DeleteAccountDialog(props: any, ref: Ref<DialogRef>) {
    const dialogRef = useForwardRef<DialogRef>(ref);
    const account = useSelector<AmotaiAccount>(state => state.users.account!);
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const onClose = () => {
        dialogRef.current?.hide();
    };

    const onDelete = async () => {
        try {
            setSubmitting(true);
            await dispatch(deleteAccount(account.id));
            dispatch(showSuccess("Account successfully deleted"));
            setSubmitting(false);
        } catch (e) {
            dispatch(showError(e.message));
            setSubmitting(false);
        }
    }

    return (
        <Dialog ref={dialogRef} disableDismiss={submitting} className={styles.contact_card_container}
                title={"Delete account"}>
            <p>Are you sure you would like to delete your account? This can not be undone.</p>
            <div className={styles.button_container}>
                <Button loading={submitting} onClick={onDelete}>Delete account</Button>
                <Button disabled={submitting} plainLink onClick={onClose}>Cancel</Button>
            </div>
        </Dialog>
    )
}

export default React.forwardRef(DeleteAccountDialog);
