/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/07/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import MaterialTable, { MaterialTableProps } from "material-table";
import React from "react";
import styles from "./Table.module.scss";

export type TableProps<T extends object> = MaterialTableProps<T>;

export default function Table<T extends object = any>(props: TableProps<T>) {
    const { options, title, components, ...otherProps } = props;
    return (
        <MaterialTable {...otherProps}
                       components={{
                           ...components,
                           Container: (ContainerProps: any) => <div {...ContainerProps} className={styles.table} />,
                       }}
                       title={"title"}
                       options={{
                           toolbar: false,
                           showTitle: !!title,
                           draggable: false,
                           search: false,
                           pageSizeOptions: [5, 10, 25, 50, 100, 500],
                           paginationType: "stepped",
                           showFirstLastPageButtons: false,
                           maxBodyHeight: window.innerHeight * 0.7,
                           ...options,
                       }} />
    );
}
