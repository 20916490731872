import React from "react";
import styles from "./ReportHeader.module.scss";

type ReportHeaderProps = {
    title: string,
    subtitle: string
};

export default function ReportHeader(props: ReportHeaderProps) {
    const { title, subtitle } = props;

    return (
        <div className={styles.header}>
            <h5>{title}</h5>
            <p>{subtitle}</p>
        </div>
    );
}