import OldSupplierAccount from "../../../types/OldSupplierAccount";
import get from "./util/get";

function getOldSupplier(id: number): Promise<OldSupplierAccount> {
    return get(`/v1/suppliers/${id}`);
}

const OldSupplierAccountAPI = {
    getOldSupplier
};

export default OldSupplierAccountAPI;
