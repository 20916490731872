import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "../business-details/BusinessDetails.module.scss";
import useSelector from "../../../../../hooks/useSelector";
import { AccountType } from "../../../../../types/AmotaiAccount";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useDispatch from "../../../../../hooks/useDispatch";
import { getMyAccount } from "../../../../../redux/actions/users";
import { showError } from "../../../../../redux/actions/snackbars";
import Loading from "../../../../widgets/loading/Loading";
import AmotaiUser from "../../../../../types/AmotaiUser";
import BuyerBusinessProfile from "./buyer-client/BuyerBusinessProfile";
import SupplierBusinessProfile from "./supplier/SupplierBusinessProfile";
import BusinessContact from "../../../../../types/BusinessContact";
import { getBusinessContacts } from "../../../../../redux/actions/businessContacts";
import BuyerClient from "../../../../../types/BuyerClient";
import Supplier from "../../../../../types/Supplier";
import { getAccountSubscription } from "../../../../../redux/actions/saaSPlans";

export default function BusinessProfile() {
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const me = useSelector<AmotaiUser>(state => state.users.me!);
    const account = useSelector<Supplier | BuyerClient>(state => state.users.account!);
    const businessContacts = useSelector<BusinessContact[]>(state => state.businessContacts.businessContacts);

    useMountEffect(async () => {
        try {
            const acc = await dispatch(getMyAccount());
            await dispatch(getBusinessContacts(acc.id));
            await dispatch(getAccountSubscription(acc?.id));
        } catch (e) {
            dispatch(showError(e.message));
            history.push("/");
        }
        setLoading(false);
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <div className={styles.business_details}>

                {account.type === AccountType.BUYER_CLIENT && (
                    <BuyerBusinessProfile account={account} businessContacts={businessContacts} me={me} />
                )}

                {account.type === AccountType.SUPPLIER && (
                    <SupplierBusinessProfile account={account as Supplier} businessContacts={businessContacts} me={me} />
                )}

            </div>
        </>
    );
}

