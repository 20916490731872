import firebase from "firebase/app";
import "firebase/auth";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { GA4ContextProvider } from "src/hooks/GA4Context";
import useDispatch from "src/hooks/useDispatch";
import useSelector from "src/hooks/useSelector";
import { getMyAccount, getMyUser, logoutUser } from "src/redux/actions/users";
import { isAccountDisabled } from "src/util/helper";
import { showError } from "../redux/actions/snackbars";
import { SystemRole } from "../types/AmotaiUser";
import Account from "./pages/account/Account";
import BlogDetail from "./pages/blog-detail/BlogDetail";
import Blog from "./pages/blog/Blog";
import DirectoryDetail from "./pages/directory-detail/DirectoryDetail";
import ResultsView from "./pages/directory/ResultsView";
import SearchView from "./pages/directory/SearchView";
import EventDetail from "./pages/event-detail/EventDetail";
import Events from "./pages/events/Events";
import ForgotPassword from "./pages/forgot-password/ForgotPassword";
import LogIn from "./pages/log-in/LogIn";
import BuyerClientOnboarding from "./pages/onboarding/BuyerClientOnboarding";
import FunderOnboarding from "./pages/onboarding/FunderOnboarding";
import IwiOnboarding from "./pages/onboarding/IwiOnboarding";
import SupplierOnboarding from "./pages/onboarding/SupplierOnboarding";
import PasswordReset from "./pages/password-reset/PasswordReset";
import Reporting from "./pages/reporting/Reporting";
import ResourceDetail from "./pages/resource-detail/ResourceDetail";
import Resources from "./pages/resources/Resources";
import SignUp from "./pages/sign-up/SignUp";
import PolicyPage from "./pages/termsAndConditionsHome/policy/PolicyPage";
import TermsAndConditionsHome from "./pages/termsAndConditionsHome/TermsAndConditionsHome";
import UserInvited from "./pages/user-invited/UserInvited";
import EventBanner from "./widgets/banner/EventBanner";
import Footer from "./widgets/footer/Footer";
import NavBar from "./widgets/navBar/NavBar";
import PageLoader from "./widgets/pageLoader/PageLoader";
import Receipt from "./widgets/receipt/Receipt";

ReactGA.initialize("UA-169226902-1");

function Main() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const account = useSelector((state) => state.users.account);
    const me = useSelector(state => state.users.me);
    const isSystemAdmin = useSelector(state => state.users.isSystemAdmin);
    const isRegionalManager = useSelector(state => state.users.isRegionalManager);
    const isSupplierViewer = useSelector(state => state.users.isSupplierViewer);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const onAuthStateChanged = async (firebaseUser: firebase.User | null) => {
            if (firebaseUser) {
                setIsLoggedIn(true)
                try {
                    const user = await dispatch(getMyUser());
                    if (user?.account) {
                        await dispatch(getMyAccount());
                        history.push("/suppliers");
                    }

                    if (user?.account && location.pathname === "/log-in") {
                        history.push("/suppliers");
                    }

                    if ((isSystemAdmin || isRegionalManager) && location.pathname === "/log-in") {
                        history.push("/suppliers");
                    }

                    if ((me ?? user)?.systemRole === SystemRole.SUPPLIER_VIEWER && location.pathname === "/log-in") {
                        history.push("/suppliers");
                    }
                } catch (e) {
                    if (e.status === 401 || e.status === 403) {
                        await firebase.auth().signOut();
                        history.push("/log-in");
                        dispatch(logoutUser());
                        dispatch(showError('User is disabled, please contact admin.'));
                    } else {
                        dispatch(showError(e.message));
                    }
                }
            }

            setLoading(false);
        };
        console.log('Application started')
        firebase.auth().onAuthStateChanged(onAuthStateChanged);
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (me && isSystemAdmin) {
            setIsLoggedIn(true);
        }

        if (me && isRegionalManager) {
            setIsLoggedIn(true);
        }

        if (me && isSupplierViewer) {
            setIsLoggedIn(true);
        }

        if (me && account) {
            setIsLoggedIn(true);
        }

        if (!me) {
            setIsLoggedIn(false);
        }

    }, [me, account, isSystemAdmin, isRegionalManager, isSupplierViewer]);

    useEffect(() => {

        ReactGA.pageview(location.pathname + location.search);
    }, [location]);

    const renderAuthedRoutes = () => {
        return (
            <Switch>
                {(!isSystemAdmin && !isRegionalManager) && <Route path={"/account"} component={Account} />}
                {(!isAccountDisabled(account) || isSystemAdmin || isRegionalManager) && <>
                    <Route exact path={["/suppliers", "/buyer-clients"]} component={SearchView} />
                    <Route exact path={["/suppliers/results", "/buyer-clients/results"]} component={ResultsView} />
                    <Route exact path={["/suppliers/details/:id", "/buyer-clients/details/:id"]} component={DirectoryDetail} />
                    <Route path={"/password-reset"} component={PasswordReset} />
                    <Route path={"/resource-detail/:id"} component={ResourceDetail} />
                    <Route path={"/blog/:slug"} component={BlogDetail} />
                    <Route exact path={"/blog"} component={Blog} />
                    <Route path={"/events/:slug"} component={EventDetail} />
                    <Route exact path={"/events"} component={Events} />
                    <Route exact path={"/resources"} component={Resources} />
                    {account?.organisationType === "iwi" && <Route exact path={["/iwi/reporting", "/iwi/reporting/*"]} component={Reporting} />}
                </>}

                {firebase.auth().currentUser?.getIdToken && me?.account &&
                    <Redirect from={"*"} to={"/account/my-profile"} />}
            </Switch>
        )
    }

    const directoryRoute = location.pathname === "/sign-up" ||
        location.pathname.includes("/onboarding") ||
        location.pathname === "/log-in" ||
        location.pathname === "/forgot-password" ||
        location.pathname === "/password-reset" ||
        location.pathname === "/user-invited" ||
        location.pathname === "/receipt";

    return (
        <>
            {!directoryRoute && <NavBar />}
            {isLoggedIn && <EventBanner />}
            <Switch>
                {loading && <Route path={"*"} component={PageLoader} />}
                <Route path={"/log-in"} component={LogIn} />
                <Route path={"/receipt"} component={Receipt} />
                <Route path={"/forgot-password"} component={ForgotPassword} />
                <Route path={"/user-invited"} component={UserInvited} />
                <Route path={"/terms-and-conditions/:slug"} component={PolicyPage} />
                <Route exact path={"/terms-and-conditions"} component={TermsAndConditionsHome} />
                <Route path={"/sign-up"}>
                    {isLoggedIn ? <Redirect to={"/account/my-profile"} /> : <SignUp />}
                </Route>
                <Route exact path={"/onboarding/buyers"}>
                    {isLoggedIn ? <Redirect to={"/account/my-profile"} /> : <BuyerClientOnboarding />}
                </Route>
                <Route exact path={"/onboarding/funders"}>
                    {isLoggedIn ? <Redirect to={"/account/my-profile"} /> : <FunderOnboarding />}
                </Route>
                <Route exact path={["/onboarding/suppliers", "/onboarding/suppliers/:id"]}>
                    {isLoggedIn ? <Redirect to={"/account/my-profile"} /> : <SupplierOnboarding />}
                </Route>
                <Route exact path={"/onboarding/iwi"}>
                    {isLoggedIn ? <Redirect to={"/account/my-profile"} /> : <IwiOnboarding />}
                </Route>
                {isLoggedIn && renderAuthedRoutes()}
                {!isLoggedIn && <Redirect from={"*"} to={"/log-in"} />}
            </Switch>
            {!directoryRoute && <Footer />}
        </>
    );

}

export default function () {
    return (
        <Router>
            <ScrollToTop />
            <GA4ContextProvider>
                <Main />
            </GA4ContextProvider>
        </Router>
    );
}

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}