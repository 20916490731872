import React, { forwardRef, Ref, useEffect, useRef, useState } from "react";
import { Formik, Field } from "formik";
import {
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import {
    Token,
    StripeError,
} from "@stripe/stripe-js";
import * as Yup from "yup";
import styles from "../../dialog/Dialog.module.scss";
import Button from "../../button/Button";
import Dialog, { DialogRef } from "../../dialog/Dialog";
import FormikInput from "../../formikFields/FormikInput";
import useForwardRef from "../../../../hooks/useForwardRef";
import StripeInput, { StripeInputRef } from "../../stripeInput/StripeInput";
import { CheckboxGroupItem } from "../../formikFields/FormikCheckboxGroup";
import { showError } from "../../../../redux/actions/snackbars";
import useDispatch from "../../../../hooks/useDispatch";

type CreateCardDialogProps = {
    error?: string;
    onClose: () => void;
    onSubmit: (token: {
        token?: Token | undefined,
        error?: StripeError | undefined
    } | undefined, values: CreateCardValues) => void;
};

export type CreateCardValues = { cardName: string, isDefault: string[] };

const CreateCardValidation = Yup.object({
    cardName: Yup.string()
        .trim()
        .required("Required")
});

function CreateCardDialog(props: CreateCardDialogProps, ref: Ref<DialogRef>) {
    const [loading, setLoading] = useState<boolean>(true);
    const cardElementRef = useRef<StripeInputRef>(null);
    const expiryElementRef = useRef<StripeInputRef>(null);
    const cvcElementRef = useRef<StripeInputRef>(null);
    const dialogRef = useForwardRef<DialogRef>(ref);
    const { error } = props;
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();

    useEffect(() => {
        if (stripe && elements) {
            setLoading(false);
        }
    }, [stripe, elements]);

    const validateStripeInput = () => {
        let valid = true;

        if (!cardElementRef.current?.touched()) {
            valid = false;
            cardElementRef.current?.setElementError("Required");
        }

        if (!expiryElementRef.current?.touched()) {
            valid = false;
            expiryElementRef.current?.setElementError("Required");
        }

        if (!cvcElementRef.current?.touched()) {
            valid = false;
            cvcElementRef.current?.setElementError("Required");
        }

        if (cardElementRef.current?.hasErrors() || expiryElementRef.current?.hasErrors() || cvcElementRef.current?.hasErrors()) {
            valid = false;
        }

        return valid;
    };

    const onSubmit = async (values: CreateCardValues) => {
        if (!validateStripeInput()) {
            return;
        }

        try {
            setLoading(true);
            const cardElement = elements?.getElement("cardNumber");

            if (!cardElement) {
                throw new Error("Error creating card");
            }

            const token = await stripe?.createToken(cardElement, { name: values.cardName });

            if (!token) {
                throw new Error("Error creating card");
            }

            if (token.error) {
                throw new Error(token.error.message);
            }

            setLoading(false);
            props.onSubmit(token, values);
        } catch (e) {
            dispatch(showError(e.message));
            setLoading(false);
        }
    };

    const onClose = () => {
        dialogRef.current?.hide();
        props.onClose();
    };

    return (
        <Dialog ref={dialogRef} onClose={onClose} disableBackdropClick={loading} className={styles.dialog} title={"Add new card"} style={{maxWidth:"unset"}}>
                <Formik<CreateCardValues> initialValues={{ cardName: "", isDefault: [] }}
                                          validationSchema={CreateCardValidation}
                                          onSubmit={onSubmit}>
                    {(formikBag) => (
                        <form onSubmit={formikBag.handleSubmit}>
                            <FormikInput name={"cardName"} label={"Cardholder name"} />
                            <StripeInput ref={cardElementRef} label={"Card number"} />
                            <StripeInput ref={expiryElementRef} label={"Expiry date"} type={"expiry"} />
                            <StripeInput ref={cvcElementRef} label={"Cvc"} type={"cvc"} />
                            <Field name={"isDefault"} component={CheckboxGroupItem} single label={"Default card"}
                                   value={"isDefault"} />
                            {!!error && <span className={styles.form_error}>{error}</span>}
                            <div className={styles.card_form_footer}>
                                <Button type={"submit"} loading={loading}>Add new card</Button>
                                <Button
                                        plainLink
                                        onClick={onClose}
                                        type={"button"}
                                        disabled={loading}>
                                    Cancel
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
        </Dialog>
    );
}

export default forwardRef(CreateCardDialog);
