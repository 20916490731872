import React from "react";
import useDispatch from "src/hooks/useDispatch";
import useSelector from "src/hooks/useSelector";
import { updateMyOpts } from "src/redux/actions/users";
import Button from "../button/Button";
import styles from './Banner.module.scss';

const dismiss_key = 'dismiss_feature_key';
export default function NewFeatureBanner() {
    const show = useSelector(state => state.users.options?.[dismiss_key]) !== 'hide';
    const dispatch = useDispatch();
    const dismiss = () => {
        dispatch(updateMyOpts({ name: dismiss_key, value: 'hide' }));
    }

    if (!show) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <span>Kia ora, welcome to our upgraded search directory. </span>
                <a
                    href={`${process.env.AMOTAI_SITE_URL}/how-to-video`}
                    target='_blank'
                    rel="noopener noreferrer"
                >
                    Click here
                </a>
                <span> to see the improvements, and the &#39;how to use&#39; video guide.</span>
            </div>
            <Button plain className={styles.dismiss} onClick={dismiss}>Dismiss</Button>
        </div>
    )
}