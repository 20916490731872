import React from "react";
import { Link } from "react-router-dom";
import styles from "./TermsAndConditionsHome.module.scss";
import PageContainer from "../../widgets/pageContainer/PageContainer";

export default function TermsAndConditionsHome() {

    return (
        <PageContainer>
            <h2>Terms and Conditions</h2>
            <h6>The following Terms and Conditions apply to all members and registered suppliers on application and at
                all times during the period you remain a member or registered supplier of Amotai. Please take the time
                to read and understand these Terms and Conditions.
            </h6>
            <div className={styles.policies}>
                <Link to={"/terms-and-conditions/terms-of-use"}>Amotai Website Terms of Use</Link><br/><br/>
                <Link to={"/terms-and-conditions/privacy-policy"}>Amotai Privacy Policy</Link><br/><br/>
                <Link to={"/terms-and-conditions/members-terms-and-conditions"}>Amotai Members Terms and Conditions</Link><br/><br/>
                <Link to={"/terms-and-conditions/registered-suppliers-terms-and-conditions"}>Amotai Registered Suppliers Terms and
                    Conditions</Link><br/><br/>
                <Link to={"/terms-and-conditions/member-supplier-code-of-conduct"}>Amotai Member and Supplier Code of Conduct</Link><br/><br/>
                <Link to={"/terms-and-conditions/events-terms-and-conditions"}>Amotai Events Terms and Conditions</Link><br/><br/>
                <Link to={"/terms-and-conditions/complaint form"}>Amotai Complaint form</Link><br/><br/>

            </div>
        </PageContainer>
    );

}