/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 18/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import instance from "./instance";

export default function post(url: string, data?: any, params?: {}): Promise<any> {
    return instance
        .post(url, data, {
            params,
        })
        .then((data) => data.data);
}
