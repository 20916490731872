import React, { ReactNode } from "react";
import styles from "./AccountHeader.module.scss";

type Props = {
    title: string;
    subtitle?: string;
    action?: ReactNode;
}
export default function AccountHeader(props: Props) {
    const { title, subtitle, action } = props;

    return (
        <div className={styles.header}>
            <div className={styles.content}>
                <h3>{title}</h3>
                <span className={styles.subtitle}>{subtitle}</span>
            </div>
            {action}
        </div>
    );
}