import classNames from "classnames";
import { useFormikContext } from "formik";
import React from "react";
import ReactTooltip from "react-tooltip";
import FormikAddressInput from "src/components/widgets/formikFields/FormikAddressInput";
import * as Yup from "yup";
import useDispatch from "../../../../hooks/useDispatch";
import useMountEffect from "../../../../hooks/useMountEffect";
import useSelector from "../../../../hooks/useSelector";
import { getMainCategories } from "../../../../redux/actions/categories";
import Tooltip from "../../../../resources/images/svg/tooltip-icon.svg";
import { Category } from "../../../../types/Category";
import { LEGAL_STRUCTURE, NATIONWIDE_REGION, REGIONS, REGIONS_NO_NATIONWIDE } from "../../../../util/constants";
import FormikCheckboxGroup from "../../../widgets/formikFields/FormikCheckboxGroup";
import FormikInput from "../../../widgets/formikFields/FormikInput";
import FormikNumberInput from "../../../widgets/formikFields/FormikNumberInput";
import FormikSelect from "../../../widgets/formikFields/FormikSelect";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import styles from "./FunderOnboarding.module.scss";
import { FunderFormValues } from "./funderValidation";

export const FunderOrgDetailsValidationSchema = Yup.object({
    name: Yup.string().required("Required"),
    legalStructure: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    size: Yup.number().required("Required"),
    physicalAddressComponents: Yup.string().required("Required"),
    regions: Yup.array().of(Yup.string()).min(1, "At least one region required"),
    regionBased: Yup.string().required("Required")
});

export default function Page4(props: any) {
    const dispatch = useDispatch();
    const categories = useSelector<Category[]>((state) => state.categories.main);

    useMountEffect(async () => {
        if (!categories || !categories.length) {
            await dispatch(getMainCategories());
        }
    });

    const { values } = useFormikContext<FunderFormValues>();

    const renderFields = () => (
        <>
            <FormikInput name={"name"}
                inputClassname={classNames(styles.half_input, styles.first)}
                placeholder={"e.g. Acme Limited"}
                label={"Organisation name"} />
            <FormikSelect name={"legalStructure"}
                containerClassName={styles.half_input}
                label={"Structure"}
                options={LEGAL_STRUCTURE} />
            <FormikSelect name={"category"}
                label={"Primary Industry"}
                tooltip={"Select all relevant categories that you work in so that suppliers are able to understand the services/works/goods you are looking for"}
                options={categories.map((cat) => ({ name: cat.name, value: cat }))} />
            <FormikNumberInput name={"size"}
                allowNegative={false}
                label={"Size of workforce (Total number of Employees)"} />
            <FormikInput name={"postalAddress"}
                label={"Postal Address"}
                placeholder={"1 Example St, Auckland, 0610, New Zealand"}
                inputClassname={styles.full_input} />
            <FormikAddressInput name={"physicalAddressComponents"}
                formattedName={"physicalAddressInput"}
                label={"Physical Address"}
                placeholder={"1 Example St, Auckland, 0610, New Zealand"}
                inputClassname={styles.full_input} />
            <SectionHeader title={"Regions"} />
            <div className={styles.checkbox_container}>
                <div className={styles.heading}>
                    <label>Regions of operation</label>
                    <img src={Tooltip} alt={"Help"} data-tip={"Select all the relevant regions you operate in"} />
                </div>
                {values.regions.includes(NATIONWIDE_REGION) ?
                    <FormikCheckboxGroup name={"regions"} stringItems={[NATIONWIDE_REGION]} /> :
                    <FormikCheckboxGroup name={"regions"} stringItems={REGIONS} />}
            </div>

            <div className={styles.checkbox_container}>
                <div className={styles.heading}>
                    <label>Region based</label>
                    <img src={Tooltip} alt={"Help"} data-tip={"Select the region your company is based in"} />
                </div>
                <FormikSelect name={"regionBased"} options={REGIONS_NO_NATIONWIDE} />
            </div>
            <ReactTooltip
                place={"top"}
                effect={"solid"}
                multiline
                backgroundColor={"#F1F1F2"}
                borderColor={"#E6E7E8"}
                textColor={"#000000"}
                clickable
            />
        </>
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Your Organisation’s Details</h5>
            <div className={styles.inner_container}>
                <div className={styles.onboarding_form}>
                    {children}
                </div>
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Business Details"} />
                {renderFields()}
            </div>
        )
    }

    return renderFormStep(renderFields());
}