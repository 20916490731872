import { MenuItem } from '@material-ui/core';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Input, { InputRef } from 'src/components/widgets/input/Input';
import Loading from 'src/components/widgets/loading/Loading';
import ScrollerContainer from 'src/components/widgets/scroller/ScrollerContainer';
import useGA4Context from 'src/hooks/GA4Context';
import useDispatch from 'src/hooks/useDispatch';
import DirectoryAPI from 'src/redux/actions/api/directory';
import { showError } from 'src/redux/actions/snackbars';
import { AccountType } from 'src/types/AmotaiAccount';
import { Category } from 'src/types/Category';
import { HitType, SearchSuggestion } from 'src/types/SearchResult';
import { getHitTypeOrder } from 'src/util/helper';
import { ResultsViewRouteParams } from '../ResultsView';
import styles from './SearchBar.module.scss';

type Props = {
    accountType: AccountType,
    top3categories: Category[] | null,
    onTop3CategoryClicked?: (id: number, hitType: HitType, name: string) => void,
    onBrowseAllClicked?: () => void,
}

export default function SearchBar(props: Props) {
    const { accountType, top3categories, onTop3CategoryClicked, onBrowseAllClicked } = props;
    const [popoverDim, setPopoverDim] = useState<{ width?: number, maxHeight: number }>({ maxHeight: 500 });
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [lowercaseKeyword, setLowercaseKeyword] = useState<string>();
    const divRef = useRef<HTMLDivElement>(null);
    const [allHits, setAllHits] = useState<SearchSuggestion[]>([]);
    const [hits, setHits] = useState<SearchSuggestion[]>([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const inputRef = useRef<InputRef>(null)
    const { clientWidth } = divRef.current ?? {};
    const { categorySearchEvent } = useGA4Context();

    const path = accountType === AccountType.SUPPLIER ? 'suppliers' : 'buyer-clients';

    useEffect(() => {
        if (clientWidth) {
            setPopoverDim(p => ({ ...p, width: clientWidth }))
        }
    }, [clientWidth]);

    useEffect(() => {
        setOpen(false);
        inputRef.current?.reset();
    }, [accountType]);

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                setOpen(false);
            }
        };
        window.addEventListener('keydown', listener);
        return () => window.removeEventListener('keydown', listener);
    }, []);

    const handleSelect = (selected: SearchSuggestion) => {
        if (selected.hitType === HitType.AccountName) {
            //company selected
            history.push(`/${path}/details/${selected.id}`)
        } else {
            goResults(selected);
        }
        setOpen(false);
    }

    const onKeywordChange = debounce(async (keyword?: string) => {
        const _lowercaseKeyword = keyword?.toLowerCase() ?? '';
        setLowercaseKeyword(_lowercaseKeyword);
        if (_lowercaseKeyword.length >= 3) {
            setAllHits([]);
            setHits([]);
            setOpen(true);
            try {
                setLoading(true);
                let hits = await DirectoryAPI.getSearchSuggestions(accountType, _lowercaseKeyword);
                if (accountType === AccountType.BUYER_CLIENT) {
                    hits = hits.filter(h => h.hitType === HitType.AccountName || h.hitType === HitType.Category);
                }
                setAllHits(hits.sort((a, b) => getHitTypeOrder(a.hitType) - getHitTypeOrder(b.hitType)));
                setHits(hits.slice(0, 25));
            } catch (error) {
                dispatch(showError(error.message));
            } finally {
                setLoading(false);
            }
        } else {
            setOpen(false)
        }
    }, 800);

    const browseAllCat = () => {
        setOpen(false);
        onBrowseAllClicked?.();
    }

    const goResults = (request?: SearchSuggestion) => {
        if (request) {
            categorySearchEvent(request, accountType);
        }
        const result: ResultsViewRouteParams | undefined = request ? { request } : undefined;
        history.push(`/${path}/results`, result);
    }

    const loadMore = async () => {
        const d = allHits.slice(hits.length, hits.length + 5);
        setHits(prev => [...prev, ...d]);
    }

    const getType = (hitType: HitType) => {
        switch (hitType) {
            case HitType.Category:
                return 'Industry';
            case HitType.SubCategory:
                return 'Sector';
            case HitType.SubSubCategory:
                return 'Service';
            case HitType.AccountName:
                return 'Company';
            default:
                return '';
        }
    }

    const wordings = useMemo(() => {
        const wordings = {
            title: 'Search for an industry, service or business name',
            placeholder: 'Search name, service or an industry',
            tips: 'Browse all industries and services ',
        };
        if (accountType === AccountType.BUYER_CLIENT) {
            wordings.title = 'Search for an industry or business name';
            wordings.placeholder = 'Search for an industry or business name';
            wordings.tips = 'Browse all industries ';
        }
        return wordings;
    }, [accountType]);

    return (
        <div className={styles.search}>
            <div className={styles.title}>
                <div>{wordings.title}</div>
                <div onClick={() => goResults()}>View all</div>
            </div>
            <div ref={divRef} onClick={(evt: any) => {
                let maxHeight = window.innerHeight - evt.clientY - 60;
                if (maxHeight < 80) {
                    maxHeight = 80;
                } else if (maxHeight > 500) {
                    maxHeight = 500;
                }
                setPopoverDim(p => ({ ...p, maxHeight }))
            }}>
                <Input
                    containerClassname={styles.input_container}
                    inputClassName={styles.input}
                    placeholder={wordings.placeholder}
                    onChange={onKeywordChange}
                    ref={inputRef}
                />
            </div>
            {!!top3categories?.length && (
                <div className={styles.frequent_search}>
                    <span>Frequently searched:</span>
                    <div className={styles.items}>
                        {top3categories.map(c => (
                            <span key={Math.random()} onClick={() => onTop3CategoryClicked?.(c.id, HitType.Category, c.name)}>{c.name}</span>
                        ))}
                    </div>
                </div>
            )}
            {popoverDim.width && open &&
                <ScrollerContainer
                    hasMore={hits.length < allHits.length}
                    loadMore={loadMore}
                >
                    <div className={styles.popover} style={popoverDim}>
                        {loading && <Loading />}
                        {lowercaseKeyword && hits?.map(hit => (
                            <MenuItem key={hit.id} className={styles.menu} onClick={() => handleSelect(hit)}>
                                <div className={styles.item}>
                                    <span>{hit.name}</span>
                                    <span>{getType(hit.hitType)}</span>
                                </div>
                            </MenuItem>
                        ))}
                        {!allHits.length && !loading && (
                            <div className={styles.no_result}>
                                <span>No results found </span>
                            </div>
                        )}
                        {hits.length >= allHits.length && !loading && (
                            <div className={styles.tips}>
                                <span>{wordings.tips}</span>
                                <span onClick={browseAllCat}>here</span>
                            </div>
                        )}
                    </div>
                </ScrollerContainer>
            }
        </div>
    )
}