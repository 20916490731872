import React from "react";
import * as Yup from "yup";
import ReactTooltip from "react-tooltip";
import FormikSelect from "../../../widgets/formikFields/FormikSelect";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import styles from "../buyerClient/BuyerClientOnboarding.module.scss";
import { INSURANCES, SUPPLIER_TURNOVER } from "../../../../util/constants";
import OtherInsurancesFieldArray from "../../../widgets/formikFields/OtherInsurancesFieldArray";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";


export const OrgFinancialsValidationSchema = Yup.object({
    turnoverInLastFinancialYear: Yup.string().required("Required"),
    professionalIndemnity: Yup.string().required("Required"),
    publicLiabilityInsurance: Yup.string().required("Required"),
    otherInsurances: Yup.array().of(Yup.object({
        name: Yup.string(),
        value: Yup.string(),
    })),
})

export default function Page4(props: any) {



    const renderFields = () => (
        <>
            <FormikSelect name={"turnoverInLastFinancialYear"}
                          label={"Annual turnover"}
                          options={SUPPLIER_TURNOVER}
                          tooltip={"Amount of revenue your business had from normal business activities within the financial year"} />
            <FormikSelect name={"professionalIndemnity"}
                          label={"Professional Indemnity Insurance"}
                          options={INSURANCES}
                          tooltip={"Specify the amount of professional indemnity insurance you hold"} />
            <FormikSelect name={"publicLiabilityInsurance"}
                          label={"Public Liability"}
                          options={INSURANCES}
                          tooltip={"Specify the amount of public liability insurance you hold"} />
            <OtherInsurancesFieldArray />
        </>
    )

    const renderFormStep = (children: any) => (
        <FormStep validationSchema={OrgFinancialsValidationSchema}>
            <h5>Tell us about your financials</h5>
            <div className={styles.inner_container}>
                <div className={styles.onboarding_form}>
                    {children}
                </div>
                <ReactTooltip
                    place={"top"}
                    effect={"solid"}
                    multiline
                    backgroundColor={"#F1F1F2"}
                    borderColor={"#E6E7E8"}
                    textColor={"#000000"}
                    clickable
                />
            </div>
        </FormStep>
    )

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Financial Details"}/>

                {renderFields()}
            </div>
        )
    }

    return renderFormStep(renderFields());
}