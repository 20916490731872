export enum SaaSPlanStatus {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED'
}
export enum LogoType {
    NONE = 'NONE',
    BUYER = 'BUYER',
    CHAMPION = 'CHAMPION'
}
export enum SaaSPlanPeriod {
    ANNUAL = "ANNUAL",
    MONTHLY = "MONTHLY"
}
export default interface SaaSPlan {
    id: number;
    status: SaaSPlanStatus;
    createdAt: string;
    name: string;
    amount: number;
    maxUsers: number;
    maxContacts: number;
    eventDiscount: number;
    fullProfile: boolean;
    displayBadge: boolean;
    logoType: LogoType;
    invoicable: boolean;
    invoiceInterval?: InvoiceInterval;
    period: SaaSPlanPeriod;
    customizedFor?: CustomizedFor;
    invoiceAnnualAmount?: number;
}

export enum InvoiceInterval {
    ANNUAL = 'ANNUAL',
    MONTHLY = 'MONTHLY'
}

export enum CustomizedFor {
    MandateAgency = 'MandateAgency',
}

export type PlanResult = {
    name: string;
    displayBadge: boolean;
}