import React, { useRef, useState } from "react";
import { useField, useFormikContext } from "formik";
import classNames from "classnames";
import { debounce } from "lodash";
import styles from "../input/Input.module.scss";
import FormikInput from "./FormikInput";
import useDispatch from "../../../hooks/useDispatch";
import { getAddressSuggestion } from "../../../redux/actions/address";

type InputProps = {
    name: string;
    label?: string;
    disabled?: boolean;
    formattedName: string;
    inputClassname?: string;
    noPadding?: boolean;
    tooltip?: string;
    placeholder?: string;
    types?: string;
}

export default function FormikAddressInput(props: InputProps) {
    const { name, label, inputClassname, noPadding, disabled, formattedName, types, ...otherProps } = props;
    const { isSubmitting } = useFormikContext();
    const fieldProps = useField(name);
    const inputFieldProps = useField(formattedName);
    const dispatch = useDispatch();
    const [, , formattedNameHelpers] = inputFieldProps;
    const [, , helpers] = fieldProps;
    const [addressSuggestions, setAddressSuggestions] = useState<Suggestion[]>([]);
    const prevString = useRef<string>();

    const className = classNames(styles.padding, { [styles.noPadding]: noPadding }, inputClassname);
    
    const onAddressChange = debounce(async (e: any) => {
            const key:string = e.target.value;
            if ((key??'').length > 3) {
                if (prevString.current !== key) {
                    prevString.current = key;
                    const nz:Suggestion[] = await dispatch(getAddressSuggestion(key.toLowerCase(), ["nz"], types));
                    const au:Suggestion[] = await dispatch(getAddressSuggestion(key.toLowerCase(), ["au"], types));
                    const suggestions = nz.concat(au);
                    setAddressSuggestions(suggestions);
                }
            } else {
                setAddressSuggestions([]);
            }
        }, 1000
    );

    const onSuggestionClick = (suggestion: Suggestion) => {
        formattedNameHelpers.setValue(suggestion.place_name);
        helpers.setValue({
            formattedAddress: suggestion.place_name,
            streetNumber: suggestion.address || "",
            street: suggestion.text || "",
            suburb: (suggestion.context.find((context) => context.id.includes("locality")) || {}).text || "",
            city: (suggestion.context.find((context) => context.id.includes("place")) || {}).text || "",
            postcode: (suggestion.context.find((context) => context.id.includes("postcode")) || {}).text || "",
            country: (suggestion.context.find((context) => context.id.includes("country")) || {}).text || "",
            placeType: suggestion.place_type
        });
        setAddressSuggestions([]);
    };

    return (
        <div className={className}>
            <FormikInput onChange={(e) => onAddressChange(e)}
                         name={formattedName || name}
                         label={label}
                         inputClassname={inputClassname}
                         autoComplete="off"
                         disabled={isSubmitting || disabled}
                         {...otherProps} />
            <div className={styles.menu_dropdown_container}>
                {!!addressSuggestions.length && (
                    <div className={styles.menu_dropdown}>
                        {addressSuggestions.map((suggestion, index) => {
                            return (
                                <span onClick={() => onSuggestionClick(suggestion)}
                                      key={`mapbox-suggestion-${index + 1}`}
                                      className={styles.menu_item}>{suggestion.place_name}</span>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}

type Suggestion = {
    id: string;
    type: string;
    place_type: string[];
    relevance: number;
    properties: any;
    address: string; //street number
    text: string; //street name
    place_name: string; //full address string
    bbox: number[];
    center: number[];
    geometry: any;
    context: Array<{ id: string, text: string }>;
}
