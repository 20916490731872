import React, { forwardRef, Ref, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Dialog, { DialogRef } from "../../dialog/Dialog";
import useForwardRef from "../../../../hooks/useForwardRef";
import styles from "./CreateOrUpdateAccountUserDialog.module.scss";
import AmotaiUser, { AccountRole } from "../../../../types/AmotaiUser";
import Button from "../../button/Button";
import FormikInput from "../../formikFields/FormikInput";
import FormikNumberInput from "../../formikFields/FormikNumberInput";
import FormikSelect from "../../formikFields/FormikSelect";
import useDispatch from "../../../../hooks/useDispatch";
import { inviteToAccount, updateAccountUser } from "../../../../redux/actions/users";
import useSelector from "../../../../hooks/useSelector";
import AmotaiAccount from "../../../../types/AmotaiAccount";
import { CreateUserDTO } from "../../../../types/CreateUserDTO";
import { showError, showSuccess } from "../../../../redux/actions/snackbars";

type CreateOrUpdateUserDialogProps = {
    onSubmit?: (values: CreateOrUpdateUserValues) => void;
    onClose?: () => void;
    selectedUser?: AmotaiUser | null;
};

export type CreateOrUpdateUserValues = {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    jobTitle: string;
    accountRole: string;
};

const CreateOrUpdateUserValidation = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email("Must be a valid email").trim().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    accountRole: Yup.string().required("Required"),
    jobTitle: Yup.string().required("Required")
});

function CreateOrUpdateAccountUserDialog(props: CreateOrUpdateUserDialogProps, ref: Ref<DialogRef>) {
    const { selectedUser } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const dialogRef = useForwardRef<DialogRef>(ref);
    const account = useSelector<AmotaiAccount>(state => state.users.account!);

    const onCancelClick = () => {
        props.onClose?.();
        dialogRef.current?.hide();
    };

    const onSubmit = async (values: CreateOrUpdateUserValues, formikBag: any) => {
        setLoading(true);

        try {
            if (selectedUser) {
                await dispatch(updateAccountUser(account.id, selectedUser.id, {
                    ...values
                }));
            } else {
                const dto: CreateUserDTO = {
                    ...values
                };

                await dispatch(inviteToAccount(account.id, dto));
            }

            if (props.onSubmit) {
                props.onSubmit(values);
            }
            onCancelClick();
            formikBag.resetForm();
            dispatch(showSuccess("User invited successfully."));
        } catch (e) {
            dispatch(showError(e.message));
        }

        setLoading(false);
    };

    return (
        <Dialog ref={dialogRef} disableBackdropClick={loading} title={!!selectedUser ? "Edit" : "Invite New User"}>
            <div className={styles.dialog_form}>
                <Formik<CreateOrUpdateUserValues>
                    initialValues={{
                        firstName: selectedUser?.firstName || "",
                        lastName: selectedUser?.lastName || "",
                        email: selectedUser?.email || "",
                        phoneNumber: selectedUser?.phoneNumber || "",
                        jobTitle: selectedUser?.jobTitle || "",
                        accountRole: selectedUser?.accountRole || ""
                    }}
                    validationSchema={CreateOrUpdateUserValidation}
                    onSubmit={onSubmit}>
                    {(formikBag) => (
                        <form onSubmit={formikBag.handleSubmit}>
                            <FormikInput name={"firstName"} label={"First Name"} />
                            <FormikInput name={"lastName"} label={"Last Name"} />
                            <FormikInput name={"email"} label={"Email"} />
                            <FormikInput name={"jobTitle"} label={"Job Title"} />
                            <FormikNumberInput name={"phoneNumber"} label={"Phone number"} phone />
                            <div className={styles.permission}>
                                <p>Account roles</p>
                                <span><strong>Admins</strong> can access all parts of the business profile.<br /><strong>Users</strong> can only view the directory and their own profile. They can not view or update any business details.</span>
                            </div>
                            <FormikSelect name={"accountRole"}
                                          options={[AccountRole.USER, AccountRole.ADMIN]}
                                          label={"Account role"} />
                            <div className={styles.dialog_footer}>
                                <Button type={"submit"} loading={loading}>{selectedUser ? "Save" : "Invite"}</Button>
                                <span />
                                <Button plainLink type={"button"} onClick={onCancelClick}
                                        disabled={loading}>Cancel</Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </Dialog>
    );
}

export default forwardRef(CreateOrUpdateAccountUserDialog);
