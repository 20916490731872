export default interface Resource {
    _id: string;
    title: string;
    content: any;
    detail: string;
    embed: string;
    _createdAt: string;
    author: string;
    file: {
        _type: string;
        asset: Asset;
    }
}

export enum ResourcePermission {
    SUPPLIER = 'SUPPLIER',
    BUYER_CLIENT = 'BUYER_CLIENT',
    BUYER_CLIENT_FUNDER = 'BUYER_CLIENT_FUNDER',
    BUYER_CLIENT_IWI = 'BUYER_CLIENT_IWI',
}

export type Asset = {
    _id: string;
    extension: string;
    mimeType: string;
    originalFilename: string;
    url: string;
}
