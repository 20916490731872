import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import SanityAPI from "../../util/SanityAPI";
import Post from "../../types/Post";

export enum Types {
    SET_POST = "posts:SET_POST",
    SET_POSTS = "posts:SET_POSTS",
}

export const setPost = createAction<Post>(Types.SET_POST);
export const setPosts = createAction<Post[]>(Types.SET_POSTS);

export const Actions = {
    [Types.SET_POST]: setPost,
    [Types.SET_POSTS]: setPosts,
};
export type Actions = typeof Actions;

export const getPost = wrapper(SanityAPI.getPostBySlug, {
    action: setPost
});

export const getPosts = wrapper(SanityAPI.getPosts, {
    action: setPosts
});

export const searchPosts = wrapper(SanityAPI.searchPosts);