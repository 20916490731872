/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 20/03/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import MuiButton, { ButtonProps as MuiButtonProps } from "@material-ui/core/Button";
import classNames from "classnames";
import {Link} from "react-router-dom";
import React, { Ref } from "react";
import Spinner from "../spinner/Spinner";
import styles from "./Button.module.scss";

export type ButtonProps = {
    href?: string;
    extLink?: string;
    hash?: string;
    target?: string;
    rel?: string;
    loading?: boolean;
    plain?: boolean;
    plainLink?: boolean;
    white?: boolean;
    border?: boolean;
    borderwhite?: boolean;
    borderdark?: boolean;
    borderTurquoise?: boolean;
    dark?: boolean;
    uppercase?: boolean;
    small?: boolean;
    center?: boolean;
    large?: boolean;
    regular?: boolean;
    long?: boolean;
    selected?: boolean;
    component?: string;
} & MuiButtonProps;

function Button(props: ButtonProps, ref: Ref<HTMLButtonElement>) {
    const { children, href, hash, loading, plain,plainLink, white, border, borderwhite, borderdark, dark, borderTurquoise, uppercase, small, center, large, regular, long, selected, endIcon, variant, disabled, extLink, ...otherProps } = props;

    const isDisabled = disabled || loading;
    const className = classNames(
        styles.button,
        {
            [styles.default]: !plain && !border && !dark && !white,
            [styles.plain]: plain,
            [styles.plainLink]: plainLink,
            [styles.white]: white,
            [styles.border]: border,
            [styles.borderwhite]: borderwhite,
            [styles.borderTurquoise]: borderTurquoise,
            [styles.borderdark]: borderdark,
            [styles.dark]: dark,
            [styles.uppercase]: uppercase,
            [styles.center]: center,
            [styles.large]: large,
            [styles.regular]: regular,
            [styles.long]: long,
            [styles.selected]: selected,
        },
        props.className,
    );

    let { size } = props;
    if (small) {
        size = "small";
    }

    const button = (
        <MuiButton component={"button"}
                   {...otherProps}
                   disabled={isDisabled}
                   className={className}
                   size={size}
                   endIcon={loading ? <Spinner small white={!plain} /> : endIcon}
                   buttonRef={ref}>
            <span className={styles.children}>{children}</span>
        </MuiButton>
    );

    if (href) {
        return (
            <Link className={styles.link} to={href}>
                {button}
            </Link>
        );
    }

    if (extLink) {
        return (
            <a className={styles.link} href={extLink}>{button}</a>
        )
    }


    return button;
}

export default React.forwardRef(Button);
