import React, { useState } from "react";
import useSelector from "../../../../hooks/useSelector";
import AccountHeader from "../../account/component/AccountHeader";
import moment from "moment";
import useMountEffect from "../../../../hooks/useMountEffect";
import { getMyAccount } from "../../../../redux/actions/users";
import Loading from "../../../widgets/loading/Loading";
import ReportHeader from "../../../widgets/reportHeader/ReportHeader";
import styles from "./OperationsReport.module.scss";
import classNames from "classnames";

export default function OperationsReport() {
    const [loading, setLoading] = useState<boolean>(true);
    const account = useSelector(state => state.users.account!);
    const { regionReport, employmentReport, categoryReport } = useSelector(state => state.reporting);

    useMountEffect(async () => {
        if (!account) {
            await getMyAccount();
        }
        setLoading(false);
    });

    if (loading) {
        return <Loading />;
    }

    // const renderOwnershipGraph = () => {
    //     const { maleOwned, femaleOwned, unspecifiedOwned, maleTotal, femaleTotal, unspecifiedTotal } = ownershipReport!;
    //
    //     const maleOwnedPercentage = Number(maleOwned) * 100;
    //     const femaleOwnedPercentage = Number(femaleOwned) * 100;
    //     const unspecifiedOwnedPercentage = Number(unspecifiedOwned) * 100;
    //
    //     return (
    //         <>
    //             <div className={styles.ownership_graph}>
    //                 <div className={classNames({ [styles.male]: maleOwnedPercentage > 0 })}
    //                      style={{ width: maleOwnedPercentage > 0 ? `${maleOwnedPercentage}%` : "0" }}>
    //                     {maleOwnedPercentage > 10 ? `${maleOwnedPercentage.toFixed(2)}%` : ""}
    //                 </div>
    //                 <div className={classNames({ [styles.female]: femaleOwnedPercentage > 0 })}
    //                      style={{ width: femaleOwnedPercentage > 0 ? `${femaleOwnedPercentage}%` : "0" }}>
    //                     {femaleOwnedPercentage > 10 ? `${femaleOwnedPercentage.toFixed(2)}%` : ""}
    //                 </div>
    //                 <div className={classNames({ [styles.unspecified]: unspecifiedOwnedPercentage })}
    //                      style={{ width: unspecifiedOwnedPercentage > 0 ? `${unspecifiedOwnedPercentage}%` : "0" }}>
    //                     {unspecifiedOwnedPercentage > 10 ? `${unspecifiedOwnedPercentage.toFixed(2)}%` : ""}
    //                 </div>
    //             </div>
    //             <div className={styles.legend_container}>
    //                 <div className={styles.legend}>
    //                     <div className={styles.title}>
    //                         <div className={classNames(styles.circle, styles.male_circle)} />
    //                         <strong>Male owned</strong>
    //                     </div>
    //                     <div className={styles.subtitle}>
    //                         {`${maleOwnedPercentage.toFixed(2)}%, ${maleTotal} Total`}
    //                     </div>
    //                 </div>
    //                 <div className={styles.legend}>
    //                     <div className={styles.title}>
    //                         <div className={classNames(styles.circle, styles.female_circle)} />
    //                         <strong>Female owned</strong>
    //                     </div>
    //                     <div className={styles.subtitle}>
    //                         {`${femaleOwnedPercentage.toFixed(2)}%, ${femaleTotal} Total`}
    //                     </div>
    //                 </div>
    //                 <div className={styles.legend}>
    //                     <div className={styles.title}>
    //                         <div className={classNames(styles.circle, styles.unspecified_circle)} />
    //                         <strong>Unspecified owners</strong>
    //                     </div>
    //                     <div className={styles.subtitle}>
    //                         {`${unspecifiedOwnedPercentage.toFixed(2)}%, ${unspecifiedTotal} Total`}
    //                     </div>
    //                 </div>
    //             </div>
    //         </>
    //     );
    // };

    const renderCategoryReport = () => {
        if (!categoryReport) {
            return <Loading />;
        }

        let fullCount = 0;
        Object.values(categoryReport).forEach(v => fullCount += v);

        return (
            <>
                <ReportHeader title={"Business Categories"} subtitle={"Breakdown of the different sectors Iwi businesses are in"} />
                <div className={styles.category_graph_container}>
                    {Object.keys(categoryReport).map((key: string) => (
                        <div className={styles.row}>
                            <div className={styles.left}>
                                {key}
                            </div>
                            <div className={styles.right_container}>
                                <div style={{ width: `${(categoryReport[key] / fullCount) * 100}%` }} className={styles.right}/>
                                <div className={styles.category_count}>{categoryReport[key]}</div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={classNames(styles.table_report, styles.categories_table)}>
                    <table>
                        <thead>
                        <tr>
                            <th>Category</th>
                            <th>No. operating</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys((categoryReport || {})).map((key: string) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{(categoryReport || {})[key]}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    const renderRegionReport = () => {
        if (!regionReport) {
            return <Loading />;
        }

        return (
            <>
                <ReportHeader title={"Region Of Operation"} subtitle={"Breakdown of the locations Iwi businesses service"} />
                <div className={styles.table_report}>
                    <table>
                        <thead>
                        <tr>
                            <th>Region</th>
                            <th>No. operating</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys((regionReport || {})).map((key) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{(regionReport || {})[key]}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };

    const renderEmploymentReport = () => {
        return (
            <div className={styles.employment_report_container}>
                <ReportHeader title={"Employment"} subtitle={"Breakdown of business employment statistics across businesses. This shows the total number of FTE’s (Full Time Equivalents), the average number of FTE’s and how many  Māori, female and under 25’s Iwi businesses collectively employ."} />
                <div className={styles.row}>
                    <div className={classNames(styles.row_item, styles.half)}>
                        <span className={styles.header}>{employmentReport?.totalFTE}</span>
                        <span className={styles.subheader}>Total FTE</span>
                    </div>
                    <div className={classNames(styles.row_item, styles.half)}>
                        <span className={styles.header}>{Number(employmentReport?.averageFTE).toFixed(2)}</span>
                        <span className={styles.subheader}>Average FTE</span>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={classNames(styles.row_item, styles.third)}>
                        <span className={styles.header}>{employmentReport?.maoriFTE}</span>
                        <span className={styles.subheader}>Māori FTE</span>
                    </div>
                    <div className={classNames(styles.row_item, styles.third)}>
                        <span className={styles.header}>{employmentReport?.femaleFTE}</span>
                        <span className={styles.subheader}>Female FTE</span>
                    </div>
                    <div className={classNames(styles.row_item, styles.third)}>
                        <span className={styles.header}>{employmentReport?.underTwentyFourFTE}</span>
                        <span className={styles.subheader}>FTE Under 25</span>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={styles.operations_report_container}>
            <AccountHeader title={account.name}
                           subtitle={`Member since: ${moment(account.createdAt).format("DD/MM/YYYY")}`} />

            {/*<ReportHeader title={"Ownership"} subtitle={"Hello"} />*/}
            {/*<div className={styles.ownership_graph_container}>*/}
            {/*    {renderOwnershipGraph()}*/}
            {/*</div>*/}
            {renderEmploymentReport()}
            {renderCategoryReport()}
            {renderRegionReport()}
        </div>
    );
}
