import { Form, Formik } from "formik";
import React, { forwardRef, Ref, useState } from 'react';
import { ResultsViewRouteParams } from "src/components/pages/directory/ResultsView";
import useDispatch from 'src/hooks/useDispatch';
import useForwardRef from 'src/hooks/useForwardRef';
import { showError } from 'src/redux/actions/snackbars';
import { createPref } from "src/redux/actions/users";
import Preference, { PreferenceType } from "src/types/Preference";
import * as Yup from "yup";
import Button from '../../button/Button';
import Dialog, { DialogRef } from '../../dialog/Dialog';
import FormikInput from "../../formikFields/FormikInput";
import styles from './SaveSearchDialog.module.scss';

type Props = {
    prefValue: ResultsViewRouteParams;
    onFinish?: (result: Preference) => void;
}

const schemaValidation = Yup.object({
    title: Yup.string().required('Title is required'),
})

function SaveSearchDialog(props: Props, ref: Ref<DialogRef>) {
    const { prefValue, onFinish } = props;
    const dispatch = useDispatch();
    const dialogRef = useForwardRef<DialogRef>(ref);
    const [loading, setLoading] = useState<boolean>();

    const handleSubmit = async (values: { title: string }) => {
        if (!prefValue) {
            dispatch(showError('Nothing to save, no fav has been set or updated'))
            return;
        }
        const pref = {
            type: PreferenceType.Search,
            name: values.title,
            value: JSON.stringify(prefValue),
        } as Preference;
        try {
            setLoading(true);
            const result = await dispatch(createPref(pref));
            dialogRef.current?.hide();
            onFinish?.(result);
        } catch (error) {
            dispatch(showError(`Failed to save fav search: ${error.message}`));
            throw error;
        } finally {
            setLoading(false);
        }
    }

    return (
        <Dialog
            ref={dialogRef}
            title='Save search preference'
        >
            <Formik
                onSubmit={handleSubmit}
                validationSchema={schemaValidation}
                initialValues={{ title: '' }}
            >
                {({ isValid, dirty }) => (
                    <Form className={styles.container}>
                        {/* <div className={styles.description}>
                            some descriptions here
                        </div> */}
                        <FormikInput
                            name='title'
                            label='Title of saved search'
                        />
                        <div className={styles.footer}>
                            <Button plainLink onClick={() => dialogRef.current?.hide()} disabled={loading}>Cancel</Button>
                            <Button type='submit' disabled={loading || !isValid || !dirty} loading={loading}>save</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default forwardRef(SaveSearchDialog);