import AmotaiUser, { SystemRole } from "src/types/AmotaiUser";
import useSelector from "./useSelector";

type Props = {
    user?: AmotaiUser | null,
    isSuperAdmin: boolean,
    isAdmin: boolean,
    isRegionalManager: boolean,
}
export default function useUserRoles(): Props {
    const user = useSelector((state) => state.users.me);
    const isSuperAdmin = user?.systemRole === SystemRole.SUPER_ADMIN;
    const isAdmin = user?.systemRole === SystemRole.ADMIN;
    const isRegionalManager = user?.systemRole === SystemRole.REGIONAL_MANAGER;
    return {
        user,
        isSuperAdmin,
        isAdmin,
        isRegionalManager
    };
}

