import { AccountType } from "src/types/AmotaiAccount";
import DirectoryItem from "../../types/DirectoryItem";
import DirectoryAPI from "./api/directory";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum DirectoryTypes {
    SET_DIRECTORY_ITEM = "directory:SET_DIRECTORY_ITEM",
    SET_SORTED_CATEGORIES = "directory:SET_SORTED_CATEGORIES"
}

export const setDirectoryItem = createAction<DirectoryPayloads[DirectoryTypes.SET_DIRECTORY_ITEM]>(DirectoryTypes.SET_DIRECTORY_ITEM);
const setSortedCategories = createAction<DirectoryPayloads[DirectoryTypes.SET_SORTED_CATEGORIES]>(DirectoryTypes.SET_SORTED_CATEGORIES);

export type DirectoryPayloads = {
    [DirectoryTypes.SET_DIRECTORY_ITEM]: DirectoryItem | null,
    [DirectoryTypes.SET_SORTED_CATEGORIES]: { accountType: AccountType, categories: number[] },
}

export const getDirectoryItem = wrapper(DirectoryAPI.getDirectoryItem, {
    action: setDirectoryItem
});

export const getSortedCategories = wrapper(DirectoryAPI.getSortedCategories, {
    action: (categories, params) => {
        const accountType: AccountType = params[0];
        return setSortedCategories({ categories, accountType });
    }
});
