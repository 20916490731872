import Card from "src/types/Card";
import get from "./util/get";
import put from "./util/put";
import post from "./util/post";
import del from "./util/del";

function getCards(accountId: number): Promise<Card[]> {
    return get(`v1/accounts/${accountId}/cards`);
}

function getSingleCard(accountId: number, cardId: string): Promise<Card> {
    return get(`v1/accounts/${accountId}/cards/${cardId}`);
}

function createCard(accountId: number, cardToken: string, isDefault: boolean): Promise<Card> {
    return post(`v1/accounts/${accountId}/cards`, {}, {
        token: cardToken,
        isDefault
    });
}

function updateCard(accountId: number, cardId: string, card: Card): Promise<Card> {
    return put(`v1/accounts/${accountId}/cards/${cardId}`, card);
}

/** delete one card (probably default card) and return the left cards incl. default card */
function removeCard(accountId: number, cardId: string): Promise<Card> {
    return del(`v1/accounts/${accountId}/cards/${cardId}`);
}



const CardsAPI = {
    getCards,
    getSingleCard,
    createCard,
    updateCard,
    removeCard
};

export default CardsAPI;