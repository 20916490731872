import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from "uuid";
import { SnackbarConfig } from "../../components/widgets/snackbar/Snackbar";
import ReduxDispatch from "../../types/ReduxDispatch";
import createAction from "./helpers/createAction";

export enum Types {
    ADD_SNACKBAR = "snackbars:ADD_SNACKBAR",
    REMOVE_SNACKBAR = "snackbars:REMOVE_SNACKBAR",
}

export const addSnackbar = createAction(Types.ADD_SNACKBAR);
export const removeSnackbar = createAction(Types.REMOVE_SNACKBAR);

export function showNotification(text: string, extraConfig = {}) {
    return (dispatch: ReduxDispatch) => {
        const config = {
            text,
            id: uuidv4(),
            ...extraConfig,
        };

        dispatch(addSnackbar(config));
    };
}

export function showSuccess(text: string, extraConfig: Partial<SnackbarConfig> = {}) {
    return (dispatch: ReduxDispatch) => {
        dispatch(showNotification(text, { ...extraConfig, success: true }));
    };
}

export function showWarning(text: string, extraConfig: Partial<SnackbarConfig> = {}) {
    return (dispatch: ReduxDispatch) => {
        dispatch(showNotification(text, { ...extraConfig, warning: true }));
    };
}

export function showError(text: string, extraConfig: Partial<SnackbarConfig> = {}) {
    Sentry.captureMessage(text);
    return (dispatch: ReduxDispatch) => {
        dispatch(showNotification(text, { ...extraConfig, error: true }));
    };
}

