import { InputLabel } from "@material-ui/core";
import { CardCvcElement, CardNumberElement, CardExpiryElement } from "@stripe/react-stripe-js";
import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
import {
    StripeCardCvcElement,
    StripeCardCvcElementChangeEvent, StripeCardExpiryElement,
    StripeCardExpiryElementChangeEvent, StripeCardNumberElement,
    StripeCardNumberElementChangeEvent,
    StripeCardNumberElementOptions
} from "@stripe/stripe-js";
import styles from "./StripeInput.module.scss";
import classNames from "classnames";

type StripeInputProps = {
    options?: StripeCardNumberElementOptions | undefined,
    type?: "card" | "cvc" | "expiry" | undefined,
    label?: string,
    inputClassname?: string,
    setValid?: (valid:boolean) => void
}

const STRIPE_INPUT_FIELD: StripeCardNumberElementOptions = {
    style: {
        base: {
            iconColor: "#c4f0ff",
            color: "black",
            fontWeight: "500",
            fontFamily: "Poppins, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#fce883",
            },
            "::placeholder": {
                color: "#CDCDCD",
            },
        },
        invalid: {
            iconColor: "#f44336",
            color: "#f44336",
        },
    },
};

export interface StripeInputRef {
    hasErrors(): boolean;
    setElementError(error: string | null): void;
    touched(): boolean;
    element(): StripeCardNumberElement | StripeCardExpiryElement | StripeCardCvcElement | null
}

function StripeInput(props: StripeInputProps, ref: Ref<StripeInputRef>) {
    const { type, options, label } = props;
    const [error, setError] = useState<string | null>(null);
    const [touched, setTouched] = useState<boolean>(false);
    const [element, setElement] = useState<StripeCardNumberElement | StripeCardExpiryElement | StripeCardCvcElement | null>(null);

    const onReady = (e: StripeCardNumberElement | StripeCardExpiryElement | StripeCardCvcElement) => {
        setTouched(false);
        setElement(e);
    };

    const handleBlur = () => {
        if (!touched) {
            setTouched(true);
        }
    };

    const handleChange = (e: StripeCardExpiryElementChangeEvent | StripeCardNumberElementChangeEvent | StripeCardCvcElementChangeEvent) => {
        if (e.error) {
            setError(e.error.message);
            props.setValid?.(false);
        } else if (e.empty) {
            setError("Required");
            props.setValid?.(false);
        } else {
            setError(null);
            props.setValid?.(true);
        }
    };

    const hasErrors = () => {
        return !!error;
    };

    useImperativeHandle(ref, () => ({
        hasErrors,
        setElementError: (ee) => {
            setError(ee);
        },
        touched: () => touched,
        element: () => element,
    }));

    const getStripeElement = () => {
        switch (type) {
            case "cvc":
                return (
                    <CardCvcElement onReady={onReady}
                                    onBlur={handleBlur}
                                    onFocus={handleBlur}
                                    onChange={handleChange}
                                    options={{ ...STRIPE_INPUT_FIELD, ...options }} />
                );
            case "expiry":
                return (
                    <CardExpiryElement onReady={onReady}
                                       onBlur={handleBlur}
                                       onFocus={handleBlur}
                                       onChange={handleChange}
                                       options={{ ...STRIPE_INPUT_FIELD, ...options }} />
                );
            case "card":
            default:
                return (
                    <CardNumberElement onReady={onReady}
                                       onBlur={handleBlur}
                                       onFocus={handleBlur}
                                       onChange={handleChange}
                                       options={{ ...STRIPE_INPUT_FIELD, ...options }} />
                );
        }
    };

    return (
        <div className={classNames(styles.stripe_input_container, props.inputClassname)}>
            {label && <InputLabel>{label}</InputLabel>}
            {getStripeElement()}
            {error && <span className={"error"}>{error}</span>}
        </div>
    );
}

export default forwardRef(StripeInput);