import BusinessContact from "../../types/BusinessContact";
import { Actions, Types } from "../actions/businessContacts";
import { orderBy } from "lodash";

const initialState: {
    businessContacts: BusinessContact[],
    businessContactObjects: {
        [id: number]: BusinessContact
    },
    mainContact: BusinessContact | null
} = {
    businessContacts: [],
    businessContactObjects: {},
    mainContact: null
};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function businessContacts(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_BUSINESS_CONTACTS: {
            const { payload } = action as ActionValueTypes<Types.SET_BUSINESS_CONTACTS>;
            const newState = { ...state };
            let mainContact = null;

            if (!payload) {
                return state;
            }

            payload.forEach((b: BusinessContact) => {
                newState.businessContactObjects[b.id] = b;
                if (b.main) {
                    mainContact = b;
                }
                return null;
            });

            newState.businessContacts = orderBy(payload, ["main"], ["desc"]);
            newState.mainContact = mainContact;

            return newState;
        }
        default: {
            return state;
        }
    }
}