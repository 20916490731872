import firebase from "firebase/app";
import "firebase/storage";
import { useField, useFormikContext } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import useDispatch from "../../../hooks/useDispatch";
import { showError } from "../../../redux/actions/snackbars";
import FileUpload, { FileUploadProps } from "../fileUpload/FileUpload";

type FormikFileUploadProps = {
    name: string;
} & Omit<FileUploadProps, "name">

export default function FormikFileUpload(props: FormikFileUploadProps) {
    const { name, ...otherProps } = props;

    const fieldProps = useField(name);
    const { isSubmitting } = useFormikContext();
    const dispatch = useDispatch();
    const [preview, setPreview] = useState<string>(props.preview || "");
    const [loading, setLoading] = useState<boolean>();

    const [field, meta, helpers] = fieldProps;
    const fieldError = meta.error;
    const displayError = meta.touched && !!fieldError;
    const disabled = props.disabled || isSubmitting;
    const storage = firebase.storage().ref();

    useEffect(() => {
        setPreview(props.preview || "");
    }, [props.preview])

    const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;

        if (files && files.length) {
            try {
                setLoading(true);
                const firebaseFiles = [];

                for (let i = 0; i < files.length; i++) {
                    const f = files[i];
                    if (f.size > 5242880) { //5 MB = 5242880 Byte
                        throw new Error('Please only upload files that are less than 5MB');
                    }
                    const fileRef = storage.child(`uploads/${uuid()}`);
                    const file = fileRef.put(files[i]);
                    firebaseFiles.push(file);
                }

                firebaseFiles.map((firebaseFile) => {
                    firebaseFile.on("state_changed", null, null, async () => {
                        const url = await firebaseFile.snapshot.ref.getDownloadURL();
                        setPreview(url);
                        helpers.setValue(url);
                        setLoading(false);
                    });
                    return null;
                });
            } catch (err) {
                dispatch(showError(err.message));
                setLoading(false);
            }
        }

        props.onChange?.(e);
    };

    return (
        <FileUpload {...otherProps}
            {...field}
            loading={loading}
            disabled={disabled}
            helperText={fieldError}
            showError={displayError}
            preview={preview}
            onChange={onChange}
        />
    );
}