import React, { ReactElement, ReactNode, Ref, useImperativeHandle, useState } from "react";
import MuiDialog, { DialogProps as MuiDialogProps } from "@material-ui/core/Dialog";
import classNames from "classnames";
import styles from "./Dialog.module.scss";

type DialogProps = {
    title?: string;
    className?: string;
    children?: ReactNode;
    loading?: boolean;
    onShown?: () => void;
    onHide?: () => void;
    onClose?: () => void;
    onDismiss?: () => void;
    disableDismiss?: boolean;
} & Omit<MuiDialogProps, "open">;

export interface DialogRef {
    show(): void;
    hide(): void;
}

function Dialog(props: DialogProps, ref: Ref<DialogRef>): ReactElement {
    const { title, children, disableDismiss, ...otherProps } = props;
    const [isOpen, setDialogOpen] = useState(false);

    const show = () => {
        setDialogOpen(true);

        if (props.onShown) {
            props.onShown();
        }
    };

    const hide = () => {
        setDialogOpen(false);

        if (props.onHide) {
            props.onHide();
        }
    };

    const onDismiss = () => {
        if (disableDismiss) {
            return;
        }

        if (props.onDismiss) {
            props.onDismiss();
        }

        hide();
    };

    const close = () => {
        // close actions
        if (props.onClose) {
            props.onClose();
        }
    };

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide,
        close: close,
    }));

    const className = classNames(styles.dialog, props.className);

    return (
        <MuiDialog
            {...otherProps}
            open={isOpen}
            onBackdropClick={onDismiss}
            onEscapeKeyDown={onDismiss}
            onClose={close}
            disableBackdropClick={disableDismiss || !!props.loading}
            disableEscapeKeyDown={disableDismiss || !!props.loading}
            classes={{
                paper: className,
            }}>
            {title && <h3 className={styles.title}>{title}</h3>}
            {children}
        </MuiDialog>
    );
}

export default React.forwardRef(Dialog);
