import React, { useState } from "react";
import * as Yup from "yup";
import Button from "src/components/widgets/button/Button";
import firebase from "firebase/app";
import "firebase/auth";
import { Formik, Form } from "formik";
import FormikInput from "../../widgets/formikFields/FormikInput";
import OnboardingSidebar from "../../widgets/onboardingSidebar/OnboardingSidebar";
import SideBar from "../../../resources/images/png/onboarding-amotai-sidebar@2x.png";
import OnboardingContent from "../../widgets/onboardingContent/OnboardingContent";
import styles from "./PasswordReset.module.scss";

interface LoginFormValues {
    email: string;
    password: string;
}

const logInValidationSchema = Yup.object({
    email: Yup.string()
        .trim()
        .email("Must be a valid email address")
        .required("Required"),
    password: Yup.string().required("Required"),
});

export default function PasswordReset() {
    const [loading, setLoading] = useState<boolean>(false);

    const onLoginSubmit = async (values: LoginFormValues) => {
        setLoading(true);
        await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
        setLoading(false);
    };

    return (


        <div className={styles.login_container}>
            <OnboardingSidebar sidebarImage={SideBar}>
                <div  className={styles.sidebar_content_container}>
                    <div>
                        <h3>
                            You’re just a few clicks away from being connected to our Amotai Supplier and Member database
                        </h3>
                    </div>
                </div>

            </OnboardingSidebar>
            <OnboardingContent>
                <div className={styles.login_content_container}>
                    <h5>Create your new password</h5>

                    <div className={styles.inner_container}>

                        <Formik<LoginFormValues> initialValues={{
                            email: "",
                            password: ""
                        }} onSubmit={onLoginSubmit} validationSchema={logInValidationSchema}>
                            {(formProps) => (
                                <Form onSubmit={formProps.handleSubmit} >
                                    <div className={styles.login_form}>
                                        <FormikInput name={"password"} label={"Password"} type={"password"} />
                                    </div>
                                    <div className={styles.button_container}>
                                        <Button type={"submit"} loading={loading}>Set password</Button>

                                    </div>

                                </Form>
                            )}
                        </Formik>

                    </div>




                </div>
            </OnboardingContent>
        </div>



    );
};

