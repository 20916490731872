import React from "react";
import * as Yup from "yup";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import { FieldArray, connect, useFormikContext, FormikProps, FieldArrayRenderProps, useField } from "formik";
import Button from "../../../widgets/button/Button";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import { SupplierFormValues } from "./supplierValidation";
import FormikSelect from "../../../widgets/formikFields/FormikSelect";
import { HEALTH_AND_SAFETY } from "../../../../util/constants";
import styles from "../buyerClient/BuyerClientOnboarding.module.scss";
import FormikTextArea from "../../../widgets/formikFields/FormikTextArea";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";

export const HealthAndSafetyValidationSchema = Yup.object({
    healthAndSafetyQualifications: Yup.array(Yup.string().required("Please select a value")).min(1, "At least 1 required"),
    healthAndSafetyIncidences: Yup.string(),
})

function Page10(props: any) {

    const { values }: FormikProps<SupplierFormValues> = useFormikContext();
    const [, healthMeta] = useField("healthAndSafetyQualifications")
    const healthError = healthMeta.error;

    const renderHealthAndSafetyQualification = (qual: string, index: number, arrayHelpers: FieldArrayRenderProps) => (
        <div className={styles.health_safety_row}>
            <FormikSelect name={`healthAndSafetyQualifications.${index}`}
                          containerClassName={styles.no_margin_input}
                          label={"Health and Safety Qualifications"}
                          options={HEALTH_AND_SAFETY}
                          tooltip={"Add all relevant health and safety qualifications your business currently holds"} />
            <Button plainLink onClick={() => arrayHelpers.remove(index)}>- Remove</Button>
        </div>
    )

    const renderFields = () => (
        <>
            <FieldArray name={"healthAndSafetyQualifications"}
                        render={(arrayHelpers) => (
                            values?.healthAndSafetyQualifications?.length > 0
                                ? (
                                    <>
                                        {values.healthAndSafetyQualifications.map((qual, index) => renderHealthAndSafetyQualification(qual, index, arrayHelpers))}
                                        <div className={styles.add_button}>
                                            <Button plainLink onClick={() => arrayHelpers.push("")}>+ Add
                                                Qualification</Button>
                                        </div>
                                    </>
                                )
                                : (
                                    <>
                                        <div>{healthError}</div>
                                        <div className={styles.add_button}>
                                            <Button plainLink onClick={() => arrayHelpers.push("")}>+ Add
                                                Qualification</Button>
                                        </div>
                                    </>
                                )
                        )} />

            <FormikTextArea name={"healthAndSafetyIncidences"}
                            className={styles.paragraph_input}
                            label={"Any major health and safety incidences? If yes, please explain"}
                            tooltip={"Provide details on any major health safety incidents you have been involved with or any previous or current investigations"} />
        </>
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>What are your Health & Safety qualifications?</h5>
            <div className={styles.inner_container}>
                <div className={classNames(styles.employed_form, styles.onboarding_form)}>
                    {children}
                </div>
                <ReactTooltip
                    place={"top"}
                    effect={"solid"}
                    multiline
                    backgroundColor={"#F1F1F2"}
                    borderColor={"#E6E7E8"}
                    textColor={"#000000"}
                    clickable
                />
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Business Health and Safety"}/>

                {renderFields()}
            </div>
        )
    }

    return renderFormStep(renderFields());
}

export default connect(Page10);