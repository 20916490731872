import { FormControlLabel, Paper } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field, Formik, FormikProps, useField, useFormikContext } from "formik";
import moment from "moment";
import React, { useMemo, useState } from "react";
import Select from "src/components/widgets/select/Select";
import * as Yup from "yup";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { getMainCategories } from "../../../../../redux/actions/categories";
import { showError } from "../../../../../redux/actions/snackbars";
import { getMyAccount, updateMyAccount } from "../../../../../redux/actions/users";
import { AccountType } from "../../../../../types/AmotaiAccount";
import AmotaiUser, { AccountRole } from "../../../../../types/AmotaiUser";
import { Category, SubCategory, SubSubCategory } from "../../../../../types/Category";
import Supplier from "../../../../../types/Supplier";
import Button from "../../../../widgets/button/Button";
import { CheckboxGroupItem } from "../../../../widgets/formikFields/FormikCheckboxGroup";
import Loading from "../../../../widgets/loading/Loading";
import SectionHeader from "../../../../widgets/sectionHeader/SectionHeader";
import AccountHeader from "../../component/AccountHeader";
import styles from "../business-details/BusinessDetails.module.scss";

type BusinessCategoryFormValues = {
    mainCategories: Category[];
    subCategories: SubCategory[];
    subSubCategories: SubSubCategory[];
}

const CategoryValidationSchema = Yup.object({
    mainCategories: Yup.array().of(Yup.string().required("Please select a value")).min(1, "At least 1 required"),
    subCategories: Yup.array().of(Yup.string()),
    subSubCategories: Yup.array().of(Yup.string()),
});



export default function BusinessCategorySupplier() {
    const [loading, setLoading] = useState<boolean>(true);
    const [editing, setEditing] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const dispatch = useDispatch();
    const account = useSelector<Supplier>(state => state.users.account as Supplier);
    const me = useSelector<AmotaiUser>(state => state.users.me!);
    const [fieldError, setFieldError] = useState<string>();

    useMountEffect(async () => {
        try {
            await dispatch(getMyAccount());
            await dispatch(getMainCategories());
        } catch (e) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    });

    const onSubmit = async (values: BusinessCategoryFormValues) => {
        try {
            setSubmitting(true);
            await dispatch(updateMyAccount(account.id, {
                ...account,
                ...values
            }, AccountType.SUPPLIER));
            setSubmitting(false);
        } catch (e) {
            setSubmitting(false);
            dispatch(showError(e.message));
        }
    };

    if (loading) {
        return <Loading />;
    }

    const toggleEdit = async (formikBag: FormikProps<BusinessCategoryFormValues>) => {
        if (editing && (formikBag.dirty || formikBag.touched)) {
            const r = await formikBag.validateForm(formikBag.values);
            const _error = Object.values(r)[0] as string;
            if (_error) {
                setFieldError(_error);
                formikBag.setSubmitting(false)
                return;
            }
            await onSubmit(formikBag.values);
        }
        setEditing(!editing);
        if (!editing) {
            formikBag.resetForm();
        }
    }

    const initialValues: BusinessCategoryFormValues = {
        mainCategories: account?.mainCategories ?? [],
        subCategories: account?.subCategories ?? [],
        subSubCategories: account?.subSubCategories ?? [],
    }

    return (
        <Formik<BusinessCategoryFormValues> initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={CategoryValidationSchema}>
            {formikBag => {
                let actionText = "Edit";
                if (editing) {
                    if (formikBag.dirty || formikBag.touched) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                return (
                    <div className={styles.business_details}>
                        <form>
                            <AccountHeader title={account?.name!}
                                subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                                action={me?.accountRole === AccountRole.ADMIN && (
                                    <Button uppercase loading={submitting} onClick={() => toggleEdit(formikBag)}>
                                        {actionText}
                                    </Button>
                                )} />
                            <div>
                                <SectionHeader title={`${account?.organisationType === "iwi" ? "Iwi" : "Business"} Category`} />
                                <div className={styles.form}>
                                    {fieldError && <div className={styles.error}>{fieldError}</div>}
                                    <FormikMultipleCategoriesSelect disabled={!editing} />
                                </div>
                            </div>
                        </form>
                    </div>
                )
            }}
        </Formik>
    );
}

function FormikMultipleCategoriesSelect(props: { disabled: boolean }) {
    const { disabled } = props;
    const categories = useSelector<Category[]>((state) => state.categories.main);
    const { values }: FormikProps<BusinessCategoryFormValues> = useFormikContext()
    const { mainCategories, subCategories, subSubCategories } = values;
    const [, , mainHelpers] = useField("mainCategories");
    const [, , subHelpers] = useField("subCategories");
    const [, , subSubHelpers] = useField("subSubCategories");

    const mainSelectedIds = useMemo(() => mainCategories?.map(m => m.id) ?? [], [mainCategories]);

    const categoryOptions = useMemo(() => {
        return categories.filter(c => !mainSelectedIds.includes(c.id)).map(c => c.name)
    }, [categories, mainSelectedIds]);


    const onSubCategoryChange = (sub: SubCategory) => {
        const selectedSub = subCategories.find((subCat) => subCat.id === sub.id);
        const ssids = sub.subSubCategories.map((subSubCat) => subSubCat.id);
        if (!selectedSub) {
            // clear subSub categories from the existing selection
            const _subSubCategories = subSubCategories.filter((subSubCat) => !ssids.includes(subSubCat.id));
            subSubHelpers.setValue(_subSubCategories);
        }
    };

    const onSubSubCategoryChange = (subSub: SubSubCategory) => {
        const parentSubCat = categories.map(c => c.subCategories).flat().find(s => s.id === subSub.parent)!;
        const isParentSelected = !!subCategories.find(s => s.id === parentSubCat.id);
        const isChildSelected = !!subSubCategories.find((subSubCat) => subSubCat.id === subSub.id)
        if (!isParentSelected && isChildSelected) {
            // have selected a subSub category without the parent subCategory added, select it now
            subHelpers.setValue([...subCategories, parentSubCat])
        }
    }

    const onAddSinglePrimary = () => {
        const mainSelectedIds = mainCategories?.map(m => m.id) ?? [];
        const newMainCat = categories.find(c => !mainSelectedIds.includes(c.id));
        if (newMainCat) {
            const _mainCategories = [...(mainCategories ?? []), newMainCat];
            mainHelpers.setValue(_mainCategories);
        }
    }

    const onRemoveSinglePrimary = (_cat: Category) => {
        const cat = categories.find(c => c.id === _cat.id)!;
        const sids = cat.subCategories.map(s => s.id);
        const ssids = cat.subCategories.map(s => s.subSubCategories.map(ss => ss.id)).flat();
        const _subCategories = subCategories.filter(sub => !sids.includes(sub.id));
        const _subSubCategories = subSubCategories.filter(subsub => !ssids.includes(subsub.id));
        subHelpers.setValue(_subCategories);
        subSubHelpers.setValue(_subSubCategories);
        mainHelpers.setValue(mainCategories.filter(m => m.id !== cat.id));
    }

    const onSinglePrimaryChange = (newCatName: string, idx: number) => {
        // remove main, push new main in and clear old/new sub & subSub
        const newCat = categories.find(c => c.name === newCatName)!
        const oldCat = categories.find(c => c.id === mainCategories[idx].id)!;
        const sids = [
            ...oldCat.subCategories.map(s => s.id),
            ...newCat.subCategories.map(s => s.id)
        ];
        const ssids = [
            ...oldCat.subCategories.map(s => s.subSubCategories.map(ss => ss.id)).flat(),
            ...newCat.subCategories.map(s => s.subSubCategories.map(ss => ss.id)).flat()
        ];
        const _subCategories = subCategories.filter(sub => !sids.includes(sub.id));
        const _subSubCategories = subSubCategories.filter(subsub => !ssids.includes(subsub.id));
        const _mainCategories = mainCategories.map((prev, i) => i === idx ? newCat : prev);
        subHelpers.setValue(_subCategories);
        subSubHelpers.setValue(_subSubCategories);
        mainHelpers.setValue(_mainCategories)
    };

    return <>
        {mainCategories?.map(mc => categories.find(c => c.id === mc.id)!).map((cat, idx) =>
            <div key={cat.id} className={styles.industry_form}>
                <Select defaultValue={cat.name}
                    disabled={disabled}
                    label={"Primary Industry"}
                    tooltip={"Select all relevant categories that you work in so that buyers are able to understand the services/works/goods you provide"}
                    onChange={name => onSinglePrimaryChange(name, idx)}
                    options={[...categoryOptions, cat.name]}
                    containerClassName={styles.full_input} />

                {cat.subCategories?.map((sub, key) =>
                    <SubCategoryView key={`sub_${key}`}
                        disabled={disabled}
                        sub={sub}
                        onSubCategoryChange={onSubCategoryChange}
                        onSubSubCategoryChange={onSubSubCategoryChange} />
                )}
                <div className={styles.remove_button}>
                    <Button plain onClick={() => onRemoveSinglePrimary(cat)} disabled={disabled}>- Remove</Button>
                </div>
            </div>
        )}
        {categoryOptions.length > 0 && (mainCategories ?? []).length < 5 && <Button plain onClick={onAddSinglePrimary} disabled={disabled}>+ Add one primary industry</Button>}
    </>
}

function SubCategoryView(props: {
    sub: SubCategory,
    onSubCategoryChange: (sub: SubCategory) => void,
    onSubSubCategoryChange: (subSub: SubSubCategory) => void,
    disabled: boolean,
}) {
    const { sub, onSubCategoryChange, onSubSubCategoryChange, disabled } = props;
    const [expended, setExpended] = useState<boolean>(false);

    const handleSubCategoryChange = (sub: SubCategory) => {
        setExpended(true);
        onSubCategoryChange(sub);
    }
    if (!sub.subSubCategories.length) {
        return <Paper className={styles.plain_subindustry_row}>
            <Field component={CheckboxGroupItem} name={"subCategories"} label={sub.name} value={sub} />
        </Paper>
    }

    return (
        <Accordion expanded={expended} TransitionProps={{ unmountOnExit: true }} className={styles.category_container}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label={"Expand"}
                aria-controls={"additional-actions1-content"}
                id={"additional-actions1-header"}
                className={styles.category_item}
                onClick={() => setExpended(!expended)}
            >
                <FormControlLabel
                    className={styles.category_label}
                    aria-label={"Sub Industry"}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={(
                        <Field name={"subCategories"}>
                            {(bags: any) => {
                                return <CheckboxGroupItem {...bags} value={sub} disabled={disabled} sideEffect={() => handleSubCategoryChange(sub)} />
                            }}
                        </Field>
                    )}
                    label={sub.name}
                />
            </AccordionSummary>

            <AccordionDetails>
                <div className={styles.subsub_container}>
                    {sub.subSubCategories?.map((subSub, key) => (
                        <Field key={`subsub_${key}`}
                            name={"subSubCategories"}>
                            {(bags: any) => {
                                return <CheckboxGroupItem {...bags}
                                    value={subSub}
                                    disabled={disabled}
                                    sideEffect={() => onSubSubCategoryChange(subSub)}
                                    label={subSub.name}
                                />
                            }}
                        </Field>
                    ))}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};