import { FormikProps, useField, useFormikContext } from "formik";
import { orderBy } from "lodash";
import React, { useState } from "react";
import SaaSPlan from "src/types/SaaSPlan";
import * as Yup from "yup";
import useDispatch from "../../../../hooks/useDispatch";
import useMountEffect from "../../../../hooks/useMountEffect";
import useSelector from "../../../../hooks/useSelector";
import { getActiveSaaSPlans } from "../../../../redux/actions/saaSPlans";
import Loading from "../../../widgets/loading/Loading";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import PlanCard from "../../../widgets/planCard/PlanCard";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import styles from "./BuyerClientOnboarding.module.scss";
import { BuyerClientFormValues } from "./buyerClientValidation";


export const SaasPlanValidationSchema = Yup.object({
    saasPlan: Yup.number().required()
})

type Props = {
    review?: boolean,
    setPlan?: (plan: SaaSPlan) => void,
    validationSchema?: any,
}

export default function Page2({ setPlan, review }: Props) {
    const [loading, setLoading] = useState(false);
    const [, , saasHelpers] = useField("saasPlan")
    const dispatch = useDispatch();
    const saasPlans = useSelector((state) => state.saaSPlans.saaSPlans);

    useMountEffect(async () => {
        setLoading(true);
        await dispatch(getActiveSaaSPlans());
        setLoading(false);
    })
    const handlePlanSelect = (plan: SaaSPlan) => {
        setPlan?.(plan)
        saasHelpers.setValue(plan.id)
    }

    const { values }: FormikProps<BuyerClientFormValues> = useFormikContext()

    const renderFields = (disabled: boolean) => (
        <div className={styles.card_container}>
            {loading
                ? <Loading />
                : orderBy(saasPlans, ["amount"], ["asc"]).map((plan) => (
                    <PlanCard
                        showAnnualPrice={values.invoicePayment && review}
                        label={plan.name}
                        disabled={disabled}
                        key={plan.id}
                        plan={plan}
                        onSelect={() => handlePlanSelect(plan)}
                        selected={values.saasPlan === plan.id}
                        detailItems={(
                            <div className={styles.plan_details}>
                                <span>{plan.maxUsers > 25 ?
                                    "Unlimited users" :
                                    `${plan.maxUsers > 1 ? `${plan.maxUsers} users` : `${plan.maxUsers} user`}`
                                }</span>
                                {plan.fullProfile &&
                                    <span>{plan.maxContacts > 25 ?
                                        "Unlimited contacts" :
                                        `${plan.maxContacts ?? 0} contact${(plan.maxContacts ?? 0) > 1 ? 's' : ''}`
                                    }</span>}
                                {plan.displayBadge && <span>Champion badge</span>}
                            </div>
                        )} />
                ))}
        </div>
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Select your membership plan</h5>
            <div className={styles.inner_container}>
                {children}
            </div>
        </FormStep>
    );

    if (review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Plan Details"} />
                {renderFields(true)}
            </div>
        )
    }

    return (
        renderFormStep(renderFields(false))
    )
}