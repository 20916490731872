import React from "react";
import styles from "./Reporting.module.scss";
import PageContainer from "../../widgets/pageContainer/PageContainer";
import SideMenu from "../../widgets/sideMenu/SideMenu";
import { Switch, Route, Link } from "react-router-dom";
import OperationsReport from "./operations/OperationsReport";
import AffiliationReport from "./affiliations/AffiliationReport";
import useMountEffect from "../../../hooks/useMountEffect";
import useDispatch from "../../../hooks/useDispatch";
import useSelector from "../../../hooks/useSelector";
import Loading from "../../widgets/loading/Loading";
import {
    getCategoryReport,
    getEmploymentReport,
    getOwnershipReport, getRegionReport,
    getSupplierCount,
    getSupplierReport
} from "../../../redux/actions/reporting";
import AmotaiAccount from "../../../types/AmotaiAccount";

export default function Reporting() {
    const account: AmotaiAccount = useSelector(state => state.users.account!);
    const {
        iwiSuppliers,
        supplierCount,
        ownershipReport,
        employmentReport
    } = useSelector((state) => state.reporting);
    const dispatch = useDispatch();

    useMountEffect(async () => {
        if (account) {
            const iwiAffiliation = (account.iwiAffiliations || [])[0];
            dispatch(getSupplierReport(iwiAffiliation, undefined, undefined, 500));
            dispatch(getSupplierCount(iwiAffiliation));
            dispatch(getOwnershipReport(iwiAffiliation));
            dispatch(getEmploymentReport(iwiAffiliation));
            dispatch(getCategoryReport(iwiAffiliation));
            dispatch(getRegionReport(iwiAffiliation));
        }
    });

    return (
        <PageContainer>
            <div className={styles.container}>
                <div className={styles.mobile_nav}>
                    <Link to={"/iwi/reporting"}>Iwi Businesses</Link>
                    <Link to={"/iwi/reporting/operations"}>Data and Insights</Link>
                </div>
                <div className={styles.desktop}>
                    <SideMenu>
                        <li><Link to={"/iwi/reporting"}>Iwi Businesses</Link></li>
                        <li><Link to={"/iwi/reporting/operations"}>Data and Insights</Link></li>
                    </SideMenu>
                </div>

                {(!iwiSuppliers || !supplierCount || !ownershipReport || !employmentReport) ? <Loading /> : (
                    <Switch>
                        <Route exact path={"/iwi/reporting/operations"} component={OperationsReport} />
                        <Route exact path={"/iwi/reporting"} component={AffiliationReport} />
                    </Switch>
                )}
            </div>
        </PageContainer>
    );
}
