import React from "react";

export default function Facebook(props: React.SVGProps<SVGSVGElement>) {

    return (
        <svg xmlns={"http://www.w3.org/2000/svg"} width={"7.311"} height={"14"} viewBox={"0 0 7.311 14"} {...props}>
            <path
                d={"M84.744,14V7.622h2.178l.311-2.489H84.744V3.578c0-.7.233-1.244,1.244-1.244h1.322V.078C87,.078,86.222,0,85.367,0a3,3,0,0,0-3.189,3.267V5.133H80V7.622h2.178V14Z"}
                transform={"translate(-80)"} fillRule={"evenodd"} />
        </svg>
    );
}
