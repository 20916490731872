import React, { forwardRef, Ref, useState } from "react";
import Dialog, { DialogRef } from "../../dialog/Dialog";
import styles from "../createOrUpdateAccountUserDialog/CreateOrUpdateAccountUserDialog.module.scss";
import Button from "../../button/Button";
import useForwardRef from "../../../../hooks/useForwardRef";
import AmotaiUser from "../../../../types/AmotaiUser";
import useDispatch from "../../../../hooks/useDispatch";
import { deleteUserFromAccount } from "../../../../redux/actions/users";
import useSelector from "../../../../hooks/useSelector";
import AmotaiAccount from "../../../../types/AmotaiAccount";
import { showError } from "../../../../redux/actions/snackbars";

type DeleteAccountUserDialogProps = {
    user?: AmotaiUser | null;
    onSubmit?: () => void;
    onCancelClick?: () => void;
    me: AmotaiUser;
};

function DeleteAccountUserDialog(props: DeleteAccountUserDialogProps, ref: Ref<DialogRef>) {
    const { user, me } = props;
    const dialogRef = useForwardRef<DialogRef>(ref);
    const account = useSelector<AmotaiAccount>(state => state.users.account!);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const onSubmit = async () => {
        setLoading(true);

        if (user) {
            try {
                await dispatch(deleteUserFromAccount(account.id, user.id));
            } catch (e) {
                dispatch(showError(e.message));
            }
        }

        if (props.onSubmit) {
            props.onSubmit();
        }

        setLoading(false);
        dialogRef.current?.hide();
    };

    const onCancelClick = () => {
        if (props.onCancelClick) {
            props.onCancelClick();
        }
        dialogRef.current?.hide();
    };

    return (
        <Dialog ref={dialogRef} disableBackdropClick={loading}
                title={me.email === user?.email ? "Leave account?" : "Delete user?"}>
            <div className={styles.dialog_form}>                {me.email === user?.email ? (
                    <>
                        Are you sure you would like to leave this account? <br />
                        This action cannot be undone.
                    </>
                )
                :
                (
                    <>
                        Are you sure you would like to delete <b>{user?.firstName}</b>? <br />This action cannot be
                        undone.
                    </>
                )
            }
                <div className={styles.dialog_footer}>
                    <Button onClick={onSubmit}
                            loading={loading}>{me.email === user?.email ? "Leave account" : "Delete"}</Button>
                    <span />
                    <Button plainLink onClick={onCancelClick} disabled={loading}>Cancel</Button>

                </div>
            </div>

        </Dialog>
    );
}

export default forwardRef(DeleteAccountUserDialog);
