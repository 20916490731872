import Policy from "../../types/Policy";
import { Actions, Types } from "../actions/policy";

const initialState: {[key: string]: Policy} = {}

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;


export default function policy(state= initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_POLICY: {
            const { payload } = action as ActionValueTypes<Types.SET_POLICY>
            if (!payload){
                return state
            }
            return {
                ...state,
                [payload!.slug]: payload
            }
        }

        default: {
            return state;
        }
    }
}