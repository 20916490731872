import { AccountType } from "src/types/AmotaiAccount";
import DirectoryItem from "../../types/DirectoryItem";
import { DirectoryPayloads, DirectoryTypes } from "../actions/directory";

type State = {
    [id: number]: DirectoryItem,
    sortedCategories: {
        buyer?: number[],
        supplier?: number[],
    },
}
const initialState: State = {
    sortedCategories: {},
};

export default function directory(state: State = initialState, action: { type: DirectoryTypes, payload: any }) {
    switch (action.type) {
        case DirectoryTypes.SET_DIRECTORY_ITEM: {
            const payload = action.payload as DirectoryPayloads[DirectoryTypes.SET_DIRECTORY_ITEM];
            if (!payload) {
                return state;
            }
            return {
                ...state,
                [payload.id]: payload
            };
        }
        case DirectoryTypes.SET_SORTED_CATEGORIES: {
            const payload = action.payload as DirectoryPayloads[DirectoryTypes.SET_SORTED_CATEGORIES];
            const newState = { ...state };
            if (payload.accountType === AccountType.BUYER_CLIENT) {
                newState.sortedCategories = {
                    ...newState.sortedCategories,
                    buyer: payload.categories,
                }
            } else {
                newState.sortedCategories = {
                    ...newState.sortedCategories,
                    supplier: payload.categories,
                }
            }
            return newState;
        }
        default:
            return state;
    }
}
