import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import sanityImageUrl from "./sanityImageBuilder";
import IframeBlock from "./IframeBlock";

const serializers = {
    types: {
        image: (props: any) => <img alt={props.node.alt} src={sanityImageUrl(props.node.asset)}/>,
        table: (props: any) => {
            return (
                <table>
                    {props.node.rows?.map((row: any) => (
                        <tr>
                            {row.cells.map((cell: any) => (
                                <td>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </table>
            )
        },
        iframeBlock: (props: any) => {
            return (
                <IframeBlock key={props.node._key} iframe={props.node} />
            );
        },
    }
};

type SanityBlockContentType = {
    blocks: any
}

export default function SanityBlockContent(props: SanityBlockContentType) {
    return (
        <BlockContent blocks={props.blocks} serializers={serializers}/>
    );
}
