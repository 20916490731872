import classNames from "classnames";
import { FormikProps, useFormikContext } from "formik";
import React from "react";
import * as Yup from "yup";
import SaaSPlan, { CustomizedFor } from "../../../../types/SaaSPlan";
import FormikCheckbox from "../../../widgets/formikFields/FormikCheckbox";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import IwiSelection from "../iwi/Page2";
import styles from "./BuyerClientOnboarding.module.scss";
import { BuyerClientFormValues } from "./buyerClientValidation";
import Page2 from "./Page2";
import Page2MandateAgency from "./Page2MandateAgency";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";

export const BuyerReviewValidationSchema = Yup.object({
    termsAgreed: Yup.string().required("You must accept the terms and conditions"),
})

export default function Page10(props: any) {

    const plan: SaaSPlan | undefined = props.plan as SaaSPlan | undefined;
    const { values }: FormikProps<BuyerClientFormValues> = useFormikContext()

    return (
        <div>
            <h3>Review your application</h3>
            <p>Review your application below before submitting it for review.</p>

            {values.organisationType !== "iwi" &&
                (values.organisationType === CustomizedFor.MandateAgency ? <Page2MandateAgency review /> : <Page2 review />)}
            {values.organisationType === "iwi" && <IwiSelection review />}
            <Page3 review />
            <Page4 review />
            <Page5 review />
            <Page6 review />
            <Page7 review />
            <Page8 review />
            {values.invoicePayment && plan?.invoicable && values.organisationType !== CustomizedFor.MandateAgency && (
                <div className={styles.inner_container}>
                    <SectionHeader title={"Account Payment Method"} />
                    <p>Payment to be made via {plan.invoiceInterval?.toLowerCase()} invoice, we will be in touch to arrange payment.</p>
                </div>
            )}
            <div className={classNames(styles.inner_container, styles.consent_checkboxes)}>
                <FormikCheckbox name={"termsAgreed"}
                    inputValue={"agreed"}>
                    <p>I declare that:</p>
                </FormikCheckbox>
                <p style={{ fontWeight: 'normal' }}>
                    (a) I have the authority to register this entity with Amotai.  <br />
                    (b) I have completed this Amotai application to the best of my knowledge and all the information provided is true and correct.  <br />
                    (c) I understand that a false statement may disqualify the entity I am registering in the future, if during any review, the information provided is found to be untrue. <br />
                    (d) I have read and agree to the <a href='https://amotai.nz/terms-and-conditions' target='_blank' rel="noopener noreferrer">Amotai Terms &amp; Conditions</a> <br />
                </p>
            </div>
        </div>
    )
}
