import React, { useState } from "react";
import Button from "src/components/widgets/button/Button";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import styles from "../business-details/BusinessDetails.module.scss";
import Supplier from "../../../../../types/Supplier";
import SectionHeader from "../../../../widgets/sectionHeader/SectionHeader";
import AccountHeader from "../../component/AccountHeader";
import AmotaiUser, { AccountRole } from "../../../../../types/AmotaiUser";
import useDispatch from "../../../../../hooks/useDispatch";
import useSelector from "../../../../../hooks/useSelector";
import useMountEffect from "../../../../../hooks/useMountEffect";
import { getMyAccount, updateMyAccount } from "../../../../../redux/actions/users";
import { showError } from "../../../../../redux/actions/snackbars";
import Loading from "../../../../widgets/loading/Loading";
import { OrgFinancialsValidationSchema } from "../../../onboarding/supplier/Page4";
import FormikSelect from "../../../../widgets/formikFields/FormikSelect";
import { INSURANCES, SUPPLIER_TURNOVER } from "../../../../../util/constants";
import OtherInsurancesFieldArray from "../../../../widgets/formikFields/OtherInsurancesFieldArray";
import { AccountType } from "../../../../../types/AmotaiAccount";

type FinanceAndLegalValues = {
    turnoverInLastFinancialYear: string,
    professionalIndemnity: string,
    publicLiabilityInsurance: string,
    disclosures: string,
    otherInsurances: { name: string, value: string }[]
}

export default function FinanceAndLegal() {
    const [loading, setLoading] = useState<boolean>(true);
    const [editing, setEditing] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const me = useSelector<AmotaiUser>(state => state.users.me!);
    const account = useSelector<Supplier>(state => state.users.account as Supplier);

    useMountEffect(async () => {
        try {
            await dispatch(getMyAccount());
        } catch (e) {
            dispatch(showError(e.message));
            history.push("/");
        }
        setLoading(false);
    });

    const onSubmit = async (values: FinanceAndLegalValues) => {
        try {
            setLoading(true);
            const otherInsurances = {};
            values.otherInsurances.map((o) => {
                otherInsurances[o.name] = o.value;
                return null
            });
            await dispatch(updateMyAccount(account.id, {
                ...account,
                ...values,
                otherInsurances
            }, AccountType.SUPPLIER));
            setLoading(false);
        } catch (e) {
            dispatch(showError(e.message));
            setLoading(false);
        }
    };

    const toggleEdit = async (formikBag: FormikProps<FinanceAndLegalValues>) => {
        if (editing && formikBag.dirty) {
            await onSubmit(formikBag.values);
        }

        setEditing(!editing);
        if (!editing) {
            formikBag.resetForm();
        }
    };

    if (loading) {
        return <Loading />;
    }

    const getOtherInsurancesInitialValues = () => {
        return Object.keys(account.otherInsurances || {}).map((o) => {
            return {
                name: o,
                value: (account.otherInsurances || {})[o]
            }
        });
    }

    return (
        <Formik<FinanceAndLegalValues> initialValues={{
            turnoverInLastFinancialYear: account.turnoverInLastFinancialYear || "",
            professionalIndemnity: account.professionalIndemnity || "",
            publicLiabilityInsurance: account.publicLiabilityInsurance || "",
            disclosures: account.disclosures || "",
            otherInsurances: getOtherInsurancesInitialValues() || [{ name: "", value: "" }]
        }} validationSchema={OrgFinancialsValidationSchema} onSubmit={onSubmit}>
            {(formikBag) => {
                let actionText = "Edit";
                if (editing) {
                    if (formikBag.dirty) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                return (
                    <div className={styles.business_details}>
                        <div className={styles.form}>
                            <form onSubmit={formikBag.handleSubmit}>
                                <AccountHeader title={account.name}
                                               subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                                               action={me?.accountRole === AccountRole.ADMIN && (
                                                   <Button uppercase
                                                           onClick={() => toggleEdit(formikBag)}>{actionText}</Button>
                                               )}
                                />
                                <div>
                                    <SectionHeader title={"Finance and legal"} />
                                    <div className={styles.form}>
                                        <FormikSelect name={"turnoverInLastFinancialYear"}
                                                      disabled={!editing}
                                                      label={"Annual turnover"}
                                                      options={SUPPLIER_TURNOVER}
                                                      tooltip={`Amount of revenue your ${account.organisationType === "iwi" ? "Iwi" : "business"} had from normal business activities within the financial year`} />
                                        <FormikSelect name={"professionalIndemnity"}
                                                      disabled={!editing}
                                                      label={"Professional Indemnity Insurance"}
                                                      options={INSURANCES}
                                                      tooltip={"Specify the amount of professional indemnity insurance you hold"} />
                                        <FormikSelect name={"publicLiabilityInsurance"}
                                                      disabled={!editing}
                                                      label={"Public Liability"}
                                                      options={INSURANCES}
                                                      tooltip={"Specify the amount of public liability insurance you hold"} />
                                        <OtherInsurancesFieldArray disabled={!editing} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                );
            }}
        </Formik>
    );
}