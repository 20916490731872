/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 11/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { Types } from "../actions/snackbars";
import { Action } from "../actions/helpers/createAction";
import { SnackbarConfig } from "../../components/widgets/snackbar/Snackbar";

const initialState: SnackbarConfig[] = [];

export default function snackbars(state = initialState, action: Action<any> = { type: null, payload: null }): SnackbarConfig[] {
    switch (action.type) {
        case Types.ADD_SNACKBAR: {
            const config = action.payload as SnackbarConfig;
            if (!config) {
                return state;
            }

            return [...state, config];
        }

        case Types.REMOVE_SNACKBAR: {
            return [...state].filter((snackbar) => {
                return snackbar.id !== action.payload;
            });
        }
        default: {
            return state;
        }
    }
}
