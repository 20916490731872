import React from "react";
import * as Yup from "yup";
import classNames from "classnames";
import { FieldArray, FieldArrayRenderProps, FormikProps, useFormikContext } from "formik";
import styles from "../buyerClient/BuyerClientOnboarding.module.scss";
import Button from "../../../widgets/button/Button";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import FormikInput from "../../../widgets/formikFields/FormikInput";
import { SupplierFormValues } from "./supplierValidation";
import FormikNumberInput from "../../../widgets/formikFields/FormikNumberInput";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";

const BusinessReference = Yup.object({
    projectName: Yup.string(),
    client: Yup.string(),
    contactName: Yup.string(),
    contactEmail: Yup.string().email("Not a valid email address"),
    contactPhoneNumber: Yup.string(),
});

export const BusinessReferenceValidationSchema = Yup.object({
    businessReferences: Yup.array().of(BusinessReference).min(1, "At least one reference is required")
});

export type BusinessReferenceType = {
    projectName: string;
    client: string;
    contactName: string;
    contactEmail: string;
    contactPhoneNumber: string;
}


export default function Page9(props: any) {
    const { values }: FormikProps<SupplierFormValues> = useFormikContext();


    const renderBusinessReferenceFields = (reference: BusinessReferenceType, index: number, arrayHelpers: FieldArrayRenderProps) => (
        <>
            <div className={styles.reference_row} key={index}>
                <div>
                    <FormikInput name={`businessReferences[${index}].projectName`}
                                 label={"Project name"}
                                 inputClassname={styles.full_input} />
                    <FormikInput name={`businessReferences[${index}].client`}
                                 inputClassname={classNames(styles.half_input, styles.first)}
                                 label={"Client"} />
                    <FormikInput name={`businessReferences[${index}].contactName`}
                                 inputClassname={styles.half_input}
                                 label={"Contact name"} />
                    <FormikInput name={`businessReferences[${index}].contactEmail`}
                                 inputClassname={classNames(styles.half_input, styles.first)}
                                 label={"Email address"} />
                    <FormikNumberInput name={`businessReferences[${index}].contactPhoneNumber`}
                                       phone
                                       formatMask={"(###) ###-#####"}
                                       inputClassname={styles.half_input}
                                       label={"Mobile number"} />
                </div>
                <Button plainLink onClick={() => arrayHelpers.remove(index)}>- Remove</Button>
            </div>
        </>
    );

    const renderFields = () => (
        <>
            <FieldArray name={"businessReferences"}
                        render={(arrayHelpers) => (
                            values?.businessReferences?.length > 0
                                ? (
                                    <>
                                        {values.businessReferences.map((reference: BusinessReferenceType, index: number) => renderBusinessReferenceFields(reference, index, arrayHelpers))}
                                        <div className={styles.add_button}>
                                            <Button plainLink onClick={() => arrayHelpers.push({
                                                projectName: "",
                                                client: "",
                                                contactName: "",
                                                contactEmail: "",
                                                contactPhoneNumber: ""
                                            })}>+ Add project</Button>
                                        </div>
                                    </>
                                )
                                : (
                                    <div className={styles.add_button}>
                                        <Button plainLink onClick={() => arrayHelpers.push({
                                            projectName: "",
                                            client: "",
                                            contactName: "",
                                            contactEmail: "",
                                            contactPhoneNumber: ""
                                        })}>+ Add project</Button>
                                    </div>
                                )
                        )} />
        </>
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Business references</h5>
            <p>This is the referees section. This information will help Amotai verify your business. This information
                won’t be made public.</p>
            <div className={styles.inner_container}>
                <div className={classNames(styles.onboarding_form, styles.business_reference_form)}>
                    {children}
                </div>
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Business Referees"} />
                <p><strong>This is the referees section. This information will help Amotai verify your business. This
                    information won’t be made public.</strong></p>

                {renderFields()}
            </div>
        );
    }

    return renderFormStep(renderFields());
}
