import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { Tab as MuiTab } from "@material-ui/core";
import React from "react";

interface StyledTabProps {
    label: string;
}

const SingleTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: "uppercase",
            fontFamily: "Poppins, sans serif",
            fontSize: 13,
            minWidth: 72,
            color: "#231F20",
            fontWeight: "bold",
            marginRight: theme.spacing(4),
            "&:hover": {
                color: "$TURQUOISE",
                opacity: 1,
            },
        },
        selected: {
            color: "$TURQUOISE",
            opacity: 1,
        },
    }),
)((props: StyledTabProps) => <MuiTab disableRipple {...props} />);

export default SingleTab;