import React, { useState } from "react";
import { REGIONS, CATEGORIES } from "src/util/constants";
import styles from "./Filter.module.scss";
import Input from "../input/Input";
import Select from "../select/Select";
import Button from "../button/Button";

export interface IFilterFields {
    keyword?: string,
    category?: string,
    region?: string,
    orderByField?: OrderByField,
}

export type OrderByField = "date" | "name"; //add more if more fields want to be sorted, all by ASC

type Props = {
    withRegion?: boolean,
    filterFields?: IFilterFields,
    setFilterFields: (filterFields: IFilterFields | undefined) => void,
    isEvent?: boolean,
}

const ALL_CATEGORIES = "All categories";
const ALL_REGIONS = "Nationwide";
const defaultOrderByField: OrderByField = "date";

const SORT_OPTIONS: Array<{ name: string, value: OrderByField }> = [
    { name: 'Date(Upcoming first)', value: 'date' },
    { name: 'Name', value: 'name' },
]

export default function Filter(props: Props) {
    const {
        withRegion,
        filterFields,
        setFilterFields,
        isEvent,
    } = props;

    const [keyword, setKeyword] = useState<string | undefined>(filterFields?.keyword);
    const [category, setCategory] = useState<string | undefined>(filterFields?.category);
    const [region, setRegion] = useState<string | undefined>(filterFields?.region);
    const [orderByField, setOrderByField] = useState<OrderByField | undefined>(filterFields?.orderByField);

    const doFilter = () => {
        const filters: IFilterFields = { keyword, category, region, orderByField };
        if (!category || category === ALL_CATEGORIES) {
            delete filters.category;
        }
        if (!region || region === ALL_REGIONS) {
            delete filters.region;
        }
        if (!orderByField || orderByField === defaultOrderByField) {
            delete filters.orderByField;
        }
        setFilterFields(filters);
    };

    const doReset = () => {
        setKeyword(undefined);
        setCategory(undefined);
        setRegion(undefined);
        setOrderByField(undefined);
        setFilterFields(undefined);
    };

    return (
        <div className={styles.search_filter_container}>
            <Input placeholder={"Search keywords"} label={"Filter by keywords"} onChange={setKeyword}
                defaultValue={keyword} />
            {!isEvent && <Select label={"Filter by category"} options={[ALL_CATEGORIES, ...Object.keys(CATEGORIES)]}
                onChange={setCategory} defaultValue={category} />}
            {isEvent && <Select label='Sort by' options={SORT_OPTIONS}
                onChange={(v: OrderByField) => setOrderByField(v)} defaultValue={defaultOrderByField} />}
            {withRegion && (
                <Select label={"Filter by regions"} options={REGIONS} onChange={setRegion} defaultValue={region} />
            )}
            <Button style={{ marginTop: "18px" }} onClick={doFilter}>Filter</Button>
            <Button borderwhite onClick={doReset}>Clear filter</Button>
        </div>
    );
}