import React, { ReactNode, useState } from "react";
import classNames from "classnames";
import styles from "./AlertBar.module.scss";

type Props = {
    content?: ReactNode,
    children?: ReactNode,
    dismissable?: boolean,
    containerClassName?: string,
    grey?: boolean,
}

export default function AlertBar({ content, children, dismissable, containerClassName, grey }: Props) {
    const mainClass = classNames(styles.bar, {
        [styles.grey]: grey
    })
    const containerClass = classNames(styles.content, containerClassName);
    const [show, setShow] = useState<boolean>(true);
    const dismiss = () => {
        setShow(false);
    };

    return show ? (
        <div className={mainClass}>
            <div className={containerClass}>{content || children}</div>
            {dismissable && <div className={styles.dismiss} onClick={dismiss}>{`Dismiss`}</div>}
        </div>
    ) : <></>;
}