/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React from "react";
import Spinner from "../spinner/Spinner";
import Icon from "../../../resources/images/png/icon_loader.png";
import styles from "./PageLoader.module.scss";

export default function PageLoader() {
    return (
        <div className={styles.page_container}>
            <div className={styles.content}>
                <img src={Icon} alt={"Amotai"} />
                <h3>Loading...</h3>
                <Spinner vCenter hCenter containerClassName={styles.loading} />
            </div>
        </div>
    );
}

