import React, { useRef, useState } from "react";
import * as Yup from "yup";
import Button from "src/components/widgets/button/Button";
import firebase from "firebase/app";
import "firebase/auth";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import FormikInput from "../../widgets/formikFields/FormikInput";
import OnboardingSidebar from "../../widgets/onboardingSidebar/OnboardingSidebar";
import SideBar from "../../../resources/images/png/onboarding-amotai-sidebar@2x.png";
import OnboardingContent from "../../widgets/onboardingContent/OnboardingContent";
import styles from "./ForgotPassword.module.scss";
import Dialog, { DialogRef } from "../../widgets/dialog/Dialog";
import useDispatch from "../../../hooks/useDispatch";
import { showError, showSuccess } from "../../../redux/actions/snackbars";

interface ForgotPasswordFormValues {
    email: string;
}

const ForgotPasswordValidationSchema = Yup.object({
    email: Yup.string()
        .trim()
        .email("Must be a valid email address")
        .required("Required"),
});

export default function ForgotPassword() {
    const dialogRef = useRef<DialogRef>(null);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);

    const onForgotPasswordSubmit = async ({ email }: ForgotPasswordFormValues) => {
        setLoading(true);
            firebase.auth()
                .sendPasswordResetEmail(email)
                .then(() => {
                    dispatch(showSuccess("Password reset email sent. Please check inbox or spam folder."))
                    setLoading(false);
                    dialogRef?.current?.hide();
                })
                .catch((e) => {
                    dispatch(showError("There was an error resetting your password. Please try again."))
                    setLoading(false);
                })
    };

    return (
        <div className={styles.password_reset_container}>
            <OnboardingSidebar sidebarImage={SideBar}>
                <div  className={styles.sidebar_content_container}>
                    <div>
                        <h3>
                            You’re just a few clicks away from being connected to our Amotai Supplier and Member database
                        </h3>
                    </div>

                    {/*<div className={styles.image_caption}>*/}
                    {/*    <p>Featured: Buyer name, Business</p>*/}
                    {/*</div>*/}
                </div>

            </OnboardingSidebar>
            <OnboardingContent>
                <div className={styles.password_reset_content_container}>
                    <h5>Request to reset your password</h5>
                    <p>Please enter your email address below to receive a password reset link.
                    </p>

                    <div className={styles.inner_container}>

                        <Formik<ForgotPasswordFormValues>
                            initialValues={{
                            email: "",
                        }}
                            onSubmit={onForgotPasswordSubmit} validationSchema={ForgotPasswordValidationSchema}
                            enableReinitialize>
                            {({ dirty, isValid, handleSubmit }) => (
                                <Form>
                                    <div className={styles.password_reset_form}>
                                        <FormikInput name={"email"} label={"Email"} />
                                        <Link to={"/log-in"}> Back to login</Link>
                                    </div>
                                    <div className={styles.button_container}>
                                        <p>Don&apos;t have an account? <Link to={"/sign-up"}>Sign up</Link></p>
                                        <Button onClick={dialogRef?.current?.show} disabled={!dirty || !isValid}>Send reset link</Button>
                                    </div>

                                    <Dialog title={"Forgot Password"} ref={dialogRef} className={styles.reset_confirm_dialog}>
                                        <p>Are you sure you would like to reset your password?</p>
                                        <div className={styles.dialog_button_wrapper}>
                                            <Button loading={loading} onClick={() => handleSubmit()}>Confirm</Button>
                                            <Button  plainLink onClick={dialogRef?.current?.hide}>Cancel</Button>

                                        </div>
                                    </Dialog>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </OnboardingContent>
        </div>
    );
}