import SaaSPlan, { CustomizedFor } from "src/types/SaaSPlan";
import SaaSSubscription from "src/types/SaaSSbscription";
import get from "./util/get";
import post from "./util/post";

function getActivePlans(): Promise<SaaSPlan[]> {
    return get("v1/plans/active");
}

function getAccountSubscription(accountId: number): Promise<SaaSSubscription> {
    return get(`v1/accounts/${accountId}/subscription`);
}

function createOrUpdateSubscription(accountId: number, planId: number, upgradeLater?: boolean): Promise<SaaSSubscription> {
    return post(`v1/accounts/${accountId}/plan/${planId}/subscription?upgradeLater=${!!upgradeLater}`);
}

function getCustomizedPlans(customizedFor: CustomizedFor): Promise<SaaSPlan[]> {
    return get(`v1/plans/types/${customizedFor}`);
}

const SaaSPlanAPI = {
    getActivePlans,
    getAccountSubscription,
    createOrUpdateSubscription,
    getCustomizedPlans,
};

export default SaaSPlanAPI;