import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import AmotaiUser from "../../../../../types/AmotaiUser";
import BuyerClient from "../../../../../types/BuyerClient";
import Supplier from "../../../../../types/Supplier";
import { getMyAccount } from "../../../../../redux/actions/users";
import useDispatch from "../../../../../hooks/useDispatch";
import styles from "./Billing.module.scss";
import AmotaiAccount, { AccountType, PaymentType } from "../../../../../types/AmotaiAccount";
import Cards from "./cards/Cards";
import AccountHeader from "../../component/AccountHeader";
import Subscriptions from "./subscriptions/Subscriptions";
import Transactions from "./transactions/Transactions";
import { showError } from "../../../../../redux/actions/snackbars";
import PageLoader from "../../../../widgets/pageLoader/PageLoader";


export default function Billing() {
    const [loading, setLoading] = useState<boolean>(true);
    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector<AmotaiUser | undefined | null>((state) => state.users.me);
    const isOrgAdmin = useSelector<boolean>(state => state.users.isOrgAdmin);
    const account = useSelector<AmotaiAccount | Supplier | BuyerClient | undefined | null>((state) => state.users.account);

    useMountEffect(async () => {
        if (!user) {
            history.push("/");
            return;
        }

        if (!account) {
            try {
                await dispatch(getMyAccount());
            } catch (e) {
                dispatch(showError(e.message));
            }
        }

        setLoading(false);
    });

    if (loading) {
        return <PageLoader />;
    }

    return (
        <div className={styles.billing}>
            <AccountHeader title={account?.name!}
                           subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
            />
            {account?.type === AccountType.BUYER_CLIENT &&
            account?.paymentType !== PaymentType.FUNDER && (
                <Subscriptions />
            )}
            {isOrgAdmin && <Cards accountId={account?.id!} />}
            {isOrgAdmin && <Transactions accountId={account?.id!} />}
        </div>
    );
}
