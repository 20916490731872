import React from "react";
import * as Yup from "yup";
import classNames from "classnames";
import { useFormikContext } from "formik";
import styles from "./BuyerClientOnboarding.module.scss";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import BusinessContactFieldArray from "../../../widgets/formikFields/BusinessContactFieldArray";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import { BuyerClientFormValues } from "./buyerClientValidation";

const BusinessContactValidation = Yup.object({
    name: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    avatar: Yup.string().required("Required")
});

export const BuyerBusinessContactValidationSchema = Yup.object({
    businessContacts: Yup.array().of(BusinessContactValidation)
        .min(1, "At least one contact is required")
});

export default function Page8(props: any) {
    const { values } = useFormikContext<BuyerClientFormValues>();
    const selectedPlan = props.plan;

    const renderFields = () => <BusinessContactFieldArray max={selectedPlan?.maxContacts} />;

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Who can businesses get in touch with for work?</h5>
            <p>These contacts will be shared on your business profile so that suppliers can contact you directly.</p>
            <div className={styles.inner_container}>
                <div className={classNames(styles.onboarding_form, styles.business_contact_form)}>
                    {children}
                </div>
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={classNames(styles.inner_container, styles.business_contact_form)}>
                <SectionHeader title={`${values.organisationType === "iwi" ? "Iwi" : "Business"} Profile Contacts`} />
                <p>
                    <strong>
                        These contacts will be shared on your {values.organisationType === "iwi" ? "Iwi" : "business"} profile so that suppliers can contact you
                        directly.
                    </strong>
                </p>
                {renderFields()}
            </div>
        );
    }

    return renderFormStep(renderFields());
}