import OldSupplierAccount from "../../types/OldSupplierAccount";
import { Actions, Types } from "../actions/oldSupplierAccount";

const initialState: {
    oldSupplierAccount: OldSupplierAccount | null
} = {
    oldSupplierAccount: null
};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function oldSupplierAccount(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_OLD_SUPPLIER_ACCOUNT: {
            const { payload } = action as ActionValueTypes<Types.SET_OLD_SUPPLIER_ACCOUNT>;
            return {
                ...state,
                oldSupplierAccount: payload
            };
        }
        default: {
            return state;
        }
    }
}