import React, { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { Formik, FormikProps } from "formik";
import styles from "../business-details/BusinessDetails.module.scss";
import AccountHeader from "../../component/AccountHeader";
import AmotaiUser, { AccountRole } from "../../../../../types/AmotaiUser";
import Button from "../../../../widgets/button/Button";
import Supplier from "../../../../../types/Supplier";
import useSelector from "../../../../../hooks/useSelector";
import useDispatch from "../../../../../hooks/useDispatch";
import Loading from "../../../../widgets/loading/Loading";
import SectionHeader from "../../../../widgets/sectionHeader/SectionHeader";
import FormikNumberInput from "../../../../widgets/formikFields/FormikNumberInput";
import FormikInput from "../../../../widgets/formikFields/FormikInput";
import FormikTextArea from "../../../../widgets/formikFields/FormikTextArea";
import { OrgEmployeesValidationSchema } from "../../../onboarding/supplier/Page6";
import { getMyAccount, updateMyAccount } from "../../../../../redux/actions/users";
import { AccountType } from "../../../../../types/AmotaiAccount";
import { showError } from "../../../../../redux/actions/snackbars";
import useMountEffect from "../../../../../hooks/useMountEffect";

type EmploymentValues = {
    totalFTE: string,
    maoriEmployees: string,
    pasifikaEmployees: string,
    nonMaleEmployees: string,
    employeesUnderTwentyFour: string,
    pathwaysProvided: string,
};

export default function Employment() {
    const [loading, setLoading] = useState<boolean>(true);
    const [editing, setEditing] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const me = useSelector<AmotaiUser>(state => state.users.me!);
    const account = useSelector<Supplier>(state => state.users.account as Supplier);

    useMountEffect(async () => {
        try {
            await dispatch(getMyAccount());
        } catch (e) {
            dispatch(showError(e.message));
            history.push("/");
        }

        setLoading(false);
    });

    const onSubmit = async (values: EmploymentValues) => {
        try {
            setLoading(true);
            await dispatch(updateMyAccount(account.id, {
                ...account,
                ...values
            }, AccountType.SUPPLIER));
        } catch (e) {
            dispatch(showError(e.message));
        }

        setLoading(false);
    };

    const toggleEdit = async (formikBag: FormikProps<EmploymentValues>) => {
        if (editing && formikBag.dirty) {
            await onSubmit(formikBag.values);
        }

        setEditing(!editing);
        if (!editing) {
            formikBag.resetForm();
        }
    };

    if (loading) {
        return <Loading />;
    }


    return (
        <Formik<EmploymentValues> initialValues={{
            totalFTE: account.totalFTE || "",
            maoriEmployees: account.maoriEmployees || "",
            pasifikaEmployees: account.pasifikaEmployees || "",
            nonMaleEmployees: account.nonMaleEmployees || "",
            employeesUnderTwentyFour: account.employeesUnderTwentyFour || "",
            pathwaysProvided: account.pathwaysProvided || "",
        }} onSubmit={onSubmit} validationSchema={OrgEmployeesValidationSchema}>
            {(formikBag) => {
                let actionText = "Edit";
                if (editing) {
                    if (formikBag.dirty) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                return (
                    <div className={styles.business_details}>

                        <form onSubmit={formikBag.handleSubmit}>

                            <AccountHeader title={account?.name!}
                                           subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                                           action={me?.accountRole === AccountRole.ADMIN && (
                                               <Button uppercase
                                                       onClick={() => toggleEdit(formikBag)}>
                                                   {actionText}
                                               </Button>
                                           )} />
                            <div>
                                <SectionHeader title={"Employment"} />
                                <div className={styles.form}>
                                    <div className={styles.form_section}>
                                        <FormikNumberInput name={"totalFTE"}
                                                           disabled={!editing}
                                                           label={"Size of workforce (Total number of Employees)"}
                                                           allowNegative={false}
                                        />
                                        <hr />
                                        <FormikInput name={"maoriEmployees"}
                                                     disabled={!editing}
                                                     inputClassname={classNames(styles.half_input, styles.first)}
                                                     label={"Number of Māori employees"}
                                        />
                                        <FormikInput name={"pasifikaEmployees"}
                                                     disabled={!editing}
                                                     inputClassname={styles.half_input}
                                                     label={"Number of Pasifika employees"}
                                        />
                                        <hr />
                                        <FormikInput name={"nonMaleEmployees"}
                                                     disabled={!editing}
                                                     inputClassname={classNames(styles.half_input, styles.first)}
                                                     label={"Number of female employees"}
                                        />
                                        <FormikInput name={"employeesUnderTwentyFour"}
                                                     disabled={!editing}
                                                     inputClassname={styles.half_input}
                                                     label={"Number of Employees under 24"}
                                        />
                                        <hr />
                                        <FormikTextArea name={"pathwaysProvided"}
                                                        disabled={!editing}
                                                        label={"Pathways provided"}
                                                        className={styles.paragraph_input}
                                                        tooltip={"Provide details on any pathways you provide your staff. For example, apprenticeships, specialised training, first aid etc"} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                );
            }}
        </Formik>
    );
}