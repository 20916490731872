import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import useSubscription from "src/hooks/useSubscription";
import { CustomizedFor } from "src/types/SaaSPlan";
import { currency } from "src/util/helper";
import useDispatch from "../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../hooks/useSelector";
import { getAccountSubscription } from "../../../../../../redux/actions/saaSPlans";
import { getMyAccount, getMyUser } from "../../../../../../redux/actions/users";
import AmotaiAccount, { PaymentType } from "../../../../../../types/AmotaiAccount";
import AmotaiUser from "../../../../../../types/AmotaiUser";
import BuyerClient from "../../../../../../types/BuyerClient";
import SaaSSubscription, { SaaSSubscriptionStatus, SubscriptionPaymentType } from "../../../../../../types/SaaSSbscription";
import Supplier from "../../../../../../types/Supplier";
import AlertBar from "../../../component/AlertBar";
import styles from "../Billing.module.scss";
import SaaSPlans from "./SaaSPlans";

export default function Subscriptions() {
    const [, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const user = useSelector<AmotaiUser | undefined | null>((state) => state.users.me);
    const account = useSelector<AmotaiAccount | Supplier | BuyerClient | undefined | null>((state) => state.users.account);
    const subscription = useSelector<SaaSSubscription | undefined>(state => state.saaSPlans.subscription!);
    const upgradePlan = subscription?.upgradePlan;
    const { nextIntervalString, currentPaymentTypeText, nextPaymentTypeText } = useSubscription(subscription);

    useEffect(() => {
        if (!subscription && account) {
            dispatch(getAccountSubscription(account.id));
        }
    }, [subscription, account, dispatch])

    const isMandateAgency = useMemo(() => {
        return account?.organisationType === CustomizedFor.MandateAgency && subscription?.plan.customizedFor === CustomizedFor.MandateAgency
    }, [account, subscription]);

    useMountEffect(async () => {
        if (!user) {
            await dispatch(getMyUser());
        }

        if (!account) {
            const acc = await dispatch(getMyAccount());
            await dispatch(getAccountSubscription(acc.id));
        }

        if (account && account.paymentType !== PaymentType.IWI && !subscription) {
            await dispatch(getAccountSubscription(account.id));
        }

        setLoading(false);
    });

    const getAlertBarText = () => {
        if (isMandateAgency) {
            return (subscription &&
                <span>
                    <strong>You are currently on the Mandated Agency plan tier. </strong>
                    <span>Your access has been extended.</span>
                </span>
            )
        }

        if (subscription?.state === SaaSSubscriptionStatus.CANCELLING) {
            return (
                <span>
                    <strong>You are currently on the ${subscription.plan.name} tier.</strong>
                    <span>Your subscription will be cancelled on {moment(subscription.termEndsAt).format("DD MMMM yyyy")}. </span>
                    <span>Contact support if you need help.</span>
                </span>
            );
        }

        if (upgradePlan) {

            const charge = (subscription?.nextPaymentType ?? subscription?.subscriptionPaymentType) === SubscriptionPaymentType.INVOICE
                ? (upgradePlan.invoiceAnnualAmount! / 100)
                : (upgradePlan.amount / 100);
            const upgradePlanCharge = `$${currency(charge.toFixed(2))}`
            if (subscription && subscription.plan.amount > upgradePlan.amount) {
                return (
                    <span>
                        <strong>You are currently on the {subscription.plan.name} tier. </strong>
                        <span>You will be downgraded to {upgradePlan.name} on {moment(subscription.termEndsAt).format("DD MMMM yyyy")}. </span>
                        <span>You will be charged {upgradePlanCharge} on {moment(subscription.termEndsAt).format("DD MMMM yyyy")} </span>
                        <span>and per {nextIntervalString} thereafter. Contact support if you need help </span>
                    </span>
                );
            }

            return (

                <span>
                    <strong>You are currently on the {subscription?.plan.name} tier. </strong>
                    <span>You will be upgraded to {upgradePlan.name} on {moment(subscription?.termEndsAt).format("DD MMMM yyyy")}. </span>
                    <span>You will be charged {upgradePlanCharge} on {moment(subscription?.termEndsAt).format("DD MMMM yyyy")} </span>
                    <span>and every {nextIntervalString} thereafter. </span>
                    <span>Contact support if you need help</span>
                </span>
            );

        }

        if (subscription?.nextPaymentType) {
            return (
                <span>
                    <span>You are currently on <strong>{subscription.plan.name}</strong> tier </span>
                    <span>with <strong>{currentPaymentTypeText}</strong> payment type. </span>
                    <span>Your next payment will be updated to <strong>{nextPaymentTypeText}</strong> payment type </span>
                    <span>on {moment(subscription.termEndsAt).format("DD MMMM yyyy")} and {nextIntervalString} after.</span>
                </span>
            )
        }

        if (subscription?.subscriptionPaymentType === SubscriptionPaymentType.INVOICE) {
            return (
                <span>
                    <strong>You are currently on the {subscription.plan.name} tier. </strong>
                    <strong>Your invoice will be forwarded {`${subscription.plan.invoiceInterval?.toLowerCase()}ly`} by an Amotai representative. </strong>
                    <span>If you have any questions or would like to be charged directly for your membership fee, please get in touch at </span>
                    <a href="mailto:kiaora@amotai.nz">
                        <span className={styles.mail}>kiaora@amotai.nz</span>
                    </a>
                </span>
            )
        }

        return (
            <span>
                <strong>You are currently on {subscription?.plan.name} tier. </strong>
                <span>Your next bill is on {moment(subscription?.termEndsAt).format("DD MMMM yyyy")} and {nextIntervalString} after. </span>
                <span>Contact support if you need help</span>
            </span>
        );
    };

    return (
        <div>
            <h5>Subscription</h5>
            {subscription && (
                <>
                    <AlertBar content={getAlertBarText()} />
                    {subscription.subscriptionPaymentType === SubscriptionPaymentType.INVOICE ? (
                        <div className={styles.items}>
                            <div className={styles.item}>
                                <span className={styles.label}>Plan name</span>
                                <span>{isMandateAgency ? 'Mandated Agency Plan' : subscription.plan.name}</span>
                            </div>
                            <div className={styles.item}>
                                <span className={styles.label}>Billing method</span>
                                <span>{isMandateAgency ? 'N/A' : 'Invoiced'}</span>
                            </div>
                            <div className={styles.item}>
                                <span className={styles.label}>Billing frequency</span>
                                <span>{isMandateAgency ?
                                    `Free of charge`
                                    :
                                    `$${currency(((subscription.plan.invoiceAnnualAmount ?? 0) / 100).toFixed(2))} per annum`}</span>
                            </div>
                        </div>
                    ) : (
                            <div className={styles.items}>
                                <div className={styles.item}>
                                    <span className={styles.label}>Plan name</span>
                                    <span>{subscription.plan.name}</span>
                                </div>
                                <div className={styles.item}>
                                    <span className={styles.label}>Next billing date</span>
                                    <span>{new Date(subscription.termEndsAt).toLocaleDateString("en-NZ")}</span>
                                </div>
                                <div className={styles.item}>
                                    <span className={styles.label}>Bill amount</span>
                                    <span>${`${currency((subscription.plan.amount / 100).toFixed(2))} per month`}</span>
                                </div>
                            </div>
                        )}
                </>
            )}

            {account?.paymentType === PaymentType.IWI && !subscription && (
                <div className={styles.items}>
                    <div className={styles.item}>
                        <span className={styles.label}>Plan name</span>
                        <span>Iwi</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.label}>Billing method</span>
                        <span>Free</span>
                    </div>
                </div>
            )}

            <SaaSPlans />
        </div>
    );
}
