import React from "react";
import styles from "./BlogCard.module.scss";
import Button from "../button/Button";
import Post from "../../../types/Post";
import moment from "moment/moment";
import { useHistory } from "react-router-dom";
import {CardMedia} from "@material-ui/core";

type Props = {
    post: Post;
}

export default function BlogCard(props: Props){
    const { post } = props;
    const history = useHistory();

    const handleCardClick = () => {
        history.push(`/blog/${post.slug}`)
    };

    return(
        <div className={styles.blog_card} onClick={handleCardClick}>
            <CardMedia
                image={post.image}
                className={styles.image}
            />

            <span className={styles.card_date}>{moment(post.createdAt).format("D MMM YYYY")}</span>
            <h5>{post.title}</h5>
            <p>{post.subtitle}</p>
            <Button plainLink uppercase disableRipple>Read article &gt;&gt;</Button>
        </div>
    )
}