import { Paper } from "@material-ui/core";
import React, { ReactNode, useMemo } from "react";
import SaaSPlan from "src/types/SaaSPlan";
import { currency } from "src/util/helper";
import styles from "./PlanCard.module.scss";

type Props = {
    plan: SaaSPlan;
    actions?: ReactNode;
    label?: string;
    className?: string;
    showAnnualPrice?: boolean;
}
export default function PlanCard({ plan, actions, label, className, showAnnualPrice }: Props) {

    const price = useMemo(() => {
        const { amount, invoiceAnnualAmount } = plan;
        if (showAnnualPrice) {
            return currency(((invoiceAnnualAmount ?? 0) / 100).toFixed(2))
        }
        return currency(((amount ?? 0) / 100).toFixed(2));
    }, [plan, showAnnualPrice]);

    return (
        <Paper classes={{ root: styles.paper }} className={className}>
            <div className={styles.container}>
                <div className={styles.label}>
                    {label ?? plan.name}
                </div>
                <div className={styles.price_container}>
                    <div className={styles.price}>
                        <span>$</span>
                        <div>{price}</div>
                    </div>
                    <div className={styles.monthly}>
                        {showAnnualPrice ? 'per annum' : 'per month'}
                    </div>
                </div>
                <div className={styles.plan_details}>
                    <span>{plan.maxUsers > 25 ? "Unlimited licensed users" : `${plan.maxUsers > 1 ? `${plan.maxUsers} licensed users` : `${plan.maxUsers} licensed user`}`}</span>
                    {plan.fullProfile && <span>{plan.maxContacts > 25 ? "Unlimited contacts" : `${plan.maxContacts ?? 0} buyer contacts on profile`}</span>}
                    {plan.displayBadge && <span>Champion badge</span>}
                </div>
            </div>
            <div className={styles.action_container}>
                {actions}
            </div>
        </Paper>
    );
};