import React, { Ref } from "react";
import styles from "./ContactCardDialog.module.scss";
import Dialog, { DialogRef } from "../../dialog/Dialog";
import useForwardRef from "../../../../hooks/useForwardRef";
import Button from "../../button/Button";
import BusinessContact from "../../../../types/BusinessContact";

type ContactCardDialogProps = {
    onClose: () => void;
    businessContact: BusinessContact | null
};

function ContactCardDialog(props: ContactCardDialogProps, ref: Ref<DialogRef>) {
    const dialogRef = useForwardRef<DialogRef>(ref);
    const { businessContact } = props;

    const onClose = () => {
        dialogRef.current?.hide();
        props.onClose();
    };

    if (!businessContact) {
        return null;
    }

    return (
        <Dialog ref={dialogRef} className={styles.contact_card_container}>
            <div className={styles.image_container}
                 style={{display: !businessContact.avatar ? "none" : "block", backgroundImage:  `url(${businessContact.avatar})`}}/>
            <h4>{businessContact.name}</h4>
            <a href={`mailto:${businessContact.email}`}>{businessContact.email}</a>
            <a href={`tel:${businessContact.phoneNumber}`}>{businessContact.phoneNumber}</a>
            <Button onClick={onClose}>Close</Button>
        </Dialog>
    );
}

export default React.forwardRef(ContactCardDialog);