import firebase from "firebase/app";
import "firebase/auth";
import { Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { showError, showSuccess } from "../../../../../redux/actions/snackbars";
import { updateMyProfile } from "../../../../../redux/actions/users";
import AmotaiAccount from "../../../../../types/AmotaiAccount";
import AmotaiUser, { SystemRole } from "../../../../../types/AmotaiUser";
import Button from "../../../../widgets/button/Button";
import FormikFileUpload from "../../../../widgets/formikFields/FormikFileUpload";
import FormikInput from "../../../../widgets/formikFields/FormikInput";
import FormikNumberInput from "../../../../widgets/formikFields/FormikNumberInput";
import Loading from "../../../../widgets/loading/Loading";
import AccountHeader from "../../component/AccountHeader";
import styles from "./MyProfile.module.scss";

type MyProfileValues = {
    avatar: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    phoneNumber: string;
    mobileNumber: string;
    email: string;
}

export default function MyProfile() {
    const account = useSelector<AmotaiAccount>(state => state.users.account!);
    const me = useSelector<AmotaiUser>(state => state.users.me!);
    const [loading, setLoading] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useMountEffect(() => {
        if (!me || !me.account || me.systemRole === SystemRole.SUPPLIER_VIEWER) {
            history.push("/");
        }
    });

    const toggleEdit = () => setIsEditing(!isEditing);

    const onSubmit = async (values: MyProfileValues) => {
        setLoading(true);
        await dispatch(updateMyProfile(values));
        setLoading(false);
    };

    const onEditSaveClick = async (formikBag: FormikProps<MyProfileValues>) => {
        if (isEditing && formikBag.dirty) {
            await onSubmit(formikBag.values);
        }

        toggleEdit();

        if (!isEditing) {
            formikBag.resetForm();
        }
    };

    const onResetPasswordClick = async () => {
        setLoading(true);
        try {
            await firebase.auth().sendPasswordResetEmail(me.email);
            dispatch(showSuccess("Check your email for your reset link"));
            setLoading(false);
        } catch (e) {
            dispatch(showError(e.message));
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <Loading />
        );
    }

    return (
        <>
            <Formik<MyProfileValues> initialValues={{
                avatar: me.avatar || "",
                firstName: me.firstName || "",
                lastName: me.lastName || "",
                jobTitle: me.jobTitle || "",
                email: me.email || "",
                phoneNumber: me.phoneNumber || "",
                mobileNumber: me.mobileNumber || ""
            }} onSubmit={onSubmit}>
                {(formikBag) => {
                    let actionText = "Edit";

                    if (isEditing) {
                        if (formikBag.dirty) {
                            actionText = "Save";
                        } else {
                            actionText = "Cancel";
                        }
                    }

                    return (
                        <div className={styles.my_profile}>
                            <form onSubmit={formikBag.handleSubmit}>
                                <AccountHeader title={account.name}
                                    subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                                    action={(
                                        <Button type={"button"}
                                            uppercase
                                            onClick={() => onEditSaveClick(formikBag)}>
                                            {actionText}
                                        </Button>
                                    )}
                                />

                                <div>
                                    <h5>My Profile</h5>
                                    <div className={styles.profile_details}>
                                        <div className={styles.avatar}>
                                            {isEditing ? (
                                                <FormikFileUpload
                                                    name={"avatar"}
                                                    preview={me.avatar}
                                                    accept='image/*'
                                                    label={"Upload image"}
                                                />
                                            )
                                                : (
                                                    <div style={{ backgroundImage: `url(${me.avatar})` }}
                                                        className={styles.avatar_image} />
                                                )}
                                        </div>
                                        <div className={styles.text}>
                                            {isEditing ? (
                                                <>
                                                    <FormikInput name={"firstName"} label={"First Name"} />
                                                    <FormikInput name={"lastName"} label={"Last Name"} />
                                                </>
                                            ) : (
                                                    <div className={styles.disabled_input}>
                                                        <label>Name</label>
                                                        <span>{`${me.firstName} ${me.lastName}`}</span>
                                                    </div>
                                                )}

                                            {isEditing ? (
                                                <FormikInput name={"jobTitle"}
                                                    disabled={!isEditing}
                                                    label={"Job title"} />
                                            )
                                                :
                                                (
                                                    <div className={styles.disabled_input}>
                                                        <label>Job title</label>
                                                        <span>{me.jobTitle}</span>
                                                    </div>
                                                )}
                                            {isEditing ? (
                                                <FormikNumberInput name={"phoneNumber"} phone disabled={!isEditing}
                                                    label={"Phone number"} />
                                            )
                                                :
                                                (
                                                    <div className={styles.disabled_input}>
                                                        <label>Phone number</label>
                                                        <span>{me.phoneNumber}</span>
                                                    </div>
                                                )}
                                            {isEditing ? (
                                                <FormikNumberInput name={"mobileNumber"} phone disabled={!isEditing}
                                                    label={"Mobile number"} />
                                            )
                                                :
                                                (
                                                    <div className={styles.disabled_input}>
                                                        <label>Mobile number</label>
                                                        <span>{me.mobileNumber}</span>
                                                    </div>
                                                )}
                                            {isEditing ? (
                                                <FormikInput name={"email"}
                                                    disabled={!isEditing}
                                                    label={"Email address"} />
                                            )
                                                :
                                                (
                                                    <div className={styles.disabled_input}>
                                                        <label>Email address</label>
                                                        <span>{me.email}</span>
                                                    </div>
                                                )}
                                            <Button plainLink type={"button"} onClick={onResetPasswordClick}>
                                                Reset password
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                    );
                }}
            </Formik>
        </>
    );
}
