import { DateTime } from "luxon";
import MaterialTable, { Column, MaterialTableProps } from "material-table";
import React, { Ref, useState } from "react";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import useMountEffect from "../../../hooks/useMountEffect";
import ListResult from "../../../types/ListResult";
import styles from "./PagingTable.module.scss";

export type PagingTableFilter = {
    limit?: number;
    order?: string | null;
};

export type DataFilter<F extends PagingTableFilter> = {
    cursor: string | null;
} & F;

export type PagingTableState<T> = {
    page: number;
    cursors: (string | null)[];
    pages: T[][];
    lastUpdatedAt: null | string;
};

type Props<T extends Object, F extends PagingTableFilter> = {
    tableRef?: Ref<PagingTableRef<F>>;
    title?: string;
    getData: (filter: DataFilter<F>) => Promise<ListResult<T>>;
    setFilter: (filter: PagingTableFilter) => void;
    columns: Column<T>[];
    filter: F;
    onError?: (err: any) => void;
} & Omit<MaterialTableProps<T>, "columns" | "data">;

export type PagingTableRef<F> = {};

export default function PagingTable<T extends object, F extends PagingTableFilter>(props: Props<T, F>) {
    const { title, getData, setFilter, columns, filter, onError, options, ...otherProps } = props;

    const [INITIAL_STATE] = useState<PagingTableState<T>>({
        page: 0,
        cursors: [],
        pages: [],
        lastUpdatedAt: null,
    });
    const [state, setState] = useState<PagingTableState<T>>(INITIAL_STATE);

    const { pages, cursors } = state;

    const allPages = Object.values(pages).reduce((all, items) => {
        return all.concat(items);
    }, []);

    const [loading, setLoading] = useState(true);

    const getNewData = async (pageIndex: number, cursor: string | null) => {
        if (pageIndex !== 0 && !cursor) {
            return;
        }
        setLoading(true);
        const { items, nextCursor } = await getData({ ...filter, cursor });

        setState((oldState) => {
            const newCursors = [...oldState.cursors];
            newCursors.push(nextCursor);

            const newPages = [...oldState.pages];
            newPages.push(items || []);

            return {
                page: oldState.page,
                cursors: newCursors,
                pages: newPages,
                lastUpdatedAt: DateTime.local().toISO(),
            };
        });

        setLoading(false);
    };

    useMountEffect(() => {
        setState(INITIAL_STATE);
    });

    useAsyncEffect(
        async () => {
            await setState(INITIAL_STATE);
            await getNewData(0, null);
        },
        [filter],
        onError,
    );

    const onFilterChange = async (newFilter: F) => {
        await setFilter(newFilter);
    };

    const onChangePage = (page: number) => {
        const hasNextPage = !!pages[page];
        setState((oldState) => ({
            ...oldState,
            page,
        }));
        if (state.page < page && !hasNextPage) {
            const cursor = cursors[state.page];
            getNewData(state.page, cursor).catch(onError);
        }
    };

    const onChangeRowsPerPage = (rowPerPage: number) => {
        onFilterChange({
            ...filter,
            limit: rowPerPage,
        });
    };

    const onOrderChange = (orderBy: number, orderDirection: "asc" | "desc") => {
        const column = columns[orderBy];
        let field = column?.field || null;
        if (field) {
            const prefix = orderDirection === "desc" ? "-" : "";
            field = `${prefix}${field}`;
        }
        onFilterChange?.({
            ...filter,
            order: field,
        });
    };

    const hasCursor = !!cursors[state.page];
    const nextPage = pages[state.page + 1];
    const hasNextPage = !!nextPage;
    const nextPageEmpty = hasNextPage && !nextPage.length;

    if ((hasCursor || loading) && !nextPageEmpty) {
        // @ts-ignore
        allPages.push({
            user: {},
        });
    }

    return (
        <MaterialTable<T>
            {...otherProps}
            title={title}
            isLoading={loading}
            columns={columns.map((column) => ({ customSort: () => 0, ...column }))}
            data={allPages}
            onChangePage={onChangePage}
            page={state.page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onOrderChange={onOrderChange}
            components={{
                Container: (ContainerProps: any) => <div {...ContainerProps} className={styles.paging_table} />,
            }}
            options={{
                toolbar: false,
                showTitle: !!title,
                draggable: false,
                pageSize: filter.limit,
                minBodyHeight: undefined,
                // exportButton: true,
                search: false,
                pageSizeOptions: [5, 10, 25, 50, 100, 500],
                paginationType: "stepped",
                showFirstLastPageButtons: false,
                emptyRowsWhenPaging: false,
                sorting: false,
                rowStyle: (transaction): React.CSSProperties => {
                    if (transaction?.id) {
                        return {};
                    }
                    return { display: "none" };
                },
                ...options,
            }}
        />
    );
}
