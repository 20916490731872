import { Form, Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useState } from "react";
import useDispatch from "../../../../hooks/useDispatch";
import useMountEffect from "../../../../hooks/useMountEffect";
import useSelector from "../../../../hooks/useSelector";
import { getMainCategories } from "../../../../redux/actions/categories";
import { getSupplierReport } from "../../../../redux/actions/reporting";
import { getMyAccount } from "../../../../redux/actions/users";
import AmotaiAccount from "../../../../types/AmotaiAccount";
import { Category } from "../../../../types/Category";
import Button from "../../../widgets/button/Button";
import FormikSelect from "../../../widgets/formikFields/FormikSelect";
import Loading from "../../../widgets/loading/Loading";
import ReportHeader from "../../../widgets/reportHeader/ReportHeader";
import AccountHeader from "../../account/component/AccountHeader";
import styles from "./Affiliation.module.scss";

export default function AffiliationReport() {
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSuppliers, setLoadingSuppliers] = useState<boolean>(false);
    const [loadingCategories, setLoadingCategories] = useState<boolean>(true);
    const dispatch = useDispatch();
    const account = useSelector<AmotaiAccount>(state => state.users.account!);
    const categories: Category[] = useSelector(state => state.categories.main);
    const { iwiSuppliers, supplierCount } = useSelector((state) => state.reporting);


    useMountEffect(async () => {
        if (!account) {
            await dispatch(getMyAccount());
        }
        setLoading(false);

        if (!categories || !categories.length) {
            await dispatch(getMainCategories());
        }
        setLoadingCategories(false);

    });

    const handleSubmit = async (values: { category: number | string }) => {
        const { category } = values;
        setLoadingSuppliers(true);
        if (typeof category === "string" && category === "All categories") {
            await dispatch(getSupplierReport((account.iwiAffiliations || [])[0], undefined, undefined, 500));
        } else {
            await dispatch(getSupplierReport((account.iwiAffiliations || [])[0], undefined, Number(category), 500));
        }
        setLoadingSuppliers(false);
    };

    if (loading) {
        return <Loading />;
    }

    const affiliation = (account.iwiAffiliations || [])[0];

    return (
        <div className={styles.affiliation_container}>
            <AccountHeader title={account.name}
                subtitle={`Member since: ${moment(account.createdAt).format("DD/MM/YYYY")}`} />
            <div className={styles.grey_container}>
                <span className={styles.supplier_count}>{supplierCount}</span>
                <span className={styles.supplier_subtitle}>Affiliated businesses</span>
            </div>
            <div className={styles.affiliated_suppliers}>
                <ReportHeader title={`${affiliation} businesses`} subtitle={`Here are the Amotai businesses that affiliate to ${affiliation}`} />
                <Formik initialValues={{ category: "All categories" }} enableReinitialize onSubmit={handleSubmit}>
                    {(formikProps: FormikProps<any>) => (
                        <Form>
                            <div className={styles.filter}>
                                <FormikSelect name={"category"}
                                    disabled={loadingCategories}
                                    options={[
                                        {
                                            name: "All categories",
                                            value: "All categories"
                                        }, ...categories.map((c: Category) => ({
                                            name: c.name,
                                            value: c.id
                                        }))
                                    ]} />
                                <Button type={"submit"} loading={loadingSuppliers}
                                    disabled={loadingSuppliers}>Filter</Button>
                                <Button type={"button"} disabled={loadingSuppliers} plain onClick={async () => {
                                    setLoadingSuppliers(true);
                                    await dispatch(getSupplierReport((account.iwiAffiliations || [])[0], undefined, undefined, 500));
                                    formikProps.resetForm();
                                    setLoadingSuppliers(false);
                                }}>Reset</Button>
                            </div>
                            {loadingSuppliers || loadingCategories ? <Loading /> : (
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Business name</th>
                                            <th>Primary Industry</th>
                                            <th>Location</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(iwiSuppliers?.items || []).map((supplier) => (
                                            <tr key={supplier.id}>
                                                <td>{supplier.name}</td>
                                                <td>{categories.filter(c => supplier.mainCategories?.includes(c.id)).map(c => c.name).join(', ')}</td>
                                                <td>{supplier.physicalAddress}</td>
                                                <td><Button plainLink onClick={() => {
                                                    window.open(`${process.env.REACT_APP_AMOTAI_WEB_APP_URL}/suppliers/details/${supplier.id}`);
                                                }}>View</Button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
}
