import SaaSPlan from "./SaaSPlan";

export enum SaaSSubscriptionStatus {
    ACTIVE = 'ACTIVE',
    CANCELLED = 'CANCELLED',
    UPGRADING = 'UPGRADING',
    CANCELLING = 'CANCELLING`'
}
export default interface SaaSSubscription {
    id: string;
    status: string;
    state?: string;
    account: string;
    plan: SaaSPlan;
    createdAt: string;
    termStartedAt: string;
    termEndsAt: string;
    startedAt: string;
    firstChargeAt: string;
    lastChargeAt: string;
    anchorDay: number;
    chargeAttempts: number;
    upgradeTaskName: string;
    upgradePlan?: SaaSPlan;
    subscriptionPaymentType: SubscriptionPaymentType;
    nextPaymentType?: SubscriptionPaymentType;
}

export enum SubscriptionPaymentType {
    STRIPE = 'STRIPE',
    INVOICE = 'INVOICE'
}