import createAction from "./helpers/createAction";
import BusinessOwner from "../../types/BusinessOwner";
import wrapper from "./helpers/wrapper";
import BusinessOwnerAPI from "./api/businessOwner";

export enum Types {
    SET_BUSINESS_OWNERS = "businessOwners:SET_BUSINESS_OWNERS"
}

export const setBusinessOwners = createAction<BusinessOwner[]>(Types.SET_BUSINESS_OWNERS);

export const Actions = {
    [Types.SET_BUSINESS_OWNERS]: setBusinessOwners
};

export type Actions = typeof Actions;

export const getBusinessOwners = wrapper(BusinessOwnerAPI.getOwners, {
    action: setBusinessOwners
});

export const createBusinessOwners = wrapper(BusinessOwnerAPI.createOwners, {
    action: setBusinessOwners
});

export const updateBusinessOwners = wrapper(BusinessOwnerAPI.updateOwners, {
    action: setBusinessOwners
})