import BusinessContact from "../../../types/BusinessContact";
import get from "./util/get";
import put from "./util/put";
import post from "./util/post";

function getBusinessContacts(accountId: number): Promise<BusinessContact[]> {
    return get(`/v1/accounts/${accountId}/contacts`);
}

function createBusinessContacts(accountId: number, contacts: Pick<BusinessContact, "name" | "title" | "email" | "phoneNumber">[]): Promise<BusinessContact[]> {
    return post(`/v1/accounts/${accountId}/contacts`, contacts)
}

function updateBusinessContacts(accountId: number, values: BusinessContact[]): Promise<BusinessContact[]> {
    return put(`/v1/accounts/${accountId}/contacts`, values);
}

function addConnection(contactId: number): Promise<object> {
    return post(`v1/accounts/connections/contacts/${contactId}`);
}

const BusinessContactsAPI = {
    getBusinessContacts,
    createBusinessContacts,
    updateBusinessContacts,
    addConnection
}

export default BusinessContactsAPI;