
const known_chars = {
    "%and%": "&",
    "%slash%": "/"
}

/**
 * change '&' and '/' into encoded safe string
 * @param url 
 * @returns 
 */
export function encodeSpecialChars(url:string) {
    let result = url;
    for (const key in known_chars) {
        result = result.replace(new RegExp(known_chars[key], 'g'), key)
    }
    return result;
}

export function decodeSpecilaChars(str:string) {
    let result = str;
    for (const key in known_chars) {
        result = result.replace(new RegExp(key, 'g'), known_chars[key])
    }
    return result
}

