import firebase from "firebase/app";
import "firebase/auth";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Button from "src/components/widgets/button/Button";
import * as Yup from "yup";
import useDispatch from "../../../hooks/useDispatch";
import { showError } from "../../../redux/actions/snackbars";
import SideBar from "../../../resources/images/png/onboarding-amotai-sidebar@2x.png";
import FormikInput from "../../widgets/formikFields/FormikInput";
import OnboardingContent from "../../widgets/onboardingContent/OnboardingContent";
import OnboardingSidebar from "../../widgets/onboardingSidebar/OnboardingSidebar";
import styles from "./LogIn.module.scss";

interface LoginFormValues {
    email: string;
    password: string;
}

const logInValidationSchema = Yup.object({
    email: Yup.string()
        .trim()
        .email("Must be a valid email address")
        .required("Required"),
    password: Yup.string().required("Required"),
});

export default function LogIn() {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const onLoginSubmit = async (values: LoginFormValues) => {
        setLoading(true);
        try {
            values.email = values.email?.trim().toLowerCase();
            await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
            history.replace('/suppliers')
        } catch (e) {
            dispatch(showError(e.message))
            setLoading(false);
        }
    };

    return (


        <div className={styles.login_container}>
            <OnboardingSidebar sidebarImage={SideBar}>
                <div className={styles.sidebar_content_container}>
                    <div>
                        <h3>
                            You’re just a few clicks away from being connected to our Amotai Supplier and Member database
                        </h3>
                    </div>
                </div>

            </OnboardingSidebar>
            <OnboardingContent>
                <div className={styles.login_content_container}>
                    <h5>Log in to get access to the Amotai Directory</h5>
                    <p>To join Amotai, please apply via the <Link to={"/sign-up"}>sign up</Link> page.<br />
                        To learn more about Buyer memberships, you can find information in our <a
                            href={`${process.env.AMOTAI_SITE_URL}/support`}>support centre</a>.</p>

                    <div className={styles.inner_container}>

                        <Formik<LoginFormValues> initialValues={{
                            email: "",
                            password: ""
                        }} onSubmit={onLoginSubmit} validationSchema={logInValidationSchema}>
                            {(formProps) => (
                                <Form onSubmit={formProps.handleSubmit}>
                                    <div className={styles.login_form}>
                                        <FormikInput name={"email"} label={"Email"} />
                                        <FormikInput name={"password"} label={"Password"} type={"password"} />
                                        <div className={styles.forgot_container}>
                                            <Button plainLink disableRipple href={"/forgot-password"}>Forgot
                                                Password</Button>
                                        </div>
                                    </div>
                                    <div className={styles.button_container}>
                                        <p>Don&apos;t have an account? <Link to={"/sign-up"}>Sign up</Link></p>
                                        <Button type={"submit"} loading={loading}>Login</Button>
                                    </div>

                                </Form>
                            )}
                        </Formik>

                    </div>


                </div>
            </OnboardingContent>
        </div>


    );
}