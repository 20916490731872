import classNames from "classnames";
import { Field, useField } from "formik";
import React from "react";
import * as Yup from "yup";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import styles from "./BuyerClientOnboarding.module.scss";

export const BuyerOrganisationTypeSchema = Yup.object({
    organisationType: Yup.string().required("Please select an organisation type"),
});

export default function Page1(props: any) {

    const RadioOptions = () => {
        const [field, meta, helpers] = useField("organisationType");
        const fieldError = meta.error;
        const showError = meta.touched && !!fieldError;

        return (
            <>
                <div
                    className={classNames(styles.member_type_card, { [styles.member_type_card_selected]: field.value === "public" })}
                    onClick={() => helpers.setValue("public")}>
                    <h4>Public sector</h4>
                    <span>Local or Central Government</span>
                </div>
                <div
                    className={classNames(styles.member_type_card, { [styles.member_type_card_selected]: field.value === "private" })}
                    onClick={() => helpers.setValue("private")}>
                    <h4>Private sector</h4>
                    <span>Owned by individuals</span>
                </div>
                <div
                    className={classNames(styles.member_type_card, { [styles.member_type_card_selected]: field.value === "iwi" })}
                    onClick={() => helpers.setValue("iwi")}>
                    <h4>Iwi</h4>
                </div>
                <div
                    className={classNames(styles.member_type_card, { [styles.member_type_card_selected]: field.value === "nfp" })}
                    onClick={() => helpers.setValue("nfp")}>
                    <h4>Not for profit</h4>
                    <span>Owned by shareholders or trustees</span>
                </div>
                {/* <div
                    className={classNames(styles.member_type_card, { [styles.member_type_card_selected]: field.value === CustomizedFor.MandateAgency })}
                    onClick={() => helpers.setValue(CustomizedFor.MandateAgency)}>
                    <h4>Government mandated agencies</h4>
                </div> */}
                {showError && <div className={styles.error}>{fieldError}</div>}
            </>
        );
    };

    const renderFields = () => (
        <div className={styles.member_type_container}>
            <Field name={"organisationType"} component={RadioOptions} />
        </div>
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Register your business as a Buyer.</h5>
            <p>To join Amotai, please create your application for your business.
                <br />To learn more about Buyer memberships, you can find information in our <a
                    href={`${process.env.AMOTAI_SITE_URL}/support`} target={"_blank"} rel={"noopener noreferrer"}
                    style={{ textDecoration: "none", fontWeight: "bold", color: "#008997" }}>support
                    centre</a>.
            </p>
            <div className={styles.inner_container}>
                <div className={styles.onboarding_form}>
                    {children}
                </div>
            </div>
        </FormStep>
    );

    if (props.review) {
        return renderFields();
    }

    return (
        renderFormStep(renderFields())
    );
}
