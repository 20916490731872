import React from "react";
import { FieldArray, FieldArrayRenderProps, FormikProps, useFormikContext } from "formik";
import classNames from "classnames";
import styles from "../../pages/onboarding/buyerClient/BuyerClientOnboarding.module.scss";
import FormikInput from "./FormikInput";
import FormikSelect from "./FormikSelect";
import Button from "../button/Button";
import { GENDER_OPTIONS, IWI_GROUPS, PASIFIKA_GROUPS } from "../../../util/constants";
import { SupplierFormValues } from "../../pages/onboarding/supplier/supplierValidation";
import BusinessOwner from "../../../types/BusinessOwner";

type BusinessOwnerFieldArrayProps = {
    disabled?: boolean
}

export default function BusinessOwnerFieldArray(props: BusinessOwnerFieldArrayProps) {
    const { disabled } = props;
    const { values }: FormikProps<SupplierFormValues> = useFormikContext();

    const renderIwiAffiliation = (affiliation: string, index: number, arrayHelpers: FieldArrayRenderProps, parentIndex: number) => (
        <div key={`iwi.${index}`} className={styles.affiliation_row}>
            <FormikSelect name={`owners[${parentIndex}].iwiAffiliations.${index}`}
                          label={"Iwi affiliation(s)"}
                          options={IWI_GROUPS}
                          tooltip={"Select all relevant iwi affiliations for all owners"}
                          disabled={disabled}
                          containerClassName={styles.no_margin_input} />
            {!disabled && <Button plainLink onClick={() => arrayHelpers.remove(index)}>- Remove Iwi affiliation</Button>}
        </div>
    );

    const renderPasifikaAffiliation = (affiliation: string, index: number, arrayHelpers: FieldArrayRenderProps, parentIndex: number) => (
        <div key={`pasifika.${index}`} className={styles.affiliation_row}>
            <FormikSelect name={`owners[${parentIndex}].pasifikaAffiliations.${index}`}
                          label={"Pasifika Ethnic group affiliation(s)"}
                          options={PASIFIKA_GROUPS}
                          tooltip={"Select all relevant Pasifika affiliations for all owners"}
                          disabled={disabled}
                          containerClassName={styles.no_margin_input} />
            {!disabled && <Button plainLink onClick={() => arrayHelpers.remove(index)}>- Remove Pasifika affiliation</Button>}
        </div>
    );

    const renderOwnerForm = (owner: BusinessOwner, index: number, arrayHelpers: FieldArrayRenderProps) => {
        return (
            <div key={`owners.${index}`} className={styles.owner_row}>
                <FormikInput name={`owners[${index}].name`}
                             inputClassname={classNames(styles.third_input, styles.first)}
                             placeholder={"e.g. John Smith"}
                             disabled={disabled}
                             label={"Owner name"} />
                <FormikSelect name={`owners[${index}].genderIdentity`}
                              containerClassName={classNames(styles.third_input_small, styles.first)}
                              label={"Gender identity"}
                              disabled={disabled}
                              options={GENDER_OPTIONS} />
                <FormikInput name={`owners[${index}].pctOwned`}
                             inputClassname={classNames(styles.third_input_small, styles.tooltip_input)}
                             placeholder={"100"}
                             tooltip={"Provide the percentage of ownership of the business that owner has."}
                             disabled={disabled}
                             label={"% Owned"} />
                <FieldArray name={`owners[${index}].iwiAffiliations`}
                            render={(helpers) => (
                                <>
                                    {owner.iwiAffiliations?.length > 0 ?
                                        (
                                            <>
                                                {owner.iwiAffiliations.map((iwi, i) => renderIwiAffiliation(iwi, i, helpers, index))}
                                                {!disabled && (
                                                    <div className={styles.add_button_owners}>
                                                        <Button plainLink onClick={() => helpers.push("")}>
                                                            + Add Iwi affiliation
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {disabled ? <div className={styles.no_affiliations}>No Iwi affiliations</div> : (
                                                    <div className={styles.add_button_owners}>
                                                        <Button plainLink onClick={() => helpers.push("")}>
                                                            + Add Iwi affiliation
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        )
                                    }
                                </>
                            )} />
                <FieldArray name={`owners[${index}].pasifikaAffiliations`}
                            render={(helpers) => (
                                <>
                                    {owner.pasifikaAffiliations?.length > 0 ? (
                                            <>
                                                {owner.pasifikaAffiliations.map((group, i) => renderPasifikaAffiliation(group, i, helpers, index))}
                                                {!disabled && (
                                                    <div className={styles.add_button_owners}
                                                         onClick={() => helpers.push("")}>
                                                        <Button plainLink>+ Add Pasifika affiliation</Button>
                                                    </div>
                                                )}
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                {disabled ? <div className={styles.no_affiliations}>No Pasifika affiliations</div> : (
                                                    <div className={styles.add_button_owners}>
                                                        <Button plainLink onClick={() => helpers.push("")}>
                                                            + Add Pasifika affiliation
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                </>
                            )} />
                {!disabled && <Button plainLink onClick={() => arrayHelpers.remove(index)}>- Remove owner</Button>}
                <hr />
            </div>
        );
    };

    return (
        <FieldArray name={"owners"}
                    render={(arrayHelpers) => (
                        <>
                            {values?.owners?.length > 0
                                ? (
                                    <>
                                        {values.owners.map((owner, index) => renderOwnerForm(owner, index, arrayHelpers))}
                                        {!disabled && (
                                            <div className={styles.add_button}>
                                                <Button borderdark onClick={() => arrayHelpers.push({
                                                            name: "",
                                                            genderIdentity: "",
                                                            pctOwned: ""
                                                        })}>+ Add another owner</Button>
                                            </div>
                                        )}
                                    </>
                                )
                                :
                                (
                                    <>
                                        {disabled ? <span>No owners</span> : (
                                            <div className={styles.add_button}>
                                                <Button borderdark onClick={() => arrayHelpers.push({
                                                            name: "",
                                                            genderIdentity: "",
                                                            pctOwned: ""
                                                        })}>+ Add owner</Button>
                                            </div>
                                        )}
                                    </>
                                )
                            }
                        </>
                    )}
        />
    );
}