import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import SanityAPI from "../../util/SanityAPI";
import Policy from "../../types/Policy";

export enum Types {
    SET_POLICY = "policy:SET_POLICY",
}

export const setPolicy = createAction<Policy>(Types.SET_POLICY);

export const Actions = {
    [Types.SET_POLICY]: setPolicy,
};
export type Actions = typeof Actions;

export const getPolicy = wrapper(SanityAPI.getPolicyPage, {
    action: setPolicy
});
