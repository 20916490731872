import React from "react";
import * as Yup from "yup";
import classNames from "classnames";
import styles from "./FunderOnboarding.module.scss";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import BusinessContactFieldArray from "../../../widgets/formikFields/BusinessContactFieldArray";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";

const BusinessContactValidation = Yup.object({
    name: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    phoneNumber: Yup.string().required("Required"),
    avatar: Yup.string().required("Required")
})

export const FunderBusinessContactValidationSchema = Yup.object({
    businessContacts: Yup.array().of(BusinessContactValidation)
        .min(1, "At least one contact is required")
})

export default function Page8(props: {validationSchema?: Yup.ObjectSchema, review?: boolean}) {

    if (props.review) {
        return (
            <div className={classNames(styles.inner_container, styles.business_contact_form)}>
                <SectionHeader title={"Business Profile Contacts"} />
                <p><strong>These contacts will be shared on your business profile so that suppliers can contact you
                    directly.

                </strong></p>
                <BusinessContactFieldArray />
            </div>
        )
    }

    return <FormStep>
                <h5>Who can businesses get in touch with for work?</h5>
                <p>These contacts will be shared on your business profile so that suppliers can contact you directly.</p>
                <div className={styles.inner_container}>
                    <div className={classNames(styles.onboarding_form, styles.business_contact_form)}>
                        <BusinessContactFieldArray />
                    </div>
                </div>
            </FormStep>
}