import createAction from "./helpers/createAction";
import ListResult from "../../types/ListResult";
import SupplierReport from "../../types/SupplierReport";
import OwnershipReport from "../../types/OwnershipReport";
import wrapper from "./helpers/wrapper";
import ReportingAPI from "./api/reporting";
import EmploymentReport from "../../types/EmploymentReport";
import CategoryReport from "../../types/CategoryReport";

export enum Types {
    SET_SUPPLIER_REPORT = "SET_SUPPLIER_REPORT",
    SET_SUPPLIER_COUNT = "SET_SUPPLIER_COUNT",
    SET_OWNERSHIP_REPORT = "SET_OWNERSHIP_REPORT",
    SET_EMPLOYMENT_REPORT = "SET_EMPLOYMENT_REPORT",
    SET_CATEGORY_REPORT = "SET_CATEGORY_REPORT",
    SET_REGION_REPORT = "SET_REGION_REPORT"
}

export const setSupplierReport = createAction<ListResult<SupplierReport>>(Types.SET_SUPPLIER_REPORT);
export const setSupplierCount = createAction<number>(Types.SET_SUPPLIER_COUNT);
export const setOwnershipReport = createAction<OwnershipReport>(Types.SET_OWNERSHIP_REPORT);
export const setEmploymentReport = createAction<EmploymentReport>(Types.SET_EMPLOYMENT_REPORT);
export const setCategoryReport = createAction<CategoryReport>(Types.SET_CATEGORY_REPORT);
export const setRegionReport = createAction<CategoryReport>(Types.SET_REGION_REPORT);

export const Actions = {
    [Types.SET_SUPPLIER_COUNT]: setSupplierCount,
    [Types.SET_SUPPLIER_REPORT]: setSupplierReport,
    [Types.SET_OWNERSHIP_REPORT]: setOwnershipReport,
    [Types.SET_EMPLOYMENT_REPORT]: setEmploymentReport,
    [Types.SET_CATEGORY_REPORT]: setCategoryReport,
    [Types.SET_REGION_REPORT]: setRegionReport
};

export type Actions = typeof Actions;

export const getSupplierReport = wrapper(ReportingAPI.getIwiSuppliers, {
    action: setSupplierReport
});

export const getSupplierCount = wrapper(ReportingAPI.getSupplierIwiCount, {
    action: setSupplierCount
});

export const getOwnershipReport = wrapper(ReportingAPI.getOwnershipReport, {
    action: setOwnershipReport
});

export const getEmploymentReport = wrapper(ReportingAPI.getEmploymentReport, {
    action: setEmploymentReport
});

export const getCategoryReport = wrapper(ReportingAPI.getCategoryReport, {
    action: setCategoryReport
});

export const getRegionReport = wrapper(ReportingAPI.getRegionReport, {
    action: setRegionReport
});
