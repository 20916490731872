import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import classNames from "classnames";
import React from "react";
import styles from "../../pages/onboarding/buyerClient/BuyerClientOnboarding.module.scss";
import FormikInput from "./FormikInput";
import Button from "../button/Button";
import { BusinessReferenceType } from "../../pages/onboarding/supplier/Page9";
import FormikNumberInput from "./FormikNumberInput";

type BusinessReferenceFieldArrayProps = {
    disabled?: boolean
}

export default function BusinessReferenceFieldArray(props: BusinessReferenceFieldArrayProps) {
    const { values }: any = useFormikContext();
    const { disabled } = props;

    const renderBusinessReferenceFields = (reference: BusinessReferenceType, index: number, arrayHelpers: FieldArrayRenderProps) => (
        <div key={`businessReferences.${index}`}>
            <div className={styles.reference_row}>
                <div>
                    <FormikInput name={`businessReferences[${index}].projectName`}
                                 disabled={disabled}
                                 label={"Project name"}
                    className={styles.full_input}/>
                    <FormikInput name={`businessReferences[${index}].client`}
                                 disabled={disabled}
                                 inputClassname={classNames(styles.half_input, styles.first)}
                                 label={"Client"} />
                    <FormikInput name={`businessReferences[${index}].contactName`}
                                 disabled={disabled}
                                 inputClassname={styles.half_input}
                                 label={"Contact name"} />
                    <FormikInput name={`businessReferences[${index}].contactEmail`}
                                 disabled={disabled}
                                 inputClassname={classNames(styles.half_input, styles.first)}
                                 label={"Email address"} />
                    <FormikNumberInput name={`businessReferences[${index}].contactPhoneNumber`}
                                       disabled={disabled}
                                       phone
                                       inputClassname={styles.half_input}
                                       label={"Mobile number"} />
                </div>
                {!disabled && <Button plainLink onClick={() => arrayHelpers.remove(index)}>- Remove</Button>}
            </div>
        </div>
    );

    return (
        <FieldArray name={"businessReferences"}
                    render={(arrayHelpers) => (
                        values?.businessReferences?.length > 0
                            ? (
                                <>
                                    {values.businessReferences.map((reference: BusinessReferenceType, index: number) => renderBusinessReferenceFields(reference, index, arrayHelpers))}
                                    <div className={styles.add_button}>
                                        {!disabled && (
                                            <Button plainLink onClick={() => arrayHelpers.push({
                                                projectName: "",
                                                client: "",
                                                contactName: "",
                                                contactEmail: "",
                                                contactPhoneNumber: ""
                                            })}>+ Add new project</Button>
                                        )}
                                    </div>
                                </>
                            )
                            : (
                                <div className={styles.add_button}>
                                    {disabled && <span>No project references.</span>}
                                    {!disabled && (
                                        <Button plainLink onClick={() => arrayHelpers.push({
                                            projectName: "",
                                            client: "",
                                            contactName: "",
                                            contactEmail: "",
                                            contactPhoneNumber: ""
                                        })}>+ Add new project</Button>
                                    )}
                                </div>
                            )
                    )} />
    );
}