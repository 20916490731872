import React from "react";
import * as Yup from "yup";
import styles from "./BuyerClientOnboarding.module.scss";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import FormikCheckboxGroup from "../../../widgets/formikFields/FormikCheckboxGroup";
import { HEALTH_AND_SAFETY, INSURANCES } from "../../../../util/constants";
import FormikSelect from "../../../widgets/formikFields/FormikSelect";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";

export const BuyerSubcontractorValidationSchema = Yup.object({
    minimumLevelOfInsuranceForSubContract: Yup.string().required("Requried"),
    healthAndSafetyForSubContract: Yup.array().of(Yup.string()).min(1, "At least 1 is required")
})

export default function Page7(props: any) {

    const renderFields = () => (
        <>
            <FormikSelect name={"minimumLevelOfInsuranceForSubContract"}
                          label={"Minimum Level of Insurance Required for Subcontractors"}
                          options={INSURANCES}
            />
            <div className={styles.checkbox_container}>
                    <label>Minimum Health and Safety Prequalifications
                        Required for Subcontactors</label>
                <FormikCheckboxGroup name={"healthAndSafetyForSubContract"} stringItems={HEALTH_AND_SAFETY} />
            </div>
        </>
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Let us know what your minimum requirements for <br />subcontractors are</h5>
            <div className={styles.inner_container}>
                <div className={styles.onboarding_form}>
                    {children}
                </div>
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Subcontractor Requirements"} />
                {renderFields()}
            </div>
        )
    }

    return renderFormStep(renderFields());
}