import { AccountType } from "src/types/AmotaiAccount";
import DirectoryItem from "src/types/DirectoryItem";
import { AccountResult, SearchSuggestion } from "src/types/SearchResult";
import get from "./util/get";
import post from "./util/post";

export type DirectorySearchParams = {
    category?: string | number | undefined;
    keyword?: string | undefined;
    iwiAffiliation?: string | undefined;
    pasifikaAffiliation?: string | undefined;
    healthAndSafety?: string | undefined;
    region?: string | undefined;
    insurance?: string | undefined;
    limit?: number;
    regionBased?: string;
}

function getDirectoryItem(id: number): Promise<DirectoryItem> {
    return get(`/v1/directory/${id}`);
}

function getSearchSuggestions(type: AccountType, keyword: string): Promise<SearchSuggestion[]> {
    return get('v1/directory/search', { params: { type, keyword } });
}

function getSearchResults(type: AccountType, request?: SearchSuggestion): Promise<AccountResult[]> {
    return post(`v1/directory/search?type=${type}`, request);
}

function getSortedCategories(type: AccountType): Promise<number[]> {
    return get('v1/directory/search/categories', { params: { type } });
}

const DirectoryAPI = {
    getDirectoryItem,
    getSearchSuggestions,
    getSearchResults,
    getSortedCategories,
};

export default DirectoryAPI;