
import classNames from "classnames";
import React from "react";
import styles from "./Section.module.scss";

type ContainerProps = {
    containerClassName?: string;
    contentClass?: string;
    sectionTitle?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default function Section(props: ContainerProps) {
    const {
        className,
        contentClass,
        containerClassName,
        sectionTitle,
        children,
        ...otherProps
    } = props;

    const sectionClassName = classNames(styles.section, containerClassName);
    const innerClassName = classNames(styles.inner, className);
    const contentClassName = classNames (styles.content, contentClass)

    return (
        <div className={sectionClassName}>
            <div {...otherProps} className={innerClassName}>
                    <div className={contentClassName}>
                        {sectionTitle && <div className={styles.section_title}>{sectionTitle}</div>}
                        {children}
                    </div>
            </div>
        </div>
    );
}
