import AmotaiUser from "src/types/AmotaiUser";
import ListResult from "src/types/ListResult";
import Preference from "src/types/Preference";
import { CreateUserDTO } from "../../../types/CreateUserDTO";
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getUser(userId: string): Promise<AmotaiUser> {
    return get(`/v1/admin/users/${userId}`);
}

function updateMyProfile(values: any): Promise<AmotaiUser> {
    return put("/v1/me", {
        ...values
    })
}

function inviteToAccount(accountId: number, user: CreateUserDTO): Promise<AmotaiUser> {
    return post(`/v1/accounts/${accountId}/users`, user);
}
function reinviteToAccount(email: string) {
    return post("/v1/accounts/users/reinvite", email);
}

function getAccountUsers(params: {
    accountId?: number;
    limit?: number;
    cursor?: string | null;
}): Promise<ListResult<AmotaiUser>> {
    return get(`/v1/accounts/${params.accountId}/users`, {
        params: {
            ...params
        },
    });
}

function updateAccountUser(accountId: number, userId: string, user: any): Promise<AmotaiUser> {
    return put(`/v1/accounts/${accountId}/users/${userId}`, user);
}

function deleteAccountUser(accountId: number, userId: string): Promise<AmotaiUser> {
    return del(`/v1/accounts/${accountId}/users/${userId}`);
}

function getMyUser(): Promise<AmotaiUser> {
    return get("/v1/me");
}

function getMyPrefs(): Promise<Preference[]> {
    return get('/v1/me/preferences');
}

function createPref(pref: Preference): Promise<Preference> {
    return post('/v1/me/preferences', pref);
}

function deleteMyPref(prefId: number): Promise<void> {
    return del(`/v1/me/preferences/${prefId}`);
}

const UsersAPI = {
    getUser,
    inviteToAccount,
    reinviteToAccount,
    getAccountUsers,
    updateAccountUser,
    getMyUser,
    deleteAccountUser,
    updateMyProfile,
    getMyPrefs,
    createPref,
    deleteMyPref,
};

export default UsersAPI;