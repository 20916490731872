import AmotaiAccount, { AccountType } from "../../../types/AmotaiAccount";
import AmotaiUser from "../../../types/AmotaiUser";
import { ACCOUNT_TYPE, CreateBuyerAccountDTO, CreateFunderAccountDTO, CreateIwiAccountDTO, CreateSupplierAccountDTO } from "../../../types/CreateAccountDTO";
import del from "./util/del";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getAccount(): Promise<AmotaiAccount> {
    return get("/v1/me/account");
}

function createAccount(account: CreateBuyerAccountDTO | CreateSupplierAccountDTO | CreateFunderAccountDTO | CreateIwiAccountDTO, type: ACCOUNT_TYPE): Promise<{ user: AmotaiUser, stripeError: string, subscriptionError: string }> {
    return post("/v1/accounts", account, { type })
}

function updateAccount(accountId: number, values: any, type: AccountType) {
    return put(`/v1/accounts/${accountId}`, values, { type });
}

function deleteAccount(accountId: number) {
    return del(`/v1/accounts/${accountId}`)
}

function submitAccountForReview(account: AmotaiAccount, type: AccountType): Promise<AmotaiAccount> {
    return put(`/v1/accounts/${account.id}/review`, account, { type })
}

const AccountAPI = {
    getAccount,
    createAccount,
    updateAccount,
    deleteAccount,
    submitAccountForReview,
}

export default AccountAPI;
