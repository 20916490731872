import { Moment } from 'moment';
import AmotaiAccount, { AccountStatus } from "src/types/AmotaiAccount";
import { HitType } from 'src/types/SearchResult';
import { OwnershipType } from 'src/types/Supplier';
import { BusinessSize } from './constants';

/**
 * NB: if account is null|undefined, this function will return false.
 * @param account 
 */
export function isAccountDisabled<T extends AmotaiAccount>(account?: T | null) {
    const Disabled_Status = [
        AccountStatus.PENDING_REVIEW,
        AccountStatus.AWAITING_PAYMENT,
        AccountStatus.DECLINED,
        AccountStatus.DISABLED,
        AccountStatus.DELETED,
    ];
    return account?.status && Disabled_Status.includes(account.status);
}

export function buildEventDateString(start: Moment, end: Moment): string[] {
    const arr: string[] = [];
    if (start.month() === end.month() && start.year() === end.year()) {
        arr.push(`${start.format('D')} - ${end.format('D')} ${start.format('MMMM YYYY')}`)
    } else if (start.year() === end.year()) {
        arr.push(`${start.format('D MMMM')} - ${end.format('D MMMM')} ${start.format('YYYY')}`)
    } else {
        arr.push(`${start.format('D MMMM YYYY')} - ${end.format('D MMMM YYYY')}`)
    }
    arr.push(start.format('h:mm A'))
    return arr;
}

/**
 * check if array A contains ANY of the elements in array B
 * @param arrA 
 * @param arrB 
 */
export function strarrContainsAnyOfStrarr(arrA?: string[], arrB?: string[]) {
    if (!arrB?.length || !arrA?.length) {
        return false;
    }
    for (const b of arrB) {
        if (arrA.includes(b)) {
            return true;
        }
    }
    return false;
}

export function getOwnershipTypes() {
    const ownershipeTypes: { [key: string]: { value: string, tip?: string } } = {};
    ownershipeTypes[OwnershipType.MaoriOwned] = {
        value: 'Māori Owned',
        tip: 'At least 50% Māori Ownership',
    };
    ownershipeTypes[OwnershipType.PasifikaOwned] = {
        value: 'Indigenous Pasifika Owned',
        tip: 'At least 50% Indigenous Pasifika Ownership',
    };
    ownershipeTypes[OwnershipType.MaoriPasifikaOwned] = {
        value: 'Māori & Indigenous Pasifika Owned',
        tip: `Meets both Māori Owned & Indigenous Pasifika Owned Criteria`,
    };
    ownershipeTypes[OwnershipType.MaoriPasifikaCombined] = {
        value: 'Combined Māori & Indigenous Pasifika Owned',
        tip: 'Combination of Māori & Indigenous Pasifika Ownership to meet the minimum 50% shareholding threshold',
    };
    return ownershipeTypes;
}

export function getBusinessSize(size: number): BusinessSize {
    if (size > 49) {
        return BusinessSize.Large;
    }
    if (size > 19) {
        return BusinessSize.Medium;
    }
    return BusinessSize.Small;
}

export function getBusinessSizeFullName(size: BusinessSize) {
    switch (size) {
        case BusinessSize.Small:
            return 'Small (0 to 19 FTE employees)';
        case BusinessSize.Medium:
            return 'Medium (20 to 49 FTE employees)';
        case BusinessSize.Large:
            return 'Large (50 or more FTE employees)';
        default:
            return '';
    }
}

export function getHitTypeOrder(hitType: HitType) {
    switch (hitType) {
        case HitType.AccountName:
            return 0;
        case HitType.Category:
            return 1;
        case HitType.SubCategory:
            return 2;
        case HitType.SubSubCategory:
            return 3;
        default:
            return 999;
    }
}

export function currency(from: string) {
    return from.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}