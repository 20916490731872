import { InputLabel, MenuItem, Select as SelectMui } from "@material-ui/core";
import classNames from "classnames";
import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
import ReactTooltip from "react-tooltip";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";
import styles from "./Select.module.scss";

type SelectProps = {
    containerClassName?: string,
    selectClassName?: string,
    label?: string,
    tooltip?: string,
    defaultValue?: string,
    disabled?: boolean;
    options: Array<string | { name: string, value: any }>,
    onChange: (v: string) => void,
    required?: boolean,
}
export interface SelectRef {
    updateValue: (v: any) => void;
}
function Select(props: SelectProps, ref: Ref<SelectRef>) {
    const { containerClassName, selectClassName, disabled } = props;
    const [value, setValue] = useState<string | undefined>(props.defaultValue);
    const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        const text = e.target.value as string;
        setValue(text);
        props.onChange(text);
    }
    const className = classNames(styles.padding, containerClassName);
    const selectClass = classNames(styles.select, selectClassName);
    useImperativeHandle(ref, () => ({
        updateValue: setValue
    }))
    return (
        <div className={className}>
            {props.label && (
                <div className={styles.label_tooltip_container}>
                    <InputLabel shrink htmlFor={"input"}>
                        {props.label}
                    </InputLabel>
                    {props.tooltip && (
                        <div data-tip={props.tooltip}>
                            <img src={Tooltip} alt={"Help"} />
                        </div>
                    )}
                </div>
            )}
            <SelectMui
                required={props.required}
                className={selectClass}
                onChange={handleChange}
                value={value}
                disabled={disabled}
                classes={{ select: selectClass }}
                fullWidth
            >
                {props.options.map(option =>
                    typeof option === "string" ?
                        <MenuItem key={`key_${option}`} value={option}>{option}</MenuItem> :
                        <MenuItem key={`key_${option.name}_${option.value}`} value={option.value}>{option.name}</MenuItem>
                )}
            </SelectMui>
            <ReactTooltip
                place={"top"}
                effect={"solid"}
                multiline
                backgroundColor={"#F1F1F2"}
                borderColor={"#E6E7E8"}
                textColor={"#000000"}
                clickable
            />
        </div>
    )
}
export default forwardRef(Select);