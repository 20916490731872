import SaaSPlan from "src/types/SaaSPlan";
import SaaSSubscription from "src/types/SaaSSbscription";
import { Actions, Types } from "../actions/saaSPlans";

const initialState: {
    saaSPlans: SaaSPlan[];
    subscription?: SaaSSubscription;
} = { saaSPlans: [] };

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function saaSPlans(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_PLANS: {
            const { payload } = action as ActionValueTypes<Types.SET_PLANS>;
            const plans = payload ?? [] as SaaSPlan[];
            return {
                ...state,
                saaSPlans: plans
            };
        }
        case Types.SET_SUBSCRIPTION: {
            const { payload } = action as ActionValueTypes<Types.SET_SUBSCRIPTION>;
            const subscription = payload!;
            return {
                ...state,
                subscription
            };
        }
        default: {
            return state;
        }
    }
}