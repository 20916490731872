import { FormikProps, useField, useFormikContext } from "formik";
import React, { useState } from "react";
import Loading from "src/components/widgets/loading/Loading";
import PlanCard from "src/components/widgets/planCard/PlanCard";
import useDispatch from "src/hooks/useDispatch";
import useMountEffect from "src/hooks/useMountEffect";
import useSelector from "src/hooks/useSelector";
import { getCustomizedPlans } from "src/redux/actions/saaSPlans";
import SaaSPlan, { CustomizedFor } from "src/types/SaaSPlan";
import * as Yup from "yup";
import { MANDATE_AGENCY } from "../../../../util/constants";
import FormikSelect from "../../../widgets/formikFields/FormikSelect";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import styles from "./BuyerClientOnboarding.module.scss";
import { BuyerClientFormValues } from "./buyerClientValidation";

export const MandateAgencyValidationSchema = Yup.object({
    mandateAgency: Yup.string().required('Mandated agency is a required field'),
    saasPlan: Yup.number().required('You need to select a plan')
});

type Props = {
    review?: boolean,
    setPlan?: (plan: SaaSPlan) => void,
    validationSchema?: any,
}
export default function Page2MandateAgency({ setPlan, review }: Props) {
    const [loading, setLoading] = useState(false);
    const [, , saasHelper] = useField("saasPlan");
    const dispatch = useDispatch();
    const saasPlans = useSelector((state) => state.saaSPlans.saaSPlans);
    const [, , paymentHelper] = useField('invoicePayment');
    const { values }: FormikProps<BuyerClientFormValues> = useFormikContext();

    useMountEffect(async () => {
        try {
            setLoading(true);
            await dispatch(getCustomizedPlans(CustomizedFor.MandateAgency));
            paymentHelper.setValue(true);
            setLoading(false);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    })

    const handlePlanSelect = (plan: SaaSPlan) => {
        setPlan?.(plan)
        saasHelper.setValue(plan.id)
    }

    const renderFields = (disabled: boolean) => (
        <>
            {loading && <Loading />}
            <FormikSelect name={"mandateAgency"}
                containerClassName={styles.card_container}
                label={""}
                options={["", ...MANDATE_AGENCY]} />
            <div className={styles.card_container}>
                {saasPlans.sort((a, b) => b.name.localeCompare(a.name)).map(plan => <PlanCard label={plan.name} hidePrice
                    disabled={disabled}
                    key={plan.id}
                    plan={plan}
                    onSelect={() => handlePlanSelect(plan)}
                    selected={values.saasPlan === plan.id}
                    detailItems={(
                        <div className={styles.plan_details}>
                            <span>{plan.maxUsers > 25 ? "Unlimited users" : `${plan.maxUsers > 1 ? `${plan.maxUsers} users` : `${plan.maxUsers} user`}`}</span>
                            {<span>{plan.maxContacts > 25 ? "Unlimited contacts" : `${plan.maxContacts ?? 0} contact${(plan.maxContacts ?? 0) > 1 ? 's' : ''}`}</span>}
                        </div>
                    )} />)}
            </div>
        </>
    );

    // const renderFormStep = (children: any) => (
    //     <FormStep>
    //         <h5>Select your Government Mandated Agency and Membership Plan</h5>
    //         <div className={styles.inner_container}>
    //             {children}
    //         </div>
    //     </FormStep>
    // );

    if (review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Government Mandated Agency"} />
                {renderFields(true)}
            </div>
        );
    }

    // return (
    //     renderFormStep(renderFields(false))
    // );
    return null;
}