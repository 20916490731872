import Address from "src/types/Address";
import { Category } from "../../../../types/Category";
import { BusinessContact } from "../supplier/Page8";

export type FunderFormValues = {

    // page 3 - userDTO details
    firstName: string;
    lastName: string;
    jobTitle: string;
    phoneNumber: string;
    mobileNumber: string;
    avatar: string;
    email: string;
    password: string;

    // page 4 - accountDTO details
    name: string;
    legalStructure: string;
    category: Category | {};
    size: number; // funderDTO
    postalAddress: string;
    physicalAddress: string;
    physicalAddressComponents: Address | null;
    regions: string[];
    regionBased: string;

    // page 5 - funderDTO details
    annualTurnover: string;
    annualAddressableSpend: string;
    approxAnnualIndirectSpend: string;
    socialProcurementInitiatives: string;

    // page 6 - accountDTO details
    logo: string;
    description: string;

    // page 7 - funderDTO details
    minimumLevelOfInsuranceForSubContract: string;
    healthAndSafetyForSubContract: string[];

    // page 8 - businessContactDTO details
    businessContacts: BusinessContact[];

    // page 10
    termsAgreed: string;
};

export const FunderInitialValues: FunderFormValues = {
    // page 3 - userDTO details
    firstName: "",
    lastName: "",
    jobTitle: "",
    phoneNumber: "",
    mobileNumber: "",
    avatar: "",
    email: "",
    password: "",

    // page 4 - accountDTO details
    name: "",
    legalStructure: "",
    category: {},
    size: 0, // funderDTO
    postalAddress: "",
    physicalAddress: "",
    physicalAddressComponents: null,
    regions: [],
    regionBased: "",

    // page 5 - funderDTO details
    annualTurnover: "",
    annualAddressableSpend: "",
    approxAnnualIndirectSpend: "",
    socialProcurementInitiatives: "",

    // page 6 - accountDTO details
    logo: "",
    description: "",

    // page 7 - funderDTO details
    minimumLevelOfInsuranceForSubContract: "",
    healthAndSafetyForSubContract: [],

    // page 8 - businessContactDTO details
    businessContacts: [{ name: "", title: "", email: "", phoneNumber: "", avatar: "" }],

    // page 10
    termsAgreed: "",
};
