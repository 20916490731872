import { capitalize } from "lodash";
import React, { useState } from "react";
import { currency } from "src/util/helper";
import { encodeSpecialChars } from "src/util/url";
import useDispatch from "../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../hooks/useSelector";
import TransactionsAPI from "../../../../../../redux/actions/api/transactions";
import { getAccountSubscription } from "../../../../../../redux/actions/saaSPlans";
import { showError } from "../../../../../../redux/actions/snackbars";
import Transaction from "../../../../../../types/Transaction";
import Button from "../../../../../widgets/button/Button";
import PagingTable from "../../../../../widgets/pagingTable/PagingTable";
import { ReceiptParams } from "../../../../../widgets/receipt/Receipt";
import Section from "../../../../../widgets/section/Section";
import styles from "../Billing.module.scss";

type Filter = NonNullable<Parameters<typeof TransactionsAPI.getAccountTransactions>[0]>;

export default function Transactions(props: any) {
    const [, setDownloadingReceipt] = useState<string>("");
    const [filter, setFilter] = useState<Filter>({
        from: null,
        to: null,
        cursor: null,
        limit: 10,
        accountId: props.accountId
    });
    const dispatch = useDispatch();
    const account: any = useSelector((state) => state.users.account)
    const subscription: any = useSelector((state) => state.saaSPlans.subscription);

    useMountEffect(async () => {
        await dispatch(getAccountSubscription(account?.id));
    })

    const handleReceiptDownload = (id: string, params: ReceiptParams) => {
        setDownloadingReceipt(id);
        const siteURLParam = encodeURI(`${window.location.origin}/receipt?params=${encodeSpecialChars(JSON.stringify(params))}`);
        const url = `${process.env.REACT_APP_PDF_SERVICE_URL}/pdf?url=${siteURLParam}&title=amotai-receipt`;
        window.open(url);
    }

    const renderDownloadLink = (t: Transaction) => {
        if (account) {
            const receiptParams: ReceiptParams = {
                businessName: account.name,
                businessAddress: account.physicalAddress,
                invoiceDate: t.created,
                invoiceNo: t.id,
                reference: account.stripeCustomerId,
                saasPlan: subscription?.plan?.name,
                metaData: t.metadata,
                total: t.amount - (t.amountRefunded ?? 0),
            };
            const failure = {
                failed: (t.failureCode??'').length > 0,
                message: t.failureMessage,
            }
            const fullRefunded = t.refunded && t.amount === t.amountRefunded;
            let btnText = `Download receipt`;
            if (failure.failed) {
                btnText = failure.message;
            } else if (fullRefunded) {
                btnText = 'Refunded';
            }
            return (
                <Button plainLink
                        disabled={failure.failed || fullRefunded}
                        onClick={() => handleReceiptDownload(t.id, receiptParams)}
                        className={styles.download_link}>
                    {btnText}
                </Button>
            )
        }

        return dispatch(showError("There was an error downloading your receipt"))
    }

    const transactionsColumn = [
        {
            title: "Date",
            render: (t: Transaction) => t.created ? new Date(t.created).toLocaleDateString("en-NZ") : "n/a"
        },
        {
            title: "Card",
            field: "last4"
        },
        {
            title: "Type",
            render: (t: Transaction) => capitalize(t.type || "")
        },
        {
            title: "Amount",
            render: (t: Transaction) => t.amount ? `${t.currency.toUpperCase()} $${currency((t.amount / 100).toFixed(0))}` : "$0"
        },
        {
            render: renderDownloadLink
        }
    ];

    return (
        <Section className={styles.cards}>
            <h5>Billing history</h5>
            <PagingTable getData={TransactionsAPI.getAccountTransactions}
                         setFilter={setFilter}
                         filter={filter}
                         columns={transactionsColumn} />
        </Section>
    );
}