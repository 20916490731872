import SaaSPlan, { SaaSPlanPeriod } from "src/types/SaaSPlan";
import SaaSSubscription, { SubscriptionPaymentType } from "src/types/SaaSSbscription";

export type SubscriptionInfo = {
    nextPlan?: SaaSPlan;
    nextPaymentType?: SubscriptionPaymentType;
    nextIntervalString?: string;
    currentPaymentTypeText?: string;
    nextPaymentTypeText?: string;
}
export default function useSubscription(subscription?: SaaSSubscription): SubscriptionInfo {
    if (!subscription) {
        return {};
    }
    const { plan, upgradePlan, nextPaymentType, subscriptionPaymentType } = subscription;
    const subscriptionInfo: SubscriptionInfo = {
        nextPlan: upgradePlan??plan,
        nextPaymentType: nextPaymentType??subscriptionPaymentType,
        nextIntervalString: ''
    };

    subscriptionInfo.currentPaymentTypeText = subscription.subscriptionPaymentType === SubscriptionPaymentType.INVOICE ? 'Invoice' : 'Automatic';
    if (subscription.nextPaymentType) {
        subscriptionInfo.nextPaymentTypeText = subscription.nextPaymentType === SubscriptionPaymentType.INVOICE ? 'Invoice' : 'Automatic';
    }
    if (subscriptionInfo.nextPaymentType === SubscriptionPaymentType.STRIPE) {
        if (!(upgradePlan ?? plan).period) {
            subscriptionInfo.nextIntervalString = '';
        }
        const term = (upgradePlan ?? plan).period === SaaSPlanPeriod.ANNUAL ? 'annum' : 'month';
        subscriptionInfo.nextIntervalString = term;
    } else {
        subscriptionInfo.nextIntervalString = 'annum';
    }
    return subscriptionInfo;
}