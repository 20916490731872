import { FormControlLabel, Paper } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field, FormikProps, useField, useFormikContext } from "formik";
import React, { useMemo, useState } from "react";
import Button from "src/components/widgets/button/Button";
import Select from "src/components/widgets/select/Select";
import * as Yup from "yup";
import useDispatch from "../../../../hooks/useDispatch";
import useMountEffect from "../../../../hooks/useMountEffect";
import useSelector from "../../../../hooks/useSelector";
import { getMainCategories } from "../../../../redux/actions/categories";
import { Category, SubCategory, SubSubCategory } from "../../../../types/Category";
import { CheckboxGroupItem } from "../../../widgets/formikFields/FormikCheckboxGroup";
import Loading from "../../../widgets/loading/Loading";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import styles from "../buyerClient/BuyerClientOnboarding.module.scss";
import { SupplierFormValues } from "./supplierValidation";

export const CategoryValidationSchema = Yup.object({
    // category: Yup.object().required("Required"),
    mainCategories: Yup.array().of(Yup.string().required("Please select a value")).min(1, "At least 1 required"),
    subCategories: Yup.array().of(Yup.string()),
    subSubCategories: Yup.array().of(Yup.string()),
});

export default function Page3(props: any) {
    const [loading, setLoading] = useState(true);
    const { values }: FormikProps<SupplierFormValues> = useFormikContext();
    const { mainCategories, subCategories, subSubCategories } = values;
    const [, , mainHelpers] = useField("mainCategories");
    const [, , subHelpers] = useField("subCategories");
    const [, , subSubHelpers] = useField("subSubCategories");
    const dispatch = useDispatch();
    const categories = useSelector<Category[]>((state) => state.categories.main);

    useMountEffect(async () => {
        if (!categories || !categories.length) {
            await dispatch(getMainCategories());
        }

        setLoading(false);
    });

    const onSubCategoryChange = (sub: SubCategory) => {
        const selectedSub = subCategories.find((subCat) => subCat.id === sub.id);
        const ssids = sub.subSubCategories.map((subSubCat) => subSubCat.id);
        if (!selectedSub) {
            // clear subSub categories from the existing selection
            const _subSubCategories = subSubCategories.filter((subSubCat) => !ssids.includes(subSubCat.id));
            subSubHelpers.setValue(_subSubCategories);
        }
    };

    const onSubSubCategoryChange = (subSub: SubSubCategory) => {
        const parentSubCat = categories.map(c => c.subCategories).flat().find(s => s.id === subSub.parent)!;
        const isParentSelected = !!subCategories.find(s => s.id === parentSubCat.id);
        const isChildSelected = !!subSubCategories.find((subSubCat) => subSubCat.id === subSub.id)
        if (!isParentSelected && isChildSelected) {
            // have selected a subSub category without the parent subCategory added, select it now
            subHelpers.setValue([...subCategories, parentSubCat])
        }
    }

    const categoryOptions = useMemo(() => {
        const mainSelectedIds = mainCategories?.map(m => m.id) ?? [];
        return categories.filter(c => !mainSelectedIds.includes(c.id)).map(c => c.name)
    }, [categories, mainCategories]);

    const onAddSinglePrimary = () => {
        const mainSelectedIds = mainCategories?.map(m => m.id) ?? [];
        const newMainCat = categories.find(c => !mainSelectedIds.includes(c.id));
        if (newMainCat) {
            const _mainCategories = [...(mainCategories ?? []), newMainCat];
            mainHelpers.setValue(_mainCategories);
        }
    }

    const onRemoveSinglePrimary = (cat: Category) => {
        const sids = cat.subCategories.map(s => s.id);
        const ssids = cat.subCategories.map(s => s.subSubCategories.map(ss => ss.id)).flat();
        const _subCategories = subCategories.filter(sub => !sids.includes(sub.id));
        const _subSubCategories = subSubCategories.filter(subsub => !ssids.includes(subsub.id));
        subHelpers.setValue(_subCategories);
        subSubHelpers.setValue(_subSubCategories);
        mainHelpers.setValue(mainCategories.filter(m => m.id !== cat.id));
    }

    const onSinglePrimaryChange = (newCatName: string, idx: number) => {
        // remove main, push new main in and clear old/new sub & subSub
        const newCat = categories.find(c => c.name === newCatName)!
        const oldCat = mainCategories[idx];
        const sids = [
            ...oldCat.subCategories.map(s => s.id),
            ...newCat.subCategories.map(s => s.id)
        ];
        const ssids = [
            ...oldCat.subCategories.map(s => s.subSubCategories.map(ss => ss.id)).flat(),
            ...newCat.subCategories.map(s => s.subSubCategories.map(ss => ss.id)).flat()
        ];
        const _subCategories = subCategories.filter(sub => !sids.includes(sub.id));
        const _subSubCategories = subSubCategories.filter(subsub => !ssids.includes(subsub.id));
        const _mainCategories = mainCategories.map((prev, i) => i === idx ? newCat : prev);
        subHelpers.setValue(_subCategories);
        subSubHelpers.setValue(_subSubCategories);
        mainHelpers.setValue(_mainCategories)
    };

    const renderFields = () => {
        return <>
            {mainCategories?.map((cat, idx) =>
                <div key={cat.id} className={styles.industry_form}>
                    <Select defaultValue={cat.name}
                        label={"Primary Industry"}
                        tooltip={"Select all relevant categories that you work in so that buyers are able to understand the services/works/goods you provide"}
                        onChange={name => onSinglePrimaryChange(name, idx)}
                        options={[...categoryOptions, cat.name]}
                        containerClassName={styles.full_input} />

                    {cat.subCategories?.map((sub, key) =>
                        <SubCategoryView key={`sub_${key}`}
                            sub={sub}
                            onSubCategoryChange={onSubCategoryChange}
                            onSubSubCategoryChange={onSubSubCategoryChange} />
                    )}
                    <div className={styles.remove_button}>
                        <Button plain onClick={() => onRemoveSinglePrimary(cat)}>- Remove</Button>
                    </div>
                </div>
            )}
            {categoryOptions.length > 0 && (mainCategories ?? []).length < 5 && <Button plain onClick={onAddSinglePrimary}>+ Add one primary industry</Button>}
        </>
    }

    if (loading) {
        return <Loading />;
    }

    return props.review ?
        (<div className={styles.inner_container}>
            {renderFields()}
        </div>)
        :
        (<FormStep>
            <h5>Tell us about your organisation</h5>
            <div className={styles.inner_container}>
                {renderFields()}
            </div>
        </FormStep>)
}

function SubCategoryView(props: { sub: SubCategory, onSubCategoryChange: (sub: SubCategory) => void, onSubSubCategoryChange: (subSub: SubSubCategory) => void }) {
    const { sub, onSubCategoryChange, onSubSubCategoryChange } = props;
    if (!sub.subSubCategories.length) {
        return <Paper className={styles.plain_subindustry_row}>
            <Field component={CheckboxGroupItem} name={"subCategories"} label={sub.name} value={sub} />
        </Paper>
    }

    return (
        <Accordion TransitionProps={{ unmountOnExit: true }} className={styles.category_container}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label={"Expand"}
                aria-controls={"additional-actions1-content"}
                id={"additional-actions1-header"}
                className={styles.category_item}
            >
                <FormControlLabel
                    className={styles.category_label}
                    aria-label={"Sub Industry"}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={(
                        <Field component={CheckboxGroupItem}
                            name={"subCategories"}
                            value={sub}
                            sideEffect={() => onSubCategoryChange(sub)}
                        />
                    )}
                    label={sub.name}
                />
            </AccordionSummary>

            <AccordionDetails>
                <div className={styles.subsub_container}>
                    {sub.subSubCategories?.map((subSub, key) => (
                        <Field key={`subsub_${key}`}
                            component={CheckboxGroupItem}
                            name={"subSubCategories"}
                            label={subSub.name}
                            value={subSub}
                            sideEffect={() => onSubSubCategoryChange(subSub)} />
                    ))}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
