import React, { ReactNode, useCallback } from "react";
import { useField, useFormikContext } from "formik";
import { InputLabel, MenuItem, Select as SelectMui, SelectProps } from "@material-ui/core";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";
import styles from "../select/Select.module.scss";

type InputProps = {
    name: string;
    onChange?: (e: React.ChangeEvent) => void;
    options: any[];
    label?: string;
    defaultValue?: string;
    containerClassName?: string;
    tooltip?: string;
    noPadding?: boolean;
    width?: number;
    mr?: string;
    height?: number;
} & Omit<SelectProps, "name" | "value" | "error" | "onChange">;

export default function FormikSelect(props: InputProps) {
    const { name, options, ...otherProps } = props;
    const { isSubmitting } = useFormikContext();
    const fieldProps = useField(name);

    const [field, meta] = fieldProps;
    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;

    const disabled = props.disabled || isSubmitting;
    const error = showError ? fieldError : null;

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            field.onChange(e);
            props.onChange?.(e);
        },
        [field, props.onChange],
    );

    const className = classNames(styles.padding, otherProps.containerClassName, { [styles.noPadding]: otherProps.noPadding });
    const selectClass = classNames(styles.select);

    const renderOptions = () => {
        const arr: ReactNode[] = [];

        options.forEach((option: string | { name: string, value: any }) => {
            if (typeof option === "string") {
                arr.push(<MenuItem key={`key_${option}`} value={option}>{option}</MenuItem>);
            } else {
                arr.push(<MenuItem key={`key_${option.name}`}
                                   value={option.value}>{option.name}</MenuItem>);
            }
        });

        return arr;
    };

    return (
        <div className={className} style={{ width: props.width, marginRight: props.mr }}>
            {props.label && (
                <div className={styles.label_tooltip_container}>
                    <InputLabel shrink htmlFor={"input"}>
                        {props.label}
                    </InputLabel>
                    {props.tooltip && (
                        <div data-tip={props.tooltip} className={styles.tooltip}>
                            <img src={Tooltip} alt={"Help"} />
                        </div>
                    )}
                </div>
            )}

            <SelectMui {...field}
                       value={field.value}
                       required={props.required}
                       className={selectClass}
                       onChange={onChange}
                       classes={{ select: selectClass }}
                       style={{ height: props.height }}
                       disabled={disabled}
                       fullWidth>
                {renderOptions()}
            </SelectMui>
            {showError && error && <div className={styles.error}>{error}</div>}
            <ReactTooltip
                place={"top"}
                effect={"solid"}
                multiline
                backgroundColor={"#F1F1F2"}
                borderColor={"#E6E7E8"}
                textColor={"#000000"}
                clickable
            />
        </div>
    );
}
