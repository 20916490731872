import ListResult from "../../../types/ListResult";
import Transaction from "../../../types/Transaction";
import get from "./util/get";

function getAccountTransactions(params: {
    from?: string | null;
    to?: string | null;
    cursor?: string | null;
    limit?: number;
    accountId?: number
}): Promise<ListResult<Transaction>> {
    return get(`/v1/accounts/${params.accountId}/transactions`, {
        params: { ...params }
    });
}

const TransactionsAPI = {
    getAccountTransactions
};

export default TransactionsAPI;