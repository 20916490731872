import { FormControlLabel } from "@material-ui/core";
import React from "react";
import { useField } from "formik";
import { StyledCheckbox } from "../checkbox/Checkbox";
import styles from "./FormikCheckbox.module.scss";


type Props = {
    name: string;
    inputLabel?: string;
    children?: any
    inputValue: any;
    onChange?: (value: any) => void;
}

export default function FormikCheckbox({ name, inputLabel, children, inputValue, onChange }: Props) {
    const [field, , helpers] = useField(name);
    const checked = !!field?.value || false

    const handleChange = () => {
        if (onChange) {
            onChange(!!field?.value);
        }
        if (!field.value) {
            return helpers.setValue(inputValue);
        }
        return helpers.setValue(null);
    };

    if (inputLabel) {
        return (
            <FormControlLabel control={<StyledCheckbox checked={checked} onChange={handleChange} />}
                              label={inputLabel} />
        )
    }

    return (
        <label className={styles.custom_checkbox}>
            <StyledCheckbox checked={checked} onChange={handleChange} />
            {children}
        </label>
    );
}