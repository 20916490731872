import AmotaiAccount from "src/types/AmotaiAccount";
import AmotaiUser from "src/types/AmotaiUser";
import ListResult from "src/types/ListResult";
import Preference from "src/types/Preference";
import BuyerClient from "../../types/BuyerClient";
import Supplier from "../../types/Supplier";
import AccountAPI from "./api/account";
import UsersAPI from "./api/users";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum UsersTypes {
    SET_USER = "users:SET_USER",
    SET_USERS = "users:SET_USERS",
    SET_MY_USER = "users:SET_MY_USER",
    SET_MY_ACCOUNT = "users:SET_MY_ACCOUNT",
    LOG_OUT_USER = "users:LOG_OUT_USER",
    SET_PREF = "userS:SET_PREF",
    SET_PREFS = "userS:SET_PREFS",
    DEL_PREF = "user:DEL_PREF",
    SET_OPTS = "user:SET_OPTS",
}

const setUser = createAction<UserPayloads[UsersTypes.SET_USER]>(UsersTypes.SET_USER);
const setUsers = createAction<UserPayloads[UsersTypes.SET_USERS]>(UsersTypes.SET_USERS);
export const setMyUser = createAction<UserPayloads[UsersTypes.SET_MY_USER]>(UsersTypes.SET_MY_USER);
const setMyAccount = createAction<UserPayloads[UsersTypes.SET_MY_ACCOUNT]>(UsersTypes.SET_MY_ACCOUNT);

const setPref = createAction<UserPayloads[UsersTypes.SET_PREF]>(UsersTypes.SET_PREF);
const setPrefs = createAction<UserPayloads[UsersTypes.SET_PREFS]>(UsersTypes.SET_PREFS);
const delPref = createAction<UserPayloads[UsersTypes.DEL_PREF]>(UsersTypes.DEL_PREF);

export const updateMyOpts = createAction<UserPayloads[UsersTypes.SET_OPTS]>(UsersTypes.SET_OPTS);

export type UserPayloads = {
    [UsersTypes.SET_USER]: AmotaiUser,
    [UsersTypes.SET_USERS]: ListResult<AmotaiUser>,
    [UsersTypes.SET_MY_USER]: AmotaiUser | null,
    [UsersTypes.SET_MY_ACCOUNT]: AmotaiAccount | Supplier | BuyerClient,
    [UsersTypes.LOG_OUT_USER]: undefined,
    [UsersTypes.SET_PREF]: Preference,
    [UsersTypes.SET_PREFS]: Preference[],
    [UsersTypes.DEL_PREF]: { prefId: number },
    [UsersTypes.SET_OPTS]: { name: string, value: any },
}

export const logoutUser = () => {
    return { type: UsersTypes.LOG_OUT_USER }
};

export const getUser = wrapper(UsersAPI.getUser, {
    action: setUser,
});

export const getMyUser = wrapper(UsersAPI.getMyUser, {
    action: setMyUser,
})

export const updateMyProfile = wrapper(UsersAPI.updateMyProfile, {
    action: setMyUser
})

export const getMyAccount = wrapper(AccountAPI.getAccount, {
    action: setMyAccount
})

export const createAccount = wrapper(AccountAPI.createAccount, {
    onSuccess: ({ dispatch }, data) => dispatch(setMyUser(data.user))
})

export const deleteAccount = wrapper(AccountAPI.deleteAccount, {
    action: logoutUser
})

export const reviewAccount = wrapper(AccountAPI.submitAccountForReview, {
    action: setMyAccount
})

export const getAccountUsers = wrapper(UsersAPI.getAccountUsers, {
    action: setUsers,
});

export const updateAccountUser = wrapper(UsersAPI.updateAccountUser, {
    action: setUser
});

export const deleteUserFromAccount = wrapper(UsersAPI.deleteAccountUser);

export const inviteToAccount = wrapper(UsersAPI.inviteToAccount, {
    action: setUser
});

export const reinviteToAccount = wrapper(UsersAPI.reinviteToAccount)

export const updateMyAccount = wrapper(AccountAPI.updateAccount, {
    action: setMyAccount
});

export const createPref = wrapper(UsersAPI.createPref, { action: setPref });
export const getMyPrefs = wrapper(UsersAPI.getMyPrefs, { action: setPrefs });
export const deleteMyPref = wrapper(UsersAPI.deleteMyPref, {
    action: (_, params) => {
        const prefId = params[0];
        return delPref({ prefId });
    }
});
