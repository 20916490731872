import React, { useState, useRef } from "react";
import Button from "src/components/widgets/button/Button";
import useSelector from "src/hooks/useSelector";
import { useHistory } from "react-router-dom";
import AmotaiUser from "src/types/AmotaiUser";
import moment from "moment";
import AccountHeader from "../../component/AccountHeader";
import styles from "./Users.module.scss";
import CreateOrUpdateUserDialog
    from "../../../../widgets/dialogs/createOrUpdateAccountUserDialog/CreateOrUpdateAccountUserDialog";
import AmotaiAccount from "../../../../../types/AmotaiAccount";
import DeleteAccountUserDialog from "../../../../widgets/dialogs/deleteAccountUserDialog/DeleteAccountUserDialog";
import { DialogRef } from "../../../../widgets/dialog/Dialog";
import PagingTable from "../../../../widgets/pagingTable/PagingTable";
import UsersAPI from "../../../../../redux/actions/api/users";

type Filter = NonNullable<Parameters<typeof UsersAPI.getAccountUsers>[0]>;

export default function Users() {
    const me = useSelector<AmotaiUser>(state => state.users.me!);
    const account = useSelector<AmotaiAccount>(state => state.users.account!);
    const isOrgAdmin = useSelector<boolean>(state => state.users.isOrgAdmin);
    const createOrUpdateDialogRef = useRef<DialogRef>(null);
    const deleteDialogRef = useRef<DialogRef>(null);
    const [selectedUser, setSelectedUser] = useState<AmotaiUser | null>(null);
    const [filter, setFilter] = useState<Filter>({
        cursor: null,
        limit: 10,
        accountId: account.id
    });
    const history = useHistory();

    if (!isOrgAdmin) {
        history.push("/account");
    }

    const columns = [
        {
            title: "Name",
            render: (user: AmotaiUser) => `${user.firstName} ${user.lastName}`
        },
        {
            title: "Email address",
            field: "email"
        },
        {
            title: "Permission",
            field: "accountRole"
        },
        {
            title: "",
            render: (user: AmotaiUser) => (
                <div className={styles.links}>
                    {me.email !== user.email && (
                        <Button plainLink onClick={() => {
                            setSelectedUser(user);
                            createOrUpdateDialogRef.current?.show();
                        }}>Edit</Button>
                    )}
                    <Button plainLink onClick={() => {
                        setSelectedUser(user);
                        deleteDialogRef.current?.show();
                    }}>{me.email === user.email ? "Leave account" : "Delete"}</Button>
                </div>
            )
        }
    ];

    const resetFilters = () => {
        setFilter({
            cursor: null,
            limit: 10,
            accountId: account.id
        });
        setSelectedUser(null);
    };

    return (
        <>
            <div className={styles.users}>
                <AccountHeader title={account?.name!}
                               subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                               action={(
                                   <Button uppercase
                                           onClick={() => createOrUpdateDialogRef.current?.show()}>
                                       Invite
                                   </Button>
                               )}
                />
                <div>
                    <h5>Account Users</h5>
                    <PagingTable columns={columns}
                                 getData={UsersAPI.getAccountUsers}
                                 setFilter={setFilter}
                                 filter={filter}
                    />
                </div>

                <CreateOrUpdateUserDialog ref={createOrUpdateDialogRef} selectedUser={selectedUser} onSubmit={resetFilters} onClose={()=>setSelectedUser(null)} />
                <DeleteAccountUserDialog ref={deleteDialogRef} user={selectedUser} me={me} onSubmit={resetFilters} onCancelClick={()=>setSelectedUser(null)}  />
            </div>
        </>
    );
}