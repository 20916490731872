import Post from "../../types/Post";
import { Actions, Types } from "../actions/posts";

const initialState: {[key: string]: Post} = {}

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;


export default function posts(state= initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_POST: {
            const { payload } = action as ActionValueTypes<Types.SET_POST>
            return {
                ...state,
                [payload!.slug]: payload
            }
        }

        case Types.SET_POSTS: {
            const { payload } = action as ActionValueTypes<Types.SET_POSTS>
            const newState = { ...state }
            payload!.forEach((post: Post) => {
                newState[post.slug] = post
            })
            return {
                ...newState
            }
        }

        default: {
            return state;
        }
    }
}