import React from "react";
import moment from "moment";
import styles from "./Overview.module.scss";
import AccountHeader from "../component/AccountHeader";
import Button from "../../../widgets/button/Button";
import openNewWindow from "../../../../resources/images/svg/open-new-window.svg";
import useSelector from "../../../../hooks/useSelector";
import useMountEffect from "../../../../hooks/useMountEffect";
import useDispatch from "../../../../hooks/useDispatch";
import { getMyAccount } from "../../../../redux/actions/users";

export default function Overview() {
    const account = useSelector(state => state.users.account!);
    const dispatch = useDispatch();

    useMountEffect(() => {
        if (!account) {
            dispatch(getMyAccount())
        }
    })

    return (
        <div className={styles.overview}>
            <AccountHeader title={account.name}
                           subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                           action={(
                               <Button uppercase href={"/account/business-details"}>View profile
                                   page &gt;&gt;</Button>
                           )}
            />
            <div className={styles.links}>
                <h5>Links</h5>

                <a href={"mailto:kiaora@amotai.nz"} target={"_blank"} rel={"noopener noreferrer"}>Contact us <img
                    src={openNewWindow}
                    alt={"Opens in new window"} /></a>
            </div>
        </div>
    );
}