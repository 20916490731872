import React, { useRef, useState } from "react";
import firebase from "firebase/app";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { AccordionDetails } from "@material-ui/core";
import Page3, { FunderUserDetailsValidationSchema } from "./funder/Page3";
import Page4, { FunderOrgDetailsValidationSchema } from "./funder/Page4";
import Page5, { FunderFinancialDetailsValidationSchema } from "./funder/Page5";
import Page6, { FunderBusinessProfileValidationSchema } from "./funder/Page6";
import Page7, { FunderSubcontractorValidationSchema } from "./funder/Page7";
import Page8, { FunderBusinessContactValidationSchema } from "./funder/Page8";
import Page10, { FunderReviewValidationSchema } from "./funder/Page10";
import OnboardingSidebar from "../../widgets/onboardingSidebar/OnboardingSidebar";
import SideBar from "../../../resources/images/png/onboarding-supplier-sidebar@2x.png";
import OnboardingContent from "../../widgets/onboardingContent/OnboardingContent";
import styles from "./Onboarding.module.scss";
import MultiStepForm from "../../widgets/multiStepForm/MultiStepForm";
import { FunderFormValues, FunderInitialValues } from "./funder/funderValidation";
import { createAccount, getMyAccount, getMyUser, updateMyProfile } from "../../../redux/actions/users";
import useDispatch from "../../../hooks/useDispatch";
import { showError } from "../../../redux/actions/snackbars";
import AccountSubmittedDialog from "../../widgets/dialogs/accountSubmittedDialog/AccountSubmittedDialog";
import { DialogRef } from "../../widgets/dialog/Dialog";
import Button from "../../widgets/button/Button";
import Arrow from "../../../resources/images/svg/faq-arrow.svg";
import { NATIONWIDE_REGION } from "../../../util/constants";
import { ACCOUNT_TYPE, CreateFunderAccountDTO } from "../../../types/CreateAccountDTO";
import { createBusinessContacts } from "src/redux/actions/businessContacts";

export default function FunderOnboarding() {
    const dispatch = useDispatch();
    const accountSubmittedDialogRef = useRef<DialogRef>(null);
    const [submitting, setSubmitting] = useState(false);
    const [showIntro, setShowIntro] = useState(true);

    function mergeSchemas(...schemas: any) {
        const [first, ...rest] = schemas;

        return rest.reduce(
            (mergedSchemas: any, schema: any) => mergedSchemas.concat(schema),
            first
        );
    }

    const mergedSchemas = mergeSchemas(
        FunderUserDetailsValidationSchema,
        FunderOrgDetailsValidationSchema,
        FunderFinancialDetailsValidationSchema,
        FunderBusinessProfileValidationSchema,
        FunderSubcontractorValidationSchema,
        FunderBusinessContactValidationSchema,
        FunderReviewValidationSchema
    );

    const handleSubmit = async (values: FunderFormValues) => {
        setSubmitting(true);
        try {
            values.email = values.email?.trim().toLowerCase();
            await firebase.auth().createUserWithEmailAndPassword(values.email, values.password);
            await dispatch(updateMyProfile({
                firstName: values.firstName,
                lastName: values.lastName,
                name: `${values.firstName} ${values.lastName}`,
                jobTitle: values.jobTitle,
                phoneNumber: values.phoneNumber,
                mobileNumber: values.mobileNumber,
                avatar: values.avatar
            }));
            // create account

            let submittedRegions = [...values.regions];
            if (values.regions.includes(NATIONWIDE_REGION)) {
                // clear other regions and only submit nationwide
                submittedRegions = [NATIONWIDE_REGION];
            }

            const funderDTO: CreateFunderAccountDTO = {
                size: values.size,
                postalAddress: values.postalAddress,
                physicalAddress: values.physicalAddress,
                physicalAddressComponents: values.physicalAddressComponents,
                annualTurnover: values.annualTurnover,
                annualAddressableSpend: values.annualAddressableSpend,
                approxAnnualIndirectSpend: values.approxAnnualIndirectSpend,
                socialProcurementInitiatives: values.socialProcurementInitiatives,
                minimumLevelOfInsuranceForSubContract: values.minimumLevelOfInsuranceForSubContract,
                healthAndSafetyForSubContract: values.healthAndSafetyForSubContract,
                name: values.name,
                legalStructure: values.legalStructure,
                phoneNumber: values.phoneNumber,
                email: values.email,
                description: values.description,
                logo: values.logo,
                regions: submittedRegions,
                category: values.category,
                funderBuyer: true,
                regionBased: values.regionBased,
            };

            console.log('Create funder:', funderDTO);

            await dispatch(createAccount(funderDTO, ACCOUNT_TYPE.buyerClient));
            const account = await dispatch(getMyAccount());
            await dispatch(createBusinessContacts(account.id, values.businessContacts.map((b) => ({
                ...b,
                email: b.email?.trim()?.toLowerCase(),
                account: account.id
            }))));
            await dispatch(getMyUser());
            accountSubmittedDialogRef.current?.show();
            setSubmitting(false);
        } catch (err) {
            setSubmitting(false);
            dispatch(showError(err.message));
        }
    };

    if (showIntro) {
        return (
            <div className={styles.onboarding_container}>
                <OnboardingSidebar sidebarImage={SideBar}>
                    <h3>
                        You’re just a few clicks away from being connected to our Amotai Supplier and Member database
                    </h3>

                </OnboardingSidebar>
                <OnboardingContent>
                    <div className={styles.supplier_container}>
                        <h5>Get access to the Amotai Directory</h5>
                        <p>Congratulations, you&apos;ve taken the first step to becoming an Amotai Funder.
                            To Complete the process and join Amotai, please create your account for your organisation.
                        </p>

                        <div className={styles.faqs}>
                            <Accordion className={styles.faq_content}>
                                <AccordionSummary
                                    className={styles.faq_question}
                                    expandIcon={<img src={Arrow} alt={""} />}
                                >
                                    <Typography className={styles.faq_question_text}>Your personal profile
                                        details</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={styles.faq_answer}>
                                    <div>
                                        <ul>
                                            <li>Name</li>
                                            <li>Job title</li>
                                            <li>Phone number</li>
                                            <li>Email address</li>
                                            <li>Password</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={styles.faq_content}>
                                <AccordionSummary
                                    className={styles.faq_question}
                                    expandIcon={<img src={Arrow} alt={""} />}
                                >
                                    <Typography className={styles.faq_question_text}>Your business details</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={styles.faq_answer}>
                                    <div>
                                        <ul>
                                            <li>Trading and legal business name</li>
                                            <li>Business structure</li>
                                            <li>Business logo</li>
                                            <li>Business description</li>
                                            <li>Primary industry</li>
                                            <li>Size of Organisation(Number of FTE)</li>
                                            <li>Regions of operation</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={styles.faq_content}>
                                <AccordionSummary
                                    className={styles.faq_question}
                                    expandIcon={<img src={Arrow} alt={""} />}
                                >
                                    <Typography className={styles.faq_question_text}>Your subcontractors&apos; minimum
                                        requirement</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={styles.faq_answer}>
                                    <div>
                                        <ul>
                                            <li>Minimum level of insurance</li>
                                            <li>Minimum health and safty prequalifications required</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div style={{ margin: "27px 0px", fontWeight: "bold" }}>Please note we cannot save any of your
                            information until the very last page when you select submit
                        </div>
                        <div className={styles.single_button_container}>
                            <Button onClick={() => setShowIntro(false)}>START MY APPLICATION</Button>
                        </div>
                    </div>
                </OnboardingContent>
            </div>
        );
    }

    const renderBreadcrumbs = (stepNumber: number, handleClick: (n: number) => void) => (
        <ul className={styles.breadcrumbs}>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 0,
                [styles.step_selected]: stepNumber === 0
            })}
                onClick={() => handleClick(0)}>Your profile
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 1,
                [styles.step_selected]: stepNumber === 1
            })}
                onClick={() => handleClick(1)}>About your organisation
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 2,
                [styles.step_selected]: stepNumber === 2
            })}
                onClick={() => handleClick(2)}>Financial details
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 3,
                [styles.step_selected]: stepNumber === 3
            })}
                onClick={() => handleClick(3)}>Business profile
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 4,
                [styles.step_selected]: stepNumber === 4
            })}
                onClick={() => handleClick(4)}>Supplier requirements
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 5,
                [styles.step_selected]: stepNumber === 5
            })}
                onClick={() => handleClick(5)}>Business contacts
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 6,
                [styles.step_selected]: stepNumber === 6
            })}
                onClick={() => handleClick(6)}>Review
            </li>
        </ul>
    );

    return (
        <div className={styles.onboarding_container}>
            <MultiStepForm initialValues={FunderInitialValues}
                           buttonsWrapperClass={styles.action_buttons}
                           onboardingContainerClass={styles.supplier_container}
                           submitting={submitting}
                           onSubmit={handleSubmit}
                           steps={[
                               <Page3 validationSchema={FunderUserDetailsValidationSchema} />,
                               <Page4 validationSchema={FunderOrgDetailsValidationSchema} />,
                               <Page5 validationSchema={FunderFinancialDetailsValidationSchema} />,
                               <Page6 validationSchema={FunderBusinessProfileValidationSchema} />,
                               <Page7 validationSchema={FunderSubcontractorValidationSchema} />,
                               <Page8 validationSchema={FunderBusinessContactValidationSchema} />,
                               <Page10 validationSchema={mergedSchemas} />
                           ]}
                           breadcrumbs={renderBreadcrumbs} />

            <AccountSubmittedDialog ref={accountSubmittedDialogRef}
                                    title={"Success! Funder account created"}
                                    content={"You've finished your funder account creation and have access to the directory. Your business profile won't be published to the directory until Amotai has reviewed and approved it."}
                                    btnText={"FIND BUSINESSES"}
            />
        </div>
    );
}
