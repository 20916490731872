import { debounce } from 'lodash';
import React, { PropsWithChildren } from 'react';
import styles from './ScrollerContainer.module.scss';

type ScrollerProps = {
    loadMore: () => Promise<void>;
    hasMore: boolean;
}

export default function ScrollerContainer(props: PropsWithChildren<ScrollerProps>) {
    const { loadMore, hasMore, children } = props;

    const onScroll = debounce(
        async (target: any) => {
            if (target && hasMore) {
                const { scrollHeight, scrollTop, clientHeight } = target;
                const bottom = scrollHeight - scrollTop === clientHeight;
                if (bottom) {
                    await loadMore();
                }
            }
        },
        150
    );


    return (
        <div className={styles.container} onScroll={({ target }) => onScroll(target)}>
            {children}
        </div>
    )
}