import { CardMedia } from "@material-ui/core";
import classnames from 'classnames';
import moment from 'moment';
import React from "react";
import { useHistory } from "react-router-dom";
import AmotaiEvent from "src/types/AmotaiEvent";
import { buildEventDateString } from "src/util/helper";
import styles from "./EventCard.module.scss";

type Props = {
    event: AmotaiEvent,
    featured?: boolean,
}
export default function EventCard({ event, featured }: Props) {
    const history = useHistory();
    const { slug, cardImage, title, details } = event;
    // const { location, startedAt, endedAt, region } = details;
    const datetimeDisplay = details ? buildEventDateString(moment(details.startedAt), moment(details.endedAt)).join(', ') : '';

    const containerClass = classnames(styles.container, {
        [styles.featured_container]: featured
    })

    const imageClass = classnames(styles.image, {
        [styles.featured_image]: featured
    })

    const contentClass = classnames(styles.post_content, {
        [styles.featured_content]: featured
    });
    const subtitleClass = classnames(styles.subtitle, {
        [styles.featured_subtitle]: featured
    })

    const onViewEvent = () => {
        history.push(`/events/${slug}`);
    };

    return (
        <div className={containerClass} onClick={onViewEvent}>
            <CardMedia
                image={cardImage}
                className={imageClass}
            />
            <div className={contentClass}>
                <h5>{title}</h5>
                <span className={subtitleClass}>
                    {`${(featured ? details?.location : details?.region) ?? ''} • ${datetimeDisplay}`}
                </span>
                {featured &&
                    <div className={styles.button}>VIEW EVENT DETAILS &gt;&gt;</div>
                }
            </div>
        </div>
    );
}
