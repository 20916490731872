import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import ga4 from 'react-ga4';
import { AccountType } from 'src/types/AmotaiAccount';
import { HitType, SearchSuggestion } from 'src/types/SearchResult';
import { GA4_CATEGORY_EVENT } from 'src/util/constants';
import useSelector from './useSelector';

interface GA4ContextProps {
    categorySearchEvent: (suggestion: SearchSuggestion, accountType: AccountType) => void;
}

const GA4Context = createContext<GA4ContextProps>({
    categorySearchEvent: () => { },
});

export function GA4ContextProvider({ children }: { children: ReactNode }) {
    const me = useSelector(state => state.users.me);
    useEffect(() => {
        ga4.initialize(process.env.REACT_APP_FIREBASE_MESUREMENT_ID as string);
    }, []);

    const categorySearchEvent = (suggestion: SearchSuggestion, accountType: AccountType) => {
        const { hitType, id, name } = suggestion;
        const categoryTypes = [HitType.Category, HitType.SubCategory, HitType.SubSubCategory];
        if (categoryTypes.includes(hitType) && ga4.isInitialized) {
            const catName = name;
            const catType = hitType;
            ga4.event(GA4_CATEGORY_EVENT, {
                catId: id,
                catName,
                catType,
                accType: accountType,
                amotaiUserId: me?.id,
            })
        }
    }

    return (
        <GA4Context.Provider
            value={{ categorySearchEvent }}
        >
            {children}
        </GA4Context.Provider>
    );
}

export default function useGA4Context() {
    return useContext<GA4ContextProps>(GA4Context);
}

/**
 * GA Instructions:
 * 1. GA -> admin -> custom definitions -> custom dimensions -> create new
 * 2. create 5 new dimensions: cat_id, cat_name, cat_type, acc_type, amotai_user_id
 * 3. sync in bigquery datasource
 */