import classNames from "classnames";
import { Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useState } from "react";
import * as Yup from "yup";
import useDispatch from "../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../hooks/useSelector";
import { getMainCategories } from "../../../../../../redux/actions/categories";
import { showError } from "../../../../../../redux/actions/snackbars";
import { updateMyAccount } from "../../../../../../redux/actions/users";
import Address from "../../../../../../types/Address";
import AmotaiUser, { AccountRole } from "../../../../../../types/AmotaiUser";
import BuyerClient from "../../../../../../types/BuyerClient";
import { Category } from "../../../../../../types/Category";
import {
    ANNUAL_ADDRESSABLE_SPEND,
    ANNUAL_INDIRECT_SPEND,
    BUYER_TURNOVER,
    HEALTH_AND_SAFETY,
    INSURANCES,
    LEGAL_STRUCTURE, NATIONWIDE_REGION,
    REGIONS, REGIONS_NO_NATIONWIDE
} from "../../../../../../util/constants";
import Button from "../../../../../widgets/button/Button";
import FormikAddressInput from "../../../../../widgets/formikFields/FormikAddressInput";
import FormikCheckboxGroup from "../../../../../widgets/formikFields/FormikCheckboxGroup";
import FormikInput from "../../../../../widgets/formikFields/FormikInput";
import FormikNumberInput from "../../../../../widgets/formikFields/FormikNumberInput";
import FormikSelect from "../../../../../widgets/formikFields/FormikSelect";
import Loading from "../../../../../widgets/loading/Loading";
import SectionHeader from "../../../../../widgets/sectionHeader/SectionHeader";
import AccountHeader from "../../../component/AccountHeader";
import styles from "../BusinessDetails.module.scss";

type BuyerClientProps = {
    account: BuyerClient;
    me: AmotaiUser,
};

export type BuyerClientFormValues = {
    name: string;
    legalStructure: string;
    category: Category;
    size: number;
    regions: string[];
    postalAddress: string;
    physicalAddress: string;
    physicalAddressComponents: Address;
    annualTurnover: string;
    annualAddressableSpend: string;
    approxAnnualIndirectSpend: string;
    socialProcurementInitiatives: string;
    minimumLevelOfInsuranceForSubContract: string;
    healthAndSafetyForSubContract: string[];
};

const BuyerClientValidationSchema = Yup.object({
    name: Yup.string().required("Required"),
    legalStructure: Yup.string().required("Required"),
    category: Yup.mixed().when("organisationType", {
        is: "iwi",
        then: Yup.string().nullable(),
        otherwise: Yup.string().required("Required")
    }),
    size: Yup.number().required("Required"),
    regions: Yup.array().min(1, "At least one region is required"),
    postalAddress: Yup.string().required("Required"),
    physicalAddress: Yup.string().required("Required"),
    physicalAddressComponents: Yup.object().nullable(),
    annualTurnover: Yup.string().required("Required"),
    annualAddressableSpend: Yup.string().required("Required"),
    approxAnnualIndirectSpend: Yup.string().required("Required"),
    socialProcurementInitiatives: Yup.string().required("Required"),
    minimumLevelOfInsuranceForSubContract: Yup.string().required("Required"),
    healthAndSafetyForSubContract: Yup.array().min(1, "At least one option must be selected"),
    regionBased: Yup.string().required("Required")
});


function BuyerClientOverview(props: BuyerClientProps) {
    const { account, me } = props;
    const [loading, setLoading] = useState<boolean>(true);
    const [editing, setEditing] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const dispatch = useDispatch();
    const categories = useSelector<Category[]>((state) => state.categories.main);
    const categoryOptions = categories.map((category) => ({ name: category.name, value: category }));

    useMountEffect(async () => {
        try {
            if (!categories || !categories.length) {
                await dispatch(getMainCategories());
            }
        } catch (e) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    });

    const onSubmit = async (values: BuyerClientFormValues) => {
        try {
            setSubmitting(true);
            let updatingRegions = [...values.regions];
            if (values.regions.includes(NATIONWIDE_REGION)) {
                updatingRegions = [NATIONWIDE_REGION];
            }
            const { mainContact, ...payload } = { ...account, ...values };
            payload.regions = updatingRegions;
            await dispatch(updateMyAccount(account.id, payload, account.type));
            setSubmitting(false);
        } catch (e) {
            dispatch(showError(e.message));
        }
    };

    const [initialCategory] = categories.filter((c) => c.id === account.category?.id);
    const initialValues = {
        name: account.name || "",
        legalStructure: account.legalStructure || "",
        category: initialCategory || null,
        size: account.size || 0,
        regions: account.regions || [],
        postalAddress: account.postalAddress || "",
        physicalAddress: account.physicalAddress || "",
        physicalAddressInput: account.physicalAddressComponents?.formattedAddress ?? "",
        physicalAddressComponents: account.physicalAddressComponents || null,
        annualTurnover: account.annualTurnover || "",
        annualAddressableSpend: account.annualAddressableSpend || "",
        approxAnnualIndirectSpend: account.approxAnnualIndirectSpend || "",
        socialProcurementInitiatives: account.socialProcurementInitiatives || "",
        minimumLevelOfInsuranceForSubContract: account.minimumLevelOfInsuranceForSubContract || "",
        healthAndSafetyForSubContract: account.healthAndSafetyForSubContract || [],
        organisationType: account.organisationType,
        regionBased: account.regionBased
    };

    const toggleEdit = async (formikBag: FormikProps<BuyerClientFormValues>) => {
        if (editing && (formikBag.dirty || formikBag.touched.regions || formikBag.touched.healthAndSafetyForSubContract)) {
            await onSubmit(formikBag.values);
        }

        setEditing(!editing);
        if (!editing) {
            formikBag.resetForm();
        }
    };

    if (submitting || loading) {
        return <Loading />;
    }


    const isIwi = account?.organisationType === "iwi";
    return (
        <>
            <Formik<BuyerClientFormValues> validationSchema={BuyerClientValidationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}>
                {(formikBag) => {
                    let actionText = "Edit";
                    if (editing) {
                        if (formikBag.dirty || formikBag.touched.regions || formikBag.touched.healthAndSafetyForSubContract) {
                            actionText = "Save";
                        } else {
                            actionText = "Cancel";
                        }
                    }

                    return (
                        <form onSubmit={formikBag.handleSubmit}>
                            <AccountHeader title={account.name}
                                subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                                action={me?.accountRole === AccountRole.ADMIN && (
                                    <Button uppercase
                                        onClick={() => toggleEdit(formikBag)}>{actionText}</Button>
                                )}
                            />
                            <SectionHeader
                                title={`${account.organisationType === "iwi" ? "Iwi" : "Business"} Details`} />
                            <div className={styles.form}>
                                <div className={styles.form_section}>
                                    <FormikInput name={"name"}
                                        label={`${account.organisationType === "iwi" ? "Iwi" : "Organisation"} Name`}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                        disabled={!editing} />
                                    <FormikSelect name={"legalStructure"}
                                        label={"Structure"}
                                        containerClassName={styles.half_input}
                                        options={LEGAL_STRUCTURE}
                                        disabled={!editing} />
                                    {!isIwi && (
                                        <FormikSelect name={"category"}
                                            label={"Primary Industry"}
                                            containerClassName={styles.full_input}
                                            options={categoryOptions}
                                            disabled={!editing} />
                                    )}
                                    <FormikInput name='postalAddress'
                                        label={"Postal Address"}
                                        disabled={!editing}
                                        inputClassname={styles.full_input} />
                                    <FormikAddressInput name={"physicalAddressComponents"}
                                        formattedName={"physicalAddressInput"}
                                        label={"Physical Address"}
                                        disabled={!editing}
                                        placeholder={"1 Example St, Auckland, 0610, New Zealand"}
                                        inputClassname={styles.full_input} />
                                </div>
                                <div className={styles.form_section}>

                                    <SectionHeader title={"Regions"} />
                                    <div className={styles.checkbox_container}>
                                        <div className={styles.heading}>
                                            <label>Regions of operation</label>
                                        </div>
                                        {formikBag.values.regions.includes(NATIONWIDE_REGION) ?
                                            <FormikCheckboxGroup name={"regions"} disabled={!editing} stringItems={[NATIONWIDE_REGION]} /> :
                                            <FormikCheckboxGroup name={"regions"} disabled={!editing} stringItems={REGIONS} />}
                                    </div>

                                    <div className={styles.checkbox_container}>
                                        <div className={styles.heading}>
                                            <label>Region based</label>
                                        </div>
                                        <FormikSelect name={"regionBased"} disabled={!editing} options={REGIONS_NO_NATIONWIDE} />
                                    </div>
                                </div>
                                <div className={styles.form_section}>
                                    <SectionHeader title={"Financial & Employment"} />
                                    <FormikNumberInput name={"size"}
                                        label={"Size of workforce (Total number of Employees)"}
                                        disabled={!editing} inputClassname={styles.full_input} />
                                    <FormikSelect name={"annualTurnover"}
                                        label={"Annual turnover"}
                                        options={BUYER_TURNOVER}
                                        disabled={!editing}
                                        containerClassName={classNames(styles.half_input, styles.first)}
                                    />
                                    <FormikSelect name={"annualAddressableSpend"}
                                        label={"Annual addressable spend"}
                                        options={ANNUAL_ADDRESSABLE_SPEND}
                                        disabled={!editing}
                                        containerClassName={styles.half_input}
                                    />
                                    <FormikSelect name={"approxAnnualIndirectSpend"}
                                        label={"Approx annual indirect spend"}
                                        options={ANNUAL_INDIRECT_SPEND}
                                        disabled={!editing}
                                    />

                                    <FormikInput name={"socialProcurementInitiatives"}
                                        multiline
                                        label={"Existing Social Procurement Initiatives"}
                                        inputClassname={styles.full_text_input} disabled={!editing} />
                                </div>

                                <div className={styles.form_section}>
                                    <SectionHeader title={"Subcontractor Requirements"} />
                                    <div className={classNames(styles.form_section, styles.health_safety_container)}>
                                        <label>Minimum Health and Safety Prequalifications Required for Subcontactors
                                        </label>
                                        <FormikCheckboxGroup name={"healthAndSafetyForSubContract"}
                                            stringItems={HEALTH_AND_SAFETY} disabled={!editing} />
                                    </div>
                                    <FormikSelect name={"minimumLevelOfInsuranceForSubContract"}
                                        options={INSURANCES}
                                        label={"Minimum Level of Insurance Required for Subcontractors"}
                                        disabled={!editing} />
                                </div>
                            </div>
                            {editing && (formikBag.dirty || formikBag.touched.regions || formikBag.touched.healthAndSafetyForSubContract) && <Button type={"submit"}>Save</Button>}
                        </form>
                    );
                }}
            </Formik>
        </>
    );
}

export default BuyerClientOverview;
