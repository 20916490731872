/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 30/06/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React from "react";
import Spinner from "../spinner/Spinner";
import styles from "./Loading.module.scss";

export default function Loading() {
    return <Spinner vCenter hCenter containerClassName={styles.loading} />;
}

