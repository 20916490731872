import React, { ChangeEvent } from "react";
import { InputLabel } from "@material-ui/core";
import classNames from "classnames";
import styles from "./FileUpload.module.scss";
import Loading from "../loading/Loading";

export type FileUploadProps = {
    id?: string;
    label?: string;
    actionText?: string;
    labelClassName?: string;
    imageClassName?:string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    accept?: string;
    disabled?: boolean;
    helperText?: string;
    preview?: string;
    showError?: boolean;
    loading?: boolean;
}

function FileUpload(props: FileUploadProps) {
    const { id, label, actionText, labelClassName, imageClassName, onChange, accept, disabled, helperText, preview, showError, loading } = props;

    return (
        <div className={classNames(styles.file_upload, labelClassName)}>
            <InputLabel htmlFor={id || "file"}>
                <div className={styles.label}>{label}</div>
                {!preview && actionText}
                {loading ? <Loading /> : (
                    <>
                        <input id={id || "file"}
                               type={"file"}
                               onChange={onChange}
                               accept={accept || "*"}
                               disabled={disabled || loading}
                               style={{ display: "none" }}
                               multiple={false} />
                        {preview && (
                            <div className={classNames(styles.preview, imageClassName)}>
                                <div style={{ backgroundImage: `url(${preview})` }} className={styles.avatar_image} />
                                {/* <img src={preview} alt={`preview.${id || "file"}`} /> */}
                            </div>
                        )}
                    </>
                )}
            </InputLabel>
            {showError && helperText && <div className={styles.error}>{helperText}</div>}
        </div>
    );
}

export default FileUpload;