import * as Sentry from "@sentry/react";
import React, { ReactNodeArray, useEffect, useState } from "react";
import { showError } from "src/redux/actions/snackbars";
import { AccountType } from "src/types/AmotaiAccount";
import BuyerClient from "src/types/BuyerClient";
import SaaSSubscription from "src/types/SaaSSbscription";
import { orderBy } from "lodash";
import useDispatch from "../../../hooks/useDispatch";
import useMountEffect from "../../../hooks/useMountEffect";
import useSelector from "../../../hooks/useSelector";
import { getAccountSubscription } from "../../../redux/actions/saaSPlans";
import Resource, { ResourcePermission } from "../../../types/Resource";
import qs from "../../../util/qs";
import SanityAPI from "../../../util/SanityAPI";
import PageContainer from "../../widgets/pageContainer/PageContainer";
import PageLoader from "../../widgets/pageLoader/PageLoader";
import ResourceCard from "../../widgets/resourceCard/ResourceCard";
import Section from "../../widgets/section/Section";
import SingleTab from "../../widgets/tabs/Tab";
import Tabs from "../../widgets/tabs/Tabs";
import styles from "./Resources.module.scss";

export enum RESOURCE_TYPE {
    image = "image",
    video = "video",
    pdf = "pdf",
    default = "default",
    webinar = "webinar"
}

enum ResourcePage {
    READINGS = 0,
    MODULES = 1,
    VIDEOS = 2,
}

export default function Resources() {
    const [readings, setReadings] = useState<Resource[]>([]);
    const [modules, setModules] = useState<Resource[]>([]);
    const [loading, setLoading] = useState(false);
    const [videos, setVideos] = useState<Resource[]>([]);
    const dispatch = useDispatch();

    const [tabNo, setTabNo] = useState<number>(0);
    const account = useSelector((state) => state.users.account);

    const search = window.location?.search;
    const query = qs.parse(search) ?? "";

    useMountEffect(() => {
        if (query.page) {
            setTabNo(Number(query.page));
        }
    });

    useEffect(() => {
        (async () => {
            if (!account) {
                return;
            }
            setLoading(true);
            try {
                let subscription: SaaSSubscription | undefined;
                if (account.type === AccountType.BUYER_CLIENT) {
                    subscription = await dispatch(getAccountSubscription(account.id));
                }
                // permission constant for saasPlans, non buyer_client accounts will be assigned champion as no filtering
                // and buyer_client accounts will filter by plan
                let planType = "";

                if (account?.type !== AccountType.BUYER_CLIENT) {
                    planType = "aukōkiri";
                }

                if (subscription) {
                    planType = subscription.plan.name.toLowerCase();
                }

                let permission: ResourcePermission | null = null;
                switch (account?.type) {
                    case AccountType.BUYER_CLIENT:
                        if ((account as BuyerClient).iwiBuyer) {
                            permission = ResourcePermission.BUYER_CLIENT_IWI;
                        } else if ((account as BuyerClient).funderBuyer) {
                            permission = ResourcePermission.BUYER_CLIENT_FUNDER;
                        } else {
                            permission = ResourcePermission.BUYER_CLIENT;
                        }
                        break;
                    case AccountType.SUPPLIER:
                        permission = ResourcePermission.SUPPLIER;
                        break;
                    default:
                }

                const w = await SanityAPI.getWebinars(permission, planType.toLowerCase());

                w.sort((a: any, b: any) => b.updatedAt > a.updatedAt ? 1 : -1);
                const r = await SanityAPI.getResources(permission, planType.toLowerCase());
                const m = await SanityAPI.getModules(permission, planType.toLowerCase());
                setReadings(r);
                setModules(m);
                setVideos(w);
            } catch (error) {
                Sentry.captureException(error);
                dispatch(showError('Failed to get resources.'));
            } finally {
                setLoading(false);
            }
        })();
    }, [account, dispatch]);

    const checkResourceType = (mimeType: string = "") => {
        if (mimeType.includes("image")) return RESOURCE_TYPE.image;
        if (mimeType.includes("video")) return RESOURCE_TYPE.video;
        if (mimeType.includes("pdf")) return RESOURCE_TYPE.pdf;
        return RESOURCE_TYPE.default;
    };

    const renderTabContent = () => {
        let tabContent: ReactNodeArray;
        switch (tabNo) {
            case ResourcePage.READINGS: {
                // reading resources
                tabContent = readings.length > 0 ? (orderBy(readings, ["title"]).map((resource) => (
                    <ResourceCard resource={resource}
                        resourceType={checkResourceType(resource?.file?.asset?.mimeType)}
                        key={resource._id} />
                ))) : [<div style={{ textAlign: "center" }}>Sorry, there are no resources available for you on your
                    chosen
                    plan. Please upgrade your plan to view.</div>];
                break;
            }
            case ResourcePage.MODULES: {
                // module resources
                tabContent = modules.length > 0 ? (orderBy(modules, ["title"]).map((resource) => (
                    <ResourceCard resource={resource}
                        resourceType={checkResourceType(resource?.file?.asset?.mimeType)}
                        key={resource._id} />
                ))) : [<div style={{ textAlign: "center" }}>Sorry, there are no modules available for you on your
                    chosen
                    plan. Please upgrade your plan to view.</div>];
                break;
            }
            case ResourcePage.VIDEOS: {
                tabContent = videos.length > 0 ? (orderBy(videos, ["title"]).map((webinar) => (
                    <ResourceCard resource={webinar}
                        resourceType={RESOURCE_TYPE.webinar}
                        key={webinar._id} />
                ))) : [<div style={{ textAlign: "center" }}>Sorry, there are no webinars available for you on your
                    chosen
                    plan. Please upgrade your plan to view.</div>];
                break;
            }

            default: {
                tabContent = [];
            }
        }
        return (
            <div className={styles.resources_listings}>
                {tabContent}
            </div>
        );
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, num: number) => {
        setTabNo(num);
        renderTabContent();
    };

    if (loading) {
        return <PageLoader />;
    }

    return (
        <PageContainer containerClassName={styles.resources}>
            <h2>Resources</h2>
            <div className={styles.resources_tabs_container}>
                <Tabs className={styles.resources_tabs} value={tabNo} onChange={handleTabChange}>
                    <SingleTab label="Readings" />
                    <SingleTab label="Modules" />
                    <SingleTab label="Videos" />
                </Tabs>
            </div>
            <Section contentClass={styles.resources_content}>
                {renderTabContent()}
            </Section>
        </PageContainer>
    );
}
