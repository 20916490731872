import Card from "src/types/Card";
import Transaction from "src/types/Transaction";
import ListResult from "../../types/ListResult";
import CardsAPI from "./api/cards";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_CARD = "cards:SET_CARD",
    SET_CARDS = "cards:SET_CARDS",
    DELETE_CARD = "cards:DELETE_CARD",
    SET_TRANSACTIONS = "cards:SET_TRANSACTIONS"
}

export const setCard = createAction<Card>(Types.SET_CARD);
export const setCards = createAction<Card[]>(Types.SET_CARDS);
export const deleteCard = createAction<Card>(Types.DELETE_CARD);
export const setTransactions = createAction<ListResult<Transaction>>(Types.SET_TRANSACTIONS);


export const Actions = {
    [Types.SET_CARD]: setCard,
    [Types.SET_CARDS]: setCards,
    [Types.DELETE_CARD]: deleteCard,
    [Types.SET_TRANSACTIONS]: setTransactions
};
export type Actions = typeof Actions;

export const createCard = wrapper(CardsAPI.createCard, {
    action: setCard,
});

export const getCards = wrapper(CardsAPI.getCards, {
    action: setCards,
});

export const getSingleCard = wrapper(CardsAPI.getSingleCard, {
    action: setCard,
});

export const updateCard = wrapper(CardsAPI.updateCard, {
    action: setCard,
});

export const removeCard = wrapper(CardsAPI.removeCard, {
    action: deleteCard
});
