import React, { useState } from "react";
import Button from "src/components/widgets/button/Button";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import styles from "../business-details/BusinessDetails.module.scss";
import Supplier from "../../../../../types/Supplier";
import AccountHeader from "../../component/AccountHeader";
import AmotaiUser, { AccountRole } from "../../../../../types/AmotaiUser";
import useDispatch from "../../../../../hooks/useDispatch";
import useSelector from "../../../../../hooks/useSelector";
import useMountEffect from "../../../../../hooks/useMountEffect";
import BusinessOwnerFieldArray from "../../../../widgets/formikFields/BusinessOwnerFieldArray";
import { getBusinessOwners, updateBusinessOwners } from "../../../../../redux/actions/businessOwners";
import { AccountType } from "../../../../../types/AmotaiAccount";
import BusinessOwner from "../../../../../types/BusinessOwner";
import { getMyAccount, updateMyAccount } from "../../../../../redux/actions/users";
import { showError } from "../../../../../redux/actions/snackbars";
import Loading from "../../../../widgets/loading/Loading";
import { OrgOwnershipValidationSchema } from "../../../onboarding/supplier/Page5";
import AlertBar from "../../component/AlertBar";

type OwnershipValues = {
    owners: BusinessOwner[]
};

export default function Ownership() {
    const [loading, setLoading] = useState<boolean>(true);
    const [editing, setEditing] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const me = useSelector<AmotaiUser>(state => state.users.me!);
    const account = useSelector<Supplier>(state => state.users.account as Supplier);
    const businessOwners = useSelector<BusinessOwner[]>(state => state.businessOwners.businessOwners);

    useMountEffect(async () => {
        try {
            const acc = await dispatch(getMyAccount());
            await dispatch(getBusinessOwners(acc.id));
        } catch (e) {
            dispatch(showError(e.message));
            history.push("/");
        }
        setLoading(false);
    });

    const onSubmit = async (values: OwnershipValues) => {
        try {
            setLoading(true);
            await dispatch(updateMyAccount(account.id, {
                ...account,
                ...values
            }, AccountType.SUPPLIER));
            const owners = values.owners.map((o) => ({
                ...o,
                account: account.id
            }));
            await dispatch(updateBusinessOwners(account.id, owners));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    const toggleEdit = async (formikBag: FormikProps<OwnershipValues>) => {
        if (editing && formikBag.dirty) {
            await onSubmit(formikBag.values);
        }

        setEditing(!editing);
        if (!editing) {
            formikBag.resetForm();
        }
    };

    if (loading) {
        return <Loading />;
    }

    const verified = !!account.ownershipType;

    return (
        <Formik<OwnershipValues> initialValues={{
            owners: businessOwners || [],
        }} onSubmit={onSubmit} validationSchema={OrgOwnershipValidationSchema}>
            {(formikBag) => {
                let actionText = "Edit";
                if (editing) {
                    if (formikBag.dirty) {
                        actionText = "Save";
                    } else {
                        actionText = "Cancel";
                    }
                }

                return (
                    <div className={styles.business_details}>
                        <div className={styles.form}>
                            <form onSubmit={formikBag.handleSubmit}>
                                <AccountHeader title={account.name}
                                               subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                                               action={me?.accountRole === AccountRole.ADMIN && (
                                                   <Button uppercase disabled={verified}
                                                           onClick={() => toggleEdit(formikBag)}>{actionText}</Button>
                                               )}
                                />
                                <AlertBar grey={!verified} containerClassName={styles.ownership_notice}>
                                    <div className={styles.ownership_main_text}>
                                        {verified ? `This accounts ownership has already been verified and the ownership details have been locked.`
                                         : `This accounts ownership has has not yet been verified.`}
                                    </div>
                                    {verified ? 
                                        <div>
                                            If your ownership details change, please email <a className={styles.email} href="mailto:kiaora@amotai.nz">kiaora@amotai.nz</a>
                                        </div>
                                        :
                                        <div>Once verified, ownership details will be locked and must be updated bu an Amotai staff member.</div>
                                    }
                                </AlertBar>
                                <BusinessOwnerFieldArray disabled={!editing} />
                            </form>
                        </div>
                    </div>

                );
            }}
        </Formik>
    );
}