import sanityClient from "@sanity/client";
import groq from "groq";
import { IFilterFields } from "src/components/widgets/filter/Filter";
import AmotaiEvent from 'src/types/AmotaiEvent';
import Policy from "src/types/Policy";
import FAQs from "../types/FAQs";
import Post from "../types/Post";
import Resource, { ResourcePermission } from '../types/Resource';

export const sanity = sanityClient({
    projectId: "xcg7kopj",
    dataset: process.env.REACT_APP_SANITY_DATASET, // process.env.NEXT_PUBLIC_SANITY_DATA_SET,
    useCdn: process.env.ENVIRONMENT !== "development"
});

function getFaqs() {
    return sanity.fetch(groq`*[_type == "support"]{...,"generalFaqs": generalFaqs[]->, "supplierFaqs": supplierFaqs[]->, "clientFaqs": clientFaqs[]->}[0]`);
}

async function getPolicyPage(slug: string): Promise<Policy | null> {
    const policy: Policy | null = await sanity.fetch(groq`*[_type == "policy" && slug.current == "/${slug}"]
    {
        title, "slug":slug.current,
        "content": content[]{..., "image": image.asset->url, 'mimeType': image.asset->mimeType}
    }[0]`);
    if (policy) {
        return {
            ...policy,
            content: policy.content?.map(c => ({ ...c, image: parseImageUrl(c.mimeType, c.image) }))
        };
    }
    return null;
}

async function getPosts(): Promise<Post[]> {
    const posts: any[] | null = await sanity.fetch(groq`*[_type == "post"] | order(_createdAt desc) 
    {
        "id": _id, author, 'createdAt': _createdAt, title, subtitle, slug, category,
        'image': image.asset->url,
        'mimeType': image.asset->mimeType,
        'createdAt': _createdAt, content
    }`);
    return posts?.map(p => ({ ...p, image: parseImageUrl(p.mimeType, p.image) })) ?? [];
}

async function getEvents(): Promise<AmotaiEvent[]> {
    const events: any[] | null = await sanity.fetch(groq`
    *[_type == "event"] | order(_createdAt desc) 
    {
        title, subtitle, featured, details, content,
        "slug": slug.current, 
        "heroImages": heroImages[]{"image": asset->url, 'mimeType': asset->mimeType},
        'cardImage': cardImage.asset->url,
        'cardMimeType': cardImage.asset->mimeType,
        'createdAt': _createdAt
    }`);
    return events?.map(e => ({
        ...e,
        heroImages: e.heroImages?.map(({ image, mimeType }: any) => parseImageUrl(mimeType, image)),
        cardImage: parseImageUrl(e.cardMimeType, e.cardImage)
    })) ?? [];
}

async function getPostBySlug(slug: string): Promise<Post | null> {
    const post = await sanity.fetch(groq`*[_type == "post" && slug == "${slug}"]
    {
        'id': _id, title, author, subtitle, slug, category,
        'image': image.asset->url,
        'mimeType': image.asset->mimeType,
        'createdAt': _createdAt, content
    }[0]`);
    if (post) {
        return {
            ...post,
            image: parseImageUrl(post.mimeType, post.image),
        }
    }
    return null;
}

async function getEventBySlug(slug: string): Promise<AmotaiEvent | null> {
    const event: (AmotaiEvent & { cardMimeType: string }) | null = await sanity.fetch(groq`*[_type == "event" && slug.current == "${slug}"]
    {
        title, subtitle, featured, details, content,
        "slug": slug.current, 
        "heroImages": heroImages[]{"image": asset->url, 'mimeType': asset->mimeType},
        'cardImage': cardImage.asset->url,
        'cardMimeType': cardImage.asset->mimeType,
        'createdAt': _createdAt
    }
    [0]`);
    if (event) {
        return {
            ...event,
            heroImages: event.heroImages?.map(({ image, mimeType }: any) => parseImageUrl(mimeType, image)).filter((v): v is string => v !== null),
            cardImage: parseImageUrl(event.cardMimeType, event.cardImage) ?? undefined,
        }
    }
    return null;
}

async function searchPosts(filters: Omit<IFilterFields, "region">): Promise<Post[]> {
    let conditions = `_type == "post"`;
    if (!!filters.category) {
        conditions = `${conditions} && category == "${filters.category}"`;
    }
    if (!!filters.keyword) {
        conditions = `${conditions} && ([title, subtitle, author] match "${filters.keyword}" || content[].children[].text match "${filters.keyword}")`;
    }
    const posts: any[] = await sanity.fetch(groq`*[${conditions}] | order(_createdAt desc) 
    {
        'id': _id, title, author, subtitle, slug, category,
        'image': image.asset->url,
        'mimeType': image.asset->mimeType,
        'createdAt': _createdAt, content
    }`);
    return posts?.map(p => ({ ...p, image: parseImageUrl(p.mimeType, p.image) }));
}

async function searchEvents(keyword?: string, region?: string): Promise<AmotaiEvent[]> {
    const events: any[] | null = await sanity.fetch(groq`
    *[_type == "event" 
    ${keyword ?
            `&& ([title] match "${keyword}" 
        || content[].children[].text match "${keyword}"
        || details.region match "${keyword}"
        || details.location match "${keyword}")`
            :
            ''
        }
    ${region ? `&& details.region match "${region}"` : ''}] 
    | order(_createdAt desc) 
    {
        title, subtitle, featured, details, content,
        "slug": slug.current, 
        "heroImages": heroImages[]{"image": asset->url, 'mimeType': asset->mimeType},
        'cardImage': cardImage.asset->url,
        'cardMimeType': cardImage.asset->mimeType,
        'createdAt': _createdAt
    }`);
    return events?.map(e => ({
        ...e,
        heroImages: e.heroImages?.map(({ image, mimeType }: any) => parseImageUrl(mimeType, image)),
        cardImage: parseImageUrl(e.cardMimeType, e.cardImage)
    })) ?? [];
}

function getMemberFaqs(): Promise<FAQs[]> {
    return sanity.fetch(groq`*[_type == "faqs"]{...}`);

}

function getResource(resourceID: string) {
    return sanity.fetch(groq`*[_type == "resource" && _id == "${resourceID}"][0]`);
}

async function getResources(permission: ResourcePermission | null, planType?: string): Promise<Resource[]> {
    let resources: Resource[] = [];
    if (!permission) {
        return resources;
    }
    if (permission === ResourcePermission.BUYER_CLIENT_IWI || permission === ResourcePermission.BUYER_CLIENT_FUNDER) {
        // iwi or funder, no plan query included
        resources = await sanity.fetch(groq`*[_type == "resource"]|["${permission}" in category->.accounts]
        {
            _id, title, content, detail,
            "file": file {_type, "asset": asset->{_id, extension, mimeType, originalFilename, url}}
        }`);
    } else if (permission === ResourcePermission.SUPPLIER) {
        resources = await sanity.fetch(groq`*[_type == "resource"]|["${permission}" in category->.accounts]
        {
            _id, title, content, detail,
            "file": file {_type, "asset": asset->{_id, extension, mimeType, originalFilename, url}}
        }`);
    } else {
        resources = await sanity.fetch(groq`*[_type == "resource"]|["${permission}" in category->.accounts]|["${planType}" in category->.plans]
            {
                _id, title, content, detail,
                "file": file {_type, "asset": asset->{_id, extension, mimeType, originalFilename, url}}
            }`);
    }
    resources?.forEach(r => {
        if (r.file) {
            r.file.asset.url = parseImageUrl(r.file.asset.mimeType, r.file.asset.url)!;
        }
    });
    return resources ?? [];
}

async function getModules(permission: ResourcePermission | null, planType?: string): Promise<Resource[]> {
    let mod: Resource[] = [];
    if (!permission) {
        return mod;
    }
    if (permission === ResourcePermission.BUYER_CLIENT_IWI || permission === ResourcePermission.BUYER_CLIENT_FUNDER) {
        // iwi or funder, no plan query included
        mod = await sanity.fetch(groq`*[_type == "module"]|["${permission}" in category->.accounts]
        {
            _id, title, content, detail,
            "file": file {_type, "asset": asset->{_id, extension, mimeType, originalFilename, url}}
        }`);
    } else if (permission === ResourcePermission.SUPPLIER) {
        mod = await sanity.fetch(groq`*[_type == "module"]|["${permission}" in category->.accounts]
        {
             _id, title, content, detail,
            "file": file {_type, "asset": asset->{_id, extension, mimeType, originalFilename, url}}
        }`);
    } else {
        mod = await sanity.fetch(groq`*[_type == "module"]|["${permission}" in category->.accounts]|["${planType}" in category->.plans]
        {
            _id, title, content, detail,
            "file": file {_type, "asset": asset->{_id, extension, mimeType, originalFilename, url}}
        }`);
    }
    mod?.forEach(r => {
        if (r.file) {
            r.file.asset.url = parseImageUrl(r.file.asset.mimeType, r.file.asset.url)!;
        }
    });
    return mod ?? [];
}

function getWebinars(permission: ResourcePermission | null, planType?: string) {
    if (!permission) {
        return Promise.resolve([]);
    }

    if (permission === ResourcePermission.BUYER_CLIENT_IWI || permission === ResourcePermission.BUYER_CLIENT_FUNDER) {
        // iwi or funder, no plan query included
        return sanity.fetch(groq`*[_type == "webinar"]|["${permission}" in category->.accounts]
        {_id, title, content, embed, "updatedAt": _updatedAt}`);
    } else if (permission === ResourcePermission.SUPPLIER) {
        return sanity.fetch(groq`*[_type == "webinar"]|["${permission}" in category->.accounts]
            {_id, title, content, embed, "updatedAt": _updatedAt}`);
    }

    return sanity.fetch(groq`*[_type == "webinar"]|["${permission}" in category->.accounts]|["${planType}" in category->.plans]
    {_id, title, content, embed, "updatedAt": _updatedAt}`);
}

function getWebinar(webinarID: string) {
    return sanity.fetch(groq`*[_type == "webinar" && _id == "${webinarID}"][0]`);
}

async function getFooter(): Promise<any> {
    const content = await sanity.fetch(groq`*[_type == "footer"]
    {
        email, address,
        socialLinks[]{..., "logo": logo.asset->url, 'mimeType': logo.asset->mimeType}
    }[0]`);
    if (content) {
        return {
            ...content,
            socialLinks: content.socialLinks?.map((c: any) => ({ ...c, logo: parseImageUrl(c.mimeType, c.logo) })) ?? null
        }
    }
    return null;
}

const SanityAPI = {
    getFaqs,
    getPolicyPage,
    getPosts,
    getEvents,
    getPostBySlug,
    getEventBySlug,
    searchPosts,
    searchEvents,
    getMemberFaqs,
    getResource,
    getResources,
    getWebinars,
    getWebinar,
    getFooter,
    getModules,
};

export default SanityAPI


function parseImageUrl(mimeType: string, url?: string) {
    if (!url) {
        return null;
    }
    if (/^(image)/.test(mimeType)) {
        url = `${url}?auto=format`;
    }
    return url;
}