import React from "react";
import * as Yup from "yup";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import styles from "../buyerClient/BuyerClientOnboarding.module.scss";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import BusinessOwnerFieldArray from "../../../widgets/formikFields/BusinessOwnerFieldArray";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";

const Owner = Yup.object({
    name: Yup.string().required("A name is required"),
    genderIdentity: Yup.string().required("Required"),
    pctOwned: Yup.number()
        .min(0, "You can't own less than 0% of the company.")
        .max(100, "You can't own more than 100% of the company.")
        .required("Percentage ownership required")
});

export const OrgOwnershipValidationSchema = Yup.object({
    owners: Yup.array().of(Owner).min(1, "At least one owner required"),
    maoriOwned: Yup.string(),
    pasifikaOwned: Yup.string(),
    iwiAffiliations: Yup.array().of(Yup.string()),
    pasifikaAffiliations: Yup.array().of(Yup.string()),
});


export default function Page5(props: any) {

    const renderFormStep = (children: any) => (
        <FormStep validationSchema={OrgOwnershipValidationSchema}>
            <h5>Who owns your organisation?</h5>
            <div className={styles.inner_container}>
                <div className={classNames(styles.ownership_form, styles.onboarding_form)}>
                    {children}
                </div>
                <ReactTooltip
                    place={"top"}
                    effect={"solid"}
                    multiline
                    backgroundColor={"#F1F1F2"}
                    borderColor={"#E6E7E8"}
                    textColor={"#000000"}
                    clickable
                />
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Business Ownership"} />
                <BusinessOwnerFieldArray />
            </div>
        );
    }

    return renderFormStep(<BusinessOwnerFieldArray />);
}
