import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import classNames from "classnames";
import firebase from "firebase/app";
import "firebase/auth";
import qs from "qs";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import SaaSPlan, { CustomizedFor } from "src/types/SaaSPlan";
import useDispatch from "../../../hooks/useDispatch";
import { createBusinessContacts } from "../../../redux/actions/businessContacts";
import { showError } from "../../../redux/actions/snackbars";
import { createAccount, getMyAccount, getMyUser, updateMyProfile } from "../../../redux/actions/users";
import { ACCOUNT_TYPE, CreateBuyerAccountDTO } from "../../../types/CreateAccountDTO";
import { NATIONWIDE_REGION } from "../../../util/constants";
import mergeSchemas from "../../../util/mergeSchemas";
import MultiStepForm from "../../widgets/multiStepForm/MultiStepForm";
import { BuyerClientFormValues, BuyerClientInitialValues } from "./buyerClient/buyerClientValidation";
import Page1, { BuyerOrganisationTypeSchema } from "./buyerClient/Page1";
import Page10, { BuyerReviewValidationSchema } from "./buyerClient/Page10";
import Page2, { SaasPlanValidationSchema } from "./buyerClient/Page2";
import Page2MandateAgency, { MandateAgencyValidationSchema } from './buyerClient/Page2MandateAgency';
import Page3, { BuyerUserDetailsValidationSchema } from "./buyerClient/Page3";
import Page4, { BuyerOrgDetailsValidationSchema } from "./buyerClient/Page4";
import Page5, { BuyerFinancialDetailsValidationSchema } from "./buyerClient/Page5";
import Page6, { BuyerBusinessProfileValidationSchema } from "./buyerClient/Page6";
import Page7, { BuyerSubcontractorValidationSchema } from "./buyerClient/Page7";
import Page8, { BuyerBusinessContactValidationSchema } from "./buyerClient/Page8";
import Page9, { BuyerCardValidationSchema, Page9Ref } from './buyerClient/Page9';
import styles from "./Onboarding.module.scss";

function OnboardingForm() {
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const history = useHistory();
    const [, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [cardToken, setCardToken] = useState<any>(null);
    const [plan, setPlan] = useState<SaaSPlan>();
    const page9Ref = useRef<Page9Ref>(null);
    const [organisationType, setOrganisationType] = useState<string>();

    useEffect(() => {
        if (stripe && elements) {
            setLoading(false);
        }
    }, [stripe, elements]);

    const isMandateAgency = organisationType === CustomizedFor.MandateAgency;

    const handleOnboardingSubmit = async (values: BuyerClientFormValues) => {
        setSubmitting(true);
        try {
            values.email = values.email?.trim().toLowerCase();
            await firebase.auth().createUserWithEmailAndPassword(values.email, values.password);
            await dispatch(updateMyProfile({
                firstName: values.firstName,
                lastName: values.lastName,
                name: `${values.firstName} ${values.lastName}`,
                jobTitle: values.jobTitle,
                phoneNumber: values.phoneNumber,
                mobileNumber: values.mobileNumber,
                avatar: values.avatar
            }));
            // create account

            let submittedRegions = [...values.regions];
            if (values.regions.includes(NATIONWIDE_REGION)) {
                // clear other regions and only submit nationwide
                submittedRegions = [NATIONWIDE_REGION];
            }

            const buyerClientDTO: CreateBuyerAccountDTO = {
                organisationType: values.organisationType,
                size: values.size,
                postalAddress: values.postalAddress,
                physicalAddress: values.physicalAddress,
                physicalAddressComponents: values.physicalAddressComponents,
                annualTurnover: values.annualTurnover,
                annualAddressableSpend: values.annualAddressableSpend,
                approxAnnualIndirectSpend: values.approxAnnualIndirectSpend,
                socialProcurementInitiatives: values.socialProcurementInitiatives,
                minimumLevelOfInsuranceForSubContract: values.minimumLevelOfInsuranceForSubContract,
                healthAndSafetyForSubContract: values.healthAndSafetyForSubContract,
                saasPlan: values.saasPlan,
                name: values.name,
                legalStructure: values.legalStructure,
                phoneNumber: values.phoneNumber,
                email: values.email,

                description: values.description,
                logo: values.logo,
                sourceToken: cardToken?.token?.id,
                invoiceBuyer: values.invoicePayment,

                regions: submittedRegions,
                category: values.category,
                regionBased: values.regionBased,
                mandateAgency: values.mandateAgency,
            };
            const errors = await dispatch(createAccount(buyerClientDTO, ACCOUNT_TYPE.buyerClient));
            if (errors.stripeError) {
                dispatch(showError(errors.stripeError));
            }

            const account = await dispatch(getMyAccount());
            await dispatch(createBusinessContacts(account.id, values.businessContacts.map((b) => ({
                ...b,
                email: b.email?.trim()?.toLowerCase(),
                account: account.id
            }))));
            await dispatch(getMyUser());
            setSubmitting(false);
            history.push("/account");
        } catch (err) {
            setSubmitting(false);
            dispatch(showError(err.message));
        }
    };

    const renderBreadcrumbs = (stepNumber: number, handleClick: (n: number) => void) => (
        <ul className={styles.breadcrumbs}>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 0,
                [styles.step_selected]: stepNumber === 0
            })}
                onClick={() => handleClick(0)}>Organisation type
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 1,
                [styles.step_selected]: stepNumber === 1
            })}
                onClick={() => handleClick(1)}>
                {isMandateAgency ? 'Mandate agency and plan' : 'Membership plan'}
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 2,
                [styles.step_selected]: stepNumber === 2
            })}
                onClick={() => handleClick(2)}>Your profile
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 3,
                [styles.step_selected]: stepNumber === 3
            })}
                onClick={() => handleClick(3)}>About your organisation
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 4,
                [styles.step_selected]: stepNumber === 4
            })}
                onClick={() => handleClick(4)}>Financial details
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 5,
                [styles.step_selected]: stepNumber === 5
            })}
                onClick={() => handleClick(5)}>Business profile
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 6,
                [styles.step_selected]: stepNumber === 6
            })}
                onClick={() => handleClick(6)}>Supplier requirements
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 7,
                [styles.step_selected]: stepNumber === 7
            })}
                onClick={() => handleClick(7)}>Business contacts
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 8,
                [styles.step_selected]: stepNumber === 8
            })}
                onClick={() => handleClick(8)}>
                {isMandateAgency ? 'Summary' : 'Payment details'}
            </li>
            {!isMandateAgency && <li className={classNames({
                [styles.step_completed]: stepNumber > 9,
                [styles.step_selected]: stepNumber === 9
            })}
                onClick={() => handleClick(9)}>Summary
            </li>}
        </ul>
    );

    const mergedSchemas = mergeSchemas(
        (isMandateAgency ? SaasPlanValidationSchema : SaasPlanValidationSchema),
        BuyerUserDetailsValidationSchema,
        BuyerOrgDetailsValidationSchema,
        BuyerFinancialDetailsValidationSchema,
        BuyerBusinessProfileValidationSchema,
        BuyerSubcontractorValidationSchema,
        BuyerBusinessContactValidationSchema,
        BuyerReviewValidationSchema
    );

    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const initialValues = { ...BuyerClientInitialValues };

    if (query.saasPlan) {
        initialValues.saasPlan = Number(query.saasPlan);
    }

    const restSteps = isMandateAgency ?
        [
            <Page2MandateAgency setPlan={setPlan} validationSchema={MandateAgencyValidationSchema} />,
            <Page3 validationSchema={BuyerUserDetailsValidationSchema} />,
            <Page4 validationSchema={BuyerOrgDetailsValidationSchema} />,
            <Page5 validationSchema={BuyerFinancialDetailsValidationSchema} />,
            <Page6 validationSchema={BuyerBusinessProfileValidationSchema} />,
            <Page7 validationSchema={BuyerSubcontractorValidationSchema} />,
            <Page8 validationSchema={BuyerBusinessContactValidationSchema} plan={plan} />,
            <Page10 validationSchema={mergedSchemas} plan={plan} />
        ]
        :
        [
            <Page2 setPlan={setPlan} validationSchema={SaasPlanValidationSchema} />,
            <Page3 validationSchema={BuyerUserDetailsValidationSchema} />,
            <Page4 validationSchema={BuyerOrgDetailsValidationSchema} />,
            <Page5 validationSchema={BuyerFinancialDetailsValidationSchema} />,
            <Page6 validationSchema={BuyerBusinessProfileValidationSchema} />,
            <Page7 validationSchema={BuyerSubcontractorValidationSchema} />,
            <Page8 validationSchema={BuyerBusinessContactValidationSchema} plan={plan} />,
            <Page9 validationSchema={BuyerCardValidationSchema} setCardToken={setCardToken} setLoading={setLoading} plan={plan} ref={page9Ref} />,
            <Page10 validationSchema={mergedSchemas} plan={plan} />
        ];

    return (
        <div className={styles.onboarding_container}>
            <MultiStepForm initialValues={initialValues}
                buttonsWrapperClass={styles.action_buttons}
                onboardingContainerClass={styles.buyer_client_container}
                submitting={submitting}
                onSubmit={handleOnboardingSubmit}
                steps={[
                    <Page1 validationSchema={BuyerOrganisationTypeSchema} onSubmit={(values: BuyerClientFormValues) => {
                        if (values.organisationType === "iwi") {
                            history.push("/onboarding/iwi");
                        }
                        setOrganisationType(values.organisationType);
                        return true;
                    }} />,
                    ...restSteps
                ]}
                breadcrumbs={renderBreadcrumbs} />
        </div>
    );
}

const stripeProvider = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

export default function BuyerClientOnboarding() {
    return (
        <Elements stripe={stripeProvider}>
            <OnboardingForm />
        </Elements>
    );
}
