import BusinessReference from "../../../types/BusinessReference";
import get from "./util/get";
import post from "./util/post";
import put from "./util/put";

function getBusinessReferences(accountId: number): Promise<BusinessReference[]> {
    return get(`/v1/accounts/${accountId}/references`);
}

function createBusinessReferences(accountId: number, values: any): Promise<BusinessReference[]> {
    return post(`/v1/accounts/${accountId}/references`, values);
}

function updateBusinessReferences(accountId: number, values: any): Promise<BusinessReference[]> {
    return put(`/v1/accounts/${accountId}/references`, values)
}

const BusinessReferenceAPI = {
    getBusinessReferences,
    createBusinessReferences,
    updateBusinessReferences
};

export default BusinessReferenceAPI;
