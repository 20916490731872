import React, { Ref } from "react";
import { useHistory } from "react-router-dom";
import styles from "./AccountSubmittedDialog.module.scss";
import Dialog, { DialogRef } from "../../dialog/Dialog";
import useForwardRef from "../../../../hooks/useForwardRef";
import Button from "../../button/Button";

type Props = {
    title?: string,
    content?: string,
    btnText?: string
}
const defaultTitle = 'Success! Account created';
const defaultContent = 'You’ve finished your account creation and have access to the directory. Your business profile won’t be published to the directory until Amotai has reviewed your application.'
const defaultBtnText = 'GO TO MY ACCOUNT'
function AccountSubmittedDialog(props: Props, ref: Ref<DialogRef>) {
    const history = useHistory();
    const dialogRef = useForwardRef<DialogRef>(ref);
    const onClose = () => {
        dialogRef.current?.hide();
        history.push("/account");
    };

    return (
        <Dialog ref={dialogRef} className={styles.contact_card_container} onDismiss={() => history.push("/account")}>
            <h5>{props.title??defaultTitle}</h5>
            <p>{props.content??defaultContent}</p>
            <Button onClick={onClose}>{props.btnText??defaultBtnText}</Button>
        </Dialog>
    )
}

export default React.forwardRef(AccountSubmittedDialog);