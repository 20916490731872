/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 14/02/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import classNames from "classnames";
import React from "react";
import styles from "./Spinner.module.scss";

export type SpinnerProps = {
    className?: string;
    containerClassName?: string;
    overlay?: boolean;
    center?: boolean;
    vCenter?: boolean;
    hCenter?: boolean;

    white?: boolean;

    small?: boolean;
    large?: boolean;
};

export default function Spinner(props: SpinnerProps) {
    const { overlay, center, vCenter, hCenter, small, large, containerClassName, white, ...otherProps } = props;

    const className = classNames(styles.spinner, {
        [styles.white]: white,
        [styles.small]: small,
        [styles.large]: large,
    });

    const spinner = (
        <svg viewBox={"0 0 24 24"} xmlns={"http://www.w3.org/2000/svg"} {...otherProps} className={className}>
            <ellipse cx={"12"} cy={"12"} rx={"10"} ry={"10"} className={styles.spinner_ellipse} />
        </svg>
    );

    if (overlay || center || vCenter || hCenter) {
        const contClassName = classNames(
            styles.container,
            {
                [styles.overlay]: overlay,
                [styles.hCenter]: hCenter,
                [styles.vCenter]: vCenter,
                [styles.center]: center,
            },
            props.containerClassName,
        );

        return <div className={contClassName}>{spinner}</div>;
    }

    return spinner;
}
