import classNames from "classnames";
import { FormikProps, useFormikContext } from "formik";
import React from "react";
import * as Yup from "yup";
import FormikFileUpload from "../../../widgets/formikFields/FormikFileUpload";
import FormikInput from "../../../widgets/formikFields/FormikInput";
import FormikNumberInput from "../../../widgets/formikFields/FormikNumberInput";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import styles from "./BuyerClientOnboarding.module.scss";
import { BuyerClientFormValues } from "./buyerClientValidation";

export const BuyerUserDetailsValidationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    jobTitle: Yup.string().required("Required"),
    phoneNumber: Yup.string(),
    mobileNumber: Yup.string().required("Required"),
    avatar: Yup.string(),
    email: Yup.string().required("Required"),
    password: Yup.string().required("Required").min(6, "Must be at least 6 characters long"),
})

export default function Page3(props: any) {

    const { values }: FormikProps<BuyerClientFormValues> = useFormikContext()

    const renderFields = () => (
        <>
            <FormikInput name={"firstName"}
                inputClassname={classNames(styles.half_input, styles.first)}
                placeholder={"John"}
                label={"First name"} />
            <FormikInput name={"lastName"}
                inputClassname={styles.half_input}
                placeholder={"Smith"}
                label={"Last name"} />
            <FormikInput name={"jobTitle"}
                placeholder={"e.g. Director"}
                label={"Job title"} />
            <FormikNumberInput name={"mobileNumber"}
                phone
                formatMask={"(###) ###-#####"}
                inputClassname={classNames(styles.half_input, styles.first)}
                label={"Mobile number"} />
            <FormikNumberInput name={"phoneNumber"}
                phone
                formatMask={"(##) ###-####"}
                inputClassname={styles.half_input}
                label={"Phone number (optional)"} />
            <label>Profile image</label>
            <FormikFileUpload
                name={"avatar"}
                label={"Select a personal profile image to upload"}
                id={"userAvatar"}
                preview={values?.avatar}
                accept='image/*'
            />
            <FormikInput name={"email"}
                placeholder={"e.g. johnsmith@business.com"}
                label={"Email address"} />
            <FormikInput name={"password"}
                type={"password"}
                label={"Password"} />
        </>
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Tell us about yourself</h5>
            <div className={styles.inner_container}>
                <div className={styles.onboarding_form}>
                    {children}
                </div>
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Personal Details"} />
                {renderFields()}
            </div>
        )
    }

    return (
        renderFormStep(renderFields())
    )
}