import { FormikProps, useFormikContext } from "formik";
import React from "react";
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";
import FormikFileUpload from "../../../widgets/formikFields/FormikFileUpload";
import FormikTextArea from "../../../widgets/formikFields/FormikTextArea";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import styles from "./FunderOnboarding.module.scss";
import { FunderFormValues } from "./funderValidation";

export const FunderBusinessProfileValidationSchema = Yup.object({
    logo: Yup.string(),
    description: Yup.string().required("Required"),
})

export default function Page6(props: any) {

    const { values }: FormikProps<FunderFormValues> = useFormikContext()

    const renderFields = () => (
        <>
            <label style={{ marginBottom: "0px" }}>Business logo</label>
            <FormikFileUpload
                name={"logo"}
                id={"logo"}
                labelClassName={styles.file_input}
                label={"Add a high resolution logo for your business profile"}
                preview={values.logo}
                imageClassName={styles.logo_image}
                accept='image/*'
            />
            <FormikTextArea name={"description"}
                label={"Business Description - This is displayed on your business profile page"}
                tooltip={"Provide a description of your business so that Amotai registered suppliers will know what your organisation does and the type of suppliers you will be looking to engage with"}
                className={styles.paragraph_input} />
            <ReactTooltip
                place={"top"}
                effect={"solid"}
                multiline
                backgroundColor={"#F1F1F2"}
                borderColor={"#E6E7E8"}
                textColor={"#000000"}
                clickable
            />
        </>
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Build your business profile</h5>
            <div className={styles.inner_container}>
                <div className={styles.onboarding_form}>
                    {children}
                </div>
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Business Profile"} />
                {renderFields()}
            </div>
        )
    }

    return renderFormStep(renderFields());
}