import SupplierReport from "../../types/SupplierReport";
import OwnershipReport from "../../types/OwnershipReport";
import { Actions, Types } from "../actions/reporting";
import ListResult from "../../types/ListResult";
import EmploymentReport from "../../types/EmploymentReport";
import CategoryReport from "../../types/CategoryReport";

const initialState: {
    iwiSuppliers?: ListResult<SupplierReport>,
    supplierCount?: number,
    ownershipReport?: OwnershipReport,
    employmentReport?: EmploymentReport,
    categoryReport?: CategoryReport,
    regionReport?: CategoryReport
} = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function reporting(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_OWNERSHIP_REPORT: {
            const { payload } = action as ActionValueTypes<Types.SET_OWNERSHIP_REPORT>;
            return {
                ...state,
                ownershipReport: payload
            };
        }
        case Types.SET_SUPPLIER_REPORT: {
            const { payload } = action as ActionValueTypes<Types.SET_SUPPLIER_REPORT>;
            return {
                ...state,
                iwiSuppliers: payload
            };
        }
        case Types.SET_SUPPLIER_COUNT: {
            const { payload } = action as ActionValueTypes<Types.SET_SUPPLIER_COUNT>;
            return {
                ...state,
                supplierCount: payload
            };
        }
        case Types.SET_EMPLOYMENT_REPORT: {
            const { payload } = action as ActionValueTypes<Types.SET_EMPLOYMENT_REPORT>;
            return {
                ...state,
                employmentReport: payload
            };
        }
        case Types.SET_CATEGORY_REPORT: {
            const { payload } = action as ActionValueTypes<Types.SET_CATEGORY_REPORT>;

            return {
                ...state,
                categoryReport: Object.entries((payload || {})).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({
                    ...r,
                    [k]: v
                }), {})
            };
        }
        case Types.SET_REGION_REPORT: {
            const { payload } = action as ActionValueTypes<Types.SET_REGION_REPORT>;
            return {
                ...state,
                regionReport: Object.entries((payload || {})).sort(([, a], [, b]) => a - b).reverse().reduce((r, [k, v]) => ({
                    ...r,
                    [k]: v
                }), {})
            };
        }
        default: {
            return state;
        }
    }
}

