import React, { useCallback, useMemo, useRef, useState } from "react";
import { Link, Redirect, Route, Switch, useHistory } from "react-router-dom";
import PageContainer from "src/components/widgets/pageContainer/PageContainer";
import useDispatch from "../../../hooks/useDispatch";
import useMountEffect from "../../../hooks/useMountEffect";
import useSelector from "../../../hooks/useSelector";
import { showError, showSuccess } from "../../../redux/actions/snackbars";
import { getMyAccount, getMyUser, reviewAccount } from "../../../redux/actions/users";
import AmotaiAccount, { AccountStatus, AccountType } from "../../../types/AmotaiAccount";
import AmotaiUser from "../../../types/AmotaiUser";
import Button from "../../widgets/button/Button";
import { DialogRef } from "../../widgets/dialog/Dialog";
import DeleteAccountDialog from "../../widgets/dialogs/deleteAccountDialog/DeleteAccountDialog";
import Loading from "../../widgets/loading/Loading";
import PageLoader from "../../widgets/pageLoader/PageLoader";
import SideMenu from "../../widgets/sideMenu/SideMenu";
import styles from "./Account.module.scss";
import AlertBar from "./component/AlertBar";
import Billing from "./routes/billing/Billing";
import BusinessDetails from "./routes/business-details/BusinessDetails";
import BusinessProfile from "./routes/business-profile/BusinessProfile";
import BusinessCategory from "./routes/businessCategory/BusinessCategory";
import BusinessCategorySupplier from "./routes/businessCategory/BusinessCategorySupplier";
import Employment from "./routes/employment/Employment";
import FinanceAndLegal from "./routes/financeAndLegal/FinanceAndLegal";
import MyProfile from "./routes/my-profile/MyProfile";
import Overview from "./routes/Overview";
import Ownership from "./routes/ownership/Ownership";
import Users from "./routes/users/Users";

export default function Account() {
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const me = useSelector<AmotaiUser>(state => state.users.me!);
    const account = useSelector<AmotaiAccount | undefined>(state => state.users.account);

    const isOrgAdmin = useSelector<boolean>(state => state.users.isOrgAdmin);

    const deleteAccountDialogRef = useRef<DialogRef>(null);

    useMountEffect(async () => {
        try {
            if (!me) {
                await dispatch(getMyUser());
            }

            await dispatch(getMyAccount());

            setLoading(false);
        } catch (e) {
            dispatch(showError(e.message));
            history.push("/");
        }
    });

    const onSubmitReviewClick = useCallback(async () => {
        if (!account) {
            return;
        }
        try {
            setSubmitting(true);
            await dispatch(reviewAccount(account, account.type));
            setSubmitting(false);
            dispatch(showSuccess("Your account was successfully submitted for review"));
        } catch (e) {
            setSubmitting(false);
            dispatch(showError(e.message));
        }
    }, [account, dispatch]);

    const alertBar = useMemo(() => {
        if (!account || !account.status) {
            return null;
        }
        switch (account.status) {
            case AccountStatus.PENDING_REVIEW: {
                return (
                    <AlertBar>
                        <div className={styles.account_status_container}>
                            <h5>Account in review</h5>
                            <span>Your account has been submitted for review. Your business will not be displayed
                            on the directory until your application has been approved. We will get in touch
                                directly if any of your details need to be updated. </span>
                            <span>If you have any questions, please get in touch with </span>
                            <a href="mailto:kiaora@amotai.nz">
                                <span className={styles.mail}>kiaora@amotai.nz</span>
                            </a>
                        </div>
                    </AlertBar>
                )
            }
            case AccountStatus.AWAITING_PAYMENT: {
                return (
                    <AlertBar>
                        <div className={styles.account_status_container}>
                            <h5>Awaiting payment</h5>
                            <span>Once we have received invoice payment we will activate your account. This will give you full access
                            to our directory of Suppliers. We will notify you via email once you have been accepted.</span>
                        </div>
                    </AlertBar>
                )
            }
            case AccountStatus.DECLINED: {
                return (
                    <AlertBar>
                        <div className={styles.account_status_container}>
                            <h5>We need some more information</h5>
                            <span>We need some more information to approve your application. We will get in touch
                            directly to help you with this process. Once you have updated your account details
                                with the required information, please re-submit your application.</span>
                            {account.reviewMessage &&
                                <div className={styles.review_message}><span>{account.reviewMessage}</span></div>}
                            <Button borderwhite loading={submitting} onClick={onSubmitReviewClick}>Submit for
                                review</Button>
                        </div>
                    </AlertBar>
                );
            }
            case AccountStatus.DISABLED: {
                return (
                    <AlertBar>
                        <div className={styles.account_status_container}>
                            <h5>Account on hold</h5>
                            <span>Your Amotai account has been changed to &ldquo;on hold&rdquo;
                            and this means you will not have access in the meantime to our Amotai database.
                                This account is on hold for the following reason:</span>
                            {account.suspensionMessage &&
                                <div className={styles.review_message}><span>{account.suspensionMessage}</span></div>}
                        </div>
                    </AlertBar>
                )
            }
            default:
                return null;
        }
    }, [account, onSubmitReviewClick, submitting]);

    if (!account) {
        return <PageLoader />;
    }

    return (
        <PageContainer containerClassName={styles.account}>
            {alertBar}
            <div className={styles.container}>
                <SideMenu>
                    {isOrgAdmin && (
                        <li><Link to="/account/business-details">Business Details</Link></li>
                    )}
                    {isOrgAdmin && (
                        <li><Link to="/account/business-profile">Business Profile</Link></li>
                    )}
                    {isOrgAdmin && account.type === AccountType.SUPPLIER && (
                        <li><Link to="/account/category">Business Category</Link></li>
                    )}
                    {isOrgAdmin && account.type === AccountType.SUPPLIER && (
                        <li><Link to="/account/ownership">Ownership</Link></li>
                    )}
                    {isOrgAdmin && account.type === AccountType.SUPPLIER && (
                        <li><Link to="/account/finance">Finance and Legal</Link></li>
                    )}
                    {isOrgAdmin && account.type === AccountType.SUPPLIER && (
                        <li><Link to="/account/employment">Employment</Link></li>
                    )}
                    {isOrgAdmin && account.type === AccountType.BUYER_CLIENT && (
                        <li><Link to="/account/billing">Billing Information</Link></li>
                    )}
                    {isOrgAdmin && <li><Link to="/account/users">Users</Link></li>}
                    <li><Link to="/account/my-profile">My Profile</Link></li>
                </SideMenu>
                {loading ? <Loading /> : (
                    <Switch>
                        {isOrgAdmin
                            ? <Route path="/account/business-details" component={BusinessDetails} />
                            : <Redirect from="/account/business-profile" to="/account/my-profile" />}
                        {isOrgAdmin
                            ? <Route path="/account/business-profile" component={BusinessProfile} />
                            : <Redirect from="/account/business-profile" to="/account/my-profile" />}
                        {isOrgAdmin
                            ? <Route path="/account/users" component={Users} />
                            : <Redirect from="/account/users" to="/account/my-profile" />}
                        {isOrgAdmin && account.type === AccountType.BUYER_CLIENT
                            ? <Route path="/account/billing" component={Billing} />
                            : <Redirect from="/account/billing" to="/account/my-profile" />}
                        <Route path="/account/my-profile" component={MyProfile} />
                        {isOrgAdmin && account.type === AccountType.SUPPLIER
                            ? <Route path="/account/ownership" component={Ownership} />
                            : <Redirect from="/account/business-profile" to="/account/my-profile" />}
                        {isOrgAdmin && account.type === AccountType.SUPPLIER
                            ? <Route path="/account/employment" component={Employment} />
                            : <Redirect from="/account/business-profile" to="/account/my-profile" />}
                        {isOrgAdmin && account.type === AccountType.SUPPLIER
                            ? <Route path="/account/category" component={account.type === AccountType.SUPPLIER ? BusinessCategorySupplier : BusinessCategory} />
                            : <Redirect from="/account/business-profile" to="/account/my-profile" />}
                        {isOrgAdmin && account.type === AccountType.SUPPLIER
                            ? <Route path="/account/finance" component={FinanceAndLegal} />
                            : <Redirect from="/account/business-profile" to="/account/my-profile" />}
                        <Route path="/account" component={Overview} />
                    </Switch>
                )}
            </div>
            <div className={styles.delete_account}>
                <Button plain regular onClick={() => {
                    deleteAccountDialogRef.current?.show();
                }}>Delete account</Button>
                <DeleteAccountDialog ref={deleteAccountDialogRef} />
            </div>
        </PageContainer>
    );
}
