import React, { useState } from "react";
import styles from "./Onboarding.module.scss";
import { BuyerClientFormValues, BuyerClientInitialValues } from "./buyerClient/buyerClientValidation";
import classNames from "classnames";
import mergeSchemas from "../../../util/mergeSchemas";
import Page3, { BuyerUserDetailsValidationSchema } from "./buyerClient/Page3";
import Page4, { BuyerOrgDetailsValidationSchema } from "./buyerClient/Page4";
import Page5, { BuyerFinancialDetailsValidationSchema } from "./buyerClient/Page5";
import Page6, { BuyerBusinessProfileValidationSchema } from "./buyerClient/Page6";
import Page7, { BuyerSubcontractorValidationSchema } from "./buyerClient/Page7";
import Page8, { BuyerBusinessContactValidationSchema } from "./buyerClient/Page8";
import Page10, { BuyerReviewValidationSchema } from "./buyerClient/Page10";
import Page2, { IwiAffiliationValidationSchema } from "./iwi/Page2";
import MultiStepForm from "../../widgets/multiStepForm/MultiStepForm";
import firebase from "firebase/app";
import "firebase/auth";
import { createAccount, getMyAccount, getMyUser, updateMyProfile } from "../../../redux/actions/users";
import { NATIONWIDE_REGION } from "../../../util/constants";
import { ACCOUNT_TYPE, CreateIwiAccountDTO } from "../../../types/CreateAccountDTO";
import { createBusinessContacts } from "../../../redux/actions/businessContacts";
import { showError } from "../../../redux/actions/snackbars";
import useDispatch from "../../../hooks/useDispatch";
import { useHistory } from "react-router-dom";

const mergedSchemas = mergeSchemas(
    IwiAffiliationValidationSchema,
    BuyerUserDetailsValidationSchema,
    BuyerOrgDetailsValidationSchema,
    BuyerFinancialDetailsValidationSchema,
    BuyerBusinessProfileValidationSchema,
    BuyerSubcontractorValidationSchema,
    BuyerBusinessContactValidationSchema,
    BuyerReviewValidationSchema
);

export default function IwiOnboarding() {
    const initialValues = { ...BuyerClientInitialValues, organisationType: "iwi" };
    const [submitting, setSubmitting] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleSubmit = async (values: BuyerClientFormValues) => {
        setSubmitting(true);
        try {
            values.email = values.email?.trim().toLowerCase();
            await firebase.auth().createUserWithEmailAndPassword(values.email, values.password);
            await dispatch(updateMyProfile({
                firstName: values.firstName,
                lastName: values.lastName,
                name: `${values.firstName} ${values.lastName}`,
                jobTitle: values.jobTitle,
                phoneNumber: values.phoneNumber,
                mobileNumber: values.mobileNumber,
                avatar: values.avatar
            }));

            // create account
            let submittedRegions = [...values.regions];
            if (values.regions.includes(NATIONWIDE_REGION)) {
                // clear other regions and only submit nationwide
                submittedRegions = [NATIONWIDE_REGION];
            }

            const buyerClientDTO:CreateIwiAccountDTO = {
                organisationType: values.organisationType,
                size: values.size,
                postalAddress: values.postalAddress,
                physicalAddress: values.physicalAddress,
                physicalAddressComponents: values.physicalAddressComponents,
                annualTurnover: values.annualTurnover,
                annualAddressableSpend: values.annualAddressableSpend,
                approxAnnualIndirectSpend: values.approxAnnualIndirectSpend,
                socialProcurementInitiatives: values.socialProcurementInitiatives,
                minimumLevelOfInsuranceForSubContract: values.minimumLevelOfInsuranceForSubContract,
                healthAndSafetyForSubContract: values.healthAndSafetyForSubContract,
                saasPlan: values.saasPlan,
                name: values.name,
                legalStructure: values.legalStructure,
                phoneNumber: values.phoneNumber,
                email: values.email,
                description: values.description,
                logo: values.logo,
                regions: submittedRegions,
                // no primary industry field for iwi
                category: null,
                iwiAffiliations: [values.iwiAffiliation],
                iwiBuyer: true,
                regionBased: values.regionBased
            };

            await dispatch(createAccount(buyerClientDTO, ACCOUNT_TYPE.buyerClient));

            const account = await dispatch(getMyAccount());
            await dispatch(createBusinessContacts(account.id, values.businessContacts.map((b) => ({
                ...b,
                email: b.email?.trim()?.toLowerCase(),
                account: account.id
            }))));
            await dispatch(getMyUser());
            setSubmitting(false);
            history.push("/account");
        } catch (err) {
            setSubmitting(false);
            dispatch(showError(err.message));
        }
    };

    const renderBreadcrumbs = (stepNumber: number, handleClick: (n: number) => void) => (
        <ul className={styles.breadcrumbs}>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 0,
                [styles.step_selected]: stepNumber === 0
            })}
                onClick={() => handleClick(0)}>
                Iwi affiliation
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 1,
                [styles.step_selected]: stepNumber === 1
            })}
                onClick={() => handleClick(1)}>
                Your profile
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 2,
                [styles.step_selected]: stepNumber === 2
            })}
                onClick={() => handleClick(2)}>
                About your organisation
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 3,
                [styles.step_selected]: stepNumber === 3
            })}
                onClick={() => handleClick(3)}>
                Financial details
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 4,
                [styles.step_selected]: stepNumber === 4
            })}
                onClick={() => handleClick(4)}>
                Business profile
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 5,
                [styles.step_selected]: stepNumber === 5
            })}
                onClick={() => handleClick(5)}>
                Supplier requirements
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 6,
                [styles.step_selected]: stepNumber === 6
            })}
                onClick={() => handleClick(6)}>
                Business contacts
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 7,
                [styles.step_selected]: stepNumber === 7
            })}
                onClick={() => handleClick(7)}>
                Summary
            </li>
        </ul>
    );

    return (
        <div className={styles.onboarding_container}>
            <MultiStepForm buttonsWrapperClass={styles.action_buttons}
                           onboardingContainerClass={styles.buyer_client_container}
                           steps={[
                               <Page2 validationSchema={IwiAffiliationValidationSchema} />,
                               <Page3 validationSchema={BuyerUserDetailsValidationSchema} />,
                               <Page4 validationSchema={BuyerOrgDetailsValidationSchema} />,
                               <Page5 validationSchema={BuyerFinancialDetailsValidationSchema} />,
                               <Page6 validationSchema={BuyerBusinessProfileValidationSchema} />,
                               <Page7 validationSchema={BuyerSubcontractorValidationSchema} />,
                               <Page8 validationSchema={BuyerBusinessContactValidationSchema} />,
                               <Page10 validationSchema={mergedSchemas} />,
                           ]}
                           breadcrumbs={renderBreadcrumbs}
                           submitting={submitting}
                           initialValues={initialValues}
                           onSubmit={handleSubmit} />
        </div>
    );
}
