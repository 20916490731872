import React from "react";

export default function LinkedIn(props: React.SVGProps<SVGSVGElement>) {

    return (
        <svg xmlns={"http://www.w3.org/2000/svg"} width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path
                d={"M22.269,0H1.731A1.731,1.731,0,0,0,0,1.731V22.269A1.731,1.731,0,0,0,1.731,24H22.269A1.731,1.731,0,0,0,24,22.269V1.731A1.731,1.731,0,0,0,22.269,0ZM8.513,18.141H5.59V9.348H8.513ZM7.052,8.147H7.033a1.523,1.523,0,1,1,.038-3.038,1.524,1.524,0,1,1-.019,3.038Zm12,9.993H16.129v-4.7c0-1.182-.423-1.988-1.481-1.988a1.6,1.6,0,0,0-1.5,1.069,2,2,0,0,0-.1.713v4.91H10.13s.038-7.968,0-8.793h2.922v1.245a2.9,2.9,0,0,1,2.634-1.451c1.923,0,3.365,1.257,3.365,3.957Zm0,0"}
                 />
        </svg>
    );
}
