import React from "react";
import styles from "./IframeBlock.module.scss";

export default function IframeBlock(props:{key: any, iframe: any}) {
    return (
        <div className={styles.iframe}>
            <iframe src={props.iframe.url} title={props?.key} frameBorder={0} allowTransparency allow={"encrypted-media"}/>
        </div>
    );
}
