import { withStyles } from "@material-ui/core/styles";
import { Tabs as MuiTabs } from "@material-ui/core";

const Tabs = withStyles({
    root: {
        borderBottom: "1px solid #fff",
    },
    indicator: {
        backgroundColor: "#008997",
    },
})(MuiTabs);

export default Tabs;