import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useMemo, useState } from 'react';
import Loading from 'src/components/widgets/loading/Loading';
import Select from 'src/components/widgets/select/Select';
import useDispatch from 'src/hooks/useDispatch';
import useSelector from 'src/hooks/useSelector';
import { getMainCategories } from 'src/redux/actions/categories';
import { AccountType } from 'src/types/AmotaiAccount';
import { Category, CategoryType, SubCategory, SubSubCategory } from 'src/types/Category';
import { HitType, SearchSuggestion } from 'src/types/SearchResult';
import styles from './Filter.module.scss';

type Props = {
    accountType?: AccountType,
    request?: SearchSuggestion,
    onChange?: (change: CategoryFilterProps) => void,
    containerClassName?: string,
    defaultValue?: CategoryFilterProps,
    expanded?: boolean;
    onExpandClick?: () => void;
}
export type CategoryFilterProps = {
    catId?: number,
    catType?: CategoryType,
    selected: SelectedCategoryProps,
}

export type SelectedCategoryProps = {
    mainCat?: Category,
    subCat?: SubCategory,
    subSubCat?: SubSubCategory,
}

const UNSELECTED_MAIN = 'Industry';
const UNSELECTED_SUB = 'Sector';
const UNSELECTED_SUBSUB = 'Service';
export default function CategoryFilter(props: Props) {
    const { accountType, request, onChange, containerClassName, defaultValue, expanded, onExpandClick } = props;
    const { main, sub, subSub } = useSelector(state => state.categories);
    const dispatch = useDispatch();
    const [selected, setSelected] = useState<SelectedCategoryProps>(defaultValue?.selected ?? {});
    const [disabled, setDisabled] = useState<{ main?: boolean, sub?: boolean, subsub?: boolean }>({});
    const sortedCategories = useSelector(state => state.directory.sortedCategories);

    useEffect(() => {
        if (!main?.length) {
            dispatch(getMainCategories());
        } else {
            if (request) {
                switch (request.hitType) {
                    case HitType.Category:
                        setDisabled({ main: true });
                        setSelected({
                            mainCat: main.find(m => m.id === request.id)
                        })
                        break;
                    case HitType.SubCategory:
                        setDisabled({ main: true, sub: true });
                        for (const mc of main) {
                            const sc = mc.subCategories.find(s => s.id === request.id);
                            if (sc) {
                                setSelected({
                                    mainCat: mc,
                                    subCat: sc,
                                })
                                break;
                            }
                        }
                        break;
                    case HitType.SubSubCategory:
                        setDisabled({ main: true, sub: true, subsub: true });
                        for (const mc of main) {
                            let finding = false;
                            for (const sc of mc.subCategories) {
                                const ssc = sc.subSubCategories.find(ss => ss.id === request.id);
                                if (ssc) {
                                    setSelected({
                                        mainCat: mc,
                                        subCat: sc,
                                        subSubCat: ssc,
                                    })
                                    finding = true;
                                    break;
                                }
                            }
                            if (finding) {
                                break;
                            }
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    }, [dispatch, main, request]);

    const catsWithBiz: Category[] | null = useMemo(() => {
        const ids = accountType === AccountType.BUYER_CLIENT ? sortedCategories.buyer : sortedCategories.supplier;
        if (!ids || !main) {
            return null;
        }
        if (accountType === AccountType.BUYER_CLIENT) {
            //for buyers
            return main.filter(m => ids.includes(m.id));
        } else {
            //for suppliers
            return main.filter(m => ids.includes(m.id)).map(m => ({
                ...m,
                subCategories: m.subCategories
                    .filter(s => ids.includes(s.id))
                    .map(s => ({
                        ...s,
                        subSubCategories: s.subSubCategories
                            .filter(ss => ids.includes(ss.id)),
                    }))
            }))
        }
    }, [sortedCategories, main, accountType]);

    const onMainChange = (name: string) => {
        const mc = main.find(m => m.name === name);
        const sel: SelectedCategoryProps = { mainCat: mc };
        setSelected(sel); //remove selected sub/subsub as main changed
        onChange?.({
            catId: mc?.id,
            catType: CategoryType.MAIN,
            selected: sel
        });
    }

    const onSubChange = (name: string) => {
        const sc = sub.find(s => s.name === name);
        const sel = {
            ...selected, //keep main
            subCat: sc,
            subSubCat: undefined, //remove subsub as sub changed
        };
        setSelected(sel)
        onChange?.({
            catId: sc?.id,
            catType: CategoryType.SUB,
            selected: sel
        });
    }

    const onSubSubChange = (name: string) => {
        const ssc = subSub.find(ss => ss.name === name);
        const sel = {
            ...selected,
            subSubCat: ssc
        }
        setSelected(sel)
        onChange?.({
            catId: ssc?.id,
            catType: CategoryType.SUB_SUB,
            selected: sel
        });
    }

    const options = useMemo(() => {
        const ids = accountType === AccountType.BUYER_CLIENT ? sortedCategories.buyer : sortedCategories.supplier;
        const { mainCat, subCat } = selected;
        const mainOpts = catsWithBiz?.map(m => m.name).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())) ?? [];
        const subOpts = mainCat?.subCategories?.filter(s => ids?.includes(s.id)).map(s => s.name) ?? [];
        const subSubOpts = subCat?.subSubCategories?.filter(ss => ids?.includes(ss.id)).map(ss => ss.name) ?? [];
        return { mainOpts, subOpts, subSubOpts };
    }, [selected, catsWithBiz, sortedCategories, accountType]);

    if (!accountType) {
        return null;
    }

    return (
        <div className={containerClassName}>
            <Accordion
                TransitionProps={{ unmountOnExit: true }}
                className={styles.collapse}
                expanded={expanded}
            >
                <AccordionSummary
                    classes={{ root: styles.accordion_summary }}
                    expandIcon={<ExpandMoreIcon />}
                    onClick={onExpandClick}
                >
                    <label className={styles.title}>Refine by industry</label>
                </AccordionSummary>
                {(!main?.length || !catsWithBiz?.length) && <Loading />}
                {!!main?.length && (
                    <AccordionDetails className={styles.content}>
                        <Select
                            containerClassName={styles.full_input}
                            selectClassName={styles.select}
                            options={[UNSELECTED_MAIN, ...options.mainOpts]}
                            onChange={onMainChange}
                            defaultValue={selected.mainCat?.name ?? UNSELECTED_MAIN}
                            disabled={disabled.main}
                        />
                        {accountType === AccountType.SUPPLIER && (
                            <>
                                <Select
                                    containerClassName={styles.full_input}
                                    selectClassName={styles.select}
                                    options={[UNSELECTED_SUB, ...options.subOpts]}
                                    onChange={onSubChange}
                                    defaultValue={selected.subCat?.name ?? UNSELECTED_SUB}
                                    disabled={disabled.sub || !options.subOpts.length}
                                />
                                <Select
                                    containerClassName={styles.full_input}
                                    selectClassName={styles.select}
                                    options={[UNSELECTED_SUBSUB, ...options.subSubOpts]}
                                    onChange={onSubSubChange}
                                    defaultValue={selected.subSubCat?.name ?? UNSELECTED_SUBSUB}
                                    disabled={disabled.subsub || !options.subSubOpts.length}
                                />
                            </>
                        )}
                    </AccordionDetails>
                )}
            </Accordion>
        </div>
    )
}