import React from "react";
import * as Yup from "yup";
import styles from "./FunderOnboarding.module.scss";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import FormikSelect from "../../../widgets/formikFields/FormikSelect";
import { ANNUAL_ADDRESSABLE_SPEND, ANNUAL_INDIRECT_SPEND, BUYER_TURNOVER } from "../../../../util/constants";
import FormikTextArea from "../../../widgets/formikFields/FormikTextArea";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";

export const FunderFinancialDetailsValidationSchema = Yup.object({
    annualTurnover: Yup.string().required("Required"),
    annualAddressableSpend: Yup.string().required("Required"),
    approxAnnualIndirectSpend: Yup.string().required("Required"),
    socialProcurementInitiatives: Yup.string().required("Required"),
})

export default function Page5(props: any) {

    const renderFields = () => (
        <>
            <FormikSelect name={"annualTurnover"}
                          label={"Annual turnover"}
                          options={BUYER_TURNOVER}
                          tooltip={"Amount of revenue your business had from normal business activities within the financial year"} />
            <FormikSelect name={"annualAddressableSpend"}
                          label={"Annual addressable spend"}
                          options={ANNUAL_ADDRESSABLE_SPEND}
                          tooltip={"The amount of spend that is under the control of procurement or spend that has the potential to be competitively sourced within a financial year"} />
            <FormikSelect name={"approxAnnualIndirectSpend"}
                          label={"Approx annual indirect spend"}
                          options={ANNUAL_INDIRECT_SPEND}
                          tooltip={"The approximate amount of spend that is directly sub-contracted by your main contractors"} />
            <FormikTextArea name={"socialProcurementInitiatives"}
                            label={"Existing social procurement initiatives"}
                            tooltip={"List any existing social procurement initiatives. policies or strategies that your organisation currently has in place."}
                            className={styles.paragraph_input} />
        </>
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Your Organisation’s Financials</h5>
            <p>This information is collated to gather the collective procurement amount our Buying organisations have and is completely confidential</p>
            <div className={styles.inner_container}>
                <div className={styles.onboarding_form}>
                    {children}
                </div>
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Financial Details"}/>
                {renderFields()}
            </div>
        )
    }

    return renderFormStep(renderFields());
}