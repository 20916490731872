/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 10/04/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { Actions, Types } from "../actions/tables";

export type PagingTableFilter = {
    limit: number;
    search?: string | null;
    order?: string | null;
    cursor?: string | null;
};

export type PagingTableState<T extends Object, F extends PagingTableFilter> = {
    page: number;
    cursors: (string | null)[];
    pages: T[][];
    filter: F;
    lastUpdatedAt: null | string;
};

type State = {
    [key: string]: PagingTableState<any, any>;
};

export const INITIAL_TABLE_STATE: PagingTableState<any, any> = {
    page: 0,
    cursors: [],
    pages: [],
    filter: {
        limit: 5,
    },
    lastUpdatedAt: null,
};

const initialState: State = {};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function tables(state = initialState, action: ActionTypes): State {
    switch (action.type) {
        case Types.SET_TABLE_STATE: {
            const { payload } = action as ActionValueTypes<Types.SET_TABLE_STATE>;
            const { tableId, state: tableState } = payload!;
            return {
                ...state,
                [tableId]: tableState,
            };
        }
        default: {
            return state;
        }
    }
}
