import SanityAPI from 'src/util/SanityAPI';
import AmotaiEvent from 'src/types/AmotaiEvent';
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_EVENTS = "events:SET_EVENTS",
    SET_EVENT = "events:SET_EVENT",
}

export const setEvent = createAction<AmotaiEvent>(Types.SET_EVENT);
export const setEvents = createAction<Array<AmotaiEvent>>(Types.SET_EVENTS);

export const Actions = {
    [Types.SET_EVENT]: setEvent,
    [Types.SET_EVENTS]: setEvents,
};
export type Actions = typeof Actions;

export const getEvent = wrapper(SanityAPI.getEventBySlug, { 
    action: setEvent 
});

export const getEvents = wrapper(SanityAPI.getEvents, {
    action: setEvents 
});

export const searchEvents = wrapper(SanityAPI.searchEvents, {
    action: setEvents
})