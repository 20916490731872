import { FormikProps, useFormikContext } from "formik";
import React from "react";
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";
import FormikFileUpload from "../../../widgets/formikFields/FormikFileUpload";
import FormikInput from "../../../widgets/formikFields/FormikInput";
import FormikTextArea from "../../../widgets/formikFields/FormikTextArea";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import styles from "../buyerClient/BuyerClientOnboarding.module.scss";
import { SupplierFormValues } from "./supplierValidation";

export const BusinessProfileValidationSchema = Yup.object({
    logo: Yup.string(),
    description: Yup.string().required("Required"),
    website: Yup.string(),
    facebookLink: Yup.string(),
    instagramLink: Yup.string(),
    twitterLink: Yup.string(),
    linkedInLink: Yup.string(),
    youtubeLink: Yup.string(),
    pinterestLink: Yup.string()
});

export default function Page7(props: any) {

    const { values }: FormikProps<SupplierFormValues> = useFormikContext();

    const renderFields = () => (
        <>
            <label>Business logo</label>
            <FormikFileUpload
                name={"logo"}
                id={"logo"}
                labelClassName={styles.file_input}
                label={"Add a high resolution logo for your business profile"}
                preview={values.logo}
                imageClassName={styles.logo_image}
                accept='image/*'
            />
            <FormikTextArea name={"description"}
                label={"Business Description - This is displayed on your business profile page"}
                tooltip={"Add a clear description of what your business does so that buyers are able to quickly understand your experience and expertise."}
                className={styles.paragraph_input} />
            <FormikInput name={"website"} placeholder={"E.g. https://businessname.co.nz/"}
                label={"Main business website"} inputClassname={styles.full_input} />
            <FormikInput name={"facebookLink"} placeholder={"E.g. https://facebook.com/businessname/"}
                label={"Facebook (optional)"} inputClassname={styles.full_input} />
            <FormikInput name={"instagramLink"} placeholder={"E.g. https://instagram.com/businessname/"}
                label={"Instagram (optional)"} inputClassname={styles.full_input} />
            <FormikInput name={"twitterLink"} placeholder={"E.g. https://twitter.com/businessname/"}
                label={"Twitter (optional)"} inputClassname={styles.full_input} />
            <FormikInput name={"linkedInLink"} placeholder={"E.g. https://linkedin.com/businessname/"}
                label={"LinkedIn (optional)"} inputClassname={styles.full_input} />
            <FormikInput name={"youtubeLink"} placeholder={"E.g. https://youtube.com/businessname/"}
                label={"Youtube (optional)"} className={styles.full_input} />
            <FormikInput name={"pinterestLink"} placeholder={"E.g. https://pinterest.com/businessname/"}
                label={"Pinterest (optional)"} className={styles.full_input} />

        </>
    );

    const renderFormStep = (children: any) => (
        <FormStep validationSchema={BusinessProfileValidationSchema}>
            <h5>Build your business profile</h5>
            <div className={styles.inner_container}>
                <div className={styles.onboarding_form}>
                    {children}
                </div>
                <ReactTooltip
                    place={"top"}
                    effect={"solid"}
                    multiline
                    backgroundColor={"#F1F1F2"}
                    borderColor={"#E6E7E8"}
                    textColor={"#000000"}
                    clickable
                />
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Business Profile"} />

                {renderFields()}
            </div>
        );
    }

    return renderFormStep(renderFields());

}