import React from "react";
import styles from "./IframeBlock.module.scss";

export default function IframeBlock(props: any) {
    return (
        <div className={styles.iframe}>
            {/* eslint-disable-next-line */}
            <iframe src={props.iframe.url} title={""} frameBorder={0} allowTransparency allow={"encrypted-media"} />
        </div>
    );
}
