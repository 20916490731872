import get from "./util/get";
import SupplierReport from "../../../types/SupplierReport";
import OwnershipReport from "../../../types/OwnershipReport";
import ListResult from "../../../types/ListResult";
import EmploymentReport from "../../../types/EmploymentReport";
import CategoryReport from "../../../types/CategoryReport";

function getSupplierIwiCount(iwi: string): Promise<number> {
    return get("/v1/reporting/iwi/count", { params: { iwi } });
}

function getIwiSuppliers(iwi: string, cursor?: string, category?: number, limit?: number): Promise<ListResult<SupplierReport>> {
    return get("/v1/reporting/iwi/suppliers", {
        params: {
            iwi,
            cursor: cursor || "",
            category: category || "",
            limit: limit || ""
        }
    });
}

function getOwnershipReport(iwi: string): Promise<OwnershipReport> {
    return get("/v1/reporting/iwi/ownership", { params: { iwi } });
}

function getEmploymentReport(iwi: string): Promise<EmploymentReport> {
    return get("/v1/reporting/iwi/employment", { params: { iwi } });
}

function getCategoryReport(iwi: string): Promise<CategoryReport> {
    return get("/v1/reporting/iwi/category", { params: { iwi } });
}

function getRegionReport(iwi: string): Promise<CategoryReport> {
    return get("/v1/reporting/iwi/region", { params: { iwi } });
}

const ReportingAPI = {
    getSupplierIwiCount,
    getIwiSuppliers,
    getOwnershipReport,
    getEmploymentReport,
    getCategoryReport,
    getRegionReport
};

export default ReportingAPI;