import moment from 'moment';
import React, { useEffect, useState } from 'react';
import useDispatch from 'src/hooks/useDispatch';
import useSelector from 'src/hooks/useSelector';
import { getEvents } from 'src/redux/actions/events';
import AmotaiEvent from 'src/types/AmotaiEvent';
import { buildEventDateString } from 'src/util/helper';
import Button from '../button/Button';
import styles from './Banner.module.scss';

const dismiss_key = 'dismiss_key';
export default function EventBanner() {
    const [show, setShow] = useState<boolean>(sessionStorage.getItem(dismiss_key) !== 'hide');
    const [event, setEvent] = useState<AmotaiEvent | undefined>(
        useSelector(state => Object.values(state.events)
            .filter(e => e.details?.startedAt && new Date(e.details.startedAt).getTime() >= Date.now())
            .find(e => e.featured))
    );
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            if (!event) {
                const events = await dispatch(getEvents());
                const active = events
                    .filter(e => e.details?.startedAt && new Date(e.details.startedAt).getTime() >= Date.now())
                    .find(e => e.featured);
                setEvent(active);
            }
        })();
        //eslint-disable-next-line
    }, [])

    const dismiss = () => {
        setShow(false);
        sessionStorage.setItem(dismiss_key, 'hide');
    }

    if (!show || !event) {
        return null;
    }

    const { details, slug } = event;
    const datetimeDisplay = details ? buildEventDateString(moment(details.startedAt), moment(details.endedAt)).join(', ') : '';

    return (
        <div className={styles.bannerbar}>
            <div className={styles.inner}>

                <div className={styles.text}>
                    <div className={styles.title}>
                        {event.title}
                    </div>
                    <div className={styles.subtitle}>
                        {`${details?.location ?? ''} • ${datetimeDisplay}`}
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button plain className={styles.dismiss} onClick={dismiss}>DISMISS</Button>
                    <Button borderwhite href={`/events/${slug}`}>VIEW EVENT &gt;&gt;</Button>
                </div>
            </div>
        </div>
    )
}