import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classnames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NewFeatureBanner from 'src/components/widgets/banner/NewFeatureBanner';
import Loading from 'src/components/widgets/loading/Loading';
import PageContainer from 'src/components/widgets/pageContainer/PageContainer';
import useGA4Context from 'src/hooks/GA4Context';
import useDispatch from 'src/hooks/useDispatch';
import useSelector from 'src/hooks/useSelector';
import useUserRoles from 'src/hooks/useUserRoles';
import { getMainCategories } from 'src/redux/actions/categories';
import { getSortedCategories } from 'src/redux/actions/directory';
import { getAccountSubscription } from 'src/redux/actions/saaSPlans';
import { showError } from 'src/redux/actions/snackbars';
import { deleteMyPref, getMyAccount, getMyPrefs } from 'src/redux/actions/users';
import deleteIcon from 'src/resources/images/svg/delete.svg';
import shieldIcon from 'src/resources/images/svg/shield-tick.svg';
import AmotaiAccount, { AccountType } from 'src/types/AmotaiAccount';
import BuyerClient from 'src/types/BuyerClient';
import { Category } from 'src/types/Category';
import Preference, { PreferenceType } from 'src/types/Preference';
import SaaSSubscription from 'src/types/SaaSSbscription';
import { HitType } from 'src/types/SearchResult';
import { ResultsViewRouteParams } from './ResultsView';
import SearchBar from './searchBar/SearchBar';
import styles from './SearchView.module.scss';

export default function SearchView() {
    const dispatch = useDispatch();
    const { main } = useSelector(state => state.categories);
    const location = useLocation();
    const [accountType, setAccountType] = useState<AccountType>();
    const [expandStates, setExpandStates] = useState<{ [name: string]: boolean }>({});
    const sortedCategories = useSelector(state => state.directory.sortedCategories);
    const allSearchPrefs = useSelector(state => state.users.preferences)?.[PreferenceType.Search];
    const myAccount = useSelector<AmotaiAccount | undefined | null>(state => state.users.account);
    const subscription = useSelector<SaaSSubscription | undefined>(state => state.saaSPlans.subscription!);
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const catRef = useRef<HTMLDivElement | null>(null);
    // const learnMoreRef = useRef<DialogRef>(null);
    const { isAdmin, isRegionalManager, isSuperAdmin } = useUserRoles();
    const { categorySearchEvent } = useGA4Context();
    const path = useMemo(() => accountType === AccountType.SUPPLIER ? 'suppliers' : 'buyer-clients', [accountType]);

    useEffect(() => {
        if (!myAccount) {
            dispatch(getMyAccount());
        }
        if (myAccount?.type === AccountType.BUYER_CLIENT && !subscription) {
            dispatch(getAccountSubscription(myAccount.id))
        }
    }, [myAccount, subscription, dispatch]);

    useEffect(() => {
        const accountType = location.pathname.includes("/suppliers") ? AccountType.SUPPLIER : AccountType.BUYER_CLIENT;
        setAccountType(accountType);
        if (!main?.length) {
            dispatch(getMainCategories());
        } else {
            const expands: { [name: string]: boolean } = {};
            main.forEach(mc => {
                expands[mc.name] = false;
            });
            setExpandStates(expands);
        }
        if ((accountType === AccountType.BUYER_CLIENT && !sortedCategories.buyer?.length)
            || (accountType === AccountType.SUPPLIER && !sortedCategories.supplier?.length)) {
            dispatch(getSortedCategories(accountType));
        }
        if (!allSearchPrefs?.length) {
            dispatch(getMyPrefs());
        }
    }, [location.pathname, main, dispatch, sortedCategories, allSearchPrefs]);

    const searchPrefs = useMemo(() => {
        const asAdmin = isAdmin || isRegionalManager || isSuperAdmin;
        if (!asAdmin) {
            if (!myAccount) {
                return null;
            } else {
                if (myAccount.type === AccountType.BUYER_CLIENT) {
                    const { iwiBuyer, funderBuyer } = myAccount as BuyerClient;
                    if (!iwiBuyer && !funderBuyer) {
                        if (!subscription) {
                            return null;
                        } else {
                            if (subscription.plan.name === 'Autere') {
                                return null;
                            }
                        }
                    }
                }
            }
        }
        return allSearchPrefs
            ?.filter(p => accountType && (JSON.parse(p.value) as ResultsViewRouteParams)?.accountType === accountType)
            ?.sort((a, b) => a.name.localeCompare(b.name)) as Preference[] | undefined;
    }, [allSearchPrefs, accountType, myAccount, subscription, isAdmin, isRegionalManager, isSuperAdmin]);

    const handleExpends = (name: string) => {
        const expands = { ...expandStates };
        for (const _name in expands) {
            if (_name === name) {
                expands[_name] = !expands[_name];
            } else {
                expands[_name] = false;
            }
        }
        setExpandStates(expands);
    }

    const onCategoryClicked = (id: number, hitType: HitType, name: string = '') => {
        const result: ResultsViewRouteParams = {
            request: { id, hitType, name },
        };
        categorySearchEvent({ name, hitType, id }, accountType!)
        history.push(`/${path}/results`, result);
    }

    const top3categories: Category[] | null = useMemo(() => {
        if (!accountType || accountType === AccountType.BUYER_CLIENT) {
            return null;
        }
        const hardcodedNames = {
            'Construction': {} as Category,
            'Professional, Scientific and Technical Services': {} as Category,
            'Information Media and Telecommunications': {} as Category,
        };
        main
            .forEach(m => {
                if (m.name === 'Construction') {
                    hardcodedNames.Construction = m;
                } else if (m.name === 'Professional, Scientific and Technical Services') {
                    hardcodedNames["Professional, Scientific and Technical Services"] = m;
                } else if (m.name === 'Information Media and Telecommunications') {
                    hardcodedNames["Information Media and Telecommunications"] = m;
                }
            })
        return Object.values(hardcodedNames);
        // const sortedCategoryIds = accountType === AccountType.BUYER_CLIENT ? sortedCategories.buyer : sortedCategories.supplier;
        // if (!sortedCategoryIds || !main) {
        //     return null;
        // }
        // const mainIds = main.map(m => m.id);
        // const top3cats: Category[] = [];
        // for (const cid of sortedCategoryIds) {
        //     if (mainIds.includes(cid)) {
        //         top3cats.push(main.find(m => m.id === cid)!);
        //     }
        //     if (top3cats.length >= 3) {
        //         break;
        //     }
        // }
        // return top3cats;
    }, [main, accountType]);

    const catsWithBiz: Category[] | null = useMemo(() => {
        const ids = accountType === AccountType.BUYER_CLIENT ? sortedCategories.buyer : sortedCategories.supplier;
        if (!ids || !main) {
            return null;
        }
        if (accountType === AccountType.BUYER_CLIENT) {
            //for buyers
            return main.filter(m => ids.includes(m.id));
        } else {
            //for suppliers
            return main.filter(m => ids.includes(m.id)).map(m => ({
                ...m,
                subCategories: m.subCategories
                    .filter(s => ids.includes(s.id))
                    .map(s => ({
                        ...s,
                        subSubCategories: s.subSubCategories
                            .filter(ss => ids.includes(ss.id)),
                    }))
            }))
        }
    }, [sortedCategories, main, accountType]);

    const onFavSearchLoad = (pref: Preference) => {
        const result: ResultsViewRouteParams = JSON.parse(pref.value);
        if (result.request) {
            categorySearchEvent(result.request, accountType!);
        }
        history.push(`/${path}/results`, result);
    }

    const onFavSearchDelete = async (pref: Preference) => {
        if (loading) {
            return;
        }
        try {
            setLoading(true);
            await dispatch(deleteMyPref(pref.id));
        } catch (error) {
            dispatch(showError(`Failed to delete: ${error.message}`));
        } finally {
            setLoading(false);
        }
    }

    const onBrowseAllClicked = () => {
        catRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    return (
        <>
            <NewFeatureBanner />
            <PageContainer
                containerClassName={classnames(styles.container, styles.outer_container)}
                className={styles.container}
            // back
            >
                <h2>{accountType === AccountType.SUPPLIER ? 'Amotai Verified Māori and Pasifika Supplier Directory' : 'Search Buyer Directory'}</h2>
                {accountType === AccountType.SUPPLIER && (
                    <div className={styles.subtitle}>
                        <img
                            src={shieldIcon}
                            alt='subtitle'
                        />
                        <span>
                            An Amotai Verified Supplier is a Māori &/or Indigenous Pasifika business
                            that continuously meets Amotai Indigenous Business Ownership criteria.
                        </span>
                    </div>
                )}
                <div className={styles.search_container}>
                    {accountType &&
                        <SearchBar
                            accountType={accountType}
                            top3categories={top3categories}
                            onTop3CategoryClicked={onCategoryClicked}
                            onBrowseAllClicked={onBrowseAllClicked}
                        />}
                    {!!searchPrefs?.length && (
                        <div className={styles.section}>
                            <div className={styles.title}>
                                My saved searches
                        </div>
                            <div className={styles.saved_content}>
                                {searchPrefs.map(p =>
                                    (
                                        <div key={p.id} className={styles.pill}>
                                            <div
                                                title={p.name}
                                                className={styles.fav_label}
                                                onClick={() => onFavSearchLoad(p)}
                                            >
                                                {p.name}
                                            </div>
                                            <img
                                                className={classnames(styles.delete, { [styles.loading]: loading })}
                                                src={deleteIcon}
                                                alt='delete saved search'
                                                onClick={() => onFavSearchDelete(p)}
                                            />
                                        </div>
                                    )
                                )
                                }
                            </div>
                        </div>
                    )}
                    <div className={classnames(styles.categories, styles.section)} ref={ref => { catRef.current = ref }}>
                        <div className={styles.title}>Browse by industry</div>
                        {!catsWithBiz?.length && <Loading />}
                        <div className={styles.others}>
                            {accountType === AccountType.BUYER_CLIENT ?
                                (
                                    catsWithBiz?.map(m => (
                                        <div key={m.name} className={styles.buyer_main_cat}>
                                            <div className={styles.main_cat} onClick={() => onCategoryClicked(m.id, HitType.Category, m.name)}>
                                                {m.name}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    !!Object.keys(expandStates).length && catsWithBiz?.map(m => (
                                        <Accordion
                                            key={m.name}
                                            TransitionProps={{ unmountOnExit: true }}
                                            className={styles.collapse}
                                            expanded={expandStates[m.name]}
                                        >
                                            <AccordionSummary
                                                className={styles.content}
                                                expandIcon={<ExpandMoreIcon />}
                                                onClick={() => handleExpends(m.name)}
                                            >
                                                <div className={styles.main_cat}>{m.name}</div>
                                            </AccordionSummary>
                                            <AccordionDetails className={styles.content}>
                                                <div className={styles.subs}>
                                                    {m.subCategories?.map(s => (
                                                        <div key={s.name} className={styles.sub}>
                                                            <label onClick={() => onCategoryClicked(s.id, HitType.SubCategory, s.name)}>
                                                                {s.name}
                                                            </label>
                                                            {s.subSubCategories?.map(ss => (
                                                                <span key={ss.id} onClick={() => onCategoryClicked(ss.id, HitType.SubSubCategory, ss.name)}>
                                                                    {ss.name}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))
                                )}
                        </div>
                    </div>
                </div>
                {/* <Dialog ref={learnMoreRef} className={styles.learn_more}>
                    <div className={styles.title}>
                        All of our Suppliers have been pre-verified
                </div>
                    <div className={styles.content}>
                        Amotai operates the only verification process in Aotearoa, NZ which validates a supplier against Amotai’s indigenous ownership criteria. An Amotai Verified Supplier is a Māori &/or Pasifika business with a valid NZBN that continuously meets one of the following ownership types;
                    <ol>
                            <li>
                                Māori Owned at least 50% Māori ownership
                        </li>
                            <li>
                                Pasifika Owned at least 50% Pasifika ownership
                        </li>
                            <li>
                                Māori, Pasifika Owned* Has at least 50% Māori ownership AND at least 50% Pasifika ownership (not &#39;AND/OR&#39;)
                        </li>
                            <li>
                                Māori & Pasifika Ownership Combined 50% total combined Māori and Pasifika ownership, when ownership level of either Māori or Pasifika category does not meet the 50% threshold
                        </li>
                        </ol>
                    </div>
                    <div className={styles.footer}>
                        <Button onClick={() => learnMoreRef.current?.hide()}>Close</Button>
                    </div>
                </Dialog> */}
            </PageContainer>
        </>
    )
}
