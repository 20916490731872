import React from "react";

export default function Youtube(props: React.SVGProps<SVGSVGElement>) {

    return (
        <svg xmlns={"http://www.w3.org/2000/svg"} width="34.8" height="24" viewBox="0 0 34.8 24" {...props}>
            <path
                transform="translate(-12.4 -16)"
                d={"M46.6,19.75a.551.551,0,0,0-.15-.45v-.15A3.243,3.243,0,0,0,43.3,16.9h.3A100.55,100.55,0,0,0,29.8,16a103.345,103.345,0,0,0-13.8.9h.3a3.243,3.243,0,0,0-3.15,2.25v.15a.551.551,0,0,1-.15.45A72.9,72.9,0,0,0,12.4,28a72.9,72.9,0,0,0,.6,8.25.551.551,0,0,0,.15.45v.15A3.243,3.243,0,0,0,16.3,39.1H16a100.55,100.55,0,0,0,13.8.9,103.346,103.346,0,0,0,13.8-.9h-.3a3.243,3.243,0,0,0,3.15-2.25V36.7a.551.551,0,0,1,.15-.45A72.9,72.9,0,0,0,47.2,28,48.818,48.818,0,0,0,46.6,19.75Zm-11.7,9-7.05,5.1a.75.75,0,0,1-.9,0,.825.825,0,0,1-.45-.75V22.9a.825.825,0,0,1,.45-.75.956.956,0,0,1,.9,0l7.05,5.1a1.146,1.146,0,0,1,.3.75A.548.548,0,0,1,34.9,28.75Z"} />
        </svg>
    );
}
