export default interface Preference {
    id: number;
    type: PreferenceType;
    name: string;
    user: string;
    createdAt: string;
    value: string;
}

export enum PreferenceType {
    Filter = 'Filter',
    Search = 'Search',
}