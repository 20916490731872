import React, { useRef, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeError, Token } from "@stripe/stripe-js";
import { Column } from "material-table";
import useSelector from "../../../../../../hooks/useSelector";
import useMountEffect from "../../../../../../hooks/useMountEffect";
import { createCard, getCards, updateCard } from "../../../../../../redux/actions/cards";
import useDispatch from "../../../../../../hooks/useDispatch";
import Loading from "../../../../../widgets/loading/Loading";
import Table from "../../../../../widgets/table/Table";
import Card from "../../../../../../types/Card";
import styles from "../Billing.module.scss";
import Button from "../../../../../widgets/button/Button";
import Section from "../../../../../widgets/section/Section";
import CreateCardDialog, { CreateCardValues } from "../../../../../widgets/dialogs/createCardDialog/CreateCardDialog";
import DeleteCardDialog from "../../../../../widgets/dialogs/deleteCardDialog/DeleteCardDialog";
import { DialogRef } from "../../../../../widgets/dialog/Dialog";
import { showError } from "../../../../../../redux/actions/snackbars";

type CardsProps = {
    accountId: number
}

const stripe = loadStripe(process.env.REACT_APP_STRIPE_API_KEY!);

function Cards(props: CardsProps) {
    const { accountId } = props;
    const [selectedCardId, setSelectedCardId] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const deleteCardDialogRef = useRef<DialogRef>(null);
    const createCardDialogRef = useRef<DialogRef>(null);

    const dispatch = useDispatch();
    const cards = useSelector((state) => state.cards.cards);

    useMountEffect(async () => {
        if (!cards || cards.length === 0) {
            await dispatch(getCards(accountId));
            setLoading(false);
        } else {
            setLoading(false);
        }
    });

    const onDeleteCard = (c: Card) => {
        deleteCardDialogRef.current?.show();
        setSelectedCardId(c.id);
    };

    const onSetDefault = async (c: Card) => {
        const payload = { ...c };
        payload.default = true;
        setLoading(true);
        try {
            await dispatch(updateCard(accountId, c.id, payload));
            await dispatch(getCards(accountId));
        } catch (e) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    };

    const onCreateCardDialogClose = () => createCardDialogRef.current?.hide();

    const onCreateCardDialogSave = async (token: {
        token?: Token | undefined,
        error?: StripeError | undefined
    } | undefined, values: CreateCardValues) => {
        try {
            setLoading(true);
            onCreateCardDialogClose();

            if (!token?.token?.id) {
                throw new Error("Error creating card");
            }

            await dispatch(createCard(accountId, token.token.id, !!values.isDefault.length));
            setLoading(false);
        } catch (e) {
            dispatch(showError(e.message));
            setLoading(false);
        }

    };

    const cardsColumn: Column<Card>[] = [
        {
            title: "Card number",
            render: c => c.default ?
                <span>{c.last4} <span className={styles.card_default}>Default card</span></span> :
                <span>{c.last4}</span>
        },
        {
            title: "Cardholder",
            field: "name"
        },
        {
            title: "Expiry",
            field: "expiryDate"
        },
        {
            render: (c: Card) => {
                if (!c.default) {
                    return (
                        <div className={styles.links}>
                            <Button plainLink onClick={() => onSetDefault(c)}>Set default</Button>
                        </div>
                    );
                }
                return null;
            }
        },
        {
            render: (c: Card) => (
                cards.length > 1 && (
                    <div className={styles.links}>
                        <Button plainLink onClick={() => onDeleteCard(c)}>Delete</Button>
                    </div>
                )
            )
        }
    ];

    return (
        <>
            {loading ? <Loading /> : (
                <Section className={styles.cards}>
                    <h5>Billing information</h5>
                    <Table<Card> columns={cardsColumn}
                                 data={cards}
                                 options={{ paging: false }} />
                    <div className={styles.card_btn}>
                        <Button borderdark
                                uppercase
                                onClick={createCardDialogRef.current?.show}>
                            + Add new card
                        </Button>
                    </div>
                </Section>
            )}

            <Elements stripe={stripe}>
                <CreateCardDialog ref={createCardDialogRef}
                                  onSubmit={onCreateCardDialogSave}
                                  onClose={onCreateCardDialogClose} />
            </Elements>
            <DeleteCardDialog ref={deleteCardDialogRef}
                              accountId={accountId}
                              cardId={selectedCardId} />
        </>
    );
}

export default Cards;
