import { CssBaseline } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import MuiPickersUtilsProvider from "@material-ui/pickers/MuiPickersUtilsProvider";
import LuxonUtils from "@date-io/luxon";
import configureStore from "../redux/store/configureStore";
import Main from "./Main";
import "./App.module.scss";
import SnackbarContainer from "./widgets/snackbar/SnackbarContainer";
import Spinner from "./widgets/spinner/Spinner";

export const { store, persistor } = configureStore();

const theme = createMuiTheme({
    spacing: 9,
    overrides: {
        MuiInputBase: {
            input: {
                height: 34,
            },
        },
        MuiCheckbox: {
            root: {
                color: "#CDCDCD",
            },
        },
        MuiInput: {
            root: {
                border: "1px solid #CDCDCD",
                borderRadius: 4,
            },
            input: {
                lineHeight: 20,
                minHeight: 28,
                fontSize: 16,
                padding: "4px 9px",
            },
            formControl: {
                "label + &": {
                    marginTop: 20,
                },
            },
        },
        MuiSelect: {
            root: {
                padding: "14px 24px 9px 9px",
                lineHeight: 2,
            },
        },
        MuiInputLabel: {
            root: {
                fontFamily: "Poppins, sans-serif",
                fontWeight: "bold",
                fontSize: 18,
                marginBottom: 4,
                color: "#000000",
                textTransform: "none",
            },
        },
        MuiButton: {
            root: {
                fontFamily: "Poppins, sans-serif",
                padding: "4px 12px",
                fontSize: 18,
                textTransform: "none",
            },
            text: {
                padding: "4px 12px",
            },
            sizeSmall: {
                padding: "0 9px",
                fontSize: 16,
            },
        },
        MuiMenuItem: {
            root: {
                paddingLeft: 18,
                paddingRight: 18,
            },
        },
    },
    props: {
        MuiInput: {
            disableUnderline: true,
        },
        MuiInputLabel: {
            shrink: true,
        },
    },
});

export default function App() {
    if (process.env.ENVIRONMENT === "production") {
        console.log = () => {};
    }
    return (
        <Provider store={store}>
            <StylesProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <PersistGate loading={<Spinner center />} persistor={persistor}>
                            <SnackbarContainer>
                                <Suspense fallback={<Spinner center />}>
                                    <CssBaseline />
                                    <Main />
                                </Suspense>
                            </SnackbarContainer>
                        </PersistGate>
                    </MuiPickersUtilsProvider>
                </ThemeProvider>
            </StylesProvider>
        </Provider>
    );
}
