import { Field, useField } from "formik";
import { FormControlLabel, InputLabel, TextareaAutosize } from "@material-ui/core";
import React, { useMemo, useState, useCallback, useEffect } from "react";
import { StyledCheckbox } from "../checkbox/Checkbox";
import styles from "./FormikCheckboxGroup.module.scss";

export function CheckboxGroupItem({ field, form, label, ...otherProps }: any) {
    const { name, value: formikValue } = field;
    const values = formikValue || [];
    // const values = [...formikValue];

    const index = typeof otherProps.value === 'object' ? values.map((v: any) => v.id).indexOf(otherProps.value.id) : values.indexOf(otherProps.value);
    let checked;
    if (typeof (otherProps.value) === "object") {
        // field value is an object, expected to have an id property
        checked = !!(values.filter((v: any) => v.id === otherProps.value.id) || [])[0];
    } else {
        checked = values.indexOf(otherProps.value) !== -1;
    }

    const handleChange = () => {
        if (index === -1) {
            values.push(otherProps.value);
        } else {
            values.splice(index, 1);
        }
        form.setFieldValue(name, values);
        form.setFieldTouched(name, true);
        if (otherProps.sideEffect) {
            otherProps.sideEffect();
        }
    };

    return (
        <FormControlLabel
            control={(
                <StyledCheckbox checked={checked}
                                onChange={handleChange}
                                disabled={otherProps.disabled} />
            )}
            label={label} />
    );
}

type CheckboxGroupProps = {
    name: string;
    // keyedItems?: { label: string, value: string }[];
    stringItems?: string[];
    disabled?: boolean;
}

export default function FormikCheckboxGroup(props: CheckboxGroupProps) {
    const { name, stringItems, disabled } = props;

    const [, meta] = useField(name);
    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;

    return (
        <>
            {stringItems?.map((item) => (
                <Field component={CheckboxGroupItem}
                       name={name}
                       label={item}
                       disabled={disabled}
                       value={item} />
            ))}
            {showError && <div className={styles.error}>{fieldError}</div>}
        </>
    );
}

export function SingleChoiceCheckboxGroup(
        props: CheckboxGroupProps & {
        xTextTrigger?: string, 
        xTextLabel?: string,
        xTextClassName?: string,
    }) {
    const { name, stringItems, disabled, xTextTrigger, xTextLabel, xTextClassName } = props;
    const [value, setValue] = useState<string>();
    const [xText, setXText] = useState<string>();
    const [error, setError] = useState<string>();
    const [, , helper] = useField(name);

    const xTextShow = useMemo(() => xTextTrigger && xTextTrigger === value, [xTextTrigger, value]);

    const itemsWithStatus = useMemo((): Array<{label: string, checked: boolean}> => {
        if (!stringItems) return [];
        return stringItems.map(s => ({label: s, checked: s === value}))
    }, [value, stringItems]);

    const handleChange = useCallback((item: string, checked: boolean) => {
        const _value = checked? item : undefined;
        helper.setValue(_value)
        setValue(_value);
    }, [helper])

    const handleTextChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const v = event.target.value;
        if (xTextShow) {
            setXText(v);
            helper.setValue(`Other: ${v}`);
        }
    }, [xTextShow, helper]);

    useEffect(() => {
        if (xTextShow && (xText??'').trim() === '') {
            setError('Please fill the details')
        } else {
            setError(undefined)
        }
    }, [xTextShow, helper, xText]);

    return (
        <>
            {itemsWithStatus?.map((item) => (
                <FormControlLabel   key={item.label} 
                                    label={item.label}
                                    control={(
                                        <StyledCheckbox checked={item.checked}
                                                        onChange={(_, checked) => handleChange(item.label, checked)}
                                                        disabled={disabled} />
                                    )}
                />
            ))}
            {xTextShow && <div>
                {xTextLabel && (
                    <div className={styles.label_tooltip_container}>
                        <InputLabel shrink htmlFor={"input"}>
                            {xTextLabel}
                        </InputLabel>
                    </div>
                )}
                <TextareaAutosize disabled={disabled} className={xTextClassName} onChange={handleTextChange} />
                {error && <div className={styles.error}>{error}</div>}
            </div>}
        </>
    );
}
