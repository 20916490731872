import { AccountType } from "src/types/AmotaiAccount";
import AmotaiUser, { AccountRole, SystemRole, UserStatus } from "src/types/AmotaiUser";
import Preference from "src/types/Preference";
import BuyerClient from "../../types/BuyerClient";
import Supplier from "../../types/Supplier";
import { UserPayloads, UsersTypes } from "../actions/users";

type State = {
    accountUsersObj: { [key: string]: AmotaiUser };
    accountUsers: AmotaiUser[];
    me?: AmotaiUser;
    account?: Supplier | BuyerClient;
    isOrgAdmin: boolean,
    isSystemAdmin: boolean,
    isRegionalManager: boolean,
    isSupplierViewer: boolean,
    preferences: { [type: string]: Preference[] };
    options: { [name: string]: any };
}

const initialState: State = {
    accountUsersObj: {},
    accountUsers: [],
    isOrgAdmin: false,
    isSystemAdmin: false,
    isRegionalManager: false,
    isSupplierViewer: false,
    preferences: {},
    options: {},
};

export default function users(state = initialState, action: { type: UsersTypes, payload: any }) {
    switch (action.type) {
        case UsersTypes.SET_USER: {
            const payload = action.payload as UserPayloads[UsersTypes.SET_USER];
            const user = payload!;
            let accountUsers = { ...state.accountUsersObj };

            if (user.status !== UserStatus.ACTIVE) {
                delete accountUsers[user.id];
            } else {
                accountUsers = {
                    ...accountUsers,
                    [user.id]: user
                };
            }

            return {
                ...state,
                accountUsersObj: accountUsers
            };
        }

        case UsersTypes.SET_MY_USER: {
            const payload = action.payload as UserPayloads[UsersTypes.SET_MY_USER];
            const me = payload!;

            return {
                ...state,
                me,
                isOrgAdmin: me?.accountRole === AccountRole.ADMIN,
                isSystemAdmin: me?.systemRole === SystemRole.SUPER_ADMIN || me?.systemRole === SystemRole.ADMIN,
                isRegionalManager: me?.systemRole === SystemRole.REGIONAL_MANAGER,
                isSupplierViewer: me?.systemRole === SystemRole.SUPPLIER_VIEWER
            };
        }

        case UsersTypes.SET_USERS: {
            const payload = action.payload as UserPayloads[UsersTypes.SET_USERS];
            const newState = { ...state };
            payload!.items.forEach((user) => {
                if (user.status === UserStatus.ACTIVE) {
                    newState.accountUsersObj[user.id] = user;
                }
            });

            return {
                ...newState,
                accountUsers: payload?.items || []
            };
        }

        case UsersTypes.SET_MY_ACCOUNT: {
            let account = action.payload as UserPayloads[UsersTypes.SET_MY_ACCOUNT];

            if (!account) {
                return { ...state };
            }

            switch (account.type) {
                case AccountType.BUYER_CLIENT:
                    account = account as BuyerClient;
                    break;
                case AccountType.SUPPLIER:
                    account = account as Supplier;
                    break;
                default:
                    break;
            }
            return {
                ...state,
                account
            };
        }

        case UsersTypes.LOG_OUT_USER: {
            return {
                ...initialState
            }
        }

        case UsersTypes.SET_PREF: {
            const payload = action.payload as UserPayloads[UsersTypes.SET_PREF];
            const pf = payload!
            const newState = { ...state };
            const prefs = newState.preferences[pf.type] ?? [];
            if (!prefs.find(p => p.id === pf.id)) {
                prefs.push(pf);
            }
            newState.preferences[pf.type] = prefs;
            return newState;
        }
        case UsersTypes.SET_PREFS: {
            const payload = action.payload as UserPayloads[UsersTypes.SET_PREFS];
            const pfs = payload!;
            const prefs: { [type: string]: Preference[] } = {};
            for (const pf of pfs) {
                const existing = prefs[pf.type] ?? [];
                existing.push(pf);
                prefs[pf.type] = existing;
            }
            return {
                ...state,
                preferences: prefs,
            };
        }
        case UsersTypes.DEL_PREF: {
            const payload = action.payload as UserPayloads[UsersTypes.DEL_PREF];
            const { prefId } = payload!;
            const prefs = { ...state.preferences };
            for (const type in prefs) {
                const pfs = prefs[type];
                if (pfs.find(p => p.id === prefId)) {
                    prefs[type] = pfs.filter(p => p.id !== prefId);
                    break;
                }
            }
            return {
                ...state,
                preferences: prefs,
            };
        }
        case UsersTypes.SET_OPTS: {
            const payload = action.payload as UserPayloads[UsersTypes.SET_OPTS];
            const { name, value } = payload;
            const opts = {
                ...state.options
            };
            opts[name] = value;
            return {
                ...state,
                options: opts,
            }
        }

        default: {
            return state;
        }
    }
}
