import { useElements, useStripe } from "@stripe/react-stripe-js";
import classNames from "classnames";
import React, { forwardRef, Ref, useImperativeHandle, useRef, useState } from "react";
import FormikCheckbox from "src/components/widgets/formikFields/FormikCheckbox";
import FormikInput from "src/components/widgets/formikFields/FormikInput";
import { FormStep } from "src/components/widgets/multiStepForm/MultiStepForm";
import StripeInput, { StripeInputRef } from "src/components/widgets/stripeInput/StripeInput";
import useDispatch from "src/hooks/useDispatch";
import { showError } from "src/redux/actions/snackbars";
import SaaSPlan from "src/types/SaaSPlan";
import * as Yup from "yup";
import styles from "./BuyerClientOnboarding.module.scss";
import { BuyerClientFormValues } from "./buyerClientValidation";

export const BuyerCardValidationSchema = Yup.object({
    cardName: Yup.string().when("invoice", {
        is: null,
        then: Yup.string().required("Required"),
        otherwise: Yup.string().notRequired(),
    }),
    invoicePayment: Yup.boolean().nullable()
});

type Props = {
    plan: SaaSPlan | undefined;
    setLoading: (loading: boolean) => void;
    setCardToken: (token: any) => void;
    validationSchema: any;
}

export type Page9Ref = {
    onSubmit: (values: BuyerClientFormValues) => Promise<boolean>
}

function Page9({ plan, setLoading, setCardToken }: Props, ref: Ref<Page9Ref>) {
    const cardElementRef = useRef<StripeInputRef>(null);
    const expiryElementRef = useRef<StripeInputRef>(null);
    const cvcElementRef = useRef<StripeInputRef>(null);
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const [showCardForm, setShowCardForm] = useState<boolean>(true);

    const validateStripeInput = () => {
        let valid = true;

        if (!cardElementRef.current?.touched()) {
            valid = false;
            cardElementRef.current?.setElementError("Required");
        }

        if (!expiryElementRef.current?.touched()) {
            valid = false;
            expiryElementRef.current?.setElementError("Required");
        }

        if (!cvcElementRef.current?.touched()) {
            valid = false;
            cvcElementRef.current?.setElementError("Required");
        }

        if (cardElementRef.current?.hasErrors() || expiryElementRef.current?.hasErrors() || cvcElementRef.current?.hasErrors()) {
            valid = false;
        }

        return valid;
    };

    const handleCardSubmit = async (values: BuyerClientFormValues): Promise<boolean> => {
        if (values.invoicePayment) {
            // payment via invoice, don't submit card
            return true;
        }

        if (!validateStripeInput()) {
            return false;
        }

        try {
            setLoading(true);
            const cardElement = elements?.getElement("cardNumber");

            if (!cardElement) {
                throw new Error("Error creating card");
            }

            const token = await stripe?.createToken(cardElement, { name: values.cardName });

            if (!token) {
                throw new Error("Error creating card");
            }

            if (token.error) {
                throw new Error(token.error.message);
            }

            setLoading(false);
            setCardToken(token);
            return true;
        } catch (e) {
            dispatch(showError(e.message));
            setLoading(false);
            return false;
        }
    };

    useImperativeHandle(ref, () => ({ onSubmit: handleCardSubmit }));

    return (
        <FormStep onSubmit={handleCardSubmit}
            validationSchema={BuyerCardValidationSchema}>
            <h5>Enter your business payment details</h5>
            <p>Enter your membership payment details below. You will be charged when you submit your application.</p>
            <div className={styles.inner_container}>
                <div className={styles.onboarding_form}>
                    {showCardForm && (
                        <>
                            <StripeInput type={"card"}
                                ref={cardElementRef}
                                label={"Card number"}
                                inputClassname={classNames(styles.half_input, styles.first)} />
                            <FormikInput name={"cardName"}
                                label={"Cardholder name"}
                                placeholder={"John Smith"}
                                inputClassname={styles.half_input} />
                            <StripeInput type={"expiry"}
                                ref={expiryElementRef}
                                label={"Expiry"}
                                inputClassname={classNames(styles.half_input, styles.first)} />
                            <StripeInput type={"cvc"}
                                ref={cvcElementRef}
                                label={"CVC"}
                                inputClassname={styles.half_input} />
                        </>
                    )}
                    {plan?.invoicable && <FormikCheckbox name={"invoicePayment"} onChange={setShowCardForm} inputValue>
                        <p>I would like to be sent a {plan.invoiceInterval?.toLowerCase()} invoice instead</p>
                    </FormikCheckbox>}
                </div>
            </div>
        </FormStep>
    );
}

export default forwardRef(Page9)
