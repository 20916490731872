import React from "react";
import classNames from "classnames";
import * as Yup from "yup";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { useFormikContext } from "formik";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import FormikInput from "../../../widgets/formikFields/FormikInput";
import FormikSelect from "../../../widgets/formikFields/FormikSelect";
import FormikCheckboxGroup from "../../../widgets/formikFields/FormikCheckboxGroup";
import { LEGAL_STRUCTURE, NATIONWIDE_REGION, REGIONS, REGIONS_NO_NATIONWIDE } from "../../../../util/constants";
import styles from "../buyerClient/BuyerClientOnboarding.module.scss";
import Tooltip from "../../../../resources/images/svg/tooltip-icon.svg";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import { SupplierFormValues } from "./supplierValidation";
import FormikAddressInput from "../../../widgets/formikFields/FormikAddressInput";

export const OrgDetailsValidationSchema = Yup.object({
    tradingName: Yup.string().required("Required"),
    legalStructure: Yup.string().required("Required"),
    legalName: Yup.string().required("Required"),
    yearEstablished: Yup.string().required("Required"),
    nzCompaniesNumber: Yup.string().required("Required"),
    nzbn: Yup.string().required("Required"),
    postalAddress: Yup.string().nullable(),
    physicalAddress: Yup.string().nullable(),
    physicalAddressComponents: Yup.object().nullable().required("Required"),
    regions: Yup.array().of(Yup.string()).min(1, "at least one region required"),
    regionBased: Yup.string().required("Required")
});

export default function Page2(props: any) {

    const yearOptions = (startYear: number) => {
        const currentYear = moment().year();
        const opts = [];

        for (let i = startYear; i <= currentYear; i++) {
            opts.unshift(i.toString());
        }
        return opts;
    };

    const { values } = useFormikContext<SupplierFormValues>();

    const renderFields = () => (
        <div>
            <FormikInput name={"tradingName"}
                         inputClassname={classNames(styles.half_input, styles.first)}
                         placeholder={"e.g. Acme"}
                         label={"Trading name of business"} />
            <FormikSelect name={"legalStructure"}
                          containerClassName={styles.half_input}
                          label={"Structure"}
                          options={LEGAL_STRUCTURE} />
            <FormikInput name={"legalName"}
                         inputClassname={classNames(styles.half_input, styles.first, styles.tooltip_input)}
                         placeholder={"Acme Limited"}
                         label={"Legal name of business"}
                         tooltip={"Legal name as per the companies office"} />
            <FormikSelect name={"yearEstablished"}
                          containerClassName={styles.half_input}
                          label={"Year Established"}
                          options={yearOptions(1950)} />
            <FormikInput name={"nzCompaniesNumber"}
                         inputClassname={classNames(styles.half_input, styles.first)}
                         placeholder={"1234567"}
                         label={"NZ Companies no."}
                         tooltip={"Provide your companies number issued by the companies office (can be found online at the companies register)"} />
            <FormikInput name={"nzbn"}
                         inputClassname={styles.half_input}
                         placeholder={"1234567"}
                         label={"NZ Business no."}
                         tooltip={"Provide your business number issued by the companies office (can be found online at the companies register)"} />

            <FormikInput    name={"postalAddress"}
                            label={"Postal Address"}
                            placeholder={"1 Example St, Auckland, 0610, New Zealand"}
                            tooltip={"This information will not be published on the platform"}
                            inputClassname={styles.full_input} />
            {/*<FormikInput name={"physicalAddress"}*/}
            {/*             inputClassname={styles.full_input}*/}
            {/*             placeholder={"1 Example St, Auckland, 0610, New Zealand"}*/}
            {/*             label={"Physical Address Old"}*/}
            {/*/>*/}
             <FormikAddressInput name={"physicalAddressComponents"}
                                formattedName={"physicalAddressInput"}
                                label={"Physical Address"}
                                placeholder={"1 Example St, Auckland, 0610, New Zealand"}
                                tooltip={"This information will not be published on the platform"}
                                inputClassname={styles.full_input} />

            <hr />
            <SectionHeader title={"Regions"} />
            <div className={styles.checkbox_container}>
                <div className={styles.heading}>
                    <label>Regions of operation</label>
                    <img src={Tooltip} alt={"Help"} data-tip={"Select all the relevant regions you operate in"} />
                </div>
                {values.regions.includes(NATIONWIDE_REGION) ?
                    <FormikCheckboxGroup name={"regions"} stringItems={[NATIONWIDE_REGION]} /> :
                    <FormikCheckboxGroup name={"regions"} stringItems={REGIONS} />}
            </div>

            <div className={styles.checkbox_container}>
                <div className={styles.heading}>
                    <label>Region based</label>
                    <img src={Tooltip} alt={"Help"} data-tip={"Select the region your company is based in"} />
                </div>
                <FormikSelect name={"regionBased"} options={REGIONS_NO_NATIONWIDE} />
            </div>
            <ReactTooltip
                place={"top"}
                effect={"solid"}
                multiline
                backgroundColor={"#F1F1F2"}
                borderColor={"#E6E7E8"}
                textColor={"#000000"}
                clickable
            />
        </div>
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Tell us about your organisation</h5>
            <div className={styles.inner_container}>
                <div className={styles.onboarding_form}>
                    {children}
                </div>
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Business Details"} />
                {renderFields()}
            </div>
        );
    }

    return renderFormStep(renderFields());
}
