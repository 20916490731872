import { Paper } from "@material-ui/core";
import React, { ReactNode, useMemo } from "react";
import SaaSPlan from "src/types/SaaSPlan";
import { currency } from "src/util/helper";
import Button from "../button/Button";
import styles from "./PlanCard.module.scss";

type Props = {
    label: string;
    plan?: SaaSPlan;
    detailItems?: ReactNode;
    onSelect: () => void;
    selected: boolean;
    disabled?: boolean;
    hidePrice?: boolean;
    showAnnualPrice?: boolean;
}
export default function PlanCard({ label, plan, detailItems, onSelect, selected, disabled, hidePrice, showAnnualPrice }: Props) {
    const price = useMemo(() => {
        if (!plan) {
            return '';
        }
        const { amount, invoiceAnnualAmount } = plan;
        let price = amount;
        if (showAnnualPrice && invoiceAnnualAmount) {
            price = invoiceAnnualAmount;
        }
        return currency((price / 100).toFixed(2))
    }, [plan, showAnnualPrice]);

    return (
        <Paper className={styles.plan_card}>
            {label && (
                <div className={styles.label}>
                    {label}
                </div>
            )}
            {plan && !hidePrice && (
                <div className={styles.price_container}>
                    <div className={styles.price}>
                        <span>$</span>
                        <h3>{price} </h3>
                    </div>
                    <div className={styles.monthly}>
                        {showAnnualPrice && plan.invoiceAnnualAmount ? 'per annum' : 'per month'}
                    </div>
                </div>

            )}
            {hidePrice && (
                <div className={styles.price_container}>
                    <div className={styles.price}>
                        <span> </span>
                        <h3>{' '} </h3>
                    </div>
                    <div className={styles.monthly}>
                        {` `}
                    </div>
                </div>
            )}
            {detailItems && (
                <div className={styles.plan_details}>
                    {detailItems}
                </div>
            )}
            {selected ?
                <Button disabled={disabled} borderwhite selected={selected} onClick={onSelect}>SELECTED</Button>
                :
                <Button disabled={disabled} borderwhite onClick={onSelect}>SELECT</Button>}
        </Paper>
    )
}