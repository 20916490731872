import Address from "./Address";
import BusinessContact from "./BusinessContact";
import { Category, SubCategory, SubSubCategory } from "./Category";

export enum AccountType {
    BUYER_CLIENT = "BUYER_CLIENT",
    SUPPLIER = "SUPPLIER",
}

export enum AccountStatus {
    ACTIVE = "ACTIVE",
    DELETED = "DELETED",
    DISABLED = "DISABLED",
    PENDING_REVIEW = "PENDING_REVIEW",
    DECLINED = "DECLINED",
    AWAITING_PAYMENT = 'AWAITING_PAYMENT',
}

export enum PaymentType {
    STRIPE = "STRIPE",
    INVOICE = "INVOICE",
    FUNDER = "FUNDER",
    IWI = "IWI"
}

export default interface AmotaiAccount {
    id: number;
    type: AccountType;
    status: AccountStatus;
    stripeCustomerId: string;
    name: string;
    phoneNumber: string;
    email: string;
    description: string;
    logo: string;
    mainContact: BusinessContact;
    category: Category;
    mainCategories: Category[];
    subCategories: SubCategory[];
    subSubCategories: SubSubCategory[];
    regions: string[];
    createdAt: string;
    legalStructure: string;
    reviewMessage: string;
    suspensionMessage: string;
    paymentType: string;
    postalAddress?: string;
    physicalAddressComponents: Address;
    organisationType?: string;
    iwiAffiliations?: string[];
    regionBased: string;
    mandateAgency?: string;
}
