import React from "react";
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";
import classNames from "classnames";
import FormikInput from "../../../widgets/formikFields/FormikInput";
import FormikTextArea from "../../../widgets/formikFields/FormikTextArea";
import {FormStep} from "../../../widgets/multiStepForm/MultiStepForm";
import styles from "../buyerClient/BuyerClientOnboarding.module.scss";
import FormikNumberInput from "../../../widgets/formikFields/FormikNumberInput";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";

export const OrgEmployeesValidationSchema = Yup.object({
    totalFTE: Yup.number().required("Required"),
    maoriEmployees: Yup.number().required("Required"),
    pasifikaEmployees: Yup.number().required("Required"),
    nonMaleEmployees: Yup.number().required("Required"),
    employeesUnderTwentyFour: Yup.number().required("Required"),
    pathwaysProvided: Yup.string(),
})

export default function Page6(props: any) {

    const renderFields = () => (
        <>
            <FormikNumberInput name={"totalFTE"}
                               label={"Size of workforce (Number of full time employees)"}
                               allowNegative={false}
            />
            <hr/>
            <FormikInput name={"maoriEmployees"}
                         inputClassname={classNames(styles.half_input, styles.first)}
                         label={"Number of Māori employees"}
            />
            <FormikInput name={"pasifikaEmployees"}
                         inputClassname={styles.half_input}
                         label={"Number of Pasifika employees"}
            />
            <hr/>
            <FormikInput name={"nonMaleEmployees"}
                         inputClassname={classNames(styles.half_input, styles.first)}
                         label={"Number of female employees"}
            />
            <FormikInput name={"employeesUnderTwentyFour"}
                         inputClassname={styles.half_input}
                         label={"Number of Employees under 24"}
            />
            <hr/>
            <FormikTextArea name={"pathwaysProvided"}
                            label={"Pathways provided"}
                            className={styles.paragraph_input}
                            tooltip={"Provide details on any pathways you provide your staff. For example, apprenticeships, specialised training, first aid etc"}/></>
    );

    const renderFormStep = (children: any) => (
        <FormStep validationSchema={OrgEmployeesValidationSchema}>
            <h5>Who’s employed at your organisation?</h5>
            <div className={styles.inner_container}>
                <div className={classNames(styles.employed_form, styles.onboarding_form)}>
                    {children}
                </div>
                <ReactTooltip
                    place={"top"}
                    effect={"solid"}
                    multiline
                    backgroundColor={"#F1F1F2"}
                    borderColor={"#E6E7E8"}
                    textColor={"#000000"}
                    clickable
                />
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Employee Details"}/>

                {renderFields()}
            </div>
        )
    }

    return renderFormStep(renderFields());
}
