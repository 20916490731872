import { Category, SubCategory, SubSubCategory } from "./Category";
import { PlanResult } from "./SaaSPlan";
import { OwnershipType } from "./Supplier";

export enum Type {
    BUYER_CLIENT = "BUYER_CLIENT",
    SUPPLIER = "SUPPLIER"
}

export default interface DirectoryItem {
    id: number;
    name?: string;
    regions?: string[];
    logo?: string;
    type: Type
    description?: string;
    champion?: boolean;
    healthAndSafetyQualifications?: string[];
    healthAndSafetyForSubContract?: string[];
    professionalIndemnity?: string;
    publicLiabilityInsurance?: string;
    otherInsurances?: { [key: string]: string };
    minimumLevelOfInsuranceForSubContract?: string;
    mainCategories?: Category[];
    category?: Category;
    subCategories?: SubCategory[];
    subSubCategories?: SubSubCategory[];
    iwiAffiliations: string[];
    pasifikaAffiliations: string[];
    facebookLink?: string;
    twitterLink?: string;
    youtubeLink?: string;
    instagramLink?: string;
    linkedInLink?: string;
    pinterestLink?: string;
    website?: string;
    fullProfile?: boolean;
    funder?: boolean;
    ownershipType?: OwnershipType;
    regionBased: string;
    plan?: PlanResult; // buyer only
}
