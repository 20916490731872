import React, {useState} from "react";
import { Link, useParams} from "react-router-dom";
import styles from "./ResourceDetail.module.scss";
import PageContainer from "../../widgets/pageContainer/PageContainer";
import Section from "../../widgets/section/Section";
import SanityAPI from "../../../util/SanityAPI";
import useAsyncEffect from "../../../hooks/useAsyncEffect";
import Resource from "../../../types/Resource";



export default function ResourceDetail() {

    const { id } = useParams();
    const [resource, setResource] = useState<Resource>();

    useAsyncEffect(async ()=>{
        const r = await SanityAPI.getWebinar(id)
        setResource(r)
    })

    const dateCreated = resource?._createdAt;
    const getDateAsString = () =>{
        if(!!dateCreated){
            const dateArray = dateCreated!.slice(0, 10).split("-").reverse();
            const dateString = dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];
            return dateString
        }else{
            return ""
        }
    }
    const author = resource?.author;
    const getAuthor = () => {
        if(!!author) {
            return author
        }else{
            return ""
        }
    }


    return (
        <PageContainer className={styles.resource_detail_page}>
            <div className={styles.left}>
                <Link to={"/resources"}>&lt;&lt; Back to resources</Link>
            </div>
            <div className={styles.right}>
                <div className={styles.resource_header}>
                    <h3>{resource?.title}</h3>
                    <span>{getDateAsString()} {getAuthor()}</span>
                </div>

                <div className={styles.video}>
                    <iframe title={"video"}
                            width={"100%"}
                            src={resource?.embed}
                            frameBorder={"0"}
                            allow={"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"}
                            allowFullScreen />
                </div>

                <Section>
                    <p>
                        {resource?.content}
                    </p>
                </Section>
            </div>
        </PageContainer>
    );
}