import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogRef } from 'src/components/widgets/dialog/Dialog';
import SaveSearchDialog from 'src/components/widgets/dialogs/saveSearchDialog/SaveSearchDialog';
import Loading from 'src/components/widgets/loading/Loading';
import PageContainer from 'src/components/widgets/pageContainer/PageContainer';
import Select, { SelectRef } from 'src/components/widgets/select/Select';
import useDispatch from 'src/hooks/useDispatch';
import useSelector from 'src/hooks/useSelector';
import useUserRoles from 'src/hooks/useUserRoles';
import DirectoryAPI from 'src/redux/actions/api/directory';
import { getAccountSubscription } from 'src/redux/actions/saaSPlans';
import { showError } from 'src/redux/actions/snackbars';
import { getMyAccount } from 'src/redux/actions/users';
import tickIcon from 'src/resources/images/svg/tick.svg';
import AmotaiAccount, { AccountType } from 'src/types/AmotaiAccount';
import BuyerClient from 'src/types/BuyerClient';
import { CategoryType } from 'src/types/Category';
import Preference, { PreferenceType } from 'src/types/Preference';
import SaaSSubscription from 'src/types/SaaSSbscription';
import { FilterKeys, FullResult, HitType, SearchFilters, SearchSuggestion } from 'src/types/SearchResult';
import { getBusinessSize, strarrContainsAnyOfStrarr } from 'src/util/helper';
import DirectoryCard from './directoryCard/DirectoryCard';
import CategoryFilter from './filters/CategoryFilter';
import CheckboxFilter from './filters/CheckboxFilter';
import styles from './ResultsView.module.scss';

export type ResultsViewRouteParams = {
    request?: SearchSuggestion;
    defaultFilters?: SearchFilters;
    accountType?: AccountType;
};

export default function ResultsView() {
    const location = useLocation();
    const accountType = location.pathname.includes("/suppliers") ? AccountType.SUPPLIER : AccountType.BUYER_CLIENT;
    const routeParams = location.state as ResultsViewRouteParams | undefined;

    const [allResults, setAllResults] = useState<FullResult[]>([]);
    const [results, setResults] = useState<FullResult[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [filters, setFilters] = useState<SearchFilters>({});
    const [expandStates, setExpandStates] = useState<{ [key in FilterKeys]: boolean }>({
        "business-size": false,
        "health-safety-insurance": false,
        "professional-indenmnity": false,
        "region-based": false,
        "region-operation": false,
        'iwi-affiliation': false,
        'pasifika-affiliation': false,
        'category': false,
        'ownership': false,
        'public-liability-insurance': false,
        'sub-contract-insurance': false,
        'sub-contract-health-safety': false,
    });
    const dispatch = useDispatch();
    const history = useHistory();

    const saveSearchDialogRef = useRef<DialogRef>(null);
    const searchPrefs = useSelector(state => state.users.preferences)?.[PreferenceType.Search]
        ?.sort((a, b) => a.name.localeCompare(b.name)) as Preference[] | undefined;
    const subscription = useSelector<SaaSSubscription | undefined>(state => state.saaSPlans.subscription!);
    const account = useSelector<AmotaiAccount | undefined | null>(state => state.users.account);

    const [prefValue, setPrefValue] = useState<ResultsViewRouteParams>({ ...routeParams, accountType });
    const [savedPrefValue, setSavedPrefValue] = useState<ResultsViewRouteParams>();

    const selectRef = useRef<SelectRef>(null);
    const { isAdmin, isSuperAdmin, isRegionalManager } = useUserRoles();

    useEffect(() => {
        if (!account) {
            dispatch(getMyAccount());
        }
        if (account?.type === AccountType.BUYER_CLIENT && !subscription) {
            dispatch(getAccountSubscription(account.id))
        }
    }, [account, subscription, dispatch]);

    const access = useMemo((): { [key in FilterKeys]: boolean } & { save_search: boolean } | null => {
        const ret: { [key in FilterKeys]: boolean } & { save_search: boolean } = {
            "business-size": true,
            "health-safety-insurance": true,
            "professional-indenmnity": true,
            "region-based": true,
            "region-operation": true,
            'iwi-affiliation': true,
            'pasifika-affiliation': true,
            'category': true,
            'ownership': true,
            'public-liability-insurance': true,
            'sub-contract-insurance': true,
            'sub-contract-health-safety': true,
            save_search: true,
        };
        const asAdmin = isAdmin || isSuperAdmin || isRegionalManager;
        if (asAdmin) {
            return ret;
        }
        if (!account) {
            return null;
        }
        if (account.type === AccountType.SUPPLIER) {
            ret.save_search = false;
            ret["business-size"] = false;
        } else {
            const { plan } = subscription ?? {};
            if (!plan) {
                const buyer = account as BuyerClient;
                if (!buyer.iwiBuyer && !buyer.funderBuyer) {
                    // business without any subscription has no access to filter
                    return null;
                } else {
                    return ret;
                }
            }
            if (/autere/i.test(plan.name)) {
                ret["business-size"] = false;
                ret.save_search = false;
            }
        }
        return ret;
    }, [subscription, account, isAdmin, isSuperAdmin, isRegionalManager]);

    const prefChanged = useMemo(() => {
        if (!savedPrefValue) {
            //case 1: unsaved, compare pref and init
            if (!routeParams) {
                return !!Object.keys(prefValue.defaultFilters ?? {}).length;
            }
            return comparePrefValue(routeParams, prefValue);
        } else {
            //case 2: saved, compare pref and saved
            return comparePrefValue(savedPrefValue, prefValue);
        }
    }, [prefValue, routeParams, savedPrefValue]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                if (routeParams?.request?.hitType !== HitType.AccountName) {
                    const results: FullResult[] = (await DirectoryAPI.getSearchResults(accountType, routeParams?.request))
                        .map(r => {
                            if (!r.ownershipType) {
                                r.ownershipType = 'na';
                            }
                            const businessSize = getBusinessSize(r.size);
                            return { ...r, businessSize };
                        });
                    const sortOption: SortOption = paramNotEmpty(routeParams) ? 'name-asc' : 'date-desc';
                    selectRef.current?.updateValue(sortOption);
                    const _results = sortResult(sortOption, results);
                    setResults(_results);
                    setAllResults(_results);
                }
                if (routeParams?.defaultFilters) {
                    const _filters: SearchFilters = {
                        ...routeParams.defaultFilters,
                        category: undefined,
                    }
                    if (routeParams.defaultFilters.category) {
                        _filters.category = routeParams.defaultFilters.category;
                    }
                    setFilters(_filters);
                }
            } catch (error) {
                dispatch(showError(error.message ?? 'Failed to get results'));
            } finally {
                setLoading(false);
            }
        })();
    }, [accountType, routeParams, dispatch]);

    const goBack = () => {
        if (history.length > 2) {
            history.goBack();
        } else {
            const route = accountType === AccountType.BUYER_CLIENT ? '/buyer-clients' : '/suppliers'
            history.push(route);
        }
    }

    useEffect(() => {
        let results = [...allResults];
        const {
            iwiAffiliation,
            businessSize,
            category,
            healthAndSafty,
            ownership,
            professionalIndemnity,
            regionBased,
            regionOperation,
            pasifikaAffiliation,
            publicLiabilityInsurance,
            healthAndSafetyForSubContract,
            minimumLevelOfInsuranceForSubContract,
        } = filters;
        if (category) {
            const { catType, catId, selected } = category;
            if (!catId) {
                switch (catType) {
                    case CategoryType.MAIN:
                        //select nothing, nothing to filter
                        break;
                    case CategoryType.SUB:
                        //match current selected main
                        results = results.filter(({ categoryIds }) => selected.mainCat && categoryIds.includes(selected.mainCat.id));
                        break;
                    case CategoryType.SUB_SUB:
                        //match current selected sub, matching main can be ignored as categories are hierarchically restricted
                        results = results.filter(({ categoryIds }) => selected.subCat && categoryIds.includes(selected.subCat.id));
                        break;
                    default:
                        break;
                }
            } else {
                results = results.filter(r => r.categoryIds.includes(catId));
            }
        }
        if (healthAndSafty?.length) {
            results = results.filter(r => strarrContainsAnyOfStrarr(r.healthAndSafetyQualifications, healthAndSafty));
        }
        if (ownership?.length) {
            results = results.filter(r => ownership.includes(r.ownershipType ?? 'na'));
        }
        if (professionalIndemnity?.length) {
            results = results.filter(r => strarrContainsAnyOfStrarr(r.professionalIndemnity, professionalIndemnity));
        }
        if (regionBased?.length) {
            results = results.filter(r => regionBased.includes(r.regionBased));
        }
        if (businessSize?.length) {
            results = results.filter(r => businessSize.includes(r.businessSize));
        }
        if (regionOperation?.length) {
            results = results.filter(r => strarrContainsAnyOfStrarr(r.regions, regionOperation));
        }
        if (publicLiabilityInsurance?.length) {
            results = results.filter(r => strarrContainsAnyOfStrarr(r.publicLiabilityInsurance, publicLiabilityInsurance));
        }
        if (iwiAffiliation?.length) {
            results = results.filter(({ iwiAffiliations }) => strarrContainsAnyOfStrarr(iwiAffiliations, iwiAffiliation));
        }
        if (pasifikaAffiliation?.length) {
            results = results.filter(({ pasifikaAffiliations }) => strarrContainsAnyOfStrarr(pasifikaAffiliations, pasifikaAffiliation));
        }
        if (healthAndSafetyForSubContract?.length) {
            results = results.filter(r => strarrContainsAnyOfStrarr(r.healthAndSafetyForSubContract, healthAndSafetyForSubContract));
        }
        if (minimumLevelOfInsuranceForSubContract?.length) {
            results = results.filter(r =>
                r.minimumLevelOfInsuranceForSubContract &&
                minimumLevelOfInsuranceForSubContract.includes(r.minimumLevelOfInsuranceForSubContract)
            );
        }
        if (Object.keys(filters).length && !routeParams) {
            //changed from view-all to filter-on-sth
            const sortOption: SortOption = 'name-asc';
            selectRef.current?.updateValue(sortOption);
            setResults(sortResult(sortOption, results));
        } else {
            setResults(results)
        }
        setPrefValue({ request: routeParams?.request, defaultFilters: filters });

    }, [filters, allResults, accountType, routeParams]);

    const updateExpands = (key: FilterKeys) => {
        const _expands = { ...expandStates };
        for (const k in _expands) {
            if (k === key) {
                _expands[k] = !_expands[k];
            } else {
                _expands[k] = false;
            }
        }
        setExpandStates(_expands);
    }

    const sortOpts: { name: string, value: SortOption }[] = [
        {
            name: 'Newest - Oldest (joining date)',
            value: 'date-desc',
        },
        {
            name: 'Oldest - Newest (joining date)',
            value: 'date-asc',
        },
        {
            name: 'A - Z (company name)',
            value: 'name-asc',
        },
        {
            name: 'Z - A (company name)',
            value: 'name-desc',
        },
    ];

    const onSaveFav = () => {
        if ((searchPrefs?.length ?? 0) >= 10) {
            dispatch(showError('You can have up to 10 saved searches'));
            return;
        }
        saveSearchDialogRef.current?.show();
    }

    const onRegionOperationChange = (regionOperation: string[]) => {
        if (regionOperation.length && !regionOperation.includes('Nationwide')) {
            regionOperation = ['Nationwide', ...regionOperation];
        }
        setFilters(prev => ({ ...prev, regionOperation }));
    }

    const onBack = () => {
        const path = accountType === AccountType.SUPPLIER ? 'suppliers' : 'buyer-clients';
        history.replace(`/${path}`);
    }

    return (
        <PageContainer
            containerClassName={styles.page_container}
            className={styles.page_container}
            back
            onBackClick={onBack}
        >
            <h2>{accountType === AccountType.SUPPLIER ? 'Amotai Verified Māori and Pasifika Supplier Directory' : 'Buyer Directory'}</h2>
            {loading ?
                <Loading /> : (
                    <div className={styles.container}>
                        <div className={styles.filters_container}>
                            <div className={styles.title}>
                                Refine Search
                            </div>
                            <div className={styles.keyword}>
                                {routeParams?.request && (
                                    <>
                                        <div className={styles.subtitle}>Keyword search</div>
                                        <div className={styles.keyword_input}>
                                            <span title={routeParams.request?.name}>{routeParams.request?.name}</span>
                                            <span className={styles.back} onClick={goBack}>Change</span>
                                        </div>
                                    </>
                                )}
                                {!routeParams && <span className={styles.back} onClick={goBack}> &lt; Back to keyword search </span>}
                            </div>
                            {access?.category && <CategoryFilter
                                accountType={accountType}
                                defaultValue={routeParams?.defaultFilters?.category}
                                //containerClassName={styles.filter_section}
                                request={routeParams?.request}
                                expanded={expandStates.category}
                                onExpandClick={() => updateExpands('category')}
                                onChange={category => setFilters(prev => ({ ...prev, category }))}
                            />}
                            {access?.["region-operation"] && <CheckboxFilter
                                defaultValue={routeParams?.defaultFilters?.regionOperation}
                                //containerClassName={styles.filter_section}
                                results={results}
                                type='region-operation'
                                expanded={expandStates["region-operation"]}
                                onExpandClick={() => updateExpands('region-operation')}
                                onChange={onRegionOperationChange}
                            />}
                            {access?.["region-based"] && <CheckboxFilter
                                defaultValue={routeParams?.defaultFilters?.regionBased}
                                //containerClassName={styles.filter_section}
                                results={results}
                                type='region-based'
                                expanded={expandStates["region-based"]}
                                onExpandClick={() => updateExpands('region-based')}
                                onChange={regionBased => setFilters(prev => ({ ...prev, regionBased }))}
                            />}
                            {access?.ownership && accountType === AccountType.SUPPLIER &&
                                <CheckboxFilter
                                    defaultValue={routeParams?.defaultFilters?.ownership}
                                    //containerClassName={styles.filter_section}
                                    results={results}
                                    type='ownership'
                                    expanded={expandStates.ownership}
                                    onExpandClick={() => updateExpands('ownership')}
                                    onChange={ownership => setFilters(prev => ({ ...prev, ownership }))}
                                    fullyExpanded
                                    wrapLabel
                                />
                            }
                            {access?.["iwi-affiliation"] && accountType === AccountType.SUPPLIER &&
                                <CheckboxFilter
                                    defaultValue={routeParams?.defaultFilters?.iwiAffiliation}
                                    //containerClassName={styles.filter_section}
                                    results={results}
                                    type='iwi-affiliation'
                                    expanded={expandStates['iwi-affiliation']}
                                    onExpandClick={() => updateExpands('iwi-affiliation')}
                                    onChange={iwiAffiliation => setFilters(prev => ({ ...prev, iwiAffiliation }))}
                                    wrapLabel
                                />
                            }
                            {access?.["pasifika-affiliation"] && accountType === AccountType.SUPPLIER &&
                                <CheckboxFilter
                                    defaultValue={routeParams?.defaultFilters?.pasifikaAffiliation}
                                    //containerClassName={styles.filter_section}
                                    results={results}
                                    type='pasifika-affiliation'
                                    expanded={expandStates['pasifika-affiliation']}
                                    onExpandClick={() => updateExpands('pasifika-affiliation')}
                                    onChange={pasifikaAffiliation => setFilters(prev => ({ ...prev, pasifikaAffiliation }))}
                                    wrapLabel
                                />
                            }
                            {access?.["business-size"] && accountType === AccountType.SUPPLIER &&
                                <CheckboxFilter
                                    defaultValue={routeParams?.defaultFilters?.businessSize}
                                    //containerClassName={styles.filter_section}
                                    results={results}
                                    type='business-size'
                                    expanded={expandStates["business-size"]}
                                    onExpandClick={() => updateExpands('business-size')}
                                    onChange={businessSize => setFilters(prev => ({ ...prev, businessSize }))}
                                />
                            }
                            {access?.["health-safety-insurance"] && accountType === AccountType.SUPPLIER &&
                                <CheckboxFilter
                                    defaultValue={routeParams?.defaultFilters?.healthAndSafty}
                                    //containerClassName={styles.filter_section}
                                    results={results}
                                    type='health-safety-insurance'
                                    expanded={expandStates["health-safety-insurance"]}
                                    onExpandClick={() => updateExpands('health-safety-insurance')}
                                    onChange={healthAndSafty => setFilters(prev => ({ ...prev, healthAndSafty }))}
                                />
                            }
                            {access?.["public-liability-insurance"] && accountType === AccountType.SUPPLIER &&
                                <CheckboxFilter
                                    defaultValue={routeParams?.defaultFilters?.publicLiabilityInsurance}
                                    //containerClassName={styles.filter_section}
                                    results={results}
                                    type='public-liability-insurance'
                                    expanded={expandStates["public-liability-insurance"]}
                                    onExpandClick={() => updateExpands('public-liability-insurance')}
                                    onChange={publicLiabilityInsurance => setFilters(prev => ({ ...prev, publicLiabilityInsurance }))}
                                />
                            }
                            {access?.["professional-indenmnity"] && accountType === AccountType.SUPPLIER &&
                                <CheckboxFilter
                                    defaultValue={routeParams?.defaultFilters?.professionalIndemnity}
                                    //containerClassName={styles.filter_section}
                                    results={results}
                                    type='professional-indenmnity'
                                    expanded={expandStates["professional-indenmnity"]}
                                    onExpandClick={() => updateExpands('professional-indenmnity')}
                                    onChange={professionalIndemnity => setFilters(prev => ({ ...prev, professionalIndemnity }))}
                                />
                            }
                            {access?.["sub-contract-health-safety"] && accountType === AccountType.BUYER_CLIENT &&
                                <CheckboxFilter
                                    defaultValue={routeParams?.defaultFilters?.healthAndSafetyForSubContract}
                                    //containerClassName={styles.filter_section}
                                    results={results}
                                    type='sub-contract-health-safety'
                                    expanded={expandStates["sub-contract-health-safety"]}
                                    onExpandClick={() => updateExpands('sub-contract-health-safety')}
                                    onChange={healthAndSafetyForSubContract => setFilters(prev => ({ ...prev, healthAndSafetyForSubContract }))}
                                />
                            }
                            {access?.["sub-contract-insurance"] && accountType === AccountType.BUYER_CLIENT &&
                                <CheckboxFilter
                                    defaultValue={routeParams?.defaultFilters?.minimumLevelOfInsuranceForSubContract}
                                    //containerClassName={styles.filter_section}
                                    results={results}
                                    type='sub-contract-insurance'
                                    expanded={expandStates["sub-contract-insurance"]}
                                    onExpandClick={() => updateExpands('sub-contract-insurance')}
                                    onChange={minimumLevelOfInsuranceForSubContract => setFilters(prev => ({ ...prev, minimumLevelOfInsuranceForSubContract }))}
                                />
                            }
                        </div>
                        <div className={styles.cards_container}>
                            <div className={styles.result_titles}>
                                <div className={styles.title}>
                                    {`${results?.length ?? 0} results`}
                                </div>
                                <div className={styles.right}>
                                    {prefChanged ?
                                        access?.save_search && (
                                            <div
                                                className={classNames(styles.save_button, { [styles.save_button_disabled]: (searchPrefs?.length ?? 0) >= 10 })}
                                                onClick={onSaveFav}
                                            >
                                                Save search
                                            </div>
                                        ) :
                                        savedPrefValue && (
                                            <div className={styles.save_button_saved}>
                                                <img src={tickIcon} alt='' />
                                                <span>Filter saved</span>
                                            </div>
                                        )}
                                    <div className={styles.sort_text}>
                                        Sort by:
                                    </div>
                                    <Select
                                        ref={selectRef}
                                        selectClassName={styles.select}
                                        defaultValue={paramNotEmpty(routeParams) ? 'name-asc' : 'date-desc'}
                                        onChange={(val: SortOption) => setResults(sortResult(val, results))}
                                        options={sortOpts}
                                    />
                                </div>
                            </div>
                            <div className={styles.cards}>
                                {results?.filter(r => !r.iwiBuyer).map(r => (
                                    <DirectoryCard
                                        key={r.id}
                                        routeParams={{ ...routeParams, ...prefValue }}
                                        item={r}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            {prefValue &&
                <SaveSearchDialog
                    prefValue={{ ...prefValue, accountType }}
                    ref={saveSearchDialogRef}
                    onFinish={() => setSavedPrefValue({ ...prefValue })}
                />
            }
        </PageContainer>
    )
};

function compareStringArrays(a: string[], b: string[]) {
    if (a.length !== b.length) {
        return false;
    }
    for (const item of a) {
        if (!b.includes(item)) {
            return false;
        }
    }
    return true;
}

function comparePrefValue(base: ResultsViewRouteParams, prefValue: ResultsViewRouteParams) {
    const { defaultFilters, request } = base;
    if (!!defaultFilters !== !!prefValue.defaultFilters) {
        return true;
    }
    if (!!request !== !!prefValue.request) {
        return true;
    }
    let defaultFiltersEquals = !defaultFilters && !prefValue.defaultFilters; //default equals if both not present
    if (defaultFilters && prefValue.defaultFilters) {
        for (const key in prefValue.defaultFilters) {
            if (!!prefValue.defaultFilters[key] !== !!defaultFilters[key]) {
                defaultFiltersEquals = true;
                break;
            } else if (!prefValue.defaultFilters[key] && !defaultFilters[key]) {
                defaultFiltersEquals = false;
            } else {
                if (Array.isArray(defaultFilters[key])) {
                    //string array
                    defaultFiltersEquals = !compareStringArrays(prefValue.defaultFilters[key], defaultFilters[key]);
                } else {
                    //object
                    defaultFiltersEquals = JSON.stringify(prefValue.defaultFilters[key]) !== JSON.stringify(defaultFilters[key]);
                }
            }
        }
    }
    let requestEquals = !request && !prefValue.request; //default equals if both not present
    if (request && prefValue.request) {
        for (const key in prefValue.request) {
            requestEquals = request[key] === prefValue.request[key];
        }
    }
    return defaultFiltersEquals && requestEquals;
}

function sortResult(val: SortOption, results: FullResult[]): FullResult[] {
    const [field, order] = val.split('-');
    if (!['date', 'name'].includes(field ?? '') || !['asc', 'desc'].includes(order ?? '')) {
        console.log('Invalid option');
        return results;
    }
    const _results = [...results].sort((a, b) => {
        if (field === 'date') {
            let time_a: number;
            let time_b: number;
            try {
                time_a = new Date(a.createdAt).getTime();
            } catch (e) {
                time_a = Date.now();
            }
            try {
                time_b = new Date(b.createdAt).getTime();
            } catch (e) {
                time_b = Date.now();
            }
            if (order === 'asc') {
                return time_a - time_b;
            } else if (order === 'desc') {
                return time_b - time_a;
            }
            return 0;
        } else if (field === 'name') {
            const name_a = a.name?.trim().toLowerCase() ?? '';
            const name_b = b.name?.trim().toLowerCase() ?? '';
            if (order === 'asc') {
                return name_a.localeCompare(name_b);
            } else if (order === 'desc') {
                return name_b.localeCompare(name_a)
            }
            return 0;
        }
        return 0;
    });
    return _results;
}

function paramNotEmpty(params?: ResultsViewRouteParams): boolean {
    if (!params) {
        return false;
    }
    const { request, accountType, defaultFilters } = params;
    const emptyReq = !request || !Object.values(request).some(x => !!x);
    const emptyFil = !defaultFilters || !Object.values(defaultFilters).some(x => !!x);
    return !!accountType || !emptyFil || !emptyReq;
}

type SortOption = 'date-asc' | 'date-desc' | 'name-asc' | 'name-desc';