import createAction from "./helpers/createAction";
import { FilterFormValues } from "../../types/FilterFormValues";

export enum Types {
    SET_FILTER = "directoryFilter:SET_FILTER",
    RESET_FILTER = "directoryFilter:RESET_FILTER"
}

export const setDirectoryFilter = createAction<FilterFormValues>(Types.SET_FILTER);
export const resetDirectoryFilter = createAction<FilterFormValues>(Types.RESET_FILTER);

export const Actions = {
    [Types.SET_FILTER]: setDirectoryFilter,
    [Types.RESET_FILTER]: resetDirectoryFilter
}

export type Actions = typeof Actions;
