import classNames from "classnames";
import { Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useState } from "react";
import * as Yup from "yup";
import useDispatch from "../../../../../../hooks/useDispatch";
import { updateBusinessReferences } from "../../../../../../redux/actions/businessReference";
import { showError } from "../../../../../../redux/actions/snackbars";
import { updateMyAccount } from "../../../../../../redux/actions/users";
import Address from "../../../../../../types/Address";
import { AccountType } from "../../../../../../types/AmotaiAccount";
import AmotaiUser, { AccountRole } from "../../../../../../types/AmotaiUser";
import BusinessReference from "../../../../../../types/BusinessReference";
import Supplier from "../../../../../../types/Supplier";
import {
    HEALTH_AND_SAFETY,
    LEGAL_STRUCTURE,
    NATIONWIDE_REGION,
    REGIONS,
    REGIONS_NO_NATIONWIDE
} from "../../../../../../util/constants";
import Button from "../../../../../widgets/button/Button";
import BusinessReferenceFieldArray from "../../../../../widgets/formikFields/BusinessReferenceFieldArray";
import FormikAddressInput from "../../../../../widgets/formikFields/FormikAddressInput";
import FormikCheckboxGroup from "../../../../../widgets/formikFields/FormikCheckboxGroup";
import FormikInput from "../../../../../widgets/formikFields/FormikInput";
import FormikSelect from "../../../../../widgets/formikFields/FormikSelect";
import Loading from "../../../../../widgets/loading/Loading";
import SectionHeader from "../../../../../widgets/sectionHeader/SectionHeader";
import AccountHeader from "../../../component/AccountHeader";
import styles from "../BusinessDetails.module.scss";

type SupplierOverviewProps = {
    account: Supplier,
    me: AmotaiUser;
    businessReferences: BusinessReference[];
};

export type SupplierFormValues = {
    name: string;
    legalStructure: string;
    legalName: string;
    yearEstablished: string;
    nzCompaniesNumber: string;
    nzbn: string;
    regions: string[];
    logo: string;
    description: string;
    postalAddress: string,
    physicalAddress: string,
    physicalAddressComponents: Address;
    healthAndSafetyQualifications: string[];
    healthAndSafetyIncidences: string;
    businessReferences: BusinessReference[];
    regionBased: string;
};

const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    legalStructure: Yup.string().required("Required"),
    legalName: Yup.string().required("Required"),
    yearEstablished: Yup.string().required("Required"),
    nzCompaniesNumber: Yup.string().required("Required"),
    postalAddress: Yup.string().required("Required"),
    physicalAddress: Yup.string().required("Required"),
    physicalAddressComponents: Yup.object().nullable(),
    nzbn: Yup.string().required("Required"),
    regions: Yup.array().required("Required").min(1, "At least 1 is required"),
    logo: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    healthAndSafetyQualifications: Yup.string().required("Required").min(1),
    healthAndSafetyIncidences: Yup.string().required("Required"),
    businessReferences: Yup.array().of(Yup.object({
        projectName: Yup.string(),
        client: Yup.string(),
        contactName: Yup.string(),
        contactEmail: Yup.string().email(),
        contactPhoneNumber: Yup.string(),
    })).min(1, "At least one reference is required"),
    regionBased: Yup.string().required("Required")
});

const renderYearOptions = () => {
    const years = [];

    for (let i = moment().year(); i > 1800; i--) {
        years.push(i.toString());
    }

    return years;
};

export default function SupplierOverview(props: SupplierOverviewProps) {
    const { account, businessReferences, me } = props;
    const [editing, setEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const initialValues = {
        name: account.name || "",
        legalStructure: account.legalStructure || "",
        legalName: account.legalName || "",
        yearEstablished: account.yearEstablished || "",
        nzCompaniesNumber: account.nzCompaniesNumber || "",
        nzbn: account.nzbn || "",
        regions: account.regions || [],
        turnoverInLastFinancialYear: account.turnoverInLastFinancialYear || "",
        professionalIndemnity: account.professionalIndemnity || "",
        publicLiabilityInsurance: account.publicLiabilityInsurance || "",
        disclosures: account.disclosures || "",
        logo: account.logo || "",
        postalAddress: account.postalAddress || "",
        physicalAddress: account.physicalAddress || "",
        physicalAddressInput: account.physicalAddressComponents?.formattedAddress ?? "",
        physicalAddressComponents: account.physicalAddressComponents || null,
        description: account.description || "",
        healthAndSafetyQualifications: account.healthAndSafetyQualifications || [],
        healthAndSafetyIncidences: account.healthAndSafetyIncidences || "",
        businessReferences: businessReferences || [],
        regionBased: account.regionBased || ""
    };

    const onSubmit = async (values: SupplierFormValues) => {
        try {
            setLoading(true);
            let updatingRegions = [...values.regions];
            if (values.regions.includes(NATIONWIDE_REGION)) {
                updatingRegions = [NATIONWIDE_REGION];
            }
            const payload = { ...account, ...values };
            payload.regions = updatingRegions;
            await dispatch(updateMyAccount(account.id, payload, AccountType.SUPPLIER));
            const references = values.businessReferences.map(r => ({ ...r, account: account.id }));
            await dispatch(updateBusinessReferences(account.id, references));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    const toggleEdit = async (formikBag: FormikProps<SupplierFormValues>) => {
        if (editing && (formikBag.dirty || formikBag.touched.regions || formikBag.touched.healthAndSafetyQualifications)) {
            await onSubmit(formikBag.values);
        }

        setEditing(!editing);
        if (!editing) {
            formikBag.resetForm();
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <Formik<SupplierFormValues> initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}>
                {(formikBag) => {
                    let actionText = "Edit";
                    if (editing) {
                        if (formikBag.dirty || formikBag.touched.regions || formikBag.touched.healthAndSafetyQualifications) {
                            actionText = "Save";
                        } else {
                            actionText = "Cancel";
                        }
                    }

                    return (
                        <form onSubmit={formikBag.handleSubmit}>
                            <AccountHeader title={account.name}
                                subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                                action={me?.accountRole === AccountRole.ADMIN && (
                                    <Button uppercase
                                        onClick={() => toggleEdit(formikBag)}>{actionText}</Button>
                                )}
                            />
                            <div className={styles.form}>
                                <div className={styles.form_section}>
                                    <SectionHeader title={"Business Details"} />

                                    <FormikInput name={"name"}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                        disabled={!editing}
                                        label={"Trading name of business"} />
                                    <FormikSelect name={"legalStructure"}
                                        noPadding
                                        containerClassName={styles.half_input} disabled={!editing}
                                        label={"Structure"}
                                        options={LEGAL_STRUCTURE} />
                                    <FormikInput name={"legalName"}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                        disabled={!editing}
                                        label={"Legal name of business"} />
                                    <FormikSelect name={"yearEstablished"} noPadding
                                        containerClassName={styles.half_input} disabled={!editing}
                                        label={"Year Established"}
                                        options={renderYearOptions()} />

                                    <FormikInput name={"nzCompaniesNumber"}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                        disabled={!editing}
                                        label={"NZ Companies no."} />
                                    <FormikInput name={"nzbn"}
                                        inputClassname={styles.half_input} disabled={!editing}
                                        placeholder={"12345678"}
                                        label={"NZ Business no."} />
                                    <FormikInput name='postalAddress'
                                        label={"Postal Address"}
                                        disabled={!editing}
                                        inputClassname={styles.full_input} />
                                    {/*<FormikInput name={"physicalAddress"}*/}
                                    {/*             inputClassname={styles.full_input}*/}
                                    {/*             disabled={!editing}*/}
                                    {/*             placeholder={"1 Example St, Auckland, 0610, New Zealand"}*/}
                                    {/*             label={"Physical Address Old"}*/}
                                    {/*/>*/}
                                    <FormikAddressInput name={"physicalAddressComponents"}
                                        formattedName={"physicalAddressInput"}
                                        label={"Physical Address"}
                                        disabled={!editing}
                                        placeholder={"1 Example St, Auckland, 0610, New Zealand"}
                                        inputClassname={styles.full_input} />
                                </div>
                                <div className={styles.form_section}>
                                    <SectionHeader title={"Regions"} />
                                    <div className={styles.checkbox_container}>
                                        <div className={styles.heading}>
                                            <label>Regions of operation</label>
                                        </div>
                                        {formikBag.values.regions.includes(NATIONWIDE_REGION) ?
                                            <FormikCheckboxGroup name={"regions"} disabled={!editing} stringItems={[NATIONWIDE_REGION]} /> :
                                            <FormikCheckboxGroup name={"regions"} disabled={!editing} stringItems={REGIONS} />}
                                    </div>

                                    <div className={styles.checkbox_container}>
                                        <div className={styles.heading}>
                                            <label>Region based</label>
                                        </div>
                                        <FormikSelect name={"regionBased"} disabled={!editing} options={REGIONS_NO_NATIONWIDE} />
                                    </div>
                                </div>
                                <div className={styles.form_section}>
                                    <SectionHeader title={"Project references"} />
                                    <BusinessReferenceFieldArray disabled={!editing} />
                                </div>
                                <div className={classNames(styles.form_section, styles.health_safety_container)}>
                                    <SectionHeader title={"Health and Safety qualifications"} />
                                    <FormikCheckboxGroup name={"healthAndSafetyQualifications"}
                                        disabled={!editing}
                                        stringItems={HEALTH_AND_SAFETY} />
                                    <FormikInput name={"healthAndSafetyIncidences"} disabled={!editing} multiline
                                        inputClassname={styles.full_text_input}
                                        label={"Any major health and safety incidences? If yes, please explain"} />
                                </div>
                            </div>
                            {editing && (formikBag.dirty || formikBag.touched.regions || formikBag.touched.healthAndSafetyQualifications) && <Button type={"submit"}>Save</Button>}

                        </form>
                    );
                }}
            </Formik>
        </>
    );
}
