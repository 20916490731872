import createAction from "./helpers/createAction";
import OldSupplierAccount from "../../types/OldSupplierAccount";
import wrapper from "./helpers/wrapper";
import OldSupplierAccountAPI from "./api/oldSupplierAccount";

export enum Types {
    SET_OLD_SUPPLIER_ACCOUNT = "oldSupplierAccount:SET_OLD_SUPPLIER_ACCOUNT"
}

export const setOldSupplierAccount = createAction<OldSupplierAccount>(Types.SET_OLD_SUPPLIER_ACCOUNT);

export const Actions = {
    [Types.SET_OLD_SUPPLIER_ACCOUNT]: setOldSupplierAccount
};

export type Actions = typeof Actions;

export const getOldSupplierAccount = wrapper(OldSupplierAccountAPI.getOldSupplier, {
    action: setOldSupplierAccount
});
