import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { PersistConfig } from "redux-persist/es/types";
import storage from "redux-persist/lib/storage";
import businessContacts from "./businessContacts";
import businessOwners from "./businessOwners";
import businessReferences from "./businessReferences";
import cards from "./cards";
import categories from "./categories";
import directory from "./directory";
import directoryFilter from "./directoryFilter";
import events from "./events";
import oldSupplierAccount from "./oldSupplierAccount";
import policy from "./policy";
import posts from "./posts";
import reporting from "./reporting";
import saaSPlans from "./saaSPlans";
import snackbars from "./snackbars";
import tables from "./tables";
import users from "./users";

const reducers = {
    businessContacts,
    businessOwners,
    businessReferences,
    cards,
    categories,
    directory,
    directoryFilter,
    events,
    oldSupplierAccount,
    policy,
    posts,
    reporting,
    saaSPlans,
    snackbars,
    tables,
    users,
};

const PERSIST_CONFIG: PersistConfig<any> = {
    key: "root",
    storage,
    whitelist: ["users"],
};

const root = combineReducers(reducers);
const persistedReducer = persistReducer(PERSIST_CONFIG, root);

export type ApplicationState = ReturnType<typeof root>;

export default persistedReducer;
