import { InputBase, InputLabel } from "@material-ui/core";
import classNames from "classnames";
import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";
import ReactTooltip from "react-tooltip";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";
import styles from "./Input.module.scss";

type InputProps = {
    containerClassname?: string,
    inputClassName?: string,
    label?: string,
    tooltip?: string,
    placeholder?: string,
    defaultValue?: string,
    onChange?: (value: string) => void,
    multiline?: boolean,
    required?: boolean,
    value?: number | string,
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}
export interface InputRef {
    reset: () => void;
}

function Input(props: InputProps, ref: Ref<InputRef>) {
    const { containerClassname, inputClassName, label, placeholder, defaultValue, onChange, multiline, value, onClick } = props;
    const [myValue, setMyValue] = useState<string | number>();
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const text: string = e.target.value;
        setMyValue(text);
        onChange?.(text)
    }
    const className = classNames(styles.padding, containerClassname);
    const inputClass = classNames(styles.input, inputClassName);
    useEffect(() => {
        setMyValue(value);
    }, [value]);

    useImperativeHandle(ref, () => ({
        reset: () => setMyValue(undefined),
    }))

    return (
        <div className={className}>
            {label &&
                <div className={styles.label_tooltip_container}>

                    <InputLabel shrink htmlFor={"input"} className={styles.label}>
                        {label}
                    </InputLabel>
                    {props.tooltip && (
                        <div data-tip={props.tooltip}>
                            <img src={Tooltip} alt={"Help"} />
                        </div>
                    )}

                </div>
            }
            <InputBase defaultValue={defaultValue} id={"input"} required={props.required}
                placeholder={placeholder} value={myValue ?? ''} onChange={handleChange} onClick={onClick}
                classes={{ input: inputClass }} multiline={multiline} fullWidth rows={3} rowsMax={12} />
            <ReactTooltip
                place={"top"}
                effect={"solid"}
                multiline
                backgroundColor={"#F1F1F2"}
                borderColor={"#E6E7E8"}
                textColor={"#000000"}
                clickable
            />
        </div>
    )
}
export default forwardRef(Input);