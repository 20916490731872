
import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { sanity } from "src/util/SanityAPI";

const imageBuilder = imageUrlBuilder(sanity);

const sanityImageUrl = (source: SanityImageSource) => {
    const url = imageBuilder.image(source).url();
    if (url) {
        return `${url}?auto=format`;
    }
    return '';
};

export default sanityImageUrl;
