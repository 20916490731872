import React, { useCallback } from "react";
import { InputLabel, TextField, TextFieldProps } from "@material-ui/core";
import { isNaN, useField, useFormikContext } from "formik";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import styles from "../input/Input.module.scss";
import Tooltip from "../../../resources/images/svg/tooltip-icon.svg";

type InputProps = {
    name: string;
    inputClassname?: string,
    noPadding?: boolean,
    label?: string,
    onKeyDown?: (e: React.KeyboardEvent) => void;
    tooltip?: string,
    autoComplete?: string,
    placeholder?: string,
    width?: number,
    height?: number,
    mr?: number,
} & Omit<TextFieldProps, "name" | "value" | "error">;

export default function FormikInput(props: InputProps) {
    const { name, inputClassname, noPadding, label, ...otherProps } = props;
    const { isSubmitting } = useFormikContext();
    const fieldProps = useField(name);

    const [field, meta] = fieldProps;
    let { value } = field;
    const fieldError = meta.error;
    const showError = meta.touched && !!fieldError;

    const disabled = otherProps?.disabled || isSubmitting;
    const helperText = showError ? fieldError : otherProps?.helperText;

    const className = classNames(styles.padding, { [styles.noPadding]: noPadding }, inputClassname);
    const inputClass = classNames(styles.input)

    const InputProps = {
        "classes": { root: inputClass }
    }

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            props.onKeyDown?.(e);
        },
        [props.onKeyDown],
    );

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);
            props.onChange?.(e);
        },
        [field, props.onChange],
    );

    value = isNaN(value) ? "" : value ?? "";
    return (
        <div className={className} style={{ width: props.width, marginRight: props.mr }}>
            {label && (
                <div className={styles.label_tooltip_container}>
                    <InputLabel shrink htmlFor={"input"}>
                        {label}
                    </InputLabel>
                    {props.tooltip && (
                        <div data-tip={props.tooltip}>
                            <img src={Tooltip} alt={"Help"} />
                        </div>
                    )}
                </div>
            )}

            <TextField
            classes={{ root: styles.formik_input }}
            {...otherProps}
            {...field}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
            error={showError}
            disabled={disabled}
            helperText={helperText} />
            <ReactTooltip
                place={"top"}
                effect={"solid"}
                multiline={true}
                backgroundColor={"#F1F1F2"}
                borderColor={"#E6E7E8"}
                textColor={"#000000"}
                clickable
            />
        </div>
    );
}
