import { CategoryFilterProps } from 'src/components/pages/directory/filters/CategoryFilter';
import { AccountType } from 'src/types/AmotaiAccount';
import { BusinessSize } from 'src/util/constants';
import { PlanResult } from './SaaSPlan';
import { OwnershipType } from './Supplier';

export interface SearchSuggestion {
    id: number;
    hitType: HitType;
    name: string;
}

export type SearchFilters = {
    category?: CategoryFilterProps,
    regionOperation?: string[],
    ownership?: string[],
    healthAndSafty?: string[],
    professionalIndemnity?: string[],
    regionBased?: string[],
    businessSize?: string[],
    iwiAffiliation?: string[],
    pasifikaAffiliation?: string[],
    publicLiabilityInsurance?: string[],
    healthAndSafetyForSubContract?: string[],
    minimumLevelOfInsuranceForSubContract?: string[],
}

export type FilterKeys =
    'region-based' |
    'region-operation' |
    'ownership' |
    'health-safety-insurance' |
    'professional-indenmnity' |
    'business-size' |
    'iwi-affiliation' |
    'pasifika-affiliation' |
    'public-liability-insurance' |
    'sub-contract-insurance' |
    'sub-contract-health-safety' |
    'category';

export enum HitType {
    Category = 'Category',
    SubCategory = 'SubCategory',
    SubSubCategory = 'SubSubCategory',
    AccountName = 'AccountName',
}

export interface AccountResult {
    id: number;
    name: string;
    type: AccountType;
    regions?: string[];
    createdAt: string;
    regionBased: string;
    categoryIds: number[];
    iwiAffiliations?: string[];
    pasifikaAffiliations?: string[]; //supplier only
    size: number;
    healthAndSafetyQualifications?: string[]; //supplier only
    publicLiabilityInsurance?: string[]; //supplier only
    professionalIndemnity?: string[]; //supplier only
    ownershipType?: OwnershipType | 'na'; //supplier only
    healthAndSafetyForSubContract?: string[]; //buyer only
    minimumLevelOfInsuranceForSubContract?: string; //buyer only
    iwiBuyer?: boolean; //buyer only
    funderBuyer?: boolean; //buyer only
    plan?: PlanResult //buyer only
}

export type FullResult = {
    businessSize: BusinessSize,
} & AccountResult;