import React, { useEffect, useMemo, useState } from "react";
import useDispatch from "src/hooks/useDispatch";
import { getEvents } from "src/redux/actions/events";
import AmotaiEvent from "src/types/AmotaiEvent";
import SanityAPI from "src/util/SanityAPI";
import EventCard from "../../widgets/event-card/EventCard";
import Filter, { IFilterFields } from "../../widgets/filter/Filter";
import PageContainer from "../../widgets/pageContainer/PageContainer";
import PageLoader from "../../widgets/pageLoader/PageLoader";
import Section from "../../widgets/section/Section";
import styles from "./Events.module.scss";

export default function Events() {
    const [events, setEvents] = useState<AmotaiEvent[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [filterFields, setFilterFields] = useState<IFilterFields>();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            setLoading(true);
            let _events: AmotaiEvent[] = events;
            const sorter = (a: AmotaiEvent, b: AmotaiEvent) => {
                const edate = '2001-01-01';
                return new Date(a.details?.startedAt ?? edate).getTime() - new Date(b.details?.startedAt ?? edate).getTime();
            }
            if (filterFields?.region || filterFields?.keyword) {
                const { region, keyword } = filterFields;
                _events = (await SanityAPI.searchEvents(keyword, region)).sort(sorter);
            } else {
                _events = (await dispatch(getEvents())).sort(sorter);
            }
            setEvents(_events.filter(e => e.details?.startedAt && new Date(e.details.startedAt).getTime() >= Date.now()));
            setLoading(false);
        })();
        // eslint-disable-next-line
    }, [filterFields]);

    const displayEvents: AmotaiEvent[] = useMemo(() => {
        if (!events || events.length === 0) {
            return [];
        }
        const featuredEvent = events.find(e => e.featured) ?? events[0];
        featuredEvent.featured = true;
        let rest = events.filter(e => e.slug !== featuredEvent.slug);
        //sort on rest if available
        switch (filterFields?.orderByField) {
            case "date":
                rest = rest.sort(sortByDate);
                break;
            case "name":
                rest = rest.sort(sortByName);
                break;
            default:
                break;
        }
        return [featuredEvent, ...rest]
    }, [events, filterFields]);

    if (loading) {
        return <PageLoader />;
    }

    return (
        <PageContainer containerClassName={styles.events}>
            <h2>Events</h2>
            <Section contentClass={styles.events_content}>
                <Filter setFilterFields={setFilterFields} isEvent withRegion filterFields={filterFields} />
                <div className={styles.events_right}>
                    {displayEvents.length === 0 ? (
                        <div style={{ textAlign: "center" }}>There are currently no events. Please check back
                            later.</div>
                    ) : (
                            <div className={styles.events_listings}>
                                {displayEvents.map(event =>
                                    <EventCard key={event.slug} event={event}
                                        featured={event.featured} />
                                )}
                            </div>
                        )}
                </div>
            </Section>
        </PageContainer>
    );
}

function sortByDate(e1: AmotaiEvent, e2: AmotaiEvent) {
    if (!e1 || !e2) {
        return 0;
    }
    const d1 = e1.details?.startedAt;
    const d2 = e2.details?.startedAt;
    if (!d1) return -1;
    if (!d2) return 1;
    return new Date(d1).getTime() - new Date(d2).getTime();
};

function sortByName(e1: AmotaiEvent, e2: AmotaiEvent) {
    if (!e1 || !e2) {
        return 0;
    }
    return e1.title.toLowerCase().localeCompare(e2.title.toLowerCase());
}
