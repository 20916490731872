import SaaSPlan from "src/types/SaaSPlan";
import SaaSSubscription from "src/types/SaaSSbscription";
import SaaSPlanAPI from "./api/saaSPlans";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_PLANS = 'saaSPlans:SET_PLANS',
    SET_SUBSCRIPTION = 'saaSPlans:SET_SUBSCRIPTION'
}

export const setSaaSPlans = createAction<SaaSPlan[]>(Types.SET_PLANS);
export const setSubscription = createAction<SaaSSubscription>(Types.SET_SUBSCRIPTION);

export const Actions = {
    [Types.SET_PLANS]: setSaaSPlans,
    [Types.SET_SUBSCRIPTION]: setSubscription
}

export type Actions = typeof Actions;

export const getActiveSaaSPlans = wrapper(SaaSPlanAPI.getActivePlans, {
    action: setSaaSPlans
});
export const getAccountSubscription = wrapper(SaaSPlanAPI.getAccountSubscription, {
    action: setSubscription
});
export const createOrUpdateSubscription = wrapper(SaaSPlanAPI.createOrUpdateSubscription, {
    action: setSubscription
});
export const getCustomizedPlans = wrapper(SaaSPlanAPI.getCustomizedPlans, {
    action: setSaaSPlans
});