import React from "react";
import styles from "./OnboardingSidebar.module.scss";
import Logo from "../../../resources/images/svg/amotai-logo-white.svg";
import classNames from "classnames";

type OnboardingSidebarProps = {
    containerClassName?: string;
    classNameLeft?: string,
    sidebarImage?: any,
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default function OnboardingSidebar(props:OnboardingSidebarProps){

    const{
        children,
        sidebarImage,
        classNameLeft,
    }=props

    const leftClassName = classNames(styles.inner_left, classNameLeft);


    return(
        <div className={leftClassName} style={{ backgroundImage: `url(${sidebarImage})`}}>
            <img src={Logo} alt={"Amotai"} className={styles.logo} />
            {children}
        </div>
    )
}