import { orderBy } from "lodash";
import moment from "moment";
import React, { ReactNode, useCallback, useRef, useState } from "react";
import useDispatch from "../../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../../hooks/useMountEffect";
import useSelector from "../../../../../../hooks/useSelector";
import { createOrUpdateSubscription, getActiveSaaSPlans } from "../../../../../../redux/actions/saaSPlans";
import { showError } from "../../../../../../redux/actions/snackbars";
import Arrow from "../../../../../../resources/images/svg/arrow-down-dark.svg";
import AmotaiAccount, { PaymentType } from "../../../../../../types/AmotaiAccount";
import SaaSPlan from "../../../../../../types/SaaSPlan";
import SaaSSubscription, { SaaSSubscriptionStatus, SubscriptionPaymentType } from "../../../../../../types/SaaSSbscription";
import Button from "../../../../../widgets/button/Button";
import Dialog, { DialogRef } from "../../../../../widgets/dialog/Dialog";
import Loading from "../../../../../widgets/loading/Loading";
import PlanCard from "../../../component/PlanCard";
import styles from "../Billing.module.scss";

export default function SaaSPlans() {
    const [loading, setLoading] = useState<boolean>(true);
    const [showPlans, setShowPlans] = useState<boolean>(false);
    const [newPlan, setNewPlan] = useState<SaaSPlan>();

    const planDialogRef = useRef<DialogRef>(null);

    const dispatch = useDispatch();
    const saasPlans = useSelector<SaaSPlan[]>(state => state.saaSPlans.saaSPlans);
    const subscription = useSelector<SaaSSubscription>(state => state.saaSPlans.subscription!);
    const account = useSelector<AmotaiAccount>(state => state.users.account!);

    useMountEffect(async () => {
        await dispatch(getActiveSaaSPlans());
        setLoading(false);
    });

    const onSubscribeOrUpdatePlan = useCallback((plan: SaaSPlan) => {
        setNewPlan(plan);
        planDialogRef.current?.show();
    }, [planDialogRef, setNewPlan]);

    const togglePlans = () => setShowPlans(!showPlans);

    const renderPlans = useCallback((): ReactNode => {
        const getInTouch = () => {
            window.location.href = 'mailto:kiaora@amotai.nz';
        };
        const renderBtn = (plan: SaaSPlan): ReactNode => {
            let text = "SUBSCRIBE";
            if (subscription) {
                const currentPlan = subscription.plan;
                if (subscription.subscriptionPaymentType === SubscriptionPaymentType.INVOICE) {
                    let invoiceButton: ReactNode = null;
                    if (currentPlan.id === plan.id) {
                        invoiceButton = <Button disabled>Current plan</Button>;
                    } else {
                        invoiceButton = <Button borderwhite onClick={getInTouch}>Get in touch</Button>
                    }
                    return invoiceButton;
                }

                const { upgradePlan } = subscription;

                const isUpgrade = currentPlan?.amount! < plan.amount;

                if (currentPlan.id === plan.id) {
                    let action = `Renews on ${moment(subscription.termEndsAt).format("DD/MM/YYYY")}`;

                    if (upgradePlan && currentPlan.amount < upgradePlan.amount) {
                        action = "UPGRADING";
                    }

                    if (upgradePlan && currentPlan.amount > upgradePlan.amount) {
                        action = "DOWNGRADING";
                    }

                    return <Button>{action}</Button>;
                }

                if (isUpgrade) {
                    text = "UPGRADE";
                    if (upgradePlan && plan.id === upgradePlan.id) {
                        text = `UPGRADES ON ${moment(subscription.termEndsAt).format("DD/MM/YYYY")}`;
                        return (
                            <Button borderwhite
                                disabled
                                onClick={() => onSubscribeOrUpdatePlan(plan)}>{text}</Button>
                        );
                    }
                } else {
                    text = "DOWNGRADE";
                    if (upgradePlan && plan.id === upgradePlan.id) {
                        text = `DOWNGRADES ON ${moment(subscription.termEndsAt).format("DD/MM/YYYY")}`;
                        return (
                            <Button borderwhite
                                disabled
                                onClick={() => onSubscribeOrUpdatePlan(plan)}>{text}</Button>
                        );
                    }
                }

                if (subscription.status === SaaSSubscriptionStatus.CANCELLING) {
                    return (
                        <Button disabled
                            borderwhite>
                            Cancelling on {moment(subscription.termEndsAt).format("DD MMMM yyyy")}
                        </Button>
                    );
                }
            }

            return <Button borderwhite onClick={() => onSubscribeOrUpdatePlan(plan)}>{text}</Button>;
        };
        return orderBy(saasPlans, ["amount"], ["asc"]).map(plan => (
            <PlanCard key={plan.id}
                plan={plan}
                showAnnualPrice={subscription.subscriptionPaymentType === SubscriptionPaymentType.INVOICE}
                actions={(<div className={styles.plan_btn}>{renderBtn(plan)}</div>)} />
        ));
    }, [subscription, saasPlans, onSubscribeOrUpdatePlan]);

    const updatePlan = useCallback(async (updateAfterTerm: boolean) => {
        planDialogRef.current?.hide();
        if (!newPlan) return;
        try {
            setLoading(true);
            await dispatch(createOrUpdateSubscription(account.id!, newPlan.id, updateAfterTerm));
        } catch (error) {
            dispatch(showError(error.message));
        }
        setLoading(false);
    }, [newPlan, planDialogRef, account, dispatch, setLoading]);

    const subscribeNewPlan = useCallback(async () => {
        planDialogRef.current?.hide();
        if (!newPlan) return;
        try {
            setLoading(true);
            await dispatch(createOrUpdateSubscription(account.id, newPlan.id, false));
        } catch (error) {
            dispatch(showError(error.message));
        }
        setLoading(false);
    }, [newPlan, planDialogRef, setLoading, account, dispatch]);

    const cancelPlanUpdate = useCallback(() => {
        setNewPlan(undefined);
        planDialogRef.current?.hide();
    }, [planDialogRef]);

    const renderNewPlanButtons = useCallback((): ReactNode => {
        if (!newPlan) return null;
        const currentPlan = subscription?.plan;
        if (currentPlan) {
            if (currentPlan.amount < newPlan.amount) {
                // text = `UPGRADE`;
                return (
                    <>
                        <Button borderwhite
                            onClick={() => updatePlan(true)}>UPGRADE AT END OF TERM</Button>
                        <Button plainLink onClick={cancelPlanUpdate}>Cancel</Button>
                    </>
                );
            }
            return (
                <>
                    <Button borderwhite
                        onClick={() => updatePlan(true)}>DOWNGRADE AT END OF TERM</Button>
                    <Button plainLink onClick={cancelPlanUpdate}>Cancel</Button>
                </>
            );

        }
        return (
            <>
                <Button borderwhite onClick={subscribeNewPlan}>SUBSCRIBE</Button>
                <Button plainLink onClick={cancelPlanUpdate}>Cancel</Button>
            </>
        );

    }, [newPlan, subscription, cancelPlanUpdate, subscribeNewPlan, updatePlan]);

    const renderPlanContent = () => {
        if (!subscription && account.paymentType === PaymentType.INVOICE) {
            return (
                <div className={styles.view_plans}>
                    Your subscription is on an annual invoice plan, please contact Amotai support to manage your
                    plan or to request payment history.
                </div>
            )
        }

        return (
            <>
                {loading ? <Loading /> : (
                    <div className={styles.view_plans}>
                        <Button className={styles.show_plans_button}
                            borderdark
                            onClick={togglePlans}>
                            View plans <img src={Arrow} alt={"arrow"} />
                        </Button>
                        {showPlans && (
                            <div className={styles.plans}>
                                {renderPlans()}
                            </div>
                        )}
                    </div>
                )}
            </>
        )
    }

    return (
        <>
            {renderPlanContent()}
            <Dialog ref={planDialogRef} className={styles.plan_dialog}>
                {newPlan && (
                    <PlanCard plan={newPlan} label={`Upgrade to ${newPlan.name}`} className={styles.plan_card} actions={
                        <div className={styles.update_plan_btns}>{renderNewPlanButtons()}</div>
                    } />
                )}
            </Dialog>
        </>
    );
}
