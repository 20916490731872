import firebase from "firebase/app";
import "firebase/auth";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "src/components/widgets/button/Button";
import { SystemRole } from "src/types/AmotaiUser";
import * as Yup from "yup";
import useDispatch from "../../../hooks/useDispatch";
import useMountEffect from "../../../hooks/useMountEffect";
import { showError, showSuccess } from "../../../redux/actions/snackbars";
import { getMyUser, reinviteToAccount } from "../../../redux/actions/users";
import SideBar from "../../../resources/images/png/onboarding-amotai-sidebar@2x.png";
import qs from "../../../util/qs";
import FormikInput from "../../widgets/formikFields/FormikInput";
import OnboardingContent from "../../widgets/onboardingContent/OnboardingContent";
import OnboardingSidebar from "../../widgets/onboardingSidebar/OnboardingSidebar";
import styles from "./UserInvited.module.scss";

type ConfirmEmailValues = {
    password: string,
    confirmPassword: string
};

const validationSchema = Yup.object().shape({
    password: Yup.string().required("Required"),
    confirmPassword: Yup.string()
        .required("Required")
        .oneOf([Yup.ref("password"), null], "Passwords don't match")
});

export default function UserInvited() {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const [expired, setExpired] = useState<boolean>(false);
    const { email } = qs.parse(window.location.search);
    useMountEffect(() => {
        if (!firebase.auth().isSignInWithEmailLink(window.location.href)) {
            history.push("/");
        }
    });
    const onGetNewLinkClick = async () => {
        await dispatch(reinviteToAccount(decodeURIComponent(email))).then(() => {
            dispatch(showSuccess("Invitation email has been re-sent"));
        });
    };

    const onLoginSubmit = async (values: ConfirmEmailValues) => {

        setLoading(true);

        try {
            const userRecord = await firebase.auth().signInWithEmailLink(decodeURIComponent(email), window.location.href);
            await userRecord.user?.updatePassword(values.password);
            const amotaiUser = await dispatch(getMyUser());
            if (amotaiUser?.systemRole === SystemRole.SUPPLIER_VIEWER) {
                history.push("/suppliers");
            } else {
                history.push("/account/my-profile");
            }
        } catch (e) {
            dispatch(showError(e.message));
            setExpired(true);
            setLoading(false);
        }
    };

    return (
        <div className={styles.login_container}>
            <OnboardingSidebar sidebarImage={SideBar}>
                <div className={styles.sidebar_content_container}>
                    <div>
                        <h3>
                            You’re just a few clicks away from being connected to our Amotai Supplier and Member database
                        </h3>
                    </div>
                </div>

            </OnboardingSidebar>
            <OnboardingContent>
                {!expired && (
                    <div className={styles.login_content_container}>
                        <h5>Nau Mai, Haere Mai</h5>
                        <p>
                            Welcome to Amotai, Supplier Diversity Aotearoa. Create a password for your account below to
                            get started.
                        </p>
                        <div className={styles.inner_container}>
                            <Formik<ConfirmEmailValues> initialValues={{
                                password: "",
                                confirmPassword: ""
                            }} onSubmit={onLoginSubmit} validationSchema={validationSchema}>
                                {(formProps) => (
                                    <Form onSubmit={formProps.handleSubmit}>
                                        <div className={styles.login_form}>
                                            <FormikInput name={"password"} label={"Create Password"}
                                                type={"password"} />
                                            <FormikInput name={"confirmPassword"} label={"Re-enter Password"}
                                                type={"password"} />
                                        </div>
                                        <div className={styles.button_container}>
                                            <Button type={"submit"} className={styles.button_container}
                                                loading={loading}>
                                                Set password
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                )}
                {expired && (
                    <div className={styles.login_content_container}>
                        <h5>Password link expired</h5>
                        <p>
                            Looks like the password reset link you received was sent over an hour ago and has now
                            expired. Would you like to receive a new link for the following address?
                        </p>
                        <p className={styles.email_label}>
                            Email address
                        </p>
                        <div className={styles.email_container}>
                            <p>
                                {decodeURIComponent(email)}
                            </p>
                            <Button onClick={onGetNewLinkClick} borderdark className={styles.get_new_link_button}>
                                Get new link
                            </Button>
                        </div>
                    </div>
                )}
            </OnboardingContent>
        </div>
    );
};

