import { Category } from "src/types/Category";
import CategoryAPI from "./api/category";
import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

export enum Types {
    SET_MAIN_CATEGORIES = "categories:SET_MAIN_CATEGORIES",
}

export const setMainCategories = createAction<Category[]>(Types.SET_MAIN_CATEGORIES);

export const Actions = {
    [Types.SET_MAIN_CATEGORIES]: setMainCategories,
}

export type Actions = typeof Actions;

export const getMainCategories = wrapper(CategoryAPI.getMainCategories, {
    action: setMainCategories
});