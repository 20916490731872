import { Category, SubCategory, SubSubCategory } from "src/types/Category";
import { Actions, Types } from "../actions/categories";

const initialState: {
    main: Category[],
    sub: SubCategory[],
    subSub: SubSubCategory[],
} = {
    main: [],
    sub: [],
    subSub: []
};

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function categories(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_MAIN_CATEGORIES: {
            const { payload } = action as ActionValueTypes<Types.SET_MAIN_CATEGORIES>;

            if (!payload?.length) {
                return {
                    ...state
                };
            }
            const main = payload;
            const sub = payload.map(m => m.subCategories ?? []).flat() ?? [];
            const subSub = sub.map(s => s?.subSubCategories ?? []).flat() ?? [];

            return {
                ...state,
                main,
                sub,
                subSub,
            };
        }
        default: {
            return state;
        }
    }
}
