import classNames from "classnames";
import React from "react";
import styles from "./OnboardingContent.module.scss";
import LogoBlack from "../../../resources/images/svg/amotai-logo-black.svg";

type OnboardingContainerProps = {
    containerClassName?: string;
    classNameRight?: string,
    sidebarImage?: any,
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default function OnboardingContent(props: OnboardingContainerProps) {
    const {
        children,
        classNameRight,
        containerClassName,
        sidebarImage,
        ...otherProps
    } = props;

    const rightClassName = classNames(styles.inner_right, classNameRight);

    return (
            <div {...otherProps} className={rightClassName}>
                <div className={styles.inner_content}>
                    <img src={LogoBlack} alt={"Amotai"} className={styles.logo} />
                    {children}
                </div>

            </div>
    );
}
