import React from "react";
import Button from "src/components/widgets/button/Button";
import "firebase/auth";
import { Link } from "react-router-dom";
import { Paper } from "@material-ui/core";
import OnboardingSidebar from "../../widgets/onboardingSidebar/OnboardingSidebar";
import SideBar from "../../../resources/images/png/onboarding-amotai-sidebar@2x.png";
import OnboardingContent from "../../widgets/onboardingContent/OnboardingContent";
import styles from "./SignUp.module.scss";
import Buyer from "../../../resources/images/png/buyer-avatar@2x.png";
import Supplier from "../../../resources/images/png/supplier-avatar@2x.png";


export default function SignUp() {
    // const [loading, setLoading] = useState<boolean>(false);

    return (
        <div className={styles.signup_container}>
            <OnboardingSidebar sidebarImage={SideBar}>
                <div className={styles.sidebar_content_container}>
                    <div>
                        <h3>
                            You’re just a few clicks away from being connected to our Amotai Supplier and Member database
                        </h3>
                    </div>
                </div>

            </OnboardingSidebar>
            <OnboardingContent>
                <div className={styles.signup_content_container}>
                    <h5>Get access to the Amotai Directory</h5>
                    <p>To join Amotai, please create your application for your business.<br/>
                    To learn more about Buyer memberships, you can find information in our <a href={`${process.env.AMOTAI_SITE_URL}/support`}>support centre</a>.</p>

                    <div className={styles.inner_container}>
                        <Paper className={styles.card}>
                            <img src={Supplier} alt={""} />
                            <h5>Māori and Pasifika
                                Suppliers</h5>
                            <p>Register your Māori and/or Pasifika owned business and get listed in our directory. Its
                                free to register.</p>
                            <Button href={"/onboarding/suppliers"}>Register as a supplier &gt;&gt;</Button>
                            <a href={`${process.env.AMOTAI_SITE_URL}/businesses`}>About Suppliers</a>
                        </Paper>
                        <Paper className={styles.card}>
                            <img src={Buyer} alt={""} />
                            <h5>Buyers</h5>
                            <p>Become a member as a buyer and gain access to our directory of businesses.</p>
                            <Button href={"/onboarding/buyers"} >Become a member &gt;&gt;</Button>
                            {/*<Button disabled>Coming soon</Button>*/}
                            <a href={`${process.env.AMOTAI_SITE_URL}/buyers`}>About Buyers</a>
                        </Paper>


                    </div>
                    <div className={styles.button_container}>

                        <p>Already have an account? <Link to={"/log-in"}>Log in</Link></p>
                    </div>

                </div>
            </OnboardingContent>
        </div>

    );
}