import classNames from "classnames";
import { Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useState } from "react";
import * as Yup from "yup";
import useDispatch from "../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../hooks/useSelector";
import { updateBusinessContacts } from "../../../../../../redux/actions/businessContacts";
import { showError } from "../../../../../../redux/actions/snackbars";
import { updateMyAccount } from "../../../../../../redux/actions/users";
import { AccountType } from "../../../../../../types/AmotaiAccount";
import AmotaiUser, { AccountRole } from "../../../../../../types/AmotaiUser";
import BusinessContact from "../../../../../../types/BusinessContact";
import Supplier from "../../../../../../types/Supplier";
import Button from "../../../../../widgets/button/Button";
import BusinessContactFieldArray from "../../../../../widgets/formikFields/BusinessContactFieldArray";
import FormikFileUpload from "../../../../../widgets/formikFields/FormikFileUpload";
import FormikInput from "../../../../../widgets/formikFields/FormikInput";
import Loading from "../../../../../widgets/loading/Loading";
import SectionHeader from "../../../../../widgets/sectionHeader/SectionHeader";
import AccountHeader from "../../../component/AccountHeader";
import styles from "../../business-details/BusinessDetails.module.scss";

type SupplierProps = {
    account: Supplier;
    businessContacts: BusinessContact[];
    me: AmotaiUser
};

type SupplierBusinessProfileValues = {
    logo: string;
    description: string;
    website: string;
    facebookLink: string;
    twitterLink: string;
    youtubeLink: string;
    instagramLink: string;
    linkedInLink: string;
    pinterestLink: string;
    businessContacts: BusinessContact[]
}

const validationSchema = Yup.object({
    logo: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    website: Yup.string()
});

export default function SupplierBusinessProfile(props: SupplierProps) {
    const { me, businessContacts, account } = props;
    const [editing, setEditing] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const activeSaasPlan: any = useSelector((state) => state.saaSPlans?.subscription?.plan);

    const onSubmit = async (values: SupplierBusinessProfileValues) => {
        try {
            setLoading(true);
            await dispatch(updateMyAccount(account.id, {
                ...account,
                ...values
            }, AccountType.SUPPLIER));

            const contacts = values.businessContacts.map((bc) => ({
                ...bc,
                account: account.id
            }));
            await dispatch(updateBusinessContacts(account.id, contacts));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    const toggleEdit = async (formikBag: FormikProps<SupplierBusinessProfileValues>) => {
        if (editing && formikBag.dirty) {
            await onSubmit(formikBag.values);
        }

        setEditing(!editing);
        if (!editing) {
            formikBag.resetForm();
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <Formik<SupplierBusinessProfileValues> initialValues={{
                logo: account.logo || "",
                description: account.description || "",
                website: account.website || "",
                facebookLink: account.facebookLink || "",
                twitterLink: account.twitterLink || "",
                youtubeLink: account.youtubeLink || "",
                instagramLink: account.instagramLink || "",
                linkedInLink: account.linkedInLink || "",
                pinterestLink: account.pinterestLink || "",
                businessContacts: businessContacts || [{ name: "", title: "", email: "", phoneNumber: "", avatar: "" }]
            }} validationSchema={validationSchema} onSubmit={onSubmit}>
                {(formikBag) => {
                    let actionText = "Edit";
                    if (editing) {
                        if (formikBag.dirty) {
                            actionText = "Save";
                        } else {
                            actionText = "Cancel";
                        }
                    }

                    return (
                        <form onSubmit={formikBag.handleSubmit}>
                            <AccountHeader title={account.name}
                                subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                                action={me?.accountRole === AccountRole.ADMIN && (
                                    <Button uppercase
                                        onClick={() => toggleEdit(formikBag)}>{actionText}</Button>
                                )} />
                            <SectionHeader title={"Business page details"} />
                            <div className={styles.form}>
                                <FormikFileUpload
                                    name={"logo"}
                                    preview={account.logo || ""}
                                    label={"Business logo"}
                                    disabled={!editing}
                                    imageClassName={styles.logo_image}
                                    accept='image/*'
                                />
                                <FormikInput name={"description"}
                                    label={"Business description"}
                                    multiline
                                    disabled={!editing}
                                    className={styles.full_input} />
                                <div className={styles.form_section}>
                                    <SectionHeader title={"Social Media"} />
                                    <FormikInput name={"website"}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                        disabled={!editing}
                                        label={"Main business website"} />
                                    <FormikInput name={"facebookLink"}
                                        inputClassname={styles.half_input}
                                        disabled={!editing}
                                        label={"Facebook (optional)"} />
                                    <FormikInput name={"instagramLink"}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                        disabled={!editing}
                                        label={"Instagram (optional)"} />
                                    <FormikInput name={"twitterLink"}
                                        inputClassname={styles.half_input}
                                        disabled={!editing}
                                        label={"Twitter (optional)"} />
                                    <FormikInput name={"linkedInLink"}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                        disabled={!editing}
                                        label={"LinkedIn (optional)"} />
                                    <FormikInput name={"youtubeLink"}
                                        inputClassname={styles.half_input}
                                        disabled={!editing}
                                        label={"Youtube (optional)"} />
                                    <FormikInput name={"pinterestLink"}
                                        inputClassname={classNames(styles.half_input, styles.first)}
                                        disabled={!editing}
                                        label={"Pinterest (optional)"} />
                                </div>
                                <div className={styles.form_section}>
                                    <SectionHeader title={"Preferred contacts"} />
                                    <BusinessContactFieldArray max={activeSaasPlan?.maxContacts} disabled={!editing} />
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
}