import { Form, Formik, FormikBag, FormikConfig, FormikProps, FormikValues } from "formik";
import React, { ReactNode, useState } from "react";
import styles from "../../pages/onboarding/Onboarding.module.scss";
import Button from "../button/Button";
import OnboardingContent from "../onboardingContent/OnboardingContent";
import OnboardingSidebar from "../onboardingSidebar/OnboardingSidebar";

export interface FormikStepProps extends Partial<Pick<FormikConfig<FormikValues>, "children" | "validationSchema" | "onSubmit">> {
    label?: string;
}

export const FormStep = ({ children }: FormikStepProps) => {
    return <>{children}</>;
};

type FormProps = {
    buttonsWrapperClass?: string;
    onboardingContainerClass?: string;
    steps: any[];
    breadcrumbs: (step: number, onClick: (n: number) => void) => ReactNode;
    submitting: boolean;
} & FormikConfig<FormikValues>

export default function MultiStepForm({ steps, ...props }: FormProps) {
    const { initialValues, onSubmit, submitting, buttonsWrapperClass } = props;
    const [stepNumber, setStepNumber] = useState(0);
    const [snapshot, setSnapshot] = useState(initialValues);

    const step = steps[stepNumber];
    const totalSteps = steps.length;
    const isLastStep = stepNumber === totalSteps - 1;

    const next = (values: any) => {
        setSnapshot(values);
        setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
    };

    const previous = (formik: FormikProps<any>) => {
        setSnapshot(formik.values);
        setStepNumber(Math.max(stepNumber - 1, 0));
        // re-validate to clear errors from prior page
        formik.setErrors({})
    };

    const setStep = (n: number, formik: FormikProps<any>) => {
        if (n > stepNumber) {
            // for (let i = stepNumber; i < n; i++) {
            //     formik.handleSubmit();
            //     setStepNumber(i + 1)
            // }
        } else {
            setSnapshot(formik.values);
            setStepNumber(n);
        }
    };

    const handleSubmit = async (values: any, bag: FormikBag<any, any>) => {
        if (step.props.onSubmit) {
            const submitSuccess = await step.props.onSubmit(values, bag);
            if (!submitSuccess) {
                return;
            }
        }
        if (step.ref?.current?.onSubmit) {
            const submitSuccess = await step.ref.current?.onSubmit(values, bag);
            if (!submitSuccess) {
                return;
            }
        }

        if (isLastStep) {
            return onSubmit(values, bag);
        }

        bag.setTouched({});
        next(values);
    };

    return (
        <Formik initialValues={snapshot}
            onSubmit={handleSubmit}
            validationSchema={step?.props?.validationSchema}
            enableReinitialize
            isInitialValid={step?.props?.validationSchema?.isValidSync(snapshot)}
        >
            {formik => {
                return (
                    <Form className={styles.onboarding_form}>
                        <OnboardingSidebar>
                            {props.breadcrumbs(stepNumber, (n) => setStep(n, formik))}
                        </OnboardingSidebar>
                        <OnboardingContent>
                            <div className={props.onboardingContainerClass}>
                                {step}
                                <div className={buttonsWrapperClass}>
                                    {stepNumber > 0 && (
                                        <Button plain onClick={() => previous(formik)}
                                            disabled={submitting}
                                            type={"button"}>
                                            Previous step
                                        </Button>
                                    )}
                                    <Button disabled={formik.isSubmitting || !formik.isValid}
                                        loading={submitting}
                                        type={"submit"}>
                                        {isLastStep ? "Submit" : "Next"}
                                    </Button>
                                </div>
                            </div>
                        </OnboardingContent>
                    </Form>
                )
            }}
        </Formik>
    );
}
