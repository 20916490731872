import createAction from "./helpers/createAction";
import BusinessReference from "../../types/BusinessReference";
import wrapper from "./helpers/wrapper";
import BusinessReferenceAPI from "./api/businessReference";

export enum Types {
    SET_BUSINESS_REFERENCES = "businessReferences:SET_BUSINESS_REFERENCES"
}

export const setBusinessReferences = createAction<BusinessReference[]>(Types.SET_BUSINESS_REFERENCES);

export const Actions = {
    [Types.SET_BUSINESS_REFERENCES]: setBusinessReferences
}

export type Actions = typeof Actions;

export const createBusinessReferences = wrapper(BusinessReferenceAPI.createBusinessReferences, {
    action: setBusinessReferences
})

export const getBusinessReferences = wrapper(BusinessReferenceAPI.getBusinessReferences, {
    action: setBusinessReferences
});

export const updateBusinessReferences = wrapper(BusinessReferenceAPI.updateBusinessReferences, {
    action: setBusinessReferences
});

