import { find } from "lodash";
import Card from "../../types/Card";
import Transaction from "../../types/Transaction";
import { Actions, Types } from "../actions/cards";

const initialState: {
    cards: Card[];
    transactions: Transaction[]
} = { cards: [], transactions: [] };

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function cards(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_CARDS: {
            const { payload } = action as ActionValueTypes<Types.SET_CARDS>;

            return {
                ...state,
                cards: payload ?? []
            };
        }
        case Types.SET_CARD: {
            const { payload } = action as ActionValueTypes<Types.SET_CARD>;
            const newCard = payload!;
            let newCards = [...state.cards];

            if (newCard.default) {
                newCards = newCards.map((c) => {
                    c.default = false;
                    return c;
                });
            }
            const cardsState = newCards.filter((c) => c.id !== newCard.id);

            cardsState.push(newCard);

            return {
                ...state,
                cards: cardsState
            };
        }
        case Types.DELETE_CARD: {
            const { payload } = action as ActionValueTypes<Types.DELETE_CARD>;
            const newCards = [...state.cards];

            if (!payload) {
                return {
                    ...state
                };
            }

            const foundCard = find(newCards, (c) => c.id === payload.id);

            if (foundCard) {
                newCards.splice(newCards.indexOf(foundCard), 1);
            }

            return {
                ...state,
                cards: newCards
            };
        }
        case Types.SET_TRANSACTIONS: {
            const { payload } = action as ActionValueTypes<Types.SET_TRANSACTIONS>;
            const transactions = payload!.items;
            return {
                ...state,
                transactions
            };
        }
        default: {
            return state;
        }
    }
}