
import classNames from "classnames";
import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./PageContainer.module.scss";

type ContainerProps = {
    containerClassName?: string;
    back?: boolean;
    onBackClick?: () => void;
    backTitle?: string;
    backTitleClass?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export default function PageContainer(props: ContainerProps) {
    const {
        children,
        className,
        containerClassName,
        back,
        onBackClick,
        backTitle,
        backTitleClass,
        ...otherProps
    } = props;
    const history = useHistory();

    const goBack = () => {
        if (onBackClick) {
            onBackClick();
        } else {
            if (history.length > 2) {
                history.goBack();
            } else {
                history.push('/account')
            }
        }
    }

    const pageClassName = classNames(styles.container, containerClassName);
    const innerClassName = classNames(styles.inner, className);

    return (
        <div className={pageClassName}>
            <div {...otherProps} className={innerClassName}>
                {back && <span className={classNames(styles.back, backTitleClass)} onClick={goBack}>&lt;&lt; {backTitle ?? 'Back'}</span>}
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div>
    );
}
