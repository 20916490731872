/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 1/07/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { PagingTableFilter, PagingTableState } from "../reducers/tables";
import createAction from "./helpers/createAction";

export enum Types {
    SET_TABLE_STATE = "tables:SET_TABLE_STATE",
}

export const setTableState = createAction<{
    tableId: string;
    state: PagingTableState<any, PagingTableFilter>;
}>(Types.SET_TABLE_STATE);

export const Actions = {
    [Types.SET_TABLE_STATE]: setTableState,
};
export type Actions = typeof Actions;

