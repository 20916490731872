import get from "./util/get";

function getMainCategories() {
    return get("/v1/categories");
}

const CategoryAPI = {
    getMainCategories
}

export default CategoryAPI;