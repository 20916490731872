import { Actions, Types } from "../actions/directoryFilter";
import { FilterFormValues } from "../../types/FilterFormValues";

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

const initialState: FilterFormValues = {
    keyword: "",
    category: "All categories",
    subCategory: "All subcategories",
    subSubCategory: "",
    region: "All regions",
    iwiAffiliation: "",
    pasifikaAffiliation: "",
    healthAndSafety: "",
    insurance: "",
    regionBased: '',
};

export default function directoryFilter(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_FILTER: {
            const { payload } = action as ActionValueTypes<Types.SET_FILTER>;
            return {
                ...state,
                ...payload
            };
        }
        case Types.RESET_FILTER: {
            return {
                ...initialState
            }
        }
        default: {
            return {
                ...state
            };
        }
    }
}
