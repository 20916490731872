import { Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useState } from "react";
import * as Yup from "yup";
import useDispatch from "../../../../../../hooks/useDispatch";
import useSelector from "../../../../../../hooks/useSelector";
import { updateBusinessContacts } from "../../../../../../redux/actions/businessContacts";
import { showError } from "../../../../../../redux/actions/snackbars";
import { updateMyAccount } from "../../../../../../redux/actions/users";
import { AccountType } from "../../../../../../types/AmotaiAccount";
import AmotaiUser, { AccountRole } from "../../../../../../types/AmotaiUser";
import BusinessContact from "../../../../../../types/BusinessContact";
import BuyerClient from "../../../../../../types/BuyerClient";
import Button from "../../../../../widgets/button/Button";
import BusinessContactFieldArray from "../../../../../widgets/formikFields/BusinessContactFieldArray";
import FormikFileUpload from "../../../../../widgets/formikFields/FormikFileUpload";
import FormikInput from "../../../../../widgets/formikFields/FormikInput";
import Loading from "../../../../../widgets/loading/Loading";
import SectionHeader from "../../../../../widgets/sectionHeader/SectionHeader";
import AccountHeader from "../../../component/AccountHeader";
import styles from "../../business-details/BusinessDetails.module.scss";

type BuyerBusinessProfileProps = {
    account: BuyerClient;
    businessContacts: BusinessContact[];
    me: AmotaiUser;
};

type BuyerBusinessProfileValues = {
    logo: string;
    description: string;
    businessContacts: BusinessContact[];
};

const validationSchema = Yup.object({
    logo: Yup.string().required("Required"),
    description: Yup.string().required(""),
    businessContacts: Yup.array().of(Yup.object({
        name: Yup.string().required("Required"),
        title: Yup.string().required("Required"),
        email: Yup.string().required("Required"),
        phoneNumber: Yup.string().required("Required"),
        avatar: Yup.string().required("Required")
    })).min(1, "At least 1 contact is required")
});

export default function BuyerBusinessProfile(props: BuyerBusinessProfileProps) {
    const { account, businessContacts, me } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const dispatch = useDispatch();
    const activeSaasPlan: any = useSelector((state) => state.saaSPlans?.subscription?.plan);

    const onSubmit = async (values: BuyerBusinessProfileValues) => {
        try {
            setLoading(true);
            await dispatch(updateMyAccount(account.id, {
                ...account,
                ...values
            }, AccountType.BUYER_CLIENT));

            const contacts = values.businessContacts.map((bc) => ({
                ...bc,
                account: account.id
            }));
            await dispatch(updateBusinessContacts(account.id, contacts));
            setLoading(false);
        } catch (e) {
            setLoading(false);
            dispatch(showError(e.message));
        }
    };

    const toggleEdit = async (formikBag: FormikProps<BuyerBusinessProfileValues>) => {
        if (editing && formikBag.dirty) {
            await onSubmit(formikBag.values);
        }

        setEditing(!editing);
        if (!editing) {
            formikBag.resetForm();
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <Formik<BuyerBusinessProfileValues> initialValues={{
                logo: account.logo || "",
                description: account.description || "",
                businessContacts: businessContacts || [{ name: "", title: "", email: "", phoneNumber: "", avatar: "" }]
            }} onSubmit={onSubmit} validationSchema={validationSchema}>
                {(formikBag) => {
                    let actionText = "Edit";
                    if (editing) {
                        if (formikBag.dirty) {
                            actionText = "Save";
                        } else {
                            actionText = "Cancel";
                        }
                    }

                    return (
                        <form onSubmit={formikBag.handleSubmit}>
                            <AccountHeader title={account.name}
                                subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                                action={me?.accountRole === AccountRole.ADMIN && (
                                    <Button uppercase
                                        onClick={() => toggleEdit(formikBag)}>{actionText}</Button>
                                )} />

                            <SectionHeader title={"Profile Details"} />
                            <div className={styles.form}>
                                <FormikFileUpload
                                    name={"logo"}
                                    preview={account.logo || ""}
                                    label={"Business logo"}
                                    disabled={!editing}
                                    imageClassName={styles.logo_image}
                                    accept='image/*'
                                />
                                <FormikInput name={"description"}
                                    label={`${account.organisationType === "iwi" ? "Iwi" : "Business"} Description`}
                                    multiline
                                    disabled={!editing}
                                    inputClassname={styles.full_text_input} />
                                <div className={styles.form_section}>
                                    <SectionHeader
                                        title={`${account.organisationType === "iwi" ? "Iwi" : "Business"} Profile Contacts`} />
                                    <p>
                                        <strong>
                                            These contacts will be shared on
                                            your {account.organisationType === "iwi" ? "Iwi" : "business"} profile so
                                            that
                                            suppliers
                                            can contact you directly.
                                        </strong>
                                    </p>
                                    <BusinessContactFieldArray max={activeSaasPlan?.maxContacts} disabled={!editing} />
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </>
    );
}