import { BusinessContact } from "../components/pages/onboarding/supplier/Page8";
import Address from "./Address";
import { Category, SubCategory, SubSubCategory } from "./Category";

interface CreateAccountDTO {
    name: string;
    legalStructure: string;
    phoneNumber: string;
    email: string;
    description: string;
    logo: string;
    mainContact?: BusinessContact;
    sourceToken?: string;
    regions: string[];
    category: Category | {};
    regionBased: string;
    physicalAddressComponents: Address | null;
    invoiceBuyer?: boolean;
    funderBuyer?: boolean;
    iwiBuyer?: boolean;
}

export interface CreateBuyerAccountDTO extends CreateAccountDTO {
    size: number;
    postalAddress: string;
    physicalAddress: string;
    annualTurnover: string;
    annualAddressableSpend: string;
    approxAnnualIndirectSpend: string;
    organisationType: string;
    socialProcurementInitiatives: string;
    minimumLevelOfInsuranceForSubContract: string;
    healthAndSafetyForSubContract: string[];
    saasPlan: number;
    mandateAgency?: string;
}

export type CreateSupplierAccountDTO = {
    generalPhoneNumber: string;
    generalEmail: string;
    postalAddress: string;
    physicalAddress: string;
    legalName: string;
    website: string;
    nzbn: string;
    nzCompaniesNumber: string;
    yearEstablished: string;
    facebookLink: string;
    twitterLink: string;
    youtubeLink: string;
    instagramLink: string;
    linkedInLink: string;
    pinterestLink: string;
    maoriEmployees: number;
    pasifikaEmployees: number;
    nonMaleEmployees: number;
    employeesUnderTwentyFour: number;
    totalFTE: number;
    pathwaysProvided: string;
    healthAndSafetyQualifications: string[];
    healthAndSafetyIncidences: string;
    turnoverInLastFinancialYear: string;
    otherInsurances: { [key: string]: string };
    publicLiabilityInsurance: string;
    disclosures: string;
    professionalIndemnity: string;
    mainCategories: Category[];
    subCategories: SubCategory[];
    subSubCategories: SubSubCategory[];
    adOption: string;
} & Omit<CreateAccountDTO, 'category'>


export interface CreateFunderAccountDTO extends CreateAccountDTO {
    size: number;
    postalAddress: string;
    physicalAddress: string;
    annualTurnover: string;
    annualAddressableSpend: string;
    approxAnnualIndirectSpend: string;
    socialProcurementInitiatives: string;
    minimumLevelOfInsuranceForSubContract: string;
    healthAndSafetyForSubContract: string[];
}

export type CreateIwiAccountDTO = Omit<CreateBuyerAccountDTO, 'category'> & {
    category: null;
    iwiAffiliations: string[];
}

export enum ACCOUNT_TYPE {
    buyerClient = "buyer_client",
    supplier = "supplier"
}
