import classnames from 'classnames';
import moment from 'moment';
import React, { useState } from "react";
import Carousel from 'react-material-ui-carousel';
import { RouteComponentProps } from "react-router-dom";
import SanityBlockContent from "src/components/widgets/block/sanityBlockContent/SanityBlockContent";
import useAsyncEffect from "src/hooks/useAsyncEffect";
import useDispatch from "src/hooks/useDispatch";
import useSelector from "src/hooks/useSelector";
import { getEvent } from "src/redux/actions/events";
import Facebook from "src/resources/images/tsx/Facebook";
import LinkedIn from "src/resources/images/tsx/LinkedIn";
import Youtube from "src/resources/images/tsx/Youtube";
import AmotaiEvent from "src/types/AmotaiEvent";
import { buildEventDateString } from "src/util/helper";
import SanityAPI from "src/util/SanityAPI";
import Button from "../../widgets/button/Button";
import PageLoader from "../../widgets/pageLoader/PageLoader";
import Section from "../../widgets/section/Section";
import styles from "./EventDetail.module.scss";

type RouteParams = {
    slug: string;
}

export type Props = {} & RouteComponentProps<RouteParams>;

export default function EventDetail(props: Props) {
    const { slug } = props.match.params;
    const dispatch = useDispatch();
    const [event, setEvent] = useState<AmotaiEvent | undefined | null>(useSelector(state => state.events[slug]));
    const [loading, setLoading] = useState<boolean>(false);
    const [socialLinks, setSocialLinks] = useState<Array<{ link: string, logo: string, alt?: string }>>([]);

    useAsyncEffect(async () => {
        if (!event) {
            setLoading(true);
            const mEvent = await dispatch(getEvent(slug));
            setEvent(mEvent);
            setLoading(false);
        }
        const footer = await SanityAPI.getFooter();
        const { socialLinks } = footer;
        setSocialLinks(socialLinks);
    });

    const getSocial = (s: { link: string, logo: string, alt?: string }) => {
        const { link } = s;
        let social = <></>;
        if (/http.*linkedin\.com/i.test(link)) {
            //linkedin
            social = <LinkedIn fill='#008997' />
        } else if (/http.*youtube\.com/i.test(link)) {
            //youtube
            social = <Youtube fill='#008997' />
        } else if (/http.*facebook\.com/i.test(link)) {
            //facebook
            social = <Facebook fill='#008997' />
        } else {
            return null;
        }
        return social;
    }

    if (loading || !event) {
        return <PageLoader />;
    }

    const { title, subtitle, details, heroImages, content } = event;
    // const { location, startedAt, rsvpBy, category, endedAt, ticketLink, socialLink } = details;
    // const [eventDate] = buildEventDateString(moment(startedAt), moment(endedAt));
    const singleHeroImage = heroImages?.length === 1;

    const bannerImageClass = classnames(styles.event_banner_image, {
        [styles.event_banner_image_single]: singleHeroImage,
    })

    return (
        <div className={styles.page_container}>
            <div className={styles.event_detail_container}>
                <div className={bannerImageClass}>
                    {!singleHeroImage ?
                        (
                            <Carousel
                                animation='fade'
                                fullHeightHover={false}
                                interval={5000}
                                indicatorContainerProps={{
                                    style: {
                                        marginTop: '-20px'
                                    }
                                }}>
                                {heroImages?.map((heroImage, i) => <img key={i} src={heroImage} alt={"event banner"} />)}
                            </Carousel>
                        ) :
                        (
                            <img src={heroImages?.[0]} alt={"event banner"} />
                        )
                    }

                </div>
                <div>
                    <Section className={styles.header} containerClassName={styles.section_event}>
                        <h3>{title}</h3>
                        {subtitle && <h5 className={styles.subtitle}>{subtitle}</h5>}
                        <div className={styles.group}>
                            {details && (
                                <div className={styles.buttons}>
                                    {details?.ticketLink &&
                                        <Button className={styles.button} extLink={details?.ticketLink}>Buy tickets</Button>}
                                    <span />
                                    {details?.socialLink &&
                                        <Button border borderTurquoise extLink={details.socialLink}>Follow on facebook &gt;&gt;</Button>}
                                </div>
                            )}
                            <div className={styles.socials}>
                                <span>Share this event</span>
                                {socialLinks.map((s, i) => (
                                    <a key={`${i}_${s.link}`} href={s.link} rel="noopener noreferrer" target="_blank">
                                        {getSocial(s)}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </Section>
                    <div className={styles.content}>
                        {details && <Section containerClassName={classnames(styles.event_content_container, styles.section_event)}>
                            <h5 className={styles.event_subheading}>Details</h5>
                            <div className={styles.event_details}>
                                <div className={styles.event_detail}>
                                    <span className={styles.title}>Event date</span>
                                    <span>{buildEventDateString(moment(details.startedAt), moment(details.endedAt))[0]}</span>
                                </div>
                                <div className={styles.event_detail}>
                                    <span className={styles.title}>RSVP by</span>
                                    <span>{moment(details.rsvpBy).format('D MMMM YYYY')}</span>
                                </div>
                                <div className={styles.event_detail}>
                                    <span className={styles.title}>Category</span>
                                    <span>{details.category}</span>
                                </div>
                                <div className={styles.event_detail}>
                                    <span className={styles.title}>Location</span>
                                    <span>{details.location}</span>
                                </div>
                            </div>
                        </Section>}
                        <Section containerClassName={styles.event_content_container}>
                            <div className={styles.event_body}>
                                <SanityBlockContent blocks={content} />
                            </div>
                        </Section>
                    </div>
                </div>
            </div>
        </div>
    );

}
