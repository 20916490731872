import React from "react";
import * as Yup from "yup";
import classNames from "classnames";
import Page3 from "./Page3";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";
import styles from "./FunderOnboarding.module.scss";
import FormikCheckbox from "../../../widgets/formikFields/FormikCheckbox";

export const FunderReviewValidationSchema = Yup.object({
    termsAgreed: Yup.string().required("You must accept the terms and conditions"),
})

export default function Page10(props: any) {

    return (
        <div>
            <h3>Review your application</h3>
            <p>Review your application below before submitting it for review.</p>

            <Page3 review />
            <Page4 review />
            <Page5 review />
            <Page6 review />
            <Page7 review />
            <Page8 review />
            <div className={classNames(styles.inner_container, styles.consent_checkboxes)}>
                <FormikCheckbox name={"termsAgreed"}
                                inputValue={"agreed"}>
                    <p>I declare that:</p>
                </FormikCheckbox>
                <p style={{fontWeight: 'normal'}}>
                    (a) I have the authority to register this entity with Amotai.  <br />
                    (b) I have completed this Amotai application to the best of my knowledge and all the information provided is true and correct.  <br />
                    (c) I understand that a false statement may disqualify the entity I am registering in the future, if during any review, the information provided is found to be untrue. <br />
                    (d) I have read and agree to the <a href='https://amotai.nz/terms-and-conditions' target='_blank' rel="noopener noreferrer">Amotai Terms &amp; Conditions</a> <br />
                </p>
            </div>
        </div>
    )
}
