import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { getBusinessContacts } from "../../../../../redux/actions/businessContacts";
import { getBusinessReferences } from "../../../../../redux/actions/businessReference";
import { showError } from "../../../../../redux/actions/snackbars";
import { getMyAccount } from "../../../../../redux/actions/users";
import { AccountType } from "../../../../../types/AmotaiAccount";
import AmotaiUser from "../../../../../types/AmotaiUser";
import BusinessReference from "../../../../../types/BusinessReference";
import BuyerClient from "../../../../../types/BuyerClient";
import Supplier from "../../../../../types/Supplier";
import Loading from "../../../../widgets/loading/Loading";
import styles from "./BusinessDetails.module.scss";
import BuyerClientOverview from "./buyer-client/BuyerClientOverview";
import SupplierOverview from "./supplier/SupplierOverview";

function BusinessDetails() {
    const [loading, setLoading] = useState<boolean>(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const me = useSelector<AmotaiUser>(state => state.users.me!);
    const account = useSelector<Supplier | BuyerClient>(state => state.users.account!);
    const businessReferences = useSelector<BusinessReference[]>(state => state.businessReferences.businessReferences);

    useMountEffect(async () => {
        try {
            const acc = await dispatch(getMyAccount());

            if (acc.type === AccountType.SUPPLIER) {
                await dispatch(getBusinessReferences(acc.id));
            }

            await dispatch(getBusinessContacts(acc.id));
        } catch (e) {
            dispatch(showError(e.message));
            history.push("/");
        }
        setLoading(false);
    });


    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <div className={styles.business_details}>
                {account.type === AccountType.BUYER_CLIENT && (
                    <BuyerClientOverview account={account} me={me} />
                )}

                {account.type === AccountType.SUPPLIER && (
                    <SupplierOverview account={account as Supplier} businessReferences={businessReferences} me={me} />
                )}

            </div>
        </>
    );
}

export default BusinessDetails;

