import React from "react";
import { useHistory } from "react-router-dom";
import FolderSVG from "../../../resources/images/svg/folder.svg";
import Resource from "../../../types/Resource";
import { RESOURCE_TYPE } from "../../pages/resources/Resources";
import Button from "../button/Button";
import styles from "./ResourceCard.module.scss";
import BlockContent from "@sanity/block-content-to-react";

type Props = {
    resource?: Resource;
    resourceType: RESOURCE_TYPE;
}

export default function ResourceCard(props: Props) {
    const { resource, resourceType } = props;
    const history = useHistory();

    let imageThumbNail = ""

    if (resourceType === RESOURCE_TYPE.webinar) {
        const youtubeVideoURL = resource!.embed;
        const vidId = youtubeVideoURL.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)?.pop();
        imageThumbNail = vidId ? `http://img.youtube.com/vi/${vidId}/0.jpg` : FolderSVG;
    } else {
        imageThumbNail = FolderSVG;
    }

    const onVideoCardClick = () => {
        history.push(`/resource-detail/${resource?._id}`);
    };
    const onDownloadCardClick = () => {
        if (!resource?.file) {
            return;
        }
        const { asset } = resource.file;
        window.open(asset.url);
    }

    return (
        <div className={styles.resource_card}>
            {resourceType === RESOURCE_TYPE.webinar ? <div className={styles.left}>
                {/* different image click actions for webinar and non webinar  */}
                <img src={imageThumbNail} alt={""} onClick={
                    resourceType === RESOURCE_TYPE.webinar ? onVideoCardClick : onDownloadCardClick}
                />
                {/* THUMBNAIL SRC FORMAT >>> http://img.youtube.com/vi/[video-id]/[thumbnail-number].jpg */}

                {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/xaazUgEKuVA" frameBorder="0" */}
                {/*        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" */}
                {/*        allowFullScreen /> */}

                {/* HOW TO >>> https://stackoverflow.com/questions/18681788/how-to-get-a-youtube-thumbnail-from-a-youtube-iframe */}
            </div> : <div />}
            <div className={resourceType === RESOURCE_TYPE.webinar ? styles.right : styles.resource_right}>
                <h5>{resource?.title}</h5>
                {resource?.content && <BlockContent blocks={resource.content} />}

                {resourceType === RESOURCE_TYPE.webinar
                    ? <Button onClick={onVideoCardClick}>View Resource</Button>
                    : resource?.file && <Button onClick={onDownloadCardClick}>Download File</Button>}
            </div>

        </div>
    );
}