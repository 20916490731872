import { FormControlLabel, Paper } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field, Formik, FormikProps } from "formik";
import moment from "moment";
import React, { useState } from "react";
import * as Yup from "yup";
import useDispatch from "../../../../../hooks/useDispatch";
import useMountEffect from "../../../../../hooks/useMountEffect";
import useSelector from "../../../../../hooks/useSelector";
import { getMainCategories } from "../../../../../redux/actions/categories";
import { showError } from "../../../../../redux/actions/snackbars";
import { getMyAccount, updateMyAccount } from "../../../../../redux/actions/users";
import { AccountType } from "../../../../../types/AmotaiAccount";
import AmotaiUser, { AccountRole } from "../../../../../types/AmotaiUser";
import { Category, SubCategory, SubSubCategory } from "../../../../../types/Category";
import Supplier from "../../../../../types/Supplier";
import Button from "../../../../widgets/button/Button";
import { CheckboxGroupItem } from "../../../../widgets/formikFields/FormikCheckboxGroup";
import FormikSelect from "../../../../widgets/formikFields/FormikSelect";
import Loading from "../../../../widgets/loading/Loading";
import SectionHeader from "../../../../widgets/sectionHeader/SectionHeader";
import AccountHeader from "../../component/AccountHeader";
import styles from "../business-details/BusinessDetails.module.scss";

type BusinessCategoryFormValues = {
    category: Category;
    subCategories: SubCategory[];
    subSubCategories: SubSubCategory[];
}

const CategoryValidationSchema = Yup.object({
    category: Yup.string().required("Required"),
    subCategories: Yup.array().of(Yup.string()),
    subSubCategories: Yup.array().of(Yup.string()),
});


export default function BusinessCategory() {
    const [loading, setLoading] = useState<boolean>(true);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const dispatch = useDispatch();
    const me = useSelector<AmotaiUser>(state => state.users.me!);
    const account = useSelector<Supplier>(state => state.users.account as Supplier);
    const categories = useSelector<Category[]>(state => state.categories.main);
    const categoryOptions = categories.map((category) => ({ name: category.name, value: category }));

    useMountEffect(async () => {
        try {
            await dispatch(getMyAccount());
            await dispatch(getMainCategories());
        } catch (e) {
            dispatch(showError(e.message));
        } finally {
            setLoading(false);
        }
    });

    const onSubmit = async (values: BusinessCategoryFormValues) => {

        try {
            setSubmitting(true);
            await dispatch(updateMyAccount(account.id, {
                ...account,
                ...values
            }, AccountType.SUPPLIER));
            setSubmitting(false);
        } catch (e) {
            setSubmitting(false);
            dispatch(showError(e.message));
        }
    };

    const toggleEdit = async (formikBag: FormikProps<BusinessCategoryFormValues>) => {
        if (editing && formikBag.dirty) {
            await onSubmit(formikBag.values);
        }

        setEditing(!editing);
        if (!editing) {
            formikBag.resetForm();
        }
    };

    const BusinessCategoryForm = (formik: FormikProps<BusinessCategoryFormValues>) => {
        const { values } = formik;
        const subField = formik.getFieldHelpers("subCategories");
        const subSubField = formik.getFieldHelpers("subSubCategories");

        const onPrimaryChange = () => {
            // clear selected sub & subSub
            subField.setValue([]);
            subSubField.setValue([]);
        };

        const onSubCategoryChange = (sub: SubCategory) => {
            const subIsSelected = values.subCategories.filter((subCat) => subCat.id === sub.id);
            const actionedSubSubIds = sub.subSubCategories.map((subSubCat) => subSubCat.id);
            const existingSubSubCategories = [...values.subSubCategories];

            if (subIsSelected.length) {
                // add all subSub categories to existing selection
                subSubField.setValue([...existingSubSubCategories, ...sub.subSubCategories]);
            } else {
                // clear subSub categories from the existing selection
                const remainingSubSub = existingSubSubCategories.filter((subSubCat) => !actionedSubSubIds.includes(subSubCat.id));
                subSubField.setValue([...remainingSubSub]);
            }
        };

        const onSubSubCategoryChange = (subSub: SubSubCategory) => {
            const filteredSub = values.subCategories.filter((subCat) => subCat.id === subSub.parent);
            const filteredSubSub = values.subSubCategories.filter((subSubCat) => subSubCat.id === subSub.id);

            if (!filteredSub.length && filteredSubSub.length) {
                // have selected a subSub category without the parent subCategory added, select it now
                const parentSubCat = values.category.subCategories.filter((subCat: any) => subCat.id === subSub.parent)[0];
                subField.setValue([...values.subCategories, parentSubCat]);
            }
        };

        const renderSubSubCategories = (subSubCategories: SubSubCategory[]) => {
            return subSubCategories.map((subSub: SubSubCategory) => {
                return (
                    <Field key={subSub.id}
                        component={CheckboxGroupItem}
                        name={"subSubCategories"}
                        label={subSub.name}
                        value={subSub}
                        sideEffect={() => onSubSubCategoryChange(subSub)} />
                );
            });
        };

        const renderExpandSubCategoryRow = (sub: SubCategory) => (
            <Accordion key={sub.id} TransitionProps={{ unmountOnExit: true }} className={styles.category_container}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-label={"Expand"}
                    aria-controls={"additional-actions1-content"}
                    id={"additional-actions1-header"}
                    className={styles.category_item}>
                    <FormControlLabel
                        className={styles.category_label}
                        aria-label={"Sub Industry"}
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={(
                            <Field component={CheckboxGroupItem}
                                name={"subCategories"}
                                value={sub}
                                sideEffect={() => onSubCategoryChange(sub)} />
                        )}
                        label={sub.name} />
                </AccordionSummary>

                <AccordionDetails>
                    <div className={styles.subsub_container}>
                        {!!sub.subSubCategories.length && renderSubSubCategories(sub.subSubCategories)}
                    </div>
                </AccordionDetails>
            </Accordion>
        );

        const renderNormalSubCategoryRow = (sub: SubCategory) => (
            <Paper className={styles.plain_subindustry_row}>
                <Field component={CheckboxGroupItem}
                    name={"subCategories"}
                    label={sub.name}
                    value={sub} />
            </Paper>
        );

        let actionText = "Edit";
        if (editing) {
            if (formik.dirty) {
                actionText = "Save";
            } else {
                actionText = "Cancel";
            }
        }

        const [selectedCategory] = categories.filter((c) => c.id === values.category?.id);
        return (
            <div className={styles.business_details}>
                <form>
                    <AccountHeader title={account?.name!}
                        subtitle={account?.createdAt ? `Member since ${moment(account.createdAt).format("DD/MM/yyyy")}` : ""}
                        action={me?.accountRole === AccountRole.ADMIN && (
                            <Button uppercase loading={submitting} onClick={() => toggleEdit(formik)}>
                                {actionText}
                            </Button>
                        )} />
                    <div>
                        <SectionHeader title={`${account.organisationType === "iwi" ? "Iwi" : "Business"} Category`} />
                        {loading ? <Loading /> : (
                            <div className={styles.form}>
                                <div className={styles.industry_form}>
                                    <FormikSelect name={"category"}
                                        label={"Primary Industry"}
                                        tooltip={"Select all relevant categories that you work in so that buyers are able to understand the services/works/goods you provide"}
                                        onChange={onPrimaryChange}
                                        disabled={!editing || formik.isSubmitting || submitting}
                                        options={categoryOptions}
                                        containerClassName={styles.full_input} />
                                    {selectedCategory?.subCategories?.length > 0 && selectedCategory.subCategories.map((sub: SubCategory) => {
                                        if (sub.subSubCategories.length) {
                                            return renderExpandSubCategoryRow(sub);
                                        }
                                        return renderNormalSubCategoryRow(sub);
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        );
    };

    const [initialCategory] = categories.filter((c) => c.id === account.category?.id);

    if (loading) {
        return <Loading />;
    }

    return (
        <Formik initialValues={{
            category: initialCategory || "",
            subCategories: account.subCategories,
            subSubCategories: account.subSubCategories

        }}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={CategoryValidationSchema}>
            {(formik: FormikProps<BusinessCategoryFormValues>) => {
                return (
                    BusinessCategoryForm(formik)
                );
            }}
        </Formik>
    );
}
