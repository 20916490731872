import BusinessReference from "../../types/BusinessReference";
import { Actions, Types } from "../actions/businessReference";

const initialState: {
    businessReferences: BusinessReference[]
} = {
    businessReferences: []
};

type ActionTypes = ReturnType<Actions[keyof Actions]>
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

export default function businessReferences(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_BUSINESS_REFERENCES: {
            const { payload } = action as ActionValueTypes<Types.SET_BUSINESS_REFERENCES>;

            if (!payload) {
                return {
                    ...state
                };
            }

            return {
                ...state,
                businessReferences: payload
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
}