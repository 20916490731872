/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 26/05/20.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { sanity } from "./SanityAPI";

const imageBuilder = imageUrlBuilder(sanity);

const sanityImageBuilder = (source: SanityImageSource) => imageBuilder.image(source);

export default sanityImageBuilder;
