import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import BusinessContactsAPI from "src/redux/actions/api/businessContacts";
import { getAccountSubscription } from "src/redux/actions/saaSPlans";
import { getMyAccount } from "src/redux/actions/users";
import AmotaiAccount, { AccountType } from "src/types/AmotaiAccount";
import SaaSSubscription from "src/types/SaaSSbscription";
import useDispatch from "../../../hooks/useDispatch";
import useSelector from "../../../hooks/useSelector";
import { getBusinessContacts } from "../../../redux/actions/businessContacts";
import { getDirectoryItem, setDirectoryItem } from "../../../redux/actions/directory";
import { showError } from "../../../redux/actions/snackbars";
import fb from '../../../resources/images/svg/social-facebook.svg';
import ins from '../../../resources/images/svg/social-instagram.svg';
import li from '../../../resources/images/svg/social-linkedin.svg';
import pt from '../../../resources/images/svg/social-pinterest.svg';
import tw from '../../../resources/images/svg/social-twitter.svg';
import yt from '../../../resources/images/svg/social-youtube.svg';
import BusinessContact from "../../../types/BusinessContact";
import DirectoryItem, { Type } from "../../../types/DirectoryItem";
import { DialogRef } from "../../widgets/dialog/Dialog";
import ContactCardDialog from "../../widgets/dialogs/contactCardDialog/ContactCardDialog";
import PageContainer from "../../widgets/pageContainer/PageContainer";
import PageLoader from "../../widgets/pageLoader/PageLoader";
import { ResultsViewRouteParams } from "../directory/ResultsView";
import styles from "./DirectoryDetail.module.scss";

type DirectoryDetailParams = {
    id?: string;
}

export default function DirectoryDetail() {
    const [loading, setLoading] = useState<boolean>(true);
    const [businessContact, setBusinessContact] = useState<BusinessContact | null>(null);
    const params = useParams<DirectoryDetailParams>();
    const id = isNaN(Number(params.id)) ? null : Number(params.id);
    const location = useLocation();
    const history = useHistory();
    const contactCardDialogRef = useRef<DialogRef>(null);
    const dispatch = useDispatch();
    const account = useSelector<DirectoryItem | null>((state) => id ? state.directory[id] : null);
    const businessContacts = useSelector<BusinessContact[]>(state => state.businessContacts.businessContacts);
    const subscription = useSelector<SaaSSubscription | undefined>(state => state.saaSPlans.subscription!);
    const myAccount = useSelector<AmotaiAccount | undefined | null>(state => state.users.account);
    // const { isAdmin, isSuperAdmin, isRegionalManager } = useUserRoles();
    const routeParams = location.state as ResultsViewRouteParams | undefined;

    useEffect(() => {
        if (!myAccount) {
            dispatch(getMyAccount());
        }
        if (myAccount?.type === AccountType.BUYER_CLIENT && !subscription) {
            dispatch(getAccountSubscription(myAccount.id))
        }
    }, [myAccount, subscription, dispatch]);

    useEffect(() => {
        (async () => {
            dispatch(setDirectoryItem(null));
            const isSupplier = location.pathname.includes("/suppliers");
            if (!id) {
                history.push(isSupplier ? "/suppliers" : "/buyer-clients");
            } else {
                try {
                    await dispatch(getBusinessContacts(id));
                    if (!account) {
                        await dispatch(getDirectoryItem(id));
                    }
                    setLoading(false);
                } catch (e) {
                    dispatch(showError(e.message));
                    setLoading(false);
                }
            }
        })();
    }, [account, id, history, location, dispatch]);

    useEffect(() => {
        if (businessContact) {
            contactCardDialogRef.current?.show();
        }
    }, [businessContact]);

    const logo = useMemo(() => account?.logo?.trim().length ? account.logo : null, [account]);

    const onContactDialogClose = () => setBusinessContact(null);

    const onClickContact = useCallback(async (businessContact: BusinessContact) => {
        try {
            await BusinessContactsAPI.addConnection(businessContact.id);
        } catch (error) {
            // console.log('Failed to add a connection:', error)
        }
        setBusinessContact(businessContact);
    }, []);

    const onBackClick = () => {
        const path = account?.type === Type.SUPPLIER ? 'suppliers' : 'buyer-clients';
        if (routeParams) {
            history.replace(`/${path}/results`, routeParams);
        } else {
            history.push(`/${path}`)
        }
    }

    if (loading) {
        return <PageLoader />;
    }

    return (
        <PageContainer
            back
            onBackClick={onBackClick}
            backTitle='Back to directory'
            backTitleClass={styles.back}
            className={styles.container}
        >
            <div className={styles.header}>
                <h2>{account?.name ?? ''}</h2>
                {account?.plan?.displayBadge && <AukokiriSash />}
            </div>
            <div className={styles.website}>
                {account?.website && (
                    <a href={account.website.includes("http") ? account.website : `http://${account.website}`}
                        target={"_blank"}
                        rel={"noopener noreferrer"}>
                        Visit website
                    </a>
                )}
            </div>
            <div className={styles.main}>
                {logo ? (
                    <img
                        src={logo}
                        className={styles.logo}
                        alt='logo'
                    />
                ) : (
                        <div className={styles.no_logo} />
                    )
                }
                <div className={styles.content}>
                    <div className={styles.section}>
                        <div className={styles.title}>About</div>
                        <div className={styles.about}>
                            {account?.description}
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={styles.title}>Services</div>
                        {account?.type === Type.SUPPLIER && account.mainCategories?.map(mc => (<>
                            <div key={mc.id} className={styles.vice_title}>{mc.name}</div>
                            <div className={styles.col_2_container}>
                                {account.subCategories?.filter(sc => sc.parent === mc.id)
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((sc) => (
                                        <div key={sc.id}>
                                            <div className={styles.sub_title}>{sc.name}</div>
                                            <ul>
                                                {account.subSubCategories?.filter(ssc => ssc.parent === sc.id)
                                                    .sort((a, b) => a.name.localeCompare(b.name))
                                                    .map((ssc) => (
                                                        <li key={ssc.id}>{ssc.name}</li>
                                                    ))}
                                            </ul>
                                        </div>
                                    ))}
                            </div>
                        </>))}
                        {account?.type === Type.BUYER_CLIENT && <div className={styles.vice_title}>{account.category?.name}</div>}
                    </div>
                    <div className={styles.section}>
                        <div className={styles.title}>Business Summary</div>
                        <div className={styles.sub_title}>Regions of operation</div>
                        <ul>
                            {account?.regions?.map((r) => {
                                const names = r.split(' - ');
                                return (
                                    <li key={r}>
                                        <span>{names[0]}</span>
                                        {names.length > 1 && <span className={styles.suffix}> • {names[1]}</span>}
                                    </li>
                                )
                            }
                            )}
                        </ul>
                        {account?.type === Type.SUPPLIER && (
                            <div className={styles.col_2_container}>
                                <div>
                                    <div className={styles.sub_title}>Health & Safety</div>
                                    <ul>
                                        {account.healthAndSafetyQualifications?.map(h => (
                                            <li key={h}>{h}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <div className={styles.sub_title}>Insurance</div>
                                    <ul>
                                        {account.publicLiabilityInsurance && (
                                            <li>
                                                {`${account.publicLiabilityInsurance.replace(' million', 'm')} Public Liability insurance`}
                                            </li>
                                        )}
                                        {account.professionalIndemnity && (
                                            <li>
                                                {`${account.professionalIndemnity.replace(' million', 'm')} Professional Indemnity insurance`}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        )}
                    </div>
                    {account?.type === Type.BUYER_CLIENT && (
                        <div className={styles.section}>
                            <div className={styles.title}>Sub-Contractor Requirements</div>
                            <div className={styles.col_2_container}>
                                <div>
                                    <div className={styles.sub_title}>Health & Safety</div>
                                    <ul>
                                        {account.healthAndSafetyForSubContract?.map(h => (
                                            <li key={h}>{h}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    <div className={styles.sub_title}>Minimum Insurance</div>
                                    <ul>
                                        {account.minimumLevelOfInsuranceForSubContract && (
                                            <li>
                                                {`Mininum ${account.minimumLevelOfInsuranceForSubContract} insurance`}
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={styles.section}>
                        <div className={styles.title}>Contact</div>
                        {businessContacts?.map(bc => (
                            <div key={bc.id} className={styles.contact_container}>
                                <div>
                                    <span>{bc.name}</span>
                                    {!!bc.title && <span className={styles.suffix}> • {bc.title}</span>}
                                </div>
                                <div onClick={() => onClickContact(bc)}>
                                    View contact details
                                </div>
                            </div>
                        ))}
                    </div>
                    {account?.type === Type.SUPPLIER && (
                        <div className={styles.section}>
                            <div className={styles.socials}>
                                {account.facebookLink && <SocialIcon social='fb' href={account.facebookLink} />}
                                {account.twitterLink && <SocialIcon social='tw' href={account.twitterLink} />}
                                {account.youtubeLink && <SocialIcon social='yt' href={account.youtubeLink} />}
                                {account.linkedInLink && <SocialIcon social='li' href={account.linkedInLink} />}
                                {account.instagramLink && <SocialIcon social='ins' href={account.instagramLink} />}
                                {account.pinterestLink && <SocialIcon social='pt' href={account.pinterestLink} />}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ContactCardDialog ref={contactCardDialogRef} businessContact={businessContact}
                onClose={onContactDialogClose} />
        </PageContainer>
    );
}

function SocialIcon({ social, href }: { social: 'fb' | 'li' | 'pt' | 'yt' | 'ins' | 'tw', href: string }) {
    const icon = useMemo(() => {
        switch (social) {
            case 'fb':
                return <img src={fb} alt='facebook' />
            case 'li':
                return <img src={li} alt='linkedin' />
            case 'pt':
                return <img src={pt} alt='pinterest' />
            case 'yt':
                return <img src={yt} alt='youtube' />
            case 'ins':
                return <img src={ins} alt='instagram' />
            case 'tw':
            default:
                return <img src={tw} alt='twitter' />
        }
    }, [social]);
    return (
        <a href={href} target="_blank" rel="noopener noreferrer">
            {icon}
        </a>
    );
}

function AukokiriSash() {
    return (
        <div className={styles.sash}>
            <span>Aukōkiri</span>
        </div>
    )
}