import classnames from 'classnames';
import React, { MouseEvent, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Loading from 'src/components/widgets/loading/Loading';
import useSelector from 'src/hooks/useSelector';
import { AccountType } from 'src/types/AmotaiAccount';
import { Category, CategoryType, SubCategory, SubSubCategory } from 'src/types/Category';
import { FullResult, HitType } from 'src/types/SearchResult';
import { getOwnershipTypes } from 'src/util/helper';
import { ResultsViewRouteParams } from '../ResultsView';
import styles from './DirectoryCard.module.scss';

type Props = {
    routeParams?: ResultsViewRouteParams;
    item: FullResult,
}
export default function DirectoryCard(props: Props) {
    const { routeParams, item } = props;
    const request = routeParams?.request;
    const { categoryIds } = item;
    const { main, sub, subSub } = useSelector(state => state.categories);
    const history = useHistory();

    const accountCats = useMemo((): { mains: Category[], subs: SubCategory[], subsubs: SubSubCategory[] } | null => {
        if (!main.length) {
            return null;
        }
        let mains = main.filter(c => categoryIds.includes(c.id));
        let subs = sub.filter(s => categoryIds.includes(s.id));
        let subsubs = subSub.filter(ss => categoryIds.includes(ss.id));
        if (request) {
            const { id, hitType } = request;
            switch (hitType) {
                case HitType.Category: {
                    const hitMain = mains.find(mc => mc.id === id);
                    if (hitMain) {
                        mains = mains.sort((a) => a.id === hitMain.id ? -1 : 1);
                    }
                    break;
                }
                case HitType.SubCategory: {
                    const hitSub = subs.find(sc => sc.id === id);
                    if (hitSub) {
                        const hitMain = mains.find(mc => mc.id === hitSub.parent);
                        mains = mains.sort((a) => a.id === hitMain?.id ? -1 : 1);
                        subs = subs.sort((a) => a.id === hitSub.id ? -1 : 1);
                    }
                    break;
                }
                case HitType.SubSubCategory: {
                    const hitSubSub = subsubs.find(ssc => ssc.id === id);
                    if (hitSubSub) {
                        const hitSub = subs.find(sc => sc.id === hitSubSub.parent);
                        const hitMain = mains.find(mc => mc.id === hitSub?.parent);
                        mains = mains.sort((a) => a.id === hitMain?.id ? -1 : 1);
                        subs = subs.sort((a) => a.id === hitSub?.id ? -1 : 1);
                        subsubs = subsubs.sort((a) => a.id === hitSubSub.id ? -1 : 1);
                    }
                    break;
                }
                default:
                    break;
            }
        }
        return { mains, subs, subsubs }
    }, [main, sub, subSub, categoryIds, request]);

    const containerClass = classnames(styles.container, styles.a_not_a, {
        [styles.container_buyer]: item.type === AccountType.BUYER_CLIENT
    });

    const ownership = useMemo(() => {
        const ownershipTypes = getOwnershipTypes();
        const { ownershipType } = item;
        if (ownershipType && ownershipType !== 'na') {
            return ownershipTypes[ownershipType].value;
        }
        return null;
    }, [item]);

    const onClick = useCallback((e: MouseEvent<any>) => {
        if (e.ctrlKey || e.metaKey) {
            return; //do browser default thing to open in a new tab
        }
        e.preventDefault();
        const path = item.type === AccountType.SUPPLIER ? 'suppliers' : 'buyer-clients';
        history.push(`/${path}/details/${item.id}`, routeParams)
    }, [item, history, routeParams]);

    return (
        <a
            className={containerClass}
            href={`/${item.type === AccountType.SUPPLIER ? 'suppliers' : 'buyer-clients'}/details/${item.id}`}
            onClick={onClick}
        >
            {item.plan?.displayBadge && <div className={styles.sash_tag}>
                <div>Aukōkiri</div>
            </div>}
            <div className={styles.title}>
                {item.name}
            </div>
            <div title={item.regions?.join(', ')} className={styles.sub_title}>
                {item.regions?.join(', ')}
            </div>
            {!accountCats && <Loading />}
            <div className={styles.pill_container}>
                {accountCats && <Pills categories={accountCats.mains} type={CategoryType.MAIN} max={1} />}
            </div>
            {AccountType.SUPPLIER === item.type && (
                <>
                    <div className={classnames(styles.pill_container, styles.pill_bordered)}>
                        {accountCats && <Pills categories={accountCats.subs} type={CategoryType.SUB} max={3} />}
                    </div>
                    <div className={styles.pill_container}>
                        {accountCats && <Pills categories={accountCats.subsubs} type={CategoryType.SUB_SUB} max={3} />}
                    </div>
                    <div className={styles.pill_container}>
                        {ownership && <div className={classnames(styles.pill, styles.pill_turquoise)}>
                            {ownership}
                        </div>}
                        <div className={classnames(styles.pill, styles.pill_turquoise)}>
                            {`${item.businessSize} business`}
                        </div>
                    </div>
                </>
            )}
        </a>
    )
}

type PillProps = {
    categories: Array<Category | SubCategory | SubSubCategory>,
    type: CategoryType,
    max: number,
}

function Pills(props: PillProps) {
    const { categories, max, type } = props;
    if (!categories.length) {
        return null;
    }
    let className: string = styles.pill;
    switch (type) {
        case CategoryType.MAIN:
            className = classnames(styles.pill, styles.pill_grey);
            break;
        case CategoryType.SUB:
            className = classnames(styles.pill, styles.pill_light);
            break;
        default:
            break;
    }
    const cats = categories.length > max ? categories.slice(0, max) : categories;
    const more = categories.length > max ? categories.slice(max) : [];

    return (<>
        {cats.map(c => (
            <div key={c.name} title={c.name} className={className}>
                {c.type === CategoryType.SUB_SUB ? `• ${c.name}` : c.name}
            </div>
        ))}
        {!!more.length && (
            <div
                className={classnames(styles.pill, styles.pill_more)}
                title={more.map(c => c.name).join(', ')}
            >
                {`+ ${more.length} more`}
            </div>
        )}
    </>)
}