import React from "react";
import { Link } from "react-router-dom";
import Facebook from "../../../resources/images/tsx/Facebook";
import LinkedIn from "../../../resources/images/svg/linkedin.svg"
import Youtube from "../../../resources/images/svg/youtube.svg"
import styles from "./Footer.module.scss";
import Arrow from "../../../resources/images/svg/arrow-up.svg";

export default function Footer() {

    return (
        <>
            <div className={styles.footer}>
                <div className={styles.footer_container}>
                    <div className={styles.footer_left}>
                        <div className={styles.footer_left_left}>
                            {/*<a href={"tel:+64 21 392 882"}><span>+64 21 392 882</span></a>*/}
                            <a href={"mailto:kiaora@amotai.nz"}><span>kiaora@amotai.nz</span></a>
                        </div>
                        {/*<div className={styles.footer_left_right}>*/}
                        {/*    <a href={"https://goo.gl/maps/7tw9jydpMWDfpQNw7"}><span>Level 5,<br />6 Henderson Valley Road, <br />Henderson, Auckland, 0612</span></a>*/}

                        {/*</div>*/}
                    </div>
                    <div className={styles.footer_right}>

                        <div className={styles.socials}>
                            <a href={"https://www.facebook.com/HewakaekenoaNZ"} rel={"noopener noreferrer"} target={"_blank"}>
                                <Facebook fill='#fff' />
                            </a>
                            <a href={"https://www.linkedin.com/company/37194946"} rel={"noopener noreferrer"} target={"_blank"}>
                                <img src={LinkedIn} alt={"LinkedIn"} />
                            </a>
                            <a href={"https://www.youtube.com/channel/UCjJKRGzaFyRIGHWTpIgs26Q?view_as=subscriber"} rel={"noopener noreferrer"} target={"_blank"}>
                                <img src={Youtube} alt={"Youtube"} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.footer_bar_container}>
                <div className={styles.footer_bar}>
                    <div className={styles.footer_bar_left}>© Amotai {new Date().getFullYear()} | <Link to={"/terms-and-conditions"}>Terms and Conditions</Link>
                    </div>
                    <div className={styles.footer_bar_right}>
                        <a href={"#top"}>
                            BACK TO TOP
                            <img src={Arrow} alt={""} />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
