import React from "react";
import * as Yup from "yup";
import styles from "../buyerClient/BuyerClientOnboarding.module.scss";
import { FormStep } from "../../../widgets/multiStepForm/MultiStepForm";
import SectionHeader from "../../../widgets/sectionHeader/SectionHeader";
import FormikSelect from "../../../widgets/formikFields/FormikSelect";
import { IWI_GROUPS } from "../../../../util/constants";

export const IwiAffiliationValidationSchema = Yup.object({
    iwiAffiliation: Yup.string().required()
});

const IWI_AFFILIATIONS = IWI_GROUPS.filter(iwi => iwi !== "None");

export default function Page2(props: any) {
    const renderFields = () => (
        <FormikSelect name={"iwiAffiliation"}
                      containerClassName={styles.card_container}
                      label={"Iwi affiliation"}
                      options={["", ...IWI_AFFILIATIONS]} />
    );

    const renderFormStep = (children: any) => (
        <FormStep>
            <h5>Select your iwi</h5>
            <p>Select the name of your Iwi that this business account will represent</p>
            <div className={styles.inner_container}>
                {children}
            </div>
        </FormStep>
    );

    if (props.review) {
        return (
            <div className={styles.inner_container}>
                <SectionHeader title={"Iwi affiliation"} />
                {renderFields()}
            </div>
        );
    }

    return (
        renderFormStep(renderFields())
    );
}