import AmotaiEvent from "src/types/AmotaiEvent";
import { Actions, Types } from "../actions/events";

type ActionTypes = ReturnType<Actions[keyof Actions]>;
type ActionValueTypes<T extends Types> = ReturnType<Actions[T]>;

const initialState: {
    [key: string]: AmotaiEvent;
} = {};

export default function events(state = initialState, action: ActionTypes) {
    switch (action.type) {
        case Types.SET_EVENTS: {
            const { payload } = action as ActionValueTypes<Types.SET_EVENTS>;
            const newState = payload!.map(e => ({ [e.slug]: e }));
            return {
                ...state,
                ...newState
            };
        }
        case Types.SET_EVENT: {
            const { payload } = action as ActionValueTypes<Types.SET_EVENT>;
            const event = payload!;
            return {
                ...state,
                [event.slug]: event
            };
        }
        default:
            return state;
    }
}