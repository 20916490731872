import AmotaiAccount from "./AmotaiAccount";

export default interface Supplier extends AmotaiAccount {
    generalPhoneNumber?: string;
    generalEmail?: string;
    postalAddress?: string;
    physicalAddress?: string;
    legalName?: string;
    website?: string;
    nzbn?: string;
    nzCompaniesNumber?: string;
    yearEstablished?: string;
    facebookLink?: string;
    twitterLink?: string;
    youtubeLink?: string;
    instagramLink?: string;
    linkedInLink?: string;
    pinterestLink?: string;
    maoriEmployees?: string;
    pasifikaEmployees?: string;
    nonMaleEmployees?: string;
    employeesUnderTwentyFour?: string;
    totalFTE?: string;
    pathwaysProvided?: string;
    healthAndSafetyQualifications?: string[];
    healthAndSafetyIncidences?: string;
    turnoverInLastFinancialYear?: string;
    otherInsurances?: { [key: string]: string };
    publicLiabilityInsurance?: string;
    disclosures?: string;
    professionalIndemnity?: string;
    ownershipType?: OwnershipType;
}

export enum OwnershipType {
    MaoriOwned = '_010_MaoriOwned', //Maori at least 50%
    PasifikaOwned = '_100_PasifikaOwned', //Pasifika at least 50%
    MaoriPasifikaOwned = '_050_MaoriPasifikaOwned', //Maori 50% and Pasifika 50%
    MaoriPasifikaCombined = '_005_MaoriPasifikaCombined' //Maori and Pasifika combined up to at least 50% ownership
}
