import { AccordionDetails } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import firebase from "firebase/app";
import "firebase/auth";
import React, { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import useDispatch from "../../../hooks/useDispatch";
import useMountEffect from "../../../hooks/useMountEffect";
import useSelector from "../../../hooks/useSelector";
import { createBusinessContacts } from "../../../redux/actions/businessContacts";
import { createBusinessOwners } from "../../../redux/actions/businessOwners";
import { createBusinessReferences } from "../../../redux/actions/businessReference";
import { getOldSupplierAccount } from "../../../redux/actions/oldSupplierAccount";
import { showError, showWarning } from "../../../redux/actions/snackbars";
import { createAccount, getMyAccount, getMyUser, updateMyProfile } from "../../../redux/actions/users";
import SideBar from "../../../resources/images/png/onboarding-supplier-sidebar@2x.png";
import Arrow from "../../../resources/images/svg/faq-arrow.svg";
import { ACCOUNT_TYPE, CreateSupplierAccountDTO } from "../../../types/CreateAccountDTO";
import OldSupplierAccount from "../../../types/OldSupplierAccount";
import { NATIONWIDE_REGION } from "../../../util/constants";
import Button from "../../widgets/button/Button";
import { DialogRef } from "../../widgets/dialog/Dialog";
import AccountSubmittedDialog from "../../widgets/dialogs/accountSubmittedDialog/AccountSubmittedDialog";
import Loading from "../../widgets/loading/Loading";
import MultiStepForm from "../../widgets/multiStepForm/MultiStepForm";
import OnboardingContent from "../../widgets/onboardingContent/OnboardingContent";
import OnboardingSidebar from "../../widgets/onboardingSidebar/OnboardingSidebar";
import styles from "./Onboarding.module.scss";
import Page1, { DetailsValidationSchema } from "./supplier/Page1";
import Page10, { HealthAndSafetyValidationSchema } from "./supplier/Page10";
import Page11, { SupplierReviewValidationSchema } from "./supplier/Page11";
import Page2, { OrgDetailsValidationSchema } from "./supplier/Page2";
import Page3, { CategoryValidationSchema } from "./supplier/Page3";
import Page4, { OrgFinancialsValidationSchema } from "./supplier/Page4";
import Page5, { OrgOwnershipValidationSchema } from "./supplier/Page5";
import Page6, { OrgEmployeesValidationSchema } from "./supplier/Page6";
import Page7, { BusinessProfileValidationSchema } from "./supplier/Page7";
import Page8, { BusinessContactValidationSchema } from "./supplier/Page8";
import Page9, { BusinessReferenceValidationSchema } from "./supplier/Page9";
import { SupplierFormValues, SupplierInitialValues } from "./supplier/supplierValidation";


export default function SupplierOnboarding() {
    const dispatch = useDispatch();
    const { id } = useParams<{ id?: string }>();
    const history = useHistory();
    const accountSubmittedDialogRef = useRef<DialogRef>(null);
    const [submitting, setSubmitting] = useState(false);
    const [showIntro, setShowIntro] = useState(true);
    const [loading, setLoading] = useState(true);
    const oldSupplier = useSelector<OldSupplierAccount | null | undefined>(state => state.oldSupplierAccount.oldSupplierAccount);

    useMountEffect(async () => {
        try {
            if (id && !Number.isNaN(id)) {
                await dispatch(getOldSupplierAccount(Number(id)));
            }
        } catch (e) {
            history.push("/onboarding/supplier");
        }

        setLoading(false);
    });

    function mergeSchemas(...schemas: any) {
        const [first, ...rest] = schemas;

        const merged = rest.reduce(
            (mergedSchemas: any, schema: any) => mergedSchemas.concat(schema),
            first
        );

        return merged;
    }

    const mergedSchemas = mergeSchemas(
        DetailsValidationSchema,
        OrgDetailsValidationSchema,
        CategoryValidationSchema,
        OrgFinancialsValidationSchema,
        OrgEmployeesValidationSchema,
        BusinessProfileValidationSchema,
        BusinessContactValidationSchema,
        BusinessReferenceValidationSchema,
        HealthAndSafetyValidationSchema,
        SupplierReviewValidationSchema,
    );

    const handleSubmit = async (values: SupplierFormValues) => {
        // test adOption with extra text required, the error has been written in the field
        const optionRgx = /(^other:?[^:]+)|^(?!other)+/i;
        if (!optionRgx.test((values.adOption ?? '').trim())) {
            dispatch(showError('Please fill how did you hear about us'));
            return;
        }
        setSubmitting(true);
        try {
            values.email = values.email?.trim().toLowerCase();
            try {
                await firebase.auth().createUserWithEmailAndPassword(values.email, values.password);
            } catch (error) {
                const { code } = error;
                if (code === 'auth/email-already-exists' || code === 'auth/email-already-in-use') {
                    try {
                        //try auth with this user
                        await firebase.auth().signInWithEmailAndPassword(values.email, values.password);
                    } catch (e) {
                        throw error; //still throw previous error as auth with email and pass failed
                    }
                } else {
                    throw error
                }
            }
            await dispatch(updateMyProfile({
                firstName: values.firstName,
                lastName: values.lastName,
                name: `${values.firstName} ${values.lastName}`,
                jobTitle: values.jobTitle,
                phoneNumber: values.phoneNumber,
                mobileNumber: values.mobileNumber,
                avatar: values.avatar
            }));
            const otherInsurances = {};
            values.otherInsurances?.forEach((insurance) => {
                if (insurance.name && insurance.value) {
                    otherInsurances[insurance.name] = insurance.value;
                }
            });

            let submittedRegions = [...values.regions];
            if (values.regions.includes(NATIONWIDE_REGION)) {
                // clear other regions and only submit nationwide
                submittedRegions = [NATIONWIDE_REGION]
            }

            const supplierDTO: CreateSupplierAccountDTO = {
                name: values.tradingName,
                legalStructure: values.legalStructure,
                phoneNumber: values.phoneNumber,
                email: values.email?.trim()?.toLowerCase(),
                description: values.description,
                logo: values.logo,
                regions: submittedRegions,
                mainCategories: values.mainCategories,
                subCategories: values.subCategories,
                subSubCategories: values.subSubCategories,

                generalPhoneNumber: values.phoneNumber,
                generalEmail: values.email?.trim()?.toLowerCase(),
                postalAddress: values.postalAddress,
                physicalAddress: values.physicalAddress,
                physicalAddressComponents: values.physicalAddressComponents,
                legalName: values.legalName,
                website: values.website,
                nzbn: values.nzbn,
                nzCompaniesNumber: values.nzCompaniesNumber,
                yearEstablished: values.yearEstablished,
                facebookLink: values.facebookLink,
                twitterLink: values.twitterLink,
                youtubeLink: values.youtubeLink,
                instagramLink: values.instagramLink,
                linkedInLink: values.linkedInLink,
                pinterestLink: values.pinterestLink,
                maoriEmployees: values.maoriEmployees,
                pasifikaEmployees: values.pasifikaEmployees,
                nonMaleEmployees: values.nonMaleEmployees,
                employeesUnderTwentyFour: values.employeesUnderTwentyFour,
                totalFTE: values.totalFTE,
                pathwaysProvided: values.pathwaysProvided,
                healthAndSafetyQualifications: values.healthAndSafetyQualifications,
                healthAndSafetyIncidences: values.healthAndSafetyIncidences,
                turnoverInLastFinancialYear: values.turnoverInLastFinancialYear,
                otherInsurances,
                publicLiabilityInsurance: values.publicLiabilityInsurance,
                disclosures: values.disclosures,
                professionalIndemnity: values.professionalIndemnity,
                regionBased: values.regionBased,
                adOption: values.adOption?.trim(),
            };

            try {
                await dispatch(createAccount(supplierDTO, ACCOUNT_TYPE.supplier));
            } catch (error) {
                // try to skip api error "User already belongs to an account"
                const message = error.message as string | undefined;
                if (!message?.toLowerCase().includes('already belongs to an account')) {
                    throw error;
                }
                dispatch(showWarning(message));
            }
            const account = await dispatch(getMyAccount());
            try {
                await dispatch(createBusinessContacts(account.id, values.businessContacts.map((b) => ({
                    ...b,
                    email: b.email?.trim()?.toLowerCase(),
                    account: account.id
                }))));
            } catch (error) {
                dispatch(showWarning('Failed to create contact, please update later in account settings'))
            }
            try {
                await dispatch(createBusinessReferences(account.id, values.businessReferences.map((b) => ({
                    ...b,
                    email: b.contactEmail?.trim()?.toLowerCase(),
                    account: account.id
                }))));
            } catch (error) {
                dispatch(showWarning('Failed to create contact, please update later in account settings'))
            }
            try {
                await dispatch(createBusinessOwners(account.id, values.owners.map((o) => ({
                    ...o,
                    account: account.id
                }))));
            } catch (error) {
                dispatch(showWarning('Failed to create contact, please update later in account settings'))
            }
            await dispatch(getMyUser());
            accountSubmittedDialogRef.current?.show();
            setSubmitting(false);
        } catch (err) {
            setSubmitting(false);
            dispatch(showError(err.message));
        }
    };

    if (showIntro) {
        return (
            <div className={styles.onboarding_container}>
                <OnboardingSidebar sidebarImage={SideBar}>
                    <h3>
                        You’re just a few clicks away from being connected to our Amotai Supplier and Member database
                    </h3>

                </OnboardingSidebar>
                <OnboardingContent>
                    <div className={styles.supplier_container}>
                        <h5>Kia ora</h5>
                        <p>
                            Thank you for taking the first step in registering as a Supplier with Amotai. <br /><br />
                            <span>Please complete all sections of the application to provide relevant details about your business and its indigenous ownership. </span>
                            <span>This allows us to then review the application against our Amotai criteria.</span> <br /><br />
                            To make this next step easier it is advised that you have all your business details available including: <br /><br />
                            <li>Legal and trading names.</li>
                            <li>NZBN number.</li>
                            <li>Ownership details &amp; the Iwi and/or Pasifika Affiliations of each respective owner(s).</li>
                            <li>Business profile, logo, social media and key contact personnel.</li>
                            <li>Relevant product/service categories (this is important for searchability purposes).</li>
                            <li>Finance, Legal, Insurance and Employment information (this is not displayed on the public section of our platform).</li>
                            <li>Health &amp; Safety, PreQuals and details of any recent project work, if applicable.</li>
                            <br /><br />
                            Please also be assured that some of the information you provide is held in the strictest confidence and is not displayed on our platform.<br /><br />
                            <span>The final step is to accept our Terms &amp; Conditions </span>
                            <span>which form part of becoming an Amotai registered supplier should your application be accepted. </span>
                            <span>These terms and conditions can be found at </span>
                            <a href='https://amotai.nz/terms-and-conditions' target='_blank' rel='noopener noreferrer'>here</a>
                            <span> and must be accepted before your application can be submitted. We recommend you try to complete </span>
                            the application in one session to avoid information being lost.<br /><br />
                            <span>Once your application has been successfully submitted, </span>
                            <span>you will receive an email acknowledging your application has been received.</span><br /><br />
                            <span>If you wish to seek help at any time during this critical step or are experiencing any technical issues </span>
                            please email <a href="mailto:kiaora@amotai.nz" target="_blank" rel="noopener noreferrer">kiaora@amotai.nz</a><br />
                            Ngā manaakitanga,<br />
                            The Amotai team<br />
                        </p>

                        <h6 className={styles.intro_sub}>What will I need?</h6>
                        <div className={styles.faqs}>
                            <Accordion className={styles.faq_content}>
                                <AccordionSummary
                                    className={styles.faq_question}
                                    expandIcon={<img src={Arrow} alt={""} />}
                                >
                                    <Typography className={styles.faq_question_text}>Your personal profile
                                        details</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={styles.faq_answer}>
                                    <div>
                                        <ul>
                                            <li>Name</li>
                                            <li>Job title</li>
                                            <li>Phone number</li>
                                            <li>Email address</li>
                                            <li>Password</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={styles.faq_content}>
                                <AccordionSummary
                                    className={styles.faq_question}
                                    expandIcon={<img src={Arrow} alt={""} />}
                                >
                                    <Typography className={styles.faq_question_text}>Your business details</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={styles.faq_answer}>
                                    <div>
                                        <ul>
                                            <li>Trading and legal business name</li>
                                            <li>Business structure</li>
                                            <li>Year established</li>
                                            <li>NZ Companies number</li>
                                            <li>NZBN (New Zealand business number)</li>
                                            <li>Primary industry</li>
                                            <li>Regions of operation</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={styles.faq_content}>
                                <AccordionSummary
                                    className={styles.faq_question}
                                    expandIcon={<img src={Arrow} alt={""} />}
                                >
                                    <Typography className={styles.faq_question_text}>Your business&apos; financial
                                        details</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={styles.faq_answer}>
                                    <div>
                                        <ul>
                                            <li>Annual turnover</li>
                                            <li>Professional Indemnity Insurance</li>
                                            <li>Public Liability Insurance</li>
                                            <li>Other insurances</li>
                                            <li>Disclosures</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={styles.faq_content}>
                                <AccordionSummary
                                    className={styles.faq_question}
                                    expandIcon={<img src={Arrow} alt={""} />}
                                >
                                    <Typography className={styles.faq_question_text}>Your business&apos; ownership
                                        details</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={styles.faq_answer}>
                                    <div>
                                        <ul>
                                            <li>Business owners</li>
                                            <li>Māori ownserhip</li>
                                            <li>Pasifika ownership</li>
                                            <li>Iwi affiliations</li>
                                            <li>Pasifika ethnic group affiliations</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={styles.faq_content}>
                                <AccordionSummary
                                    className={styles.faq_question}
                                    expandIcon={<img src={Arrow} alt={""} />}
                                >
                                    <Typography className={styles.faq_question_text}>Your business&apos; employment
                                        details</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={styles.faq_answer}>
                                    <div>
                                        <ul>
                                            <li>Number of FTE</li>
                                            <li>No. of Māori or Pasifika employees (optional)</li>
                                            <li>No. of non-male employees (optional)</li>
                                            <li>No. of employees under 24 (optional)</li>
                                            <li>Pathways provided for employees to upskill</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion className={styles.faq_content}>
                                <AccordionSummary
                                    className={styles.faq_question}
                                    expandIcon={<img src={Arrow} alt={""} />}
                                >
                                    <Typography className={styles.faq_question_text}>Your business&apos; Amotai
                                        profile</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={styles.faq_answer}>
                                    <div>
                                        <ul>
                                            <li>Business logo</li>
                                            <li>Business description</li>
                                            <li>Website</li>
                                            <li>Social media</li>
                                            <li>Business contacts</li>
                                        </ul>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div style={{ margin: "27px 0px", fontWeight: "bold" }}>Please note we cannot save any of your information until the very last page when you select submit</div>
                        <div className={styles.single_button_container}>
                            <Button onClick={() => setShowIntro(false)}>START MY APPLICATION</Button>
                        </div>
                    </div>
                </OnboardingContent>
            </div>
        );
    }

    const renderBreadcrumbs = (stepNumber: number, handleClick: (n: number) => void) => (
        <ul className={styles.breadcrumbs}>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 0,
                [styles.step_selected]: stepNumber === 0
            })}
                onClick={() => handleClick(0)}>Your profile
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 1,
                [styles.step_selected]: stepNumber === 1
            })}
                onClick={() => handleClick(1)}>About company
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 2,
                [styles.step_selected]: stepNumber === 2
            })}
                onClick={() => handleClick(2)}>Industry category
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 3,
                [styles.step_selected]: stepNumber === 3
            })}
                onClick={() => handleClick(3)}>Financial details
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 4,
                [styles.step_selected]: stepNumber === 4
            })}
                onClick={() => handleClick(4)}>Ownership details
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 5,
                [styles.step_selected]: stepNumber === 5
            })}
                onClick={() => handleClick(5)}>Employee details
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 6,
                [styles.step_selected]: stepNumber === 6
            })}
                onClick={() => handleClick(6)}>Company profile
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 7,
                [styles.step_selected]: stepNumber === 7
            })}
                onClick={() => handleClick(7)}>Primary contact details
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 8,
                [styles.step_selected]: stepNumber === 8
            })}
                onClick={() => handleClick(8)}>References
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 9,
                [styles.step_selected]: stepNumber === 9
            })}
                onClick={() => handleClick(9)}>Health and safety
            </li>
            <li className={classNames({
                [styles.step_completed]: stepNumber > 10,
                [styles.step_selected]: stepNumber === 10
            })}
                onClick={() => handleClick(10)}>Review
            </li>
        </ul>
    );

    return (
        <div className={styles.onboarding_container}>
            {loading ? <Loading /> : (
                <MultiStepForm initialValues={SupplierInitialValues(oldSupplier)}
                    buttonsWrapperClass={styles.action_buttons}
                    onboardingContainerClass={styles.supplier_container}
                    submitting={submitting}
                    onSubmit={handleSubmit}
                    steps={[
                        <Page1 validationSchema={DetailsValidationSchema} />,
                        <Page2 validationSchema={OrgDetailsValidationSchema} />,
                        <Page3 validationSchema={CategoryValidationSchema} />,
                        <Page4 validationSchema={OrgFinancialsValidationSchema} />,
                        <Page5 validationSchema={OrgOwnershipValidationSchema} />,
                        <Page6 validationSchema={OrgEmployeesValidationSchema} />,
                        <Page7 validationSchema={BusinessProfileValidationSchema} />,
                        <Page8 validationSchema={BusinessContactValidationSchema} />,
                        <Page9 validationSchema={BusinessReferenceValidationSchema} />,
                        <Page10 validationSchema={HealthAndSafetyValidationSchema} />,
                        <Page11 validationSchema={mergedSchemas} />
                    ]}
                    breadcrumbs={renderBreadcrumbs} />
            )}

            <AccountSubmittedDialog ref={accountSubmittedDialogRef} />
        </div>
    );
}
