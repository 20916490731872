import React from "react";
import styles from "./SideMenu.module.scss";

export default function SideMenu(props: any) {
    return (
        <div className={styles.menu_section}>
            <ul className={styles.menu}>
                {props.children}
            </ul>
        </div>
    );
}